import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation } from 'react-router-dom';
import { trackClickEvent } from '../../mixpanel';

const StyledButton = styled(Button)(({ theme, customstyles }) => ({
    ...customstyles,
}));

const CustomButton = ({
    children,
    customstyles = {},
    name,
    eventName='Button Clicked',
    component = null,
    onClick = null,
    ...rest
}) => {
    const location = useLocation();

    const handleClick = (ev) => {
        ev.preventDefault();
        trackClickEvent(
            eventName, 
          { buttonName: name, page: location.pathname, component }
        );
        onClick && onClick(ev);
    }
    return (
        <StyledButton
            customstyles={customstyles}
            {...rest}
            onClick={handleClick}
        >
            {children}
        </StyledButton>
    );
};

export default CustomButton;
