import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import MainLayout from './MainLayout'
import SideMenu from '../components/SideMenu'
import { profileNavMenu } from '../constants/NavMenus'
import paths from '../constants/paths'
import { useBasicInfo } from '../contexts/BasicInfo'
import { useGeneralProfile } from '../contexts/Organization'
import { useAuth } from '../contexts/Auth'
import { treatment_program } from '../constants/common'

const ProfileLayout = ({ children, menu, noWarning, hideNavButton, currentPage, currentPageTitle }) => {
    const { data } = useBasicInfo();
    const { facilityInfo } = useGeneralProfile();
    const { account_type } = useAuth();
    const info = account_type === treatment_program ? facilityInfo : data;
    return (
        <>
            <MainLayout
                currentPage={currentPage || 'Profile'}
                buttonProps={!hideNavButton && {
                    text: 'View Profile',
                    path: paths.userProfile(info?.uuid, account_type)
                }}
                noWarning={noWarning}
                currentPageTitle={currentPageTitle}
            >
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={2.5}>
                            <SideMenu
                                menu={menu || profileNavMenu}
                                withBackground
                            />
                        </Grid>
                        <Grid item xs={12} md={9.5}>
                            {children}
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        </>
    )
}

export default ProfileLayout