import React from 'react'
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import sentEmptyState from '../../assets/imgs/sent-empty-state.png'
import receivedEmptyState from '../../assets/imgs/received-empty-state.png'
import { colleagues, received, sent } from '../../constants/common';
import paths from '../../constants/paths';
const EmptyState = () => {
    const { id } = useParams();

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - 120px)',
                textAlign: 'center',
                backgroundColor: 'background.paper',
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'border.main'
            }}
        >
            <Box
                sx={{
                    maxWidth: 548
                }}
            >
                {id === sent && (
                    <CardMedia
                        image={sentEmptyState}
                        component='img'
                        sx={{
                            mb: 4,
                            width: 200,
                            height: 230,
                            maxWidth: '100%',
                            mx: 'auto'
                        }}
                    />
                )}

                {id === received && (
                    <CardMedia
                        image={receivedEmptyState}
                        component='img'
                        sx={{
                            mb: 3,
                            width: 220,
                            height: 160,
                            maxWidth: '100%',
                            mx: 'auto'
                        }}
                    />
                )}

                <Typography variant='h5' mb={3}>
                    {id === sent && 'No Referrals'}
                    {id === received && 'No Received Referrals Yet'}
                </Typography>
                <Typography variant='h5' color='text.secondary' maxWidth={328} mb={3}>
                    {id === sent && 'It\'s easy to send clients you can\'t take to your network. Start by adding your colleagues to your network'}
                    {id === received && 'It\'s easy to get started! Add colleagues to your network and let them know if you have availability to take clients'}
                </Typography>
                <Button
                    variant='contained'
                    size='large'
                    sx={{ minWidth: 328 }}
                    href={paths.network(colleagues)}
                >
                    Go to Network
                </Button>
            </Box>
        </Box>
        
    )
}

export default EmptyState