import { z } from 'zod';

export const firstNameValidation = z
  .string()
  .trim()
  .min(1, { message: 'Please add first name.' })
  .min(2, { message: 'First Name must be at least 2 characters long.' })
  .max(50, { message: 'First Name must be less than 50 characters long.' })
  .regex(/^[a-zA-Z-' ]+$/, { message: "First Name can only contain letters or special characters ‘ and -" })


export const lastNameValidation = z
  .string()
  .trim()
  .min(1, { message: 'Please add last name.' })
  .min(2, { message: 'Last Name must be at least 2 characters long.' })
  .max(50, { message: 'Last Name must be less than 50 characters long.' })
  .regex(/^[a-zA-Z-'. ]+$/, { message: "Last Name can only contain letters or special characters ‘ - and ." })

export const professionalDegreeValidation = z
  .number({
    required_error: "Please select a degree.",
    invalid_type_error: "Please select a degree.",
  });


export const requiredPhoneNumberValidation = z
  .string({ required_error: 'Please add your phone number.' })
  .min(1, { message: 'Please add your phone number.' })
  .regex(/^\d+$/, { message: 'Phone number can only contain digits.' })
  .refine((value) => value.length === 10 || value.length === 11, {
    message: 'Please enter a valid phone number (10 or 11 digits).',
  });

export const optionalPhoneNumberValidation = z
  .string()
  .regex(/^\d*$/, { message: 'Phone number can only contain digits.' })
  .nullable()
  .superRefine((value, ctx) => {
    if (value === null || value.length === 0) {
      return;
    }
    if (value.length !== 10 && value.length !== 11) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please enter a valid phone number (10 or 11 digits).',
      })
    }
  });


// const urlPattern = /^(https?:\/\/)?[\w.-]+(\.[a-z\d]+)+(:\d+)?([\/\w.-~]*)?(\?[\w&%=.,;+\[\]~-]*)?(#.*)?$/i;
const urlPattern = /^(https?:\/\/)?([a-z\d-]+\.)*[a-z\d-]+(\.[a-z]{2,})(:\d+)?(\/[a-z\d-./~]*)*(\?[\w&%=.,;+\[\]~-]*)?(#.*)?$/i;
export const websiteLinkValidation = z
  .string()
  .trim()
  .optional()
  .superRefine((value, ctx) => {
    if (!value) {
      return;
    } else if (value.length > 100) {
      ctx.addIssue({
        code: "custom",
        message: "URL must be less than 100 characters long.",
      });
    } else if (!urlPattern.test(value)) {
      ctx.addIssue({
        code: "custom",
        message: "Please enter a valid URL.",
      });
    }
  });

// .max(100, { message: 'Website Link must be less than 100 characters long.' })
// .url({ message: 'Please add a valid website URL.' })
// .or(z.literal(''))
// .regex(/^(https?:\/\/)?[\w.-]+(\.[a-z]+)+([\/\w.-]*)?(\?[\w&%=.-]*)?$/i, { message: 'Please add a valid URL.' });

export const streetAddressValidation = z
  .string()
  .trim()
  .max(100, { message: 'Street Address must be less than 100 characters long.' })
  .regex(/^[a-zA-Z0-9,.\- ]*$/, { message: 'Please enter a valid street address.' })
  .optional()
  .or(z.literal(''));

export const stateValidation = z
  .number()
  .min(1, { message: 'Please select a state.' })
  .max(9999, { message: 'State must be less than 10 digits long.' })
  .nullable() // Allow `null` as a valid value
  .refine((value) => value !== null, { message: 'Please select a state.' });

export const cityValidation = z
  .string()
  .trim()
  .min(1, { message: 'Please add a city.' })
  .max(20, { message: 'City must be less than 20 characters long.' })
  .regex(/^[a-zA-Z ]+$/, { message: 'City can only contain letters.' });

export const zipCodeValidation = z
  .string()
  .trim()
  .min(1, { message: 'Please add zip code.' })  // Ensures at least 1 character
  .superRefine((value, ctx) => {
    if (!/^\d*$/.test(value)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Zip code can only contain numbers.',
      });
    } else if (value.length !== 5) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Zip code must be 5 digits long.',
      });
    }
  });



export const currentPracticeSchema = z.object({
  current_status: z.enum([
    "accepting_new_clients",
    "not_accepting_clients",
    "somewhere_in_between",
  ], {
    errorMap: () => ({ message: "Please select any one option to continue." }),
  }),
});

const currentYear = new Date().getFullYear();

const professionalDegreeSchema = z
  .number({
    required_error: "Please select a degree.",
    invalid_type_error: "Please select a degree.",
  })

const yearGraduatedSchema = z
  .string({
    required_error: "Please add a year.",
  })
  .trim()
  .length(4, { message: "Please enter a valid year (YYYY)." })
  .refine((year) => /^[0-9]+$/.test(year), { message: "Please enter a valid year (YYYY)." })
  .refine((year) => parseInt(year) >= 1900 && parseInt(year) <= currentYear, {
    message: `Please enter a year between 1900 and ${currentYear}.`,
  });

const practiceYearSchema = z
  .string({
    required_error: "Please add a year.",
  })
  .trim()
  .length(4, { message: "Please enter a valid year (YYYY)." })
  .refine((year) => /^[0-9]+$/.test(year), { message: "Please enter a valid year (YYYY)." })
  .refine((year) => parseInt(year) >= 1900 && parseInt(year) <= currentYear, {
    message: `Please enter a year between 1900 and ${currentYear}.`,
  });

const schoolNameSchema = z
  .string({ required_error: "Please add a school name." })
  .trim()
  .min(1, { message: "Please add a school name." })
  .min(2, { message: "School name must be at least 2 characters long." })
  .max(50, { message: "School name must be less than 50 characters long." })
  .regex(/^[A-Za-z' -]+$/, {
    message: "School name can only contain letters or special characters ‘ and -",
  });

export const educationSchema = z
  .object({
    professional_degree_id: professionalDegreeSchema,
    degree_year: yearGraduatedSchema.optional(),
    practice_year: practiceYearSchema.optional(),
    college_name: schoolNameSchema,
  })

export const emailValidation = z
  .string()
  .trim()
  .min(1, { message: 'Please add email.' })
  .email('Please add a valid email.')
  .max(100, { message: 'Email must be less than 100 characters long.' });


async function checkImageDimensions(file, minWidth, minHeight, maxWidth, maxHeight) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = function () {
      const { width, height } = img;
      if (width >= minWidth && height >= minHeight && width <= maxWidth && height <= maxHeight) {
        resolve(true);
      } else {
        resolve(false);
      }
    };
    img.onerror = function () {
      resolve(false);
    };
    img.src = URL.createObjectURL(file);
  });
}

export const uploadLogoValidation = z
  .object({
    file: z
      .instanceof(File)
      .optional()
      .refine(
        (file) => !file || ["image/jpeg", "image/png", "image/jpg", "image/svg+xml"].includes(file.type),
        { message: "Please upload a valid image file (JPG, JPEG, PNG, SVG)." }
      )
      .refine(
        (file) => !file || file.size <= 5 * 1024 * 1024, // 5 MB size limit
        { message: "The logo must be less than 5 MB." }
      ),
  })
  .nullable()
  .refine(async (data) => {
    if (!data || !data.file) return true;

    const file = data.file;
    const isValidDimensions = await checkImageDimensions(file, 300, 300, 2000, 2000);
    return isValidDimensions;
  }, { message: "The logo dimensions must be between 300x300 and 2000x2000 pixels." });

export const sessionFeeRange = z.string()
  .nullable()
  .transform((val) => (val === null ? null : val.trim()))
  .superRefine((value, ctx) => {
    if (value === null || value.length === 0) {
      return;
    }

    if (value.length > 60) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Fee must be less than 60 characters long.",
      });
    }

    if (!/^[a-zA-Z0-9():\-$\s]+$/.test(value)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Fee can only contain letters, numbers, or special characters () : - and $.",
      });
    }
  });
