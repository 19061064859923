import { axiosInstance } from "./axiosInstance";

function inviteTeamMemeber(data) {
    return axiosInstance.put('teams/invite', data);
}

function checkedAssignUrl(data) {
    return axiosInstance.post('check-url', data);
}

function registerMember(data) {
    return axiosInstance.post('register-team-member', data);
}

function getTeams() {
    return axiosInstance.get('teams');
}

function deleteMember(params) {
    return axiosInstance.delete('teams/member', { params });
}

function revokeMemberInvitation(data) {
    return axiosInstance.put('teams/revoke', data);
}

function resendMemberInvitation(data) {
    return axiosInstance.put('teams/reinvite', data);
}

export {
    inviteTeamMemeber,
    checkedAssignUrl,
    getTeams,
    registerMember,
    deleteMember,
    revokeMemberInvitation,
    resendMemberInvitation
}