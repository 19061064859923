import { axiosInstance } from "./axiosInstance";

function getInsurances(params) {
    return axiosInstance.get('insurances', { params });
}

function updateInsurances(data) {
    return axiosInstance.post('insurances', data);
}

function getUserInsurances(params) {
    return axiosInstance.get('insurances/user', { params });
}

function getFees() {
    return axiosInstance.get('fees');
}

function updateFees(data) {
    return axiosInstance.post('fees', data);
}

export {
    getInsurances,
    updateInsurances,
    getUserInsurances,
    getFees,
    updateFees
}