import { axiosInstance } from "./axiosInstance";

function updateFacilityBasicInfo(data) {
    return axiosInstance.put('program-info/basic', data);
}

function updateFacilityContact(data) {
    return axiosInstance.put('program-info/contact', data);
}

function updateFacilityServices(data) {
    return axiosInstance.put('program-info/services', data);
}

function updateAboutMe(data) {
    return axiosInstance.put('program-info/about-me', data);
}

function updateFacilityPhoto(payload) {
    const data = new FormData();
    data.append('avatar', payload);
    return axiosInstance.post('program-info/avatar', data);
}

function removeFacilityPhoto() {
    return axiosInstance.post('program-info/destroy_avatar');
}

function getFacilityInfo(data) {
    return axiosInstance.get('program-info/full', data);
}

function updateFacilityFaqs(data) {
    return axiosInstance.put('program-info/faq', data);
}

function uploadFacilityImages(data) {
    const formData = new FormData();
    data?.forEach((image, index) => {
        formData.append(`images[${index}]`, image);
    });
    return axiosInstance.post('treatment-program-gallery', formData);
}

function removeFacilityImage(image_id) {
    return axiosInstance.delete('treatment-program-gallery', { params: { image_id } });
}

export {
    updateFacilityBasicInfo,
    updateFacilityContact,
    updateFacilityServices,
    getFacilityInfo,
    updateFacilityPhoto,
    updateFacilityFaqs,
    uploadFacilityImages,
    updateAboutMe,
    removeFacilityPhoto,
    removeFacilityImage,
}