import React from 'react'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as CloseIcon } from '../../assets/Icons/close.svg'

const ImageComponent = ({ image, index, handleDelete }) => {
    function handleRemove() {
        handleDelete(index)
    }
    const imageUrl = image && typeof image === 'object' ? URL.createObjectURL(image) : image;
    return (
        <Box
            sx={{
                position: 'relative'
            }}
        >
            <Avatar
                src={imageUrl}
                sx={{ width: '100%', height: 200, borderRadius: 2 }}
            />
            <IconButton
                sx={{
                    backgroundColor: 'error.light',
                    color: 'error.main',
                    position: 'absolute',
                    top: -15,
                    right: -15,
                    '&:hover': {
                        backgroundColor: 'error.light',
                    }
                }}
                onClick={handleRemove}
            >
                <CloseIcon />
            </IconButton>
        </Box>
    )
}

export default ImageComponent