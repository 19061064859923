import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ReactComponent as WatchIcon } from '../../../assets/Icons/x-watch.svg';
import Filter from './Filter';
import CheckBox from '../../../components/CheckBox';
import { addDaysValues, addWeekValues, allTimesValues, officeHours, weekDays } from '../../../constants/lists';

const AvailabilityFilter = ({ setFilters, filters , goBack ,reset}) => {
    const [expanded, setExpanded] = useState(false);


    useEffect(()=>{
        if(goBack && reset){
            setExpanded(true)
        }
    },[])
    function toggleExpand() {
        setExpanded((prevState) => !prevState)
    }

    function handleChange(ev) {
        setFilters((prevState) => {
            const arr = (prevState[ev.target.name] || [])
            const exists = Boolean(arr.find((item) => item === ev.target.value))
            return {
                ...prevState,
                [ev.target.name]: (exists ? 
                    arr.filter((item) => item !== ev.target.value)
                :
                    [...arr, ev.target.value])
            }
        })
    }

    function handleWeeklyWork(ev) {
        setFilters((prevState) => ({
            ...prevState,
            office_hours_day: ev.target.checked ? addWeekValues : []
        }))
    }

    function handleAllDaysWork(ev) {
        setFilters((prevState) => ({
            ...prevState,
            office_hours_day: ev.target.checked ? addDaysValues : []
        }))
    }

    function handleAllTimes(ev) {
        setFilters((prevState) => ({
            ...prevState,
            office_hours_time: ev.target.checked ? allTimesValues : []
        }))
    }
    
    return (
        <Filter
            label='Office Hours'
            icon={<WatchIcon />}
            isRigntIcon={true}
            goBack={goBack}
            reset={reset}
        >
            <Typography variant='body2' fontWeight={600}>Days</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                    maxHeight: expanded ? 'auto' : 90,
                    overflow: 'hidden',
                }}
            >
                <CheckBox
                    label='All Days'
                    onChange={handleAllDaysWork}
                    checked={(filters?.office_hours_day || []).length === 7}
                />

                <CheckBox
                    label='All weekdays'
                    onChange={handleWeeklyWork}
                    checked={(filters?.office_hours_day || []).length > 0 && (filters.office_hours_day || []).reduce((acc, item, _, arr) => {
                        if (arr.length < 5 || !['monday', 'tuesday', 'wednesday', 'thursday', 'friday'].includes(item))
                            acc = false
                        return acc;
                    }, true)}
                />
                {weekDays.map((item) => (
                    <CheckBox
                        key={item.value}
                        label={item.label}
                        onChange={(ev) => {
                            handleChange(ev, true)
                        }}
                        value={item.value}
                        name='office_hours_day'
                        checked={Boolean((filters?.office_hours_day || []).find((data) => data.toString() === item.value.toString()))}
                    />
                ))}
            </Box>
            
            <Button
                sx={{
                    p: 0,
                    justifyContent: 'flex-start',
                    backgroundColor: 'transparent', 
                    '&:hover': {
                        backgroundColor: 'transparent', 
                    }
                }}
                onClick={toggleExpand}
                disableRipple
            >
                {expanded ? 'See less' : 'See more'}
            </Button>

            <Typography variant='body2' fontWeight={600}>Working hours</Typography>
            <CheckBox
                label='All times'
                onChange={handleAllTimes}
                checked={(filters?.office_hours_time || []).length === 3}
            />
            {officeHours.map((item) => (
                <CheckBox
                    key={item.value}
                    label={item.label}
                    onChange={(ev) => {
                        handleChange(ev, true)
                    }}
                    value={item.value}
                    name='office_hours_time'
                    checked={Boolean((filters?.office_hours_time || []).find((data) => data.toString() === item.value.toString()))}
                />
            ))}
        </Filter>
    )
}

export default AvailabilityFilter