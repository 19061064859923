import React, { createContext, useState, useContext, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import paths from "../constants/paths";
import { useNavigate } from "react-router-dom";

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    message: null,
    type: null,
    isCustomError: false,
    autoHideDuration: 5000,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (alert.message && alert.autoHideDuration) {
      const timer = setTimeout(() => {
        setAlert({ message: null, type: null, isCustomError: false, autoHideDuration: 5000 });
      }, alert.autoHideDuration);
      return () => clearTimeout(timer);
    }
  }, [alert.message, alert.autoHideDuration]);

  const sendAlert = (message, type, isCustomError = false, autoHideDuration = 5000) => {
    setAlert({ message, type, isCustomError, autoHideDuration });
  };

  const handleClose = () => {
    setAlert({ message: null, type: null, isCustomError: false, autoHideDuration: 5000 });
  };

  const state = {
    ...alert,
    sendAlert,
  };

  return (
    <AlertContext.Provider value={state}>
      {alert.isCustomError && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={alert.isCustomError}
          message={
            <span>
              You need to change your password{" "}
              <span
                onClick={() => navigate(paths.settingsPassword)}
                style={{ cursor: "pointer", textDecoration: "underline" }}
              >
                change now
              </span>
            </span>
          }
          ContentProps={{
            variant: "error",
            sx: {
              textAlign: "center",
              backgroundColor: `${alert.type}.main`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
          action={
            alert.autoHideDuration === null && (
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            )
          }
        />
      )}
      {alert.message && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={!!alert.message}
          message={alert.message}
          ContentProps={{
            variant: "error",
            sx: {
              textAlign: "center",
              backgroundColor: `${alert.type}.main`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
          action={
            alert.autoHideDuration === null && (
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            )
          }
        />
      )}
      {children}
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);
