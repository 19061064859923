import React, { createContext, useContext, useState } from 'react';
import { updateAccount, updatePassword } from '../services/settings.services';
import { ERROR, LOADING, SUCCESS } from '../constants/common';
import { useAlert } from './Alert';


const SettingsContext = createContext({});

export const SettingsContextProvider = ({ children }) => {
    const { sendAlert } = useAlert();
  
    const [Settings, setSettings] = useState({
        updateAccountStatus: '',
        updateEditStatus: ''
    });

    function handleSettingsState(obj) {
        setSettings((prevState) => ({
            ...prevState,
            ...obj
        }))
    }

    async function updateAccountSettings(data) {
        handleSettingsState({ updateAccountStatus: LOADING })
      try {
        const response = await updateAccount(data);
        handleSettingsState({
            updateAccountStatus: SUCCESS
        })
        sendAlert('Updated successfully', 'success')
        return response;
      } catch (error) {
        handleSettingsState({ updateAccountStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }

    async function editPassword(data) {
        handleSettingsState({ updateEditStatus: LOADING })
      try {
        const response = await updatePassword(data);
        handleSettingsState({
            updateEditStatus: SUCCESS
        })
        sendAlert('Updated successfully', 'success')
        return response;
      } catch (error) {
        handleSettingsState({ updateEditStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }

    const state = {
        ...Settings,
        updateAccountSettings,
        editPassword,
        handleSettingsState
    };

    return (
        <SettingsContext.Provider value={state}>
        {children}
        </SettingsContext.Provider>
    );
};

export const useSettings = () => useContext(SettingsContext);