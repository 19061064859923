import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { RadioGroup, Radio, FormControlLabel } from "@mui/material";
import CheckBox from "../CheckBox";
import { ReactComponent as WatchIcon } from "../../assets/Icons/x-watch.svg";
import {
  addDaysValues,
  addWeekValues,
  allTimesValues,
  officeHours,
  weekDays,
} from "../../constants/lists";
import "./style.css";

const Availability = ({ widthIcon, sx, setData, data, error, onSave, handleAvailability, setError}) => {
  const handleWeeklyWork = (ev) => {
    if (setData) {
      if (ev.target.checked) {
        // setError('');
        setData({
          office_hours_day: addWeekValues,
        });
      } else {
        setData({
          office_hours_day: [],
        });
      }
    }
  }

  const handleAllDaysWork = (ev) => {
    if (setData) {
      if (ev.target.checked) {
        setData({
          office_hours_day: addDaysValues,
        });
      } else {
        setData({
          office_hours_day: [],
        });
      }
    }
  }

  const handleAllDay = (ev) => {
    if (setData) {
      if (ev.target.checked) {
        // setError('');
        setData({
          office_hours_time: allTimesValues,
        });
      } else {
        setData({
          office_hours_time: [],
        });
      }
    }
  }


  useEffect(() => {
    if ((data?.office_hours_day == null) || (data?.office_hours_day?.length === 0)) {
      // setData({
      //   office_hours_time: [],
      // });
    }
  }, [data?.office_hours_day])
  useEffect(() => {
    if (((data?.office_hours_day == null) || (data?.office_hours_day?.length === 0)) && data?.office_hours_time?.length > 0) {
      // setData({
      //   office_hours_day: addWeekValues,
      // });
    }
  }, [data?.office_hours_time])


  return (
    <div className="office_hours">
      <Paper sx={sx}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {widthIcon && <WatchIcon width={24} height={24} />}
          <Typography variant="h4" ml={widthIcon ? 1 : 0}>
            Office Hours
          </Typography>
        </Box>
        <Typography variant="h5">Days</Typography>

        <div className="flexDays-onbord">
          {weekDays.map(day => (
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              size="large"
              active={data?.office_hours_day?.includes(day.value).toString()}
              sx={{
                borderRadius: 15,
                minWidth: 110,
                fontWeight: 600,
                color: "text.primary",
              }}
              value={day.value}
              onClick={handleAvailability}
              name="office_hours_day"
              disableRipple
            >
              {day.label}
            </Button>
          ))}
        </div>

        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" row>
          <FormControlLabel
            label="All days"
            onChange={handleAllDaysWork}
            checked={data?.office_hours_day?.length === 7}
            value="all-days"
            control={<Radio />}
          />
          <FormControlLabel
            value="all-weeks"
            control={<Radio />}
            label="All weekdays"
            onChange={handleWeeklyWork}
            checked={
              data?.office_hours_day?.length > 0 &&
              data?.office_hours_day?.reduce((acc, item, _, arr) => {
                if (
                  arr.length < 5 ||
                  ![
                    "monday",
                    "tuesday",
                    "wednesday",
                    "thursday",
                    "friday",
                  ].includes(item)
                )
                  acc = false;
                return acc;
              }, true)
            }
          />
        </RadioGroup>

        <Typography variant="h5">Working hours</Typography>
        <div className="flexHours">
          {officeHours.map(time => (
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              active={data?.office_hours_time?.includes(time.value).toString()}
              fullWidth
              sx={{
                borderRadius: 15,
                minWidth: 110,
                fontWeight: 600,
                color: "text.primary",
              }}
              onClick={handleAvailability}
              value={time.value}
              name="office_hours_time"
              disableRipple
            >
              {time.label}
            </Button>
          ))}
        </div>
        <div className="all_times">
          <CheckBox
            label="All times "
            onChange={handleAllDay}
            checked={data?.office_hours_time?.length === 3}
          />
        </div>
        
        {error && (!data?.office_hours_day.length || !data?.office_hours_time.length) && (
          <Typography variant="caption" color="error">
            Please select at least one day and time option.
          </Typography>
        )}
        {!!onSave && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              sx={{
                minWidth: 150,
              }}
              onClick={onSave}
            >
              Save
            </Button>
          </Box>
        )}
      </Paper>
    </div>
  );
};

export default Availability;
