import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { ReactComponent as ChevronDown } from "../../../assets/Icons/chevron-down.svg";
import { Box } from "@mui/material";
import { Controller } from "react-hook-form";

const EducationForm = ({
  degreesList,
  onChange,
  control,
  degree,
  errors,
  onDelete,
  showDeleteButton,
  index,
}) => {

  return (
    <Grid container spacing={3} mt={2}>
      <Grid item xs={12}>
        <Controller
          name={`degrees.${index}.professional_degree_id`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              variant="outlined"
              label="Professional Degree"
              fullWidth
              SelectProps={{
                IconComponent: () => (
                  <Box
                    sx={{
                      minWidth: 24,
                      width: 24,
                      height: 24,
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mr: 2,
                    }}
                  >
                    <ChevronDown width={12} height={6} />
                  </Box>
                ),
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 220,
                      width: 250,
                    },
                  },
                },
              }}
              onChange={onChange}
              value={degree?.professional_degree_id || ""}
              error={!!errors?.degrees?.[index]?.professional_degree_id}
              helperText={
                errors?.degrees?.[index]?.professional_degree_id?.message
              }
            >
              <MenuItem value={0} disabled>
                Select a degree
              </MenuItem>
              {degreesList?.map((degree) => (
                <MenuItem key={degree.id} value={degree.id}>
                  {degree.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <Controller
          name={`degrees.${index}.degree_year`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              label="Year graduated (YYYY)"
              onChange={onChange}
              fullWidth
              error={!!errors?.degrees?.[index]?.degree_year}
              helperText={errors?.degrees?.[index]?.degree_year?.message}
            />
          )}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Controller
          name={`degrees.${index}.practice_year`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              label="Practice Year (YYYY)"
              onChange={onChange}
              fullWidth
              error={!!errors?.degrees?.[index]?.practice_year}
              helperText={errors?.degrees?.[index]?.practice_year?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={`degrees.${index}.college_name`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              label="School Name"
              onChange={onChange}
              fullWidth
              error={!!errors?.degrees?.[index]?.college_name}
              helperText={errors?.degrees?.[index]?.college_name?.message}
            />
          )}
        />
      </Grid>
      {showDeleteButton && (
        <Grid item xs={12}>
          <Button variant="outlined" color="error" onClick={() => onDelete()}>
            Delete
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default EducationForm;
