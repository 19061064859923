import React from 'react';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

const CardLoader = () => {
    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 2,
                px: 2,
                pt: 3.5,
                pb: 2,
                minWidth: 198
            }}
        >
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
        </Paper>
    )
}

export default CardLoader