import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckBox from '../CheckBox';
import { useDemographics } from '../../contexts/Demographics';
import { LOADING, SUCCESS } from '../../constants/common';

const RaceDrawer = ({ onClose, open }) => {
  const [races, setRaces] = useState([]);
  const {
    racesList,
    fetchRacesStatus,
    fetchRacesList,
    editRaces,
    updateRacesStatus,
    handleState,
    fetchDemographics
  } = useDemographics();

  function handleClose() {
    setRaces([]);
    onClose();
  }

  function handleInput(ev) {
    setRaces((prevState) => {
      const exists = !!prevState.find((item) => item === ev.target.value);
      if (exists) {
        return prevState.filter((item) => item !== ev.target.value)
      }
      return [
        ...prevState,
        ev.target.value
      ]
    });
  }

  function handleSave() {
    editRaces({ races })
  }

  useEffect(() => {
    if (open)
      fetchRacesList(true);
  }, [open])


  useEffect(() => {
    if (updateRacesStatus === SUCCESS) {
      handleState({ updateRacesStatus: '' });
      fetchDemographics(true);
      handleClose();
    }
  }, [updateRacesStatus])

  useEffect(() => {
    setRaces(racesList.reduce((acc, item) => {
      if (item.selected) {
        return [...acc, item.id.toString()]
      }
      return acc;
    }, []))


  }, [racesList, open])

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Provider Demographics</Typography>
          <Typography variant='body1' mb={1}>Please select your race.</Typography>
          <Divider sx={{ mb: 4 }} />
          <Grid container spacing={2.5}>
            {fetchRacesStatus === LOADING ? (
              <>
                {[...Array(6)].map((_, index) => (
                  <Grid item xs={6} key={index}>
                    <Skeleton animation="wave" />
                  </Grid>
                ))}
              </>
            ) : (
              racesList.map((item) => (
                <Grid item xs={6} key={item.id}>
                  <CheckBox
                    label={item.name}
                    value={item.id}
                    onChange={handleInput}
                    checked={!!races.find((value) => +value === item.id)}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Box>

        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            onClick={handleSave}
            loading={updateRacesStatus === LOADING}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default RaceDrawer;