/* eslint-disable quote-props */
import React, { forwardRef } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Link as RouterLink, BrowserRouter } from "react-router-dom";
import { StaticRouter } from "react-router-dom/server";

const LinkBehavior = forwardRef((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return (
    <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
  );
});

const Router = (props) => {
  const { children } = props;
  if (typeof window === "undefined")
    return <StaticRouter location="/">{children}</StaticRouter>;

  return <BrowserRouter>{children}</BrowserRouter>;
};

const CustomThemeProvider = ({ children }) => {
  let theme = createTheme({
    typography: {
      fontSize: 14,
      htmlFontSize: 18.3,
      fontFamily: [
        "Helvetica Neue",
        "sans-serif",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        '"Nunito"',
        "Arial",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      h1: {
        fontSize: "38px",
        fontWeight: "normal",
        lineHeight: "46px",
      },
      h2: {
        fontSize: "30px",
        fontWeight: "bold",
        lineHeight: "38px",
      },
      h3: {
        fontSize: "24px",
        fontWeight: "bold",
        lineHeight: "32px",
      },
      h4: {
        fontSize: "20px",
        fontWeight: "bold",
        lineHeight: "28px",
      },
      h5: {
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "22px",
      },
      h6: {
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "22px",
      },
      body1: {
        fontSize: "15px",
        lineHeight: "22px",
      },
      body1XL: {
        fontSize: "18px",
        lineHeight: "30px",
      },
      body2: {
        fontSize: "14px",
        lineHeight: "22px",
      },
      caption: {
        fontSize: "12px",
        lineHeight: "135%",
      },
      captionTitle: {
        fontSize: "10px",
        lineHeight: "18px",
      },
    },
    palette: {
      primary: {
        light: "#E5F4FF",
        main: "#0C8CE9",
        dark: "#0e80d3",
        contrastText: "#fff",
      },
      secondary: {
        main: "#A3A6A8",
        light: "#A3A6A8",
        contrastText: "#000000",
      },
      warning: {
        main: "#F57953",
        contrastText: "#fff",
        light: "#F57953",
      },
      success: {
        light: "#44bc7b33",
        main: "#19CB6F",
        contrastText: "#fff",
      },
      error: {
        main: "#D85442",
        light: "#FEF2EE",
        contrastText: "#fff",
      },
      info: {
        main: "#4D9AE4",
        light: "#29D2FC",
        contrastText: "#fff",
      },
      disabled: "#A0ABB3",
      background: {
        paper: "#fff",
        default: "#FAFAFA",
        light: "#E7F4FD",
      },
      text: {
        primary: "#000000",
        secondary: "#61676B",
        disabled: "#A0AEC0",
      },
      border: {
        main: "#F1F2F3",
        dark: "#949A9E",
      },
      grey: {
        0: "#ffffff",
        100: "#F5F5FA",
        200: "#FAFAFA",
        300: "#F7FAFC",
        500: "#EDF2F7",
        800: "#E2E8F0",
        900: "#EFEFF4",
        1000: "#CBD5E0",
        1200: "#A0AEC0",
        1400: "#718096",
        1500: "#707070",
      },
    },
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        },
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
    },
  });

  theme = createTheme(theme, {
    breakpoints: {
      values: {
        ...theme.breakpoints.values,
        xs: 500,
        sm: 748,
        md: 992,
        lg: 1248,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 6,
            fontSize: theme.typography.fontSize,
            paddingLeft: 16,
            paddingRight: 16,
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              boxShadow: "none",
            },
          },
          sizeLarge: {
            paddingTop: 12,
            paddingBottom: 12,
          },
          sizeMedium: {
            paddingTop: 8,
            paddingBottom: 8,
          },
          sizeSmall: {
            paddingTop: 4,
            paddingBottom: 4,
          },
          outlined: {
            '&[active="true"]': {
              backgroundColor: "#F3F9FD",
              borderColor: theme.palette.primary.main,
              boxShadow: "0px 0px 0px 2px rgba(12, 140, 233, 0.08)",
            },
          },
          ".Mui-disabled": {
            backgroundColor: theme.palette.disabled,
            color: theme.palette.background.paper,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: "none",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            border: "1px solid #F5F6F7",
            boxShadow: "0px 0px 128px rgba(0, 0, 0, 0.02)",
            borderRadius: 6,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: theme.palette.border.main,
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: 46,
            backgroundColor: theme.palette.background.light,
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            "& *": {
              boxSizing: "border-box",
            },
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            "& .MuiPaper-root:not(.MuiAlert-root)": {
              borderRadius: 0,
              border: 0,
              boxShadow: "0px 0px 64px rgba(0, 0, 0, 0.04)",
              width: 647,
            },
            "& *": {
              boxSizing: "border-box",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: theme.palette.secondary.main,
            borderRadius: 6,
          },
          input: {
            "&:not(textarea)": {
              padding: "21.5px 16px",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            "&::placeholder": {
              color: "#8C959C",
              opacity: 1,
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            "&:after, &:before": {
              display: "none",
            },
            borderRadius: 6,
            backgroundColor: "#F2F5F7",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          outlined: {
            fontSize: "14px",
            lineHeight: "22px",
            "&[data-shrink=false]": {
              transform: "translate(16px, 21px) scale(1)",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
            lineHeight: "120%",
            marginTop: 4,
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          color: "text.primary",
          "& *": {
            fontFamily: [
              "Helvetica Neue",
              "sans-serif",
              "BlinkMacSystemFont",
              '"Segoe UI"',
              '"Nunito"',
              "Arial",
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
            ].join(","),
            boxSizing: "border-box",
          },
          height: "100%",
        }}
      >
        <Router>{children}</Router>
      </Box>
    </ThemeProvider>
  );
};

export default CustomThemeProvider;
