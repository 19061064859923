import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import SideMenuInernal from "../../components/SideMenuInernal";
import { notificationNavMenu } from "../../constants/NavMenus";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { ReactComponent as CogIcon } from "../../assets/Icons/cog.svg";
import { ReactComponent as CorrectIcon } from "../../assets/Icons/correct.svg";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Button from "@mui/material/Button";
import {
  updateNotificationReadStatus,
  getNotificationList,
  updateSingleNotificationReadStatus
} from "../../services/home.services";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import paths from "../../constants/paths";
import { useAlert } from "../../contexts/Alert";
import MainLayout from "../../layouts/MainLayout";
import { useNotifcations } from "../../contexts/Notifications";

const Notifcations = () => {
  const [currentTab, setCurrentTab] = useState("All");
  const [readFilter, setReadFilter] = useState("All");
  const [link, setLink] = useState(undefined);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { sendAlert } = useAlert();
  const {
    updateNotificationData,
    notificationsList: notificationsListData,
    updateNotificationUser
  } = useNotifcations();

  const getNotificationHandler = () => {
    const data = {};
    if (currentTab !== "All") data["type"] = currentTab;
    if (readFilter !== "All") data["not_seen"] = true;

    getNotificationList(data)
      .then((res) => {
        updateNotificationData(res.data.data, res.data.unseen_count);
      })
      .catch((error) => {
        sendAlert(error.response?.data?.message, "error");
      });
  }

  useEffect(() => {
   getNotificationHandler()
  }, [currentTab, readFilter]);

  useEffect(() => {
    if(link){
      navigate(link);
    }
   }, [link]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnMarkAllClick = () => {
    updateNotificationReadStatus()
      .then((res) => {
        getNotificationHandler()
        sendAlert(res.data.data.message, "success");
      })
      .catch((error) => {
        sendAlert(error.response?.data?.message, "error");
      });
  };

  const handleOnNotificationClick = (id,link,user) => {
    updateNotificationUser(user)
    updateSingleNotificationReadStatus(id)
      .then((res) => {
        getNotificationHandler()
        // sendAlert(res.data.data.message, "success");
        setLink(link)
      })
      .catch((error) => {
        sendAlert(error.response?.data?.message, "error");
        setLink(link)
      });
  };


  
  const handleDayCreation = (createdAt) => {
    const creationDate = moment(createdAt);
    const currentDate = moment();
    const daysDifference = currentDate.diff(creationDate, "days");
    return `${
      daysDifference === 0
        ? "Today"
        : daysDifference === 1
        ? "1 day "
        : `${daysDifference} days`
    }`;
  };

  const openNavigationSetting = (createdAt) => {
    navigate(paths.settingsNotifications);
  };

  return (
    <MainLayout currentPage="Notifications">
      <Container>
        <Grid item xs={12} md={12}>
          {/* <Paper
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            my: 2,
            p: 2,
          }}
        >
          <Typography variant="h4" sx={{ width: "80%" }}>
            Notification
          </Typography>
          <Link
            href={paths.settingsNotifications}
            variant="body1"
            color="primary"
          >
            Notification settings
          </Link>
        </Paper> */}
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2.4}>
            <SideMenuInernal
              menu={notificationNavMenu}
              withBackground
              currentTab={currentTab}
              onClick={(item) => setCurrentTab(item?.type)}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                component="form"
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#FFFFFF",
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                }}
              >
                <Box style={{ display: "flex", alignItems: "center" }}>
                  {["All", "Unread"].map((item, index) => {
                    return (
                      <Chip
                        sx={{ marginLeft: 1 }}
                        label={item}
                        color={readFilter === item ? "primary" : undefined}
                        variant={!readFilter === item ? "outlined" : undefined}
                        onClick={() => setReadFilter(item)}
                      />
                    );
                  })}
                </Box>
                <Box>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    sx={{ fontSize: "18px", color: "black" }}
                  >
                    ...
                  </Button>
                </Box>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuList>
                    <MenuItem onClick={handleOnMarkAllClick}>
                      <ListItemIcon>
                        <CorrectIcon />
                      </ListItemIcon>
                      <ListItemText>Mark all as read</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={openNavigationSetting}>
                      <ListItemIcon>
                        <CogIcon />
                      </ListItemIcon>
                      <ListItemText>Notification settings</ListItemText>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
              <Divider />
              <Box>
                {notificationsListData?.length ?
                  notificationsListData?.map((item, index) => {
                      return (
                        <>
                          <Box
                            className="notification-row"
                            key={index}
                            sx={{
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              justifyContent: "space-around",
                              alignItems: "center",
                              px: 3,
                              py: 1,
                            }}
                            onClick={() => {
                              const userData={...item?.user,user_profile_image:item?.user?.main_image}
                              handleOnNotificationClick(item?.id,item?.link,userData)
                            }}
                          >
                            <Avatar
                              alt="user Image"
                              src={item?.user?.main_image}
                              align="center"
                              style={avatarStyle}
                            />
                            <Typography
                              variant="body1"
                              sx={{ width: "80%", fontSize: 14 }}
                            >
                              <span style={{fontWeight:'bold'}}>{item?.user?.first_name} {item?.user?.last_name}</span>, {item?.message}
                              <span
                                style={{ color: "#949A9E", fontSize: "14px" }}
                              >
                                {" "}
                                . {handleDayCreation(item?.created_at)}
                              </span>
                            </Typography>
                            {!item?.seen ? (
                              <div
                                style={{
                                  ...readFlagStyle,
                                  backgroundColor: "#0C8CE9",
                                }}
                              ></div>
                            ) : (
                              <div style={readFlagStyle}></div>
                            )}
                          </Box>
                          <Divider />
                        </>
                      );
                  }):null}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </MainLayout>
  );
};

const avatarStyle = {
  width: "50px",
  height: "50px",
  borderRadius: "50%",
};

const readFlagStyle = {
  width: "12px",
  height: "12px",
  borderRadius: "50%",
};

export default Notifcations;
