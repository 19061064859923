import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import LoadingButton from '@mui/lab/LoadingButton';
import { useFees } from '../../contexts/Fees';
import { LOADING, SUCCESS } from '../../constants/common';
import { ReactComponent as DollarSign } from '../../assets/Icons/dollar-sign.svg'
import { useBasicInfo } from '../../contexts/BasicInfo';
import { useLocation } from 'react-router-dom';
import paths from '../../constants/paths';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { sessionFeeSchema } from '../../validations/profileValidations';
import { debouncedTrigger } from '../../utils/helper';

const FeesDrawer = ({ onClose, open }) => {
  const { fetchFees, fees, editFees, updateFeesStatus, updateFeesState } = useFees();
  const { fetchInfo, data: info } = useBasicInfo();
  const [state, setstate] = useState({});
  const location = useLocation();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
    reset,
    setValue,
  } = useForm({
    resolver: zodResolver(sessionFeeSchema),
    mode: "onChange",
  });

  const formValues = useWatch({ control });

  useEffect(() => {
    setstate(formValues);
  }, [formValues]);


  useEffect(() => {
    fees?.map(item => {
      setValue(item.name, item.fee);
    })
  }, [fees, open])

  useEffect(() => {
    if (updateFeesStatus === SUCCESS) {
      updateFeesState('updateFeesStatus', '');
      fetchFees();
      if (info?.fees?.length < 1)
        fetchInfo();
      handleClose();
    }
  }, [updateFeesStatus])

  useEffect(() => {
    if (open && location.pathname === paths.home)
      fetchFees();
  }, [open, location])

  const handleClose = () => {
    reset();
    onClose();
  }

  const handleInput = (ev) => {
    const { name, value } = ev.target;
    setValue(name, value);
    debouncedTrigger(name, trigger);
  }

  const onSubmit = () => {
    if (isValid)
      editFees({ fees: [state] })
  }

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
        onSubmit={handleSubmit(onSubmit)}
        component='form'
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Session Fees:</Typography>
          <Typography variant='body1' mb={1}>Please indicate your session fees.</Typography>

          <Box mb={3}>
            {Object.keys(state).length && Object.keys(state)?.map((item) => (
              <Box key={item}>
                <Controller
                  name={item}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label={item}
                      sx={{
                        mb: 1,
                        '.MuiOutlinedInput-input:not(textarea)': {
                          pl: 0
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DollarSign />
                          </InputAdornment>
                        )
                      }}
                      onChange={handleInput}
                      value={state[item] || ''}
                      fullWidth
                      error={!!errors?.[item]}
                      helperText={errors?.[item]?.message}
                    />
                  )}
                />
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            loading={updateFeesStatus === LOADING}
            type='submit'
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default FeesDrawer;