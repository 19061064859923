import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { ReactComponent as ChevronDown } from "../../assets/Icons/chevron-down.svg";
import { LOADING, SUCCESS } from "../../constants/common";
import { useBasicInfo } from "../../contexts/BasicInfo";
import { useCommon } from "../../contexts/common";
import { useDegrees } from "../../contexts/Degrees";
import { useOnboarding } from "../../contexts/onboarding";
import { educationSchema } from "../../validations/validations";

const DegreeDrawer = ({ onClose, open, data, isLocalEditing }) => {
  const { degreesList, fetchDegreesList } = useCommon();
  const {
    fetchUserDegrees,
    createUserDegree,
    editUserDegree,
    updateDegreeState,
    updateStatus,
    createStatus,
    openConfirmationModal,
    deleteStatus,
  } = useDegrees();
  const { fetchInfo, data: info } = useBasicInfo();
  const { setOnboardingState } = useOnboarding();

  const isEdit = Boolean(data);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    watch,
    setError,
    clearErrors,
    trigger,
  } = useForm({
    resolver: zodResolver(educationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      professional_degree_id: null,
      degree_year: "",
      college_name: "",
      practice_year: "",
    },
  });

  const degreeYear = watch("degree_year");
  const practiceYear = watch("practice_year");

  useEffect(() => {
    if (open) {
      fetchDegreesList();
      if (isEdit) {
        reset({
          professional_degree_id: parseInt(data?.professional_degree_id) || null,
          degree_year: data?.degree_year || "",
          college_name: data?.college_name || "",
          practice_year: data?.practice_year || "",
        });
      }
    }
  }, [open, isEdit, data, reset]);

  useEffect(() => {
    const validateYears = async () => {
      const degreeYearInt = parseInt(degreeYear);
      const practiceYearInt = parseInt(practiceYear);

      
      if (!isNaN(degreeYearInt) && !isNaN(practiceYearInt)) {
        clearErrors("degree_year");
        clearErrors("practice_year");
  
        const isValidDegreeYear = degreeYearInt && await trigger("degree_year");
        const isValidPracticeYear = practiceYearInt && await trigger("practice_year");
        
        if (degreeYearInt > practiceYearInt) {
          if (isValidDegreeYear) {
            setError("degree_year", {
              type: "custom",
              message: "Graduation year must be less than or equal to the practice year.",
            });
          }
          if (isValidPracticeYear) {
            setError("practice_year", {
              type: "custom",
              message: "Practice year must be greater than or equal to the graduation year.",
            });
          }
        }
      }
    };

    if (degreeYear || practiceYear) {
      validateYears();
    }
  }, [degreeYear, practiceYear, clearErrors, setError, trigger, errors]);

  useEffect(() => {
    if ([deleteStatus, updateStatus, createStatus].includes(SUCCESS)) {
      handleClose();
      if (createStatus === SUCCESS && info?.degrees?.length < 1) {
        fetchInfo();
      }
      fetchUserDegrees();
      updateDegreeState("createStatus", "");
      updateDegreeState("updateStatus", "");
    }
  }, [deleteStatus, updateStatus, createStatus, fetchInfo, fetchUserDegrees, info, updateDegreeState]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleInput = (ev) => {
    setValue(ev.target.name, ev.target.value);
    trigger(ev.target.name);
  };

  const onSubmit = (formData) => {
    if (isValid) {
      const payload = { ...formData, id: data?.id };

      if (isLocalEditing) {
        setOnboardingState((prevState) => ({
          ...prevState,
          degrees: isEdit
            ? prevState.degrees.map((item, index) =>
              index === data.index ? formData : item
            )
            : [...prevState.degrees, formData],
        }));
        handleClose();
      } else {
        isEdit ? editUserDegree(payload) : createUserDegree(payload);
      }
    }
  };

  const renderTextField = (name, label) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          variant="outlined"
          label={label}
          fullWidth
          onChange={handleInput}
          error={!!errors[name]}
          helperText={errors[name]?.message}
        />
      )}
    />
  );

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          pt: 0,
        }}
      >
        <Box pb={3} px={3}>
          <Typography variant="h4" mb={1}>
            Education
          </Typography>
          <Typography variant="body1" mb={1}>
            Where did you go to school, and when did you start practicing?
          </Typography>
          <Divider sx={{ mb: 4 }} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="professional_degree_id"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    variant="outlined"
                    label="Professional Degree"
                    fullWidth
                    SelectProps={{
                      IconComponent: ChevronDown,
                      MenuProps: {
                        PaperProps: { style: { maxHeight: 220, width: 250 } },
                      },
                    }}
                    onChange={handleInput}
                    error={!!errors.professional_degree_id}
                    helperText={errors.professional_degree_id?.message}
                  >
                    <MenuItem value={0} disabled>
                      Select a degree
                    </MenuItem>
                    {degreesList?.map((degree) => (
                      <MenuItem key={degree.id} value={degree.id}>
                        {degree.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>{renderTextField("degree_year", "Year graduated (YYYY)")}</Grid>
            <Grid item xs={12}>{renderTextField("practice_year", "Year Began Practicing (YYYY)")}</Grid>
            <Grid item xs={12}>{renderTextField("college_name", "School Name")}</Grid>
            {isEdit && (
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Button
                  onClick={openConfirmationModal}
                  size="small"
                  color="error"
                  sx={{
                    minWidth: 120,
                    backgroundColor: "error.light",
                    color: "error.main",
                  }}
                >
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box
          sx={{
            boxShadow: "0px 0px 32px rgba(0, 0, 0, 0.08)",
            px: 1,
            pt: 3,
            pb: 5.625,
            display: "flex",
            alignItems: "center",
            gap: 1,
            position: "sticky",
            width: "100%",
            backgroundColor: "background.paper",
            bottom: 0,
            zIndex: 9,
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            sx={{ color: "primary.main" }}
            size="large"
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            size="large"
            fullWidth
            loading={[createStatus, updateStatus].includes(LOADING)}
            onClick={handleSubmit(onSubmit)}
          >
            {isEdit ? "Save" : "Create"}
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DegreeDrawer;
