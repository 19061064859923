import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Portal from '@mui/material/Portal'
import { ReactComponent as CloseIcon } from '../../../../assets/Icons/close.svg'
import { ReactComponent as ChevronRightIcon } from '../../../../assets/Icons/chevron-right.svg'

const GallerySlider = ({ elements, onClose, activeIndex }) => {
    const [index, setIndex] = useState(0);

    const element = elements[index] || {};
    
    useEffect(() => {
        setIndex(activeIndex);
    }, [activeIndex])

    return (
        <Portal>
            <Box sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(00, 00, 00, 0.8)',
                zIndex: 9999
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} color='border'>
                        <CloseIcon />
                    </Button>
                </Box>

                <Box sx={{ position: 'absolute', top: 30, left: 0, width: '100%', height: 'calc(100% - 60px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box
                        component="img"
                        src={element?.image_url}
                        sx={{maxHeight: '80%', maxWidth: '80%', width: 'auto', height: 'auto' }}
                    />
                </Box>
                {elements.length > 1 && (
                    <>
                        <Button sx={{ position: 'absolute', top: '50%', left: 20, transform: 'translateY(-50%)' }} onClick={() => setIndex(index - 1)} disabled={index <= 0} color='border'>
                            <Box
                                component='span'
                                sx={{
                                    transform: 'rotate(180deg)',
                                    display: 'flex'
                                }}
                            >
                                <ChevronRightIcon />
                            </Box>
                        </Button>
                        <Button sx={{ position: 'absolute', top: '50%', right: 20, transform: 'translateY(-50%)' }} onClick={() => setIndex(index + 1)} disabled={index === (elements.length - 1)} color='border'>
                            <ChevronRightIcon />
                        </Button>
                    </>
                )}
            </Box>
        </Portal>
    )
}

export default GallerySlider