import React, { useEffect } from 'react'
import { useCommon } from '../../../contexts/common';
import { detox, facility_types, payment_options, services } from '../../../constants/common';
import { Box, Checkbox, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { ReactComponent as ChevronDown } from '../../../assets/Icons/chevron-down.svg'
import Filter from './Filter';

const CustomMenuProps = () => {
  return (
    {
      PaperProps: {
        style: {
          maxHeight: 224,
          width: 250,
        },
      },
    }
  )
}

const CustomIcon = ({ props }) => {
  return (
    <Box
      {...props}
      component='span'
      sx={{
        minWidth: 24,
        width: 24,
        height: 24,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: '50% !important',
        right: '16px !important',
        transform: 'translateY(-50%) !important',
      }}
    >
      <ChevronDown width={12} height={6} />
    </Box>
  )
}


const ServicesAvilable = ({ filters, setFilters, goBack, reset }) => {
  const { 
    fetchTreatmentTypes,
    facilityTypesList,
    servicesList,
    detoxList,
    paymentOptionsList,
  } = useCommon();

  function handleInput(ev) {
    setFilters((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value
    }))
  }

  useEffect(() => {
    fetchTreatmentTypes(facility_types);
    fetchTreatmentTypes(services);
    fetchTreatmentTypes(detox);
    fetchTreatmentTypes(payment_options);
  }, [])

  return (
    <Filter
      label='Services Available '
      isRigntIcon={true}
      goBack={goBack}
      reset={reset}
      expandedDefault
    >
      <FormControl fullWidth>
        <InputLabel
          id="treatment_type"
          sx={{
            "&[data-shrink=false]": {
              transform: "translate(12px, 11.5px) scale(1)",
              color: 'text.primary'
            },
          }}
        >
          Treatment Type
        </InputLabel>
        <Select
          labelId="treatment_type"
          label='Treatment Type'
          fullWidth
          sx={{
            '& .MuiOutlinedInput-input': {
              py: 1.5,
            }
          }}
          name='treatment_types'
          multiple
          renderValue={(selected) => selected.map((val) => facilityTypesList.find((item) => item.id === +val)?.name).join(', ')}
          MenuProps={CustomMenuProps}
          IconComponent={(props) => (
            <CustomIcon props={props}/>
          )}
          onChange={handleInput}
          value={filters?.treatment_types || []}
        >
          <MenuItem
            sx={{
              p: 0
            }}
            value={null}
            disabled
          />
          {facilityTypesList.map((item) => (
            <MenuItem
              sx={{
                py: 1,
                gap: 1
              }}
              value={item.id}
              key={item.id}
            >
              <Checkbox sx={{ p: 0 }} checked={Boolean((filters?.treatment_types || []).find((val) => +val === item.id))} />
              <Typography variant='body2'>{item.name}</Typography>
          </MenuItem>
          ))}
        </Select>
      </FormControl>
        
      <FormControl fullWidth>
        <InputLabel
          id="available-services-label"
          sx={{
            "&[data-shrink=false]": {
              transform: "translate(12px, 11.5px) scale(1)",
              color: 'text.primary'
            },
          }}
        >
          Available Services
        </InputLabel>
        <Select
          labelId="available-services-label"
          label='Available Services'
          fullWidth
          sx={{
            '& .MuiOutlinedInput-input': {
              py: 1.5,
            }
          }}
          name='available_services'
          multiple
          renderValue={(selected) => selected.map((val) => servicesList.find((item) => item.id === +val)?.name).join(', ')}
          MenuProps={CustomMenuProps}
          IconComponent={(props) => (
            <CustomIcon props={props} />
          )}
          onChange={handleInput}
          value={filters?.available_services || []}
        >
          <MenuItem
            sx={{
              p: 0
            }}
            value={null}
            disabled
          />
          {servicesList.map((item) => (
            <MenuItem
              sx={{
                py: 1,
                gap: 1
              }}
              value={item.id}
              key={item.id}
            >
              <Checkbox sx={{ p: 0 }} checked={Boolean((filters?.available_services || []).find((val) => +val === item.id))} />
              <Typography variant='body2'>{item.name}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel
          id="detox-for-label"
          sx={{
            "&[data-shrink=false]": {
              transform: "translate(12px, 11.5px) scale(1)",
              color: 'text.primary'
            },
          }}
        >
          Detox For
        </InputLabel>
        <Select
          labelId="detox-for-label"
          label='Detox for'
          fullWidth
          sx={{
            '& .MuiOutlinedInput-input': {
              py: 1.5,
            }
          }}
          name='detox_for'
          multiple
          renderValue={(selected) => selected.map((val) => detoxList.find((item) => item.id === +val)?.name).join(', ')}
          MenuProps={CustomMenuProps}
          IconComponent={(props) => (
            <CustomIcon props={props} />
          )}
          onChange={handleInput}
          value={filters?.detox_for || []}
        >
          <MenuItem
            sx={{
              p: 0
            }}
            value={null}
            disabled
          />
          {detoxList.map((item) => (
            <MenuItem
              sx={{
                py: 1,
                gap: 1
              }}
              value={item.id}
              key={item.id}
            >
              <Checkbox sx={{ p: 0 }} checked={Boolean((filters?.detox_for || []).find((val) => +val === item.id))} />
              <Typography variant='body2'>{item.name}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel
          id="payment-options-label"
          sx={{
            "&[data-shrink=false]": {
              transform: "translate(16px, 12px) scale(1)",
              color: 'text.primary'
            },
          }}
        >
          Payment options
        </InputLabel>
        <Select
          labelId="payment-options-label"
          label='Payment options'
          fullWidth
          sx={{
            '& .MuiOutlinedInput-input': {
              py: 1.5,
            }
          }}
          name='payment_options'
          multiple
          renderValue={(selected) => selected.map((val) => paymentOptionsList.find((item) => item.id === +val)?.name).join(', ')}
          MenuProps={CustomMenuProps}
          IconComponent={(props) => (
            <CustomIcon props={props} />
          )}
          onChange={handleInput}
          value={filters?.payment_options || []}
        >
          <MenuItem
            sx={{
              p: 0
            }}
            value={null}
            disabled
          />
          {paymentOptionsList.map((item) => (
            <MenuItem
              sx={{
                py: 1,
                gap: 1
              }}
              value={item.id}
              key={item.id}
            >
              <Checkbox sx={{ p: 0 }} checked={Boolean((filters?.payment_options || []).find((val) => +val === item.id))} />
              <Typography variant='body2'>{item.name}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Filter>
  )
}

export default ServicesAvilable