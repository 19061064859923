import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import { ReactComponent as ThermometerIcon } from '../../assets/Icons/thermometer.svg'
import { ReactComponent as EditIcon } from '../../assets/Icons/edit-3.svg'
import { useTreatment } from '../../contexts/teamentModalities';
import { LOADING, SUCCESS, medication_prescriber, treatment_program } from '../../constants/common';
import CheckBox from '../../components/CheckBox';
import { useBasicInfo } from '../../contexts/BasicInfo';
import { useAuth } from '../../contexts/Auth';


const ProfileTreatment = () => {
  const [has_medication_prescriber, setHasMedicationPrescriber] = useState(0);
  const { data, fetchInfo } = useBasicInfo();
  const { account_type } = useAuth();
  const {
    fetchUserTreatmentModalities,
    userTreatmentModalities,
    fetchTreatmentModalitiesStatus,
    handleOpenDrawer,
    editMedicationPrescriber,
    updateMedicationPrescriberStatus,
    updateTreatmentModalitiesState
  } = useTreatment();


  const openDrawer = () => {
    handleOpenDrawer();
  }

  const handleCheckBox = (ev) => {
    setHasMedicationPrescriber(ev.target.checked ? 1 : 0)
    editMedicationPrescriber({ has_medication_prescriber: ev.target.checked ? 1 : 0 })
  }

  useEffect(() => {
    fetchUserTreatmentModalities({ account_type: account_type === treatment_program ? treatment_program : 'user' });
  }, []);

  useEffect(() => {
    setHasMedicationPrescriber(data?.info?.has_medication_prescriber)
  }, [data]);

  useEffect(() => {
    if (updateMedicationPrescriberStatus === SUCCESS) {
      fetchInfo();
      updateTreatmentModalitiesState('updateMedicationPrescriberStatus', '');
    }
  }, [updateMedicationPrescriberStatus]);

  return (
    <Paper
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <ThermometerIcon width={24} height={24} />
            <Typography variant='h4' ml={1}>Treatment Modalities</Typography>
          </Box>
          <Typography variant='body1'>Please select the treatment modalities you use.</Typography>
        </Box>
        {userTreatmentModalities.length > 0 ? (
          <IconButton
            sx={{
              p: 0.75
            }}
            onClick={openDrawer}
          >
            <EditIcon />
          </IconButton>
        ) : (
          <Button
            variant='contained'
            size='large'
            sx={{ minWidth: 150 }}
            onClick={openDrawer}
          >
            Add New
          </Button>
        )}
      </Box>

      {/* {userTreatmentModalities.length > 0 && ( */}
      <Divider />
      {/* )} */}

      {account_type !== treatment_program && (
        <CheckBox
          label={medication_prescriber.label}
          name={medication_prescriber.value}
          onChange={handleCheckBox}
          checked={Boolean(has_medication_prescriber)}
          disabled={updateMedicationPrescriberStatus === LOADING}
        />
      )}

      {Boolean(userTreatmentModalities.find((item) => item.type === 'therapist')) && (
        <Box
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'border.main',
            p: 3,
            borderRadius: 2
          }}
        >
          <Grid container spacing={2.5}>
            <Grid item xs={12}>
              <Typography variant='h5'>Therapy</Typography>
            </Grid>
            {fetchTreatmentModalitiesStatus === LOADING ?
              Array.from({ length: 6 }).map((_, index) => (
                <Grid item xs={6} key={index}>
                  <Skeleton animation='wave' />
                </Grid>
              )) :
              (
                userTreatmentModalities.reduce((acc, item) => {
                  if (item.type === 'therapist') {
                    return [...acc, (
                      <Grid item xs={6} key={item.id}>
                        {item.name}
                      </Grid>
                    )]
                  }
                  return acc
                }, [])
              )}
          </Grid>
        </Box>
      )}

      {Boolean(userTreatmentModalities.find((item) => item.type === 'prescribers')) && Boolean(data?.info?.has_medication_prescriber) && (
        <Box
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'border.main',
            p: 3,
            borderRadius: 2
          }}
        >
          <Grid container spacing={2.5}>
            <Grid item xs={12}>
              <Typography variant='h5'>Psychiatric</Typography>
            </Grid>
            {fetchTreatmentModalitiesStatus === LOADING ?
              Array.from({ length: 6 }).map((_, index) => (
                <Grid item xs={6} key={index}>
                  <Skeleton animation='wave' />
                </Grid>
              )) :
              (
                userTreatmentModalities.reduce((acc, item) => {
                  if (item.type === 'prescribers') {
                    return [...acc, (
                      <Grid item xs={6} key={item.id}>
                        {item.name}
                      </Grid>
                    )]
                  }
                  return acc
                }, [])
              )}
          </Grid>
        </Box>
      )}
    </Paper>
  )
}

export default ProfileTreatment;