import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

import { ReactComponent as UsersIcon } from '../../assets/Icons/users.svg';
import { ReactComponent as HeartIcon } from '../../assets/Icons/heart.svg';
import { ReactComponent as SendIcon } from '../../assets/Icons/send.svg';
import { ReactComponent as InboxIcon } from '../../assets/Icons/inbox.svg';
import paths from '../../constants/paths';
import { connections, favorites, received, sent, treatment_program } from '../../constants/common';
import { useBasicInfo } from '../../contexts/BasicInfo';
import { useGeneralProfile } from '../../contexts/Organization';
import { useAuth } from '../../contexts/Auth';

const ReferralsConnectionsCard = () => {
    const { data } = useBasicInfo();
    const { facilityInfo } = useGeneralProfile();
    const { account_type } = useAuth();
    const info = account_type === treatment_program ? facilityInfo : data;
    return (
        <Paper
            sx={{
                py: 3
            }}
        >
            <Box px={2}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant='body2' sx={{ color: 'text.secondary' }} fontWeight={600}>Referrals</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <SendIcon /> <Link href={paths.referrals(sent)} variant='body2' color='text.primary'>Sent</Link>
                    </Box>
                    {info?.referrals?.length > 0 && (
                        <Link
                            variant='body2'
                            href={paths.referrals(sent)}
                            sx={{
                                backgroundColor: '#f6f6f6',
                                borderRadius: 21.5,
                                minWidth: 22,
                                height: 22,
                                display: 'flex',
                                alignItem: 'center',
                                justifyContent: 'center',
                                px: 0.875,
                                color: 'text.primary'
                            }}
                        >
                            {info?.referrals?.length}
                        </Link>
                    )}
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ display: 'flex', color: 'border.dark' }}>
                            <InboxIcon />
                        </Box>
                        <Link href={paths.referrals(received)} variant='body2' color='text.primary'>Received</Link>
                    </Box>
                    {info?.messages?.length > 0 && (
                        <Link
                            variant='body2'
                            href={paths.referrals(received)}
                            sx={{
                                backgroundColor: '#f6f6f6',
                                borderRadius: 21.5,
                                minWidth: 22,
                                height: 22,
                                display: 'flex',
                                alignItem: 'center',
                                justifyContent: 'center',
                                px: 0.875,
                                color: 'text.primary'
                            }}
                        >
                            {info?.messages?.length}
                        </Link>
                    )}
                </Box>
            </Box>
            <Divider sx={{ my: 1.5 }} />
            <Box px={2}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant='body2' sx={{ color: 'text.secondary' }} fontWeight={600}>Network</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' , gap: 1}}>
                        <UsersIcon /> <Link href={paths.network(connections)} variant='body2' color='text.primary'>Connections</Link>
                    </Box>
                    {info?.connections?.length > 0 ? (
                        <Link
                            variant='body2'
                            href={paths.network(connections)}
                            sx={{
                                backgroundColor: '#f6f6f6',
                                borderRadius: 21.5,
                                minWidth: 22,
                                height: 22,
                                display: 'flex',
                                alignItem: 'center',
                                justifyContent: 'center',
                                px: 0.875,
                                color: 'text.primary'
                            }}
                        >
                            {info?.connections?.length}
                        </Link>
                    ) : (
                        <Link variant='caption' href={paths.network(connections)}>
                            Add
                        </Link>
                    )}
                    
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'border.dark' }}>
                        <HeartIcon /> <Link href={paths.network(favorites)} variant='body2' color='text.primary'>Favorites</Link>
                    </Box>
                    {info?.favorites?.length > 0 ? (
                        <Link
                            variant='body2'
                            href={paths.network(favorites)}
                            sx={{
                                backgroundColor: '#f6f6f6',
                                borderRadius: 21.5,
                                minWidth: 22,
                                height: 22,
                                display: 'flex',
                                alignItem: 'center',
                                justifyContent: 'center',
                                px: 0.875,
                                color: 'text.primary'
                            }}
                        >
                            {info?.favorites?.length}
                        </Link>
                    ) : (
                        <Link variant='caption' href={paths.network(favorites)}>
                            Add
                        </Link>
                    )}
                </Box>
            </Box>
        </Paper>
    )
}

export default ReferralsConnectionsCard