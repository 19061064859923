import React, { createContext, useContext, useState } from 'react';
import { getInterests, updateInterests } from '../services/interests.services';
import { ERROR, LOADING, SUCCESS } from '../constants/common';
import { useAlert } from './Alert';


const InterestsContext = createContext({});

export const InterestsContextProvider = ({ children }) => {
  const { sendAlert } = useAlert();
    const [interestsState, setInterestsState] = useState({
        fetchInterestsStatus: '',
        interests: [],
        updateInterestsStatus: '',
    });

    function handleState(obj) {
        setInterestsState((prevState) => ({
            ...prevState,
            ...obj
        }))
    }

    async function fetchInterests() {
        handleState({ fetchInterestsStatus: LOADING })
      try {
        const response = await getInterests();
        handleState({
            interests: response.data.data,
            fetchInterestsStatus: SUCCESS
        })
      } catch (error) {
        handleState({ fetchInterestsStatus: ERROR })
        Promise.reject(error)
      }
    }

    async function editInterests(data) {
        handleState({ updateInterestsStatus: LOADING })
      try {
        await updateInterests(data);
        handleState({
            updateInterestsStatus: SUCCESS
        })
        sendAlert('Updated sepcialties successfully', 'success')
      } catch (error) {
        handleState({ updateInterestsStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }

    function updateInterestsState(field, value) {
        handleState({ [field]: value })
    }
    

    const state = {
        ...interestsState,
        fetchInterests,
        editInterests,
        updateInterestsState
    };

    return (
        <InterestsContext.Provider value={state}>
        {children}
        </InterestsContext.Provider>
    );
};

export const useInterests = () => useContext(InterestsContext);