import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ReactComponent as GraduationCapIcon } from '../../assets/Icons/graduation-cap-profile.svg'
import { useTraining } from '../../contexts/Training';
import TrainingItem from './TrainingItem';

const ProfileTraining = () => {
    const { handleOpen, fetchTraining, list } = useTraining();

    function addNew() {
        handleOpen()
    }

    useEffect(() => {
        fetchTraining();
    }, [])
    
    return (
        <Paper
            sx={{
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                gap: 3
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <GraduationCapIcon width={24} height={24} />
                <Typography variant='h4' ml={1}>Training</Typography>
            </Box>
            

            <Box
                sx={{
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'border.main',
                    p: 3,
                    borderRadius: 2
                }}
            >
                <Box
                    sx={{
                        mb: 1.5
                    }}
                >
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography variant='h5'>Certificate:</Typography>
                            {list?.filter((item) => item.type === 'certificate').length < 1 && (
                                <Button
                                    sx={{
                                        p: 0,
                                        '&:hover': {
                                            backgroundColor: 'transparent'
                                        }
                                    }}
                                    onClick={addNew}
                                >
                                    Add new
                                </Button>
                            )}
                        </Box>
                        <Box component='ol' pl={2}>
                            {list?.reduce((acc, item) => {
                                if (item.type === 'certificate') {
                                    return [...acc, (
                                        <TrainingItem key={item.id} data={item} />
                                    )];
                                } else {
                                    return acc;
                                }
                            }, [])}
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        mb: 1.5
                    }}
                >
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography variant='h5'>Fellowship:</Typography>
                            {list?.filter((item) => item.type === 'fellowship').length < 1 && (
                                <Button
                                    sx={{
                                        p: 0,
                                        '&:hover': {
                                            backgroundColor: 'transparent'
                                        }
                                    }}
                                    onClick={addNew}
                                >
                                    Add new
                                </Button>
                            )}
                        </Box>
                        <Box component='ol' pl={2}>
                            {list?.reduce((acc, item) => {
                                if (item.type === 'fellowship') {
                                    return [...acc, (
                                        <TrainingItem key={item.id} data={item} />
                                    )];
                                } else {
                                    return acc;
                                }
                            }, [])}
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        mb: 1.5
                    }}
                >
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography variant='h5'>Post-Doctoral:</Typography>
                            {list?.filter((item) => item.type === 'post-doctoral').length < 1 && (
                                <Button
                                    sx={{
                                        p: 0,
                                        '&:hover': {
                                            backgroundColor: 'transparent'
                                        }
                                    }}
                                    onClick={addNew}
                                >
                                    Add new
                                </Button>
                            )}
                        </Box>
                        <Box component='ol' pl={2}>
                            {list?.reduce((acc, item) => {
                                if (item.type === 'post-doctoral') {
                                    return [...acc, (
                                        <TrainingItem key={item.id} data={item} />
                                    )];
                                } else {
                                    return acc;
                                }
                            }, [])}
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        mb: 1.5
                    }}
                >
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography variant='h5'>Residency:</Typography>
                            {list?.filter((item) => item.type === 'residency').length < 1 && (
                                <Button
                                    sx={{
                                        p: 0,
                                        '&:hover': {
                                            backgroundColor: 'transparent'
                                        }
                                    }}
                                    onClick={addNew}
                                >
                                    Add new
                                </Button>
                            )}
                        </Box>
                        <Box component='ol' pl={2}>
                            {list?.reduce((acc, item) => {
                                if (item.type === 'residency') {
                                    return [...acc, (
                                        <TrainingItem key={item.id} data={item} />
                                    )];
                                } else {
                                    return acc;
                                }
                            }, [])}
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                }}
            >
                <Button
                    variant='contained'
                    sx={{
                        minWidth: 150
                    }}
                    onClick={addNew}
                >
                    Add New
                </Button>
            </Box>
        </Paper>
    )
}

export default ProfileTraining;