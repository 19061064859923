import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";

import { ReactComponent as Checkmark } from "../../../../../assets/Icons/checkmark.svg";
import { useOnboarding } from "../../../../../contexts/onboarding";
import { useSearchParams } from "react-router-dom";
import "./style.css";
import useResize from "../../../../../hooks/useResize";
import { paymentFeatures } from "../../../../../constants/common";

const PromoCitySubscription = () => {
  const { updateOnboardingState, onboardingState, fetchPlans, plans } =
    useOnboarding();
  const isMobileView = useResize(600);

  const [searchParams] = useSearchParams();
  
  const eventPlan = plans.find((item) => item.slug === "cleveland-clinic");
  const premiumPlan = plans.find((item) => item.slug === "premium-plan");
  const specialOfferPlan = plans.find((item) => item.slug === "Special_Offer");
  const event = searchParams.get("event");
  const isInvalid = onboardingState?.error;

  useEffect(() => {
    fetchPlans();
  }, []);

  useEffect(() => {
    if (event === "ClevelandClinic") {
      updateOnboardingState({ payment_method_id: eventPlan?.id || 1 });
    } else {
      updateOnboardingState({ payment_method_id: premiumPlan?.id || '' });
    }
  }, [event, eventPlan, premiumPlan]);

  const selectSubsciptionPlan = (ev) => {
    updateOnboardingState({ payment_method_id: ev.target.value, error: false });
  }

  const checkSupport = (option, item) => {
    if (option === "Phone support") {
      return (["cleveland-clinic", "premium-plan", "Special_Offer"].includes(item?.slug)) || false;
    } else if (option === "Free business strategy call") {
      return (option === "Free business strategy call" && ["cleveland-clinic", "premium-plan"].includes(item?.slug)) || false;
    }
    return true;
  }

  const data = event === "ClevelandClinic" 
    ? eventPlan ? [eventPlan] : []
    : event === "Special_Offer"
      ? eventPlan ? [specialOfferPlan] : []
      : plans.filter(
        (item) =>
          item.slug !== "cleveland-clinic" &&
          item.slug !== "Special_Offer" &&
          item.slug !== "premium-special-offer" &&
          item.slug !== "teatment-plans"
      );

  return (
    <Container>
      <Paper
        sx={{
          pt: 3,
          px: 3,
          border: 0,
          boxShadow: "0px 0px 32px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Grid container className="membership" rowSpacing={3} columnSpacing={0}>
          <Grid item md={6}>
            <Box pt={3} pb={1.5}>
              <div className="textSmall">
                <Typography variant="h2" mb={1}>
                  Select a membership plan{" "}
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  mb={1}
                  lineHeight="24px"
                >
                  {event
                    ? "Membership plan has 60 days Free."
                    : "Promotion includes $3/month for the first 6 months in addition to your 30-day free trial. Regular pricing of $11.99/month applies thereafter."}
                </Typography>
              </div>
            </Box>
          </Grid>
          {data.length > 0 && <Grid item md={6 - 2 * 2} />}

          {data?.map((item) => (
            <Grid
              item
              md={4}
              xs={8}
              backgroundColor={
                isMobileView
                  ? null
                  : +onboardingState.payment_method_id === item.id &&
                  "background.light"
              }
              border={
                isMobileView
                  ? null
                  : +onboardingState.payment_method_id === item.id &&
                  "1px solid #9BD0F6"
              }
              borderBottom={0}
              position="relative"
              key={item.id}
            >
              <div className="bestPrice">
                {["premium-plan", "cleveland-clinic", "Special_Offer"].includes(
                  item.slug
                ) && (
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        color: "background.paper",
                        backgroundColor: "error.main",
                        py: 0.625,
                        px: 2,
                        display: "inline-block",
                        borderRadius: 16,
                        position: "absolute",
                        top: isMobileView ? "20px" : 0,
                        fontSize: isMobileView ? "12px" : "normal",
                        transform: "translate(-50%, -50%)",
                        left: isMobileView ? "45%" : "50%",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Best Value
                    </Typography>
                  )}
                <div className="pricingBox">
                  <Typography variant="h4" mb={2.5} fontSize={19}>
                    {item.name}
                  </Typography>
                  <Button
                    className="displayNone"
                    color={
                      +onboardingState.payment_method_id !== item.id
                        ? isInvalid ? "error" : "secondary"
                        : "primary"
                    }
                    variant={
                      +onboardingState.payment_method_id !== item.id
                        ? "outlined"
                        : "contained"
                    }
                    sx={{
                      backgroundColor: `${ isInvalid ? "error.light" : ""}`,
                      color:
                        +onboardingState.payment_method_id !== item.id
                          ? "primary.main"
                          : "",
                      mb: isInvalid ? 0 : 2.5,
                      paddingX: "50px",
                    }}
                    size="small"
                    value={item.id.toString()}
                    onClick={selectSubsciptionPlan}
                  >
                    Select
                  </Button>
                  { isInvalid &&
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          w: "100%",
                          py: 0.5,
                          px: 0,
                          marginLeft: "6px",
                          mb: 2.5,
                          fontSize: 14,
                          alignItems: "center",
                          color: "error.main",
                        }}
                      >
                        Please select a plan.
                      </Grid>
                  }
                  <Typography variant="body2" mb={0.5}>
                    ${item.price} /{" "}
                    <Box component="span" color="#888888">

                      {item.billed == "billed annually" && "year"}
                      {item.billed == "billed monthly" && "month"}
                      {item.billed == "one-time payment" && "six months"}
                    </Box>
                  </Typography>
                  {item.save && (
                    <Typography
                      variant="caption"
                      px={1}
                      border="1px solid"
                      borderColor="error.main"
                      color="error.main"
                      display="inline-block"
                      borderRadius={42.5}
                      mb={0.5}
                    >
                      {item.save}
                    </Typography>
                  )}
                  {item.billed && (
                    <Typography
                      variant="caption"
                      sx={{ color: "text.secondary", display: "block" }}
                    >
                      {item.billed}
                    </Typography>
                  )}
                </div>
                <Button
                  className="btnSelect"
                  color={
                    +onboardingState.payment_method_id !== item.id
                      ? "secondary"
                      : "primary"
                  }
                  variant={
                    +onboardingState.payment_method_id !== item.id
                      ? "outlined"
                      : "contained"
                  }
                  fullWidth
                  sx={{
                    color:
                      +onboardingState.payment_method_id !== item.id
                        ? "primary.main"
                        : "",
                    mb: 2.5,
                  }}
                  size="small"
                  value={item.id.toString()}
                  onClick={selectSubsciptionPlan}
                >
                  Select
                </Button>
              </div>
            </Grid>
          ))}

          <Divider
            sx={{
              width: "100%",
              my: 0,
            }}
          />
          {isMobileView ? (
            <div className="membershipText">
              <h4>Your membership</h4>
              <p>Monthly price (billed annually)</p>
            </div>
          ) : ("")}
          {paymentFeatures.map((option) => (
            <>
              <Grid className="textCenter"  item md={6} xs={12}>
                <Typography variant="body1">{option}</Typography>
              </Grid>
              {data.length > 0 && <Grid item md={6 - 2 * 2} />}
              {data?.map((item) => (
                <Grid
                  className="gridItem"
                  item
                  md={4}
                  xs={8}
                  textAlign="center"
                  backgroundColor={
                    +onboardingState.payment_method_id === item.id &&
                    "background.light"
                  }
                  borderLeft={
                    +onboardingState.payment_method_id === item.id &&
                    "1px solid #9BD0F6"
                  }
                  borderRight={
                    +onboardingState.payment_method_id === item.id &&
                    "1px solid #9BD0F6"
                  }
                  key={item.id}
                >
                  <Box pt={1.5}>
                   {checkSupport(option, item) &&  <Checkmark />}
                  </Box>
                </Grid>
              ))}
              <Grid item md={6} xs={12}>
                <Divider />
              </Grid>
              {data.length > 0 && (
                <Grid className="displayNone" item xs={6 - 2 * 2}>
                  <Divider />
                </Grid>
              )}
              {data?.map((item) => (
                <Grid
                  item
                  className="gridItem"
                  md={4}
                  xs={8}
                  backgroundColor={
                    +onboardingState.payment_method_id === item.id &&
                    "background.light"
                  }
                  borderLeft={
                    +onboardingState.payment_method_id === item.id &&
                    "1px solid #9BD0F6"
                  }
                  borderRight={
                    +onboardingState.payment_method_id === item.id &&
                    "1px solid #9BD0F6"
                  }
                  key={item.id}
                >
                  <Divider />
                </Grid>
              ))}
            </>
          ))}
        </Grid>
      </Paper>
    </Container>
  );
};

export default PromoCitySubscription;
