import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import { LOADING, SUCCESS } from "../../constants/common";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSettings } from "../../contexts/Settings";
import { validatePassword } from "../../helper/Validations";

const PasswordSettings = () => {
  const { editPassword, updateEditStatus, handleSettingsState } = useSettings();
  const initialState = {
    old_password: "",
    password: "",
    password_confirmation: "",
  };
  const [state, setState] = useState(initialState);
  const [isInvalid, setIsInvalid] = useState(false);

  function handleInput(ev) {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value,
    }));
  }

  function isValid() {
    return (
      state.old_password &&
      state.password &&
      validatePassword(state.password) &&
      validatePassword(state.password_confirmation) &&
      state.password === state.password_confirmation
    );
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (isValid()) editPassword(state);
    else setIsInvalid(true);
  }

  useEffect(() => {
    if (updateEditStatus === SUCCESS) {
      setIsInvalid(false);
      handleSettingsState("updateEditStatus", "");
      setState(initialState);
    }
  }, [updateEditStatus]);

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        p: 3,
      }}
      component="form"
      onSubmit={handleSubmit}
    >
      <Box>
        <Typography variant="h5" fontWeight={600} mb={1}>
          Change your password
        </Typography>
        <Typography variant="body1" color="text.secondary">
          For a strong password, try choosing a unique phrase that you don't use
          on any other accounts.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        <Box>
          <Typography variant="body1" mb={1.5}>
            Password
          </Typography>
          <TextField
            fullWidth
            label="Current password"
            type="password"
            name="old_password"
            onChange={handleInput}
            value={state.old_password}
            error={isInvalid && !state.old_password}
          />
        </Box>

        <Box>
          <Typography variant="body1" mb={1.5}>
            New Password
          </Typography>
          <TextField
            fullWidth
            label="New Password"
            sx={{
              mb: 1.5,
            }}
            type="password"
            name="password"
            onChange={handleInput}
            value={state.password}
            error={
              isInvalid &&
              (!state.password || !validatePassword(state.password))
            }
          />
          <TextField
            fullWidth
            label="Confirm new password"
            type="password"
            name="password_confirmation"
            onChange={handleInput}
            value={state.password_confirmation}
            error={
              isInvalid &&
              (!state.password_confirmation ||
                state.password !== state.password_confirmation ||
                !validatePassword(state.password_confirmation))
            }
          />
        </Box>
        <Box
          component="ul"
          sx={{
            m: 0,
            pl: 2,
          }}
        >
          <Typography variant="body1" component="li" color="text.secondary">
            A minimum of 12 characters
          </Typography>
          <Typography variant="body1" component="li" color="text.secondary">
            At least one lowercase letter
          </Typography>
          <Typography variant="body1" component="li" color="text.secondary">
            At least one number
          </Typography>
          <Typography variant="body1" component="li" color="text.secondary">
            Passwords match
          </Typography>
          <Typography variant="body1" component="li" color="text.secondary">
            At least one special character from this list: #.-?!@$%^&*
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <LoadingButton
          variant="contained"
          size="large"
          sx={{
            minWidth: 110,
          }}
          type="submit"
          loading={updateEditStatus === LOADING}
        >
          Save
        </LoadingButton>
      </Box>
    </Paper>
  );
};

export default PasswordSettings;
