import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import { ReactComponent as CloseIcon } from "../../assets/Icons/close.svg";
import verfiyEmail from "../../assets/imgs/verify-email.png";
import { useBasicInfo } from "../../contexts/BasicInfo";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 494,
    p: 0,
    overflow: 'hidden',
};

function VerificationModal() {
    const { data } = useBasicInfo();

    const [open, setOpen] = useState(false);

    function handleClose() {
        setOpen(false);
        localStorage.setItem('isVerifyEmailShown', 'true')
    }

    useEffect(() => {
        if((Object.keys(data)?.length > 0 && !Boolean(data?.email_verified_at)) && !JSON.parse(localStorage.getItem('isVerifyEmailShown') || 'false')) {
            setOpen(true);
        }
    }, [data])
    
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper sx={style}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        pt: 3,
                        px: 3,
                        pb: 2
                    }}
                >
                    <Box width={28} />
                    <Typography variant='h3'>Check your inbox</Typography>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            p: 0.25,
                            color: 'border.dark'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box
                    sx={{
                        px: 3,
                        pb: 3,
                        textAlign: 'center'
                    }}
                >
                    <CardMedia
                        image={verfiyEmail}
                        component='img'
                        sx={{
                            mb: 2,
                            width: 150,
                            height: 150,
                            maxWidth: '100%',
                            mx: 'auto'
                        }}
                    />
                    <Typography variant="body1" lineHeight="24px">Click the link we sent to</Typography>
                    <Typography variant="body1" lineHeight="24px" color='primary.main'>{data.email}</Typography>   
                    <Typography variant="body1" lineHeight="24px">to complete your account setup.</Typography>   
                </Box>
             
                <Box
                    sx={{
                        px: 3,
                        pb: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            minWidth: 66
                        }}
                        onClick={handleClose}
                    >
                        Done
                    </Button>
                </Box>
            </Paper>
        </Modal>
    );
}

export default VerificationModal;