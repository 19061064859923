import React from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { ReactComponent as CloseIcon } from "../../assets/Icons/close.svg";
import LoadingButton from "@mui/lab/LoadingButton";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 422,
    p: 0,
    overflow: 'hidden',
};

function ConfirmationModal({ open, onClose, deleteFunction, message, loading, modalName }) {
    function handleClose() {
        onClose();
    }

    function handleDelete() {
        deleteFunction();
    }


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper sx={style}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        pt: 3,
                        px: 3,
                        pb: 2
                    }}
                >
        
                    <Typography variant='h3'>{modalName || 'Delete'} confirmation</Typography>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            p: 0.25,
                            color: 'border.dark'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box
                    sx={{
                        px: 3,
                        py: 3,
                        textAlign: 'center'
                    }}
                >
                    {message && <Typography variant='body1' mb={2}>{message}</Typography>}
                </Box>
             
                <Box
                    sx={{
                        px: 3,
                        pb: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2
                    }}
                >
                    <LoadingButton
                        variant="contained"
                        color='error'
                        onClick={handleDelete}
                        loading={loading}
                        sx={{
                            minWidth: 100
                        }}
                    >
                        {modalName || 'Delete'}
                    </LoadingButton>

                    <Button
                        variant="outlined"
                        color='secondary'
                        sx={{
                            color: 'primary.main',
                            minWidth: 100
                        }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Box>
            </Paper>
        </Modal>
    );
}

export default ConfirmationModal;