import React from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';


const SideMenuInernal = ({ menu, onClick ,currentTab}) => {
    return  (
        <Paper
                sx={{
                    overflow: 'hidden'
                }}
            >
            {menu?.map(({Icon, ...item}) => {
                const isActive =currentTab=== item.type
                return (
                    <Box
                      className="Notifcation-tabs"
                        key={item.id}
                        variant='body2'
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            color: 'text.primary',
                            p: 2,
                            fontSize: "14px",
                            borderLeft: '3px solid transparent',
                            ...(isActive && {
                                fontWeight: 500,
                                borderColor: 'primary.main'
                            })
                        }}
                        {...(onClick ? {role: 'button'} : {})}
                        onClick={function() {
                            if (onClick) {
                                onClick(item);
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontWeight: isActive ? 600 : 400,
                                gap: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: 'border.dark'
                                }}
                            >
                                <Icon />
                            </Box>
                            {item.text}
                        </Box>
                    </Box>
                )
            })}
       </Paper>
    )
}

export default SideMenuInernal