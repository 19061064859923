import { axiosInstance } from "./axiosInstance";

function getTraining() {
    return axiosInstance.get('trainings');
}

function createTraining(data) {
    return axiosInstance.post('trainings', data);
}

function updateTraining(data) {
    return axiosInstance.put('trainings', data);
}

function deleteTraining(data) {
    return axiosInstance.delete('trainings', {params: data});
}


export {
    getTraining,
    createTraining,
    updateTraining,
    deleteTraining
}