import React, { useEffect, useState } from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import CreateAnnouncementCard from '../../components/CreateAnnouncementCard';
import TherapistInfo from '../../components/TherapistInfo';
import ProfileProgressCard from '../../components/ProfileProgressCard';
import UserVerticalCard from '../../components/UserVerticalCard';
import PostCard from '../../components/PostCard';
import ConnectionsCard from '../../components/ConnectionsCard';
import { useHomePage } from '../../contexts/HomePage';
import PostForm from '../../components/PostForm'
import InfiniteScroll from 'react-infinite-scroll-component';
import { LOADING, SUCCESS, colleagues, treatment_program } from '../../constants/common'
import CardLoader from '../../components/CardLoader'
import { useBasicInfo } from '../../contexts/BasicInfo'
import VerificationModal from '../../components/VerificationModal'
import ReferralsConnectionsCard from '../../components/ReferralsConnectionsCard'
import {  useSearchParams } from 'react-router-dom'
import paths from '../../constants/paths'
import { useGeneralProfile } from '../../contexts/Organization'
import { useAuth } from '../../contexts/Auth'

const HomePage = () => {
  const {
    fetchStatus,
    suggestPeople,
    fetchSuggestedPeople,
    fetchPosts,
    fetchPostsStatus,
    posts,
    createPostStatus
  } = useHomePage();

  const { data, fetchInfo } = useBasicInfo(); 
  const { fetchFacilityInfo, facilityInfo } = useGeneralProfile();
  const { account_type } = useAuth();
  const [searchParams] = useSearchParams();
  const post_id = searchParams.get('post_id');
  const [params, setParams] = useState({
    page: 1,
  })
  const [loading, setLoading] = useState(false)

  function fetchNexPage() {
    setParams((prevState) => ({
        ...prevState,
        page: prevState.page + 1
    }));
  }

  const asyncFetch = async () => {
    setLoading(true)
    fetchSuggestedPeople();
    if (account_type !== treatment_program){
    await  fetchInfo();
    }else{
          await  fetchFacilityInfo();
    }
    setLoading(false)
  }

  useEffect(() => {
    asyncFetch()
  }, [account_type])

  useEffect(() => {
    fetchPosts({...params, post_id });
  }, [params])

  useEffect(() => {
    if (createPostStatus === SUCCESS) {
      setParams((prevState) => ({
        ...prevState,
        page: 1
      }));
    }
  }, [createPostStatus])


  const profileProgress = [
    true,
    true,
    (Boolean(data?.info?.office_hours_time) || Boolean(data?.info?.office_hours_day)),
    ((data?.client_types || [])?.length > 0),
    ((data?.specialties || [])?.length > 0),
    ((data?.treatment_modalities || [])?.length > 0),
    ((data?.degrees || [])?.length > 0),
    ((data?.fees || [])?.length > 0 ),
  ].filter((item) => item);

  return (
    <Container>
      <Grid container spacing={{ xs: 0, md: 3 }}>
        <Grid item xs={12} md={2.5}>
          <Box
            sx={{
              position: 'sticky',
              top: 80,
              display: 'flex',
              flexDirection: 'column',
              gap: 2.5,
            }}
          >
            <Box
              sx={{
                display: {md: 'none'}
              }}
            >
              {!loading && profileProgress.length < 8 && account_type !== treatment_program &&  (
                <ProfileProgressCard progress={profileProgress} />
              )}
            </Box>
           
            <TherapistInfo />
            <Box
              sx={{
                display: {xs: 'flex', md: 'none'},
                flexDirection: 'column',
                gap: 2.5,
              }}
            >
              {/* {profileProgress.length === 8 && (
                <NetwrokCard />
              )} */}
              {(account_type === treatment_program || profileProgress.length === 8) && (
                <ReferralsConnectionsCard />
              )}
              {suggestPeople.length > 0 && (
                <ConnectionsCard />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}
        >
          {!Boolean(posts?.data?.length) && fetchPostsStatus !== LOADING && (
            <CreateAnnouncementCard />
          )}
          
          {!post_id && (
            <>
              {/* <Invitations /> */}
              <PostForm />
            </>
          )}
          <InfiniteScroll
            dataLength={params.page * (posts.per_page || 0)} //This is important field to render the next data
            next={fetchNexPage}
            hasMore={params.page < posts.last_page && fetchPostsStatus !== LOADING}
            style={{
                overflow: 'visible',
                display: 'flex',
                flexDirection: 'column',
                gap: 16
            }}
            loader={
              fetchPostsStatus === LOADING && (
                <Box
                  sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                  }}
                >
                    <CircularProgress />
                </Box>
              )
            }
          >
            {fetchPostsStatus === LOADING && params.page === 1 ? (
              <>
                <CardLoader />
              </>
            ) : (
              posts?.data.map((item, index) => {
                if (index === 0) {
                  return (
                    <Box
                      key={item.id}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2
                      }}
                    >
                      <PostCard
                        data={item}
                      />
                      {post_id && (
                        <>
                          {suggestPeople.length > 0 && (
                            <Paper
                              sx={{
                                boxShadow: 'none',
                                borderColor: 'border.main',
                                p: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2
                              }}
                            >
                              <Typography variant='h5'>Grow your network</Typography>
                              {suggestPeople.map((item) => (
                                <UserVerticalCard key={item.id} data={item} />
                              ))}
                              <Link href={paths.network(colleagues)} variant='body1' fontWeight={600} color='text.primary'>Find more specialized therapists</Link>
                            </Paper>
                          )}
                          <PostForm />
                        </>
                      )}
                    </Box>
                  )
                }
                return (
                  <PostCard
                    data={item}
                    key={item.id}
                  />
                )
              })
            )}
          </InfiniteScroll>
        </Grid>
        <Grid
          item
          xs={12}
          md={2.5}
          sx={{
            display: { xs: 'none', md: 'block' }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2.5,
              position: 'sticky',
              top: 80
            }}
          >
            {!loading && profileProgress.length < 8 && account_type !== treatment_program && (
              <ProfileProgressCard progress={profileProgress} />
            )}
            {/* {profileProgress.length === 8 && (
              <NetwrokCard />
            )} */}
             {(account_type === treatment_program || profileProgress.length === 8) && (
              <ReferralsConnectionsCard />
            )}
            {suggestPeople.length > 0 && (
              <ConnectionsCard />
            )}
          </Box>
        </Grid>
      </Grid>
      <VerificationModal />
    </Container>
  )
}

export default HomePage;