function sortAgeRanges(ageRanges) {
  function compareAgeRanges(a, b) {
    const getAge = (range) => parseInt(range.split("-")[0]);
    const ageA = getAge(a);
    const ageB = getAge(b);
    return ageA - ageB;
  }
  if (ageRanges?.length) {
    ageRanges.sort(compareAgeRanges);
    return ageRanges;
  } else {
    return [];
  }
}

export default sortAgeRanges;
