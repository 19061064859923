import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CommonContextProvider } from './contexts/common';
import { AuthContextProvider } from './contexts/Auth';
import { AlertProvider } from './contexts/Alert';
import { DemographicsContextProvider } from './contexts/Demographics';
import { LanguagesContextProvider } from './contexts/Languages';
import { OnboardingContextProvider } from './contexts/onboarding';
import { TreatmentContextProvider } from './contexts/teamentModalities';
import { SpecialtiesContextProvider } from './contexts/specialties';
import { BasicInfoContextProvider } from './contexts/BasicInfo';
import { DegreesContextProvider } from './contexts/Degrees';
import { FeesContextProvider } from './contexts/Fees';
import { InterestsContextProvider } from './contexts/Interests';
import { NetworkContextProvider } from './contexts/NetWork';
import { ReferralsContextProvider } from './contexts/Referral';
import { UserProfileContextProvider } from './contexts/UserProfile';
import CustomThemeProvider from './theme';
import { HomePageContextProvider } from './contexts/HomePage';
import { ClientServedContextProvider } from './contexts/ClientServed';
import { CredentialsContextProvider } from './contexts/Credentials';
import { SettingsContextProvider } from './contexts/Settings';
import { TrainingContextProvider } from './contexts/Training';
import { GeneralProfileContextProvider } from './contexts/Organization';
import { TeamsContextProvider } from './contexts/Teams';
import { NotificationsProvider } from './contexts/Notifications';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CustomThemeProvider>
    <AlertProvider>
      <CommonContextProvider>
        <AuthContextProvider>
          <OnboardingContextProvider>
            <BasicInfoContextProvider>
              <TreatmentContextProvider>
                <ClientServedContextProvider>
                  <SpecialtiesContextProvider>
                    <DemographicsContextProvider>
                      <LanguagesContextProvider>
                        <DegreesContextProvider>
                          <FeesContextProvider>
                            <InterestsContextProvider>
                              <NetworkContextProvider>
                                <ReferralsContextProvider>
                                  <UserProfileContextProvider>
                                    <HomePageContextProvider>
                                      <CredentialsContextProvider>
                                        <TrainingContextProvider>
                                          <SettingsContextProvider>
                                            <GeneralProfileContextProvider>
                                              <TeamsContextProvider>
                                                <NotificationsProvider>
                                                  <App />
                                                </NotificationsProvider>
                                              </TeamsContextProvider>
                                            </GeneralProfileContextProvider>
                                          </SettingsContextProvider>
                                        </TrainingContextProvider>
                                      </CredentialsContextProvider>
                                    </HomePageContextProvider>
                                  </UserProfileContextProvider>
                                </ReferralsContextProvider>
                              </NetworkContextProvider>
                            </InterestsContextProvider>
                          </FeesContextProvider>
                        </DegreesContextProvider>
                      </LanguagesContextProvider>
                    </DemographicsContextProvider>
                  </SpecialtiesContextProvider>
                </ClientServedContextProvider>
              </TreatmentContextProvider>
            </BasicInfoContextProvider>
          </OnboardingContextProvider>
        </AuthContextProvider>
      </CommonContextProvider>
    </AlertProvider>
  </CustomThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
