import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Register from '../../components/InvitationOnboarding/Register';
import CompleteProfile from '../../components/InvitationOnboarding/CompleteProfile';
import { useTeams } from '../../contexts/Teams';
import { useParams, useSearchParams } from 'react-router-dom';
import { Container } from '@mui/material';

const InvitationOnboarding = () => {
  const { checkUrl, step } = useTeams();
  const { url } = useParams();
  const [searchParams] = useSearchParams();
  const expires = searchParams.get('expires');
  const signature = searchParams.get('signature');
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const validateUrl = async () => {
      try {
        await checkUrl({
          route: 'teams.invite',
          expires,
          signature,
          signed_url: url,
        });
      } catch (error) {
        if (error.message.includes('Link expired')) {
          setIsLinkExpired(true);
        }
      } finally {
        setIsLoading(false);
      }
    };
    validateUrl();
  }, [url]);

  if (isLoading) {
    return (
      <Container maxWidth='sm'>
        <Paper
          sx={{
            textAlign: 'center',
            borderRadius: 2
          }}
        >
          <Box
            sx={{
              backgroundColor: 'primary.light',
              pt: 4,
              px: 4,
              pb: 3,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}
          >
          <Skeleton animation="wave" sx={{minHeight:30}}/>
          <Skeleton animation="wave" sx={{minHeight:30}}/>
          <Skeleton animation="wave" sx={{minHeight:30}}/>
          <Skeleton animation="wave" sx={{minHeight:30}}/>
          </Box>
        </Paper>
      </Container>
    );
  }

  if (isLinkExpired) {
    return (
      <Container maxWidth='sm'>
        <Paper
          sx={{
            textAlign: 'center',
            borderRadius: 2
          }}
        >
          <Box
            sx={{
              backgroundColor: 'primary.light',
              pt: 4,
              px: 4,
              pb: 3,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}
          >
          <Typography variant='h4' gutterBottom>
            Hello, this link has expired.
          </Typography>
          <Typography >
            To get a new link, please contact your administrator. We look forward to seeing you in the community.
          </Typography>
          <Typography mt={2}>
            Thank you
            <br/>
            <b>The Therapy Word of Mouth Team</b>
          </Typography>
          </Box>
        </Paper>
      </Container>
    );
  }

  return (
    <Box>
      {step === 1 && <Register />}
      {step === 2 && <CompleteProfile />}
    </Box>
  );
};

export default InvitationOnboarding;
