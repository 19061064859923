import React from 'react'
import { treatment_program } from '../../../constants/common';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const buttonStyles = {
    borderStyle: 'solid',
    borderColor: 'primary.main',
    borderRadius: 0,
    px: 2,
    py: 1,
    backgroundColor: 'transparent',
    '&:hover': {
        backgroundColor: 'transparent',
    }
}

const ProviderFilter = ({ setFilters, value }) => {

    function handleChange(ev) {
        setFilters((prevState) => {
            return {
                ...prevState,
                provider: ev.target.value,
                page:1
            }
        })
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pt: 1.5
            }}
        >
            <Button
                onClick={handleChange}
                value='therapists'
                color='secondary'
                sx={{
                    ...buttonStyles,
                    color: value === 'therapists' ? 'primary.main' : 'text.secondary',
                    borderBottomWidth: value === 'therapists' ? '2px' : '0',
                }}
                disableRipple
            >
                Therapists
            </Button>
            <Button
                onClick={handleChange}
                value={treatment_program}
                color='secondary'
                sx={{
                    ...buttonStyles,
                    color: value === treatment_program ? 'primary.main' : 'text.secondary',
                    borderBottomWidth: value === treatment_program ? '2px' : '0',
                }}
                disableRipple
            >
                Treatment Programs
            </Button>
        </Box>
    )
}

export default ProviderFilter