import React from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { ReactComponent as CloseIcon } from "../../assets/Icons/close.svg";
import LoadingButton from "@mui/lab/LoadingButton";
import { Controller, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { formatUrl } from "../../utils/helper";
import { postLinkSchema } from "../../validations/homepageValidation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 622,
  p: 0,
  overflow: "hidden",
};

const LinkModal = ({ open, onClose, addLink }) => {
  // Initialize React Hook Form with Zod validation
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    resolver: zodResolver(postLinkSchema),
    mode: "onChange",
  });

  // Handle modal close
  const handleClose = () => {
    onClose();
    reset();
  };

  // Handle form submission
  const onSubmit = (data) => {
    const link = {
      ...data,
      url: data.url.startsWith("http") ? data.url : `https://${data.url}`,
    };
    addLink(link);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pt: 3,
            px: 3,
            pb: 2,
          }}
        >
          <Typography variant="h3">Add Link</Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              p: 0.25,
              color: "border.dark",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            px: 3,
            pb: 3,
          }}
        >
          <TextField
            label="Label"
            {...register("label")} // Register input field
            error={!!errors.label}
            helperText={errors.label?.message}
          />
          <Controller
            name="url"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                label="URL"
                error={!!errors.url}
                helperText={errors.url?.message}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === "" || value === `https://`) {
                    field.onChange("");
                    return;
                  }
                  const formattedValue = formatUrl(value);
                  field.onChange(formattedValue);
                }}
              />
            )}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <LoadingButton
              variant="contained"
              sx={{
                minWidth: 218,
              }}
              type="submit"
              disabled={errors?.url?.message || errors?.label?.message}
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
}

export default LinkModal;
