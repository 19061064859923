import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import { useBasicInfo } from '../../../../contexts/BasicInfo';
import { LOADING, SUCCESS } from '../../../../constants/common';
import { ReactComponent as ChevronDown } from '../../../../assets/Icons/chevron-down.svg'
import { profileBasicInfoSchema } from '../../../../validations/profileValidations';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { IconButton, MenuItem, Tooltip } from '@mui/material';
import { useDegrees } from '../../../../contexts/Degrees';
import CheckBox from '../../../CheckBox';

const BasicInfoDrawer = ({ onClose, open }) => {
  const { editBasicInfo, updateInfoState, updateBasicInfoStatus, fetchInfo, data } = useBasicInfo();
  const initialState = {
    first_name: '',
    last_name: '',
    headline: '',
    professional_degree_id: 0,
    display_individual_profile: 1
  }

  const {
    list,
    fetchStatus,
    fetchUserDegrees,
  } = useDegrees();

  const [state, setState] = useState(initialState);
  const mentalHealthProvider = data?.roles?.includes('mental_health_provider');

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
  } = useForm({
    resolver: zodResolver(profileBasicInfoSchema),
    mode: "onChange",
  });

  const formValues = useWatch({ control });


  useEffect(() => {
    setState(prev => ({ ...prev, ...formValues }));
  }, [formValues]);

  useEffect(() => {
    fetchUserDegrees();
  }, [open]);

  useEffect(() => {
    if (updateBasicInfoStatus === SUCCESS) {
      updateInfoState('updateBasicInfoStatus', '')
      fetchInfo();
      handleClose();
    }
  }, [updateBasicInfoStatus]);

  useEffect(() => {
    if (data && open) {
      setValue('first_name', data.first_name || '');
      setValue('last_name', data.last_name || '');
      setValue('headline', data.info?.headline || '');
      setValue('professional_degree_id', parseInt(list?.find(degree => degree.name === data?.professional_degree || '')?.professional_degree_id || 0));
      setValue('display_individual_profile', data?.display_individual_profile);
    }
  }, [data, open, list]);


  const handleClose = () => {
    reset();
    setState(initialState);
    onClose();
  }

  const onSubmit = () => {
    if (isValid)
      editBasicInfo(state);
  }


  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Basic Information</Typography>
          <Divider sx={{ mb: 4 }} />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    label="First Name"
                    fullWidth
                    error={!!errors.first_name}
                    helperText={errors.first_name?.message}
                    value={state.first_name}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    label="Last Name"
                    fullWidth
                    error={!!errors.last_name}
                    helperText={errors.last_name?.message}
                    value={state.last_name}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'professional_degree_id'}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    variant='outlined'
                    label='Professional Degree / License'
                    fullWidth
                    SelectProps={{
                      IconComponent: () => (
                        <Box
                          sx={{
                            minWidth: 24,
                            width: 24,
                            height: 24,
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 2,
                          }}
                        >
                          <ChevronDown width={12} height={6} />
                        </Box>
                      ),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 220,
                            width: 250,
                          },
                        },
                      },
                    }}
                    value={state?.professional_degree_id || ''}
                    error={!!errors?.professional_degree_id}
                    helperText={
                      errors?.professional_degree_id?.message
                    }
                  >
                    <MenuItem key={0} value={0}>
                      Do not show
                    </MenuItem>
                    {fetchStatus === 'SUCCESS' && list?.map((degree) => (
                      <MenuItem key={degree.id} value={parseInt(degree.professional_degree_id)}>
                        {degree.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='headline'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant='outlined'
                    label='Professional Headline'
                    fullWidth
                    error={!!errors.headline}
                    helperText={errors.headline?.message}
                    value={state.headline}
                  />
                )}
              />
            </Grid>
            {
              data?.account_type == 'treatment_program' &&
              !mentalHealthProvider &&
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <CheckBox
                  label='Display My Profile'
                  value='display_individual_profile'
                  onChange={(ev) => setValue('display_individual_profile', ev.target.checked)}
                  checked={state?.display_individual_profile || false}
                />
                <Tooltip
                  arrow
                  placement='top'
                  title={
                    <Typography textAlign='start' variant='body2'>
                      When checked, your profile will appear in search results and on your organization's page.
                      When unchecked, your profile will be hidden, but you'll retain full access to the site's features.
                    </Typography>
                  }
                >
                  <IconButton
                    sx={{
                      color: 'warning.main',
                      border: '1px solid',
                      borderColor: 'warning.main',
                      backgroundColor: 'transparent',
                      p: 0.25,
                      minWidth: 21,
                      minHeight: 21,
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='10'
                      height='15'
                      viewBox='0 0 100 150'
                    >
                      <rect
                        x='45'
                        y='20'
                        width='10'
                        height='80'
                        fill='currentColor'
                      />
                      <circle cx='50' cy='120' r='10' fill='currentColor' />
                    </svg>
                  </IconButton>
                </Tooltip>
              </Grid>
            }
          </Grid>
        </Box>
        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            onClick={handleSubmit(onSubmit)}
            loading={updateBasicInfoStatus === LOADING}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default BasicInfoDrawer;