const sqlInjectionPattern = /(\b(UPDATE|DELETE\s+FROM|DROP\s+TABLE|ALTER\s+TABLE|EXEC|INSERT\s+INTO)\b\s+\w+|\b(?:1\s*=\s*1|\/\*.*?\*\/|--|\/\*.*?\*\/))/;

const hasForbiddenPatterns = (value) => {  
  return sqlInjectionPattern.test(value);
};

export const validateEmail = (email) => { 
  return String(email)
    .toLowerCase()
    .match(/^(?=[^@]{3,}@)([\w\.-_%+-]*[a-zA-Z0-9_]@(?=.{3,}\.[^.]*$)[\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z])$/);
};

/**
A minimum of 8 characters
At least one uppercase letter
At least one lowercase letter
At least one number
At least one special character from this list: #.-?!@$%^&*
 */
export const validatePassword = (password) => {
  const regex = /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~]).{12,}$/;
  return regex.test(String(password));
};

export const validatePhoneNumber = (number) => {
  if (!number) return true;  
  const regex = /^\+?1?[-.\s]?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  return regex.test(number);
};

export const validateUrl = (userInput) => {
  const urlPattern = new RegExp(
    '^((https?|ftp):\\/\\/)?' +
    '(([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,})' +
    '(\\:\\d+)?' +
    '(\\/[-a-zA-Z0-9()@:%_+.~#?&//=]*)?' +
    '(\\?[;&a-zA-Z0-9()@:%_+.~#?&//=]*)?' +
    '(\\#[-a-zA-Z0-9()@:%_+.~#?&//=]*)?$',
    'i'
  );

  const res = urlPattern.test(userInput);
  return res !== null;
};


export const validateText = (value) => {
  return /^[a-zA-Z0-9\s]+$/.test(value || '');
};

export const validateNumeric = (value) => {
  return /^[0-9]+$/.test(String(value) || '');
};

export const validateZipcode = (value) => {
  return /^\d{5}(-\d{4})?$/.test(value || '');
};

export const validateHeadline = (value) => {
  if (!value) return true;
  return !hasForbiddenPatterns(value);
  // return /^[\w\s.,!?&'"\-()@#*%]{1,80}$/.test(value || '');
}

export const validateTitle = (value) => {
  return !hasForbiddenPatterns(value);
  // && /^[A-Za-z\s'-]{1,50}$/.test(value);
}

export const validateAbout = (text) => {
  if (!text) return true;
  return !hasForbiddenPatterns(text);
  // && /^[\w\s.,!?&'"\-()@#*%:/\\]{1,2000}$/.test(text);
}

export const validateSubject = (value) => {
return !hasForbiddenPatterns(value) ;
// && /^[a-zA-Z0-9\s.,!?@#$%^&*()_+=:;/-]+$/.test(value) && !/--/.test(value);
}

/**
A minimum of 2 and maximum of 30 characters
Allowed characters are: A-Z, a-z, 0-9, ', "
*/
export const validateName = (name) => {
  if (!name) return false;
  return String(name)?.match(/^(?!.*(['"]).*\1)[a-zA-Z ]*['"]?[a-zA-Z ]*$/) &&
  // return !hasForbiddenPatterns(name) &&
    name.length >= 2 &&
    name.length <= 20;
};

export const validateOrganizationName = (name) => {
  // const regex = /^(?=.*[A-Za-z])[A-Za-z0-9\s'.,&()-]{2,50}$/;
  return !hasForbiddenPatterns(name);
};

export const validateCardHolderName = (name) => {
  const regex = /^[A-Za-z\s'-]{2,75}$/;
  return regex.test(name);
}

export const validateCardNumber = (number) => {
  const regex = /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|6(?:011|5[0-9]{2})[0-9]{12}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
  return regex.test(number);
}

export const validateMembershipId = (value) => {
  const regex = /^[a-zA-Z0-9-]{1,50}$/;
  return regex.test(value);
}

export const validateSearch = (search) => {
  if (!search) return true;
  return /^[a-zA-Z0-9\s.,@'&\-()]*$/.test(search);
}

export const validateStreetAddress = (address) => {
  if (!address) return true;
  return !hasForbiddenPatterns(address);
  // /^[a-zA-Z0-9\s.,#'&\-()\/]+$/.test(address);
}

export const validatePostContent = (content) => {
  // const forbiddenRegex = /(--|\/\*.*\*\/)/i;
  return !hasForbiddenPatterns(content);
};

export const yearValidation = (year) => {
  var text = /^[0-9]+$/;

  if (year != 0) {
    if (!text.test(year)) {
      return false;
    }

    if (year.length != 4) {
      return false;
    }
    var current_year = new Date().getFullYear();
    if (year < 1924 || year > current_year) {
      return false;
    }
    return true;
  }
  return false
};

export const parseCityState = (location) => {
  const regex = /^(.+?),\s*([A-Z]{2})$/;  
  const match = location.match(regex);
  
  if (match) {
      const city = match[1];
      const state = match[2];
      return { city, state };
  } else {
      return { city: location, state: undefined };
  }
}