import React from 'react'
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';


const SideMenu = ({ menu, selected, onClick, title, count, withBackground }) => {
    const location = useLocation();
    const content = (
        <>
            {title && <Typography variant='h4' mb={2.5}>{title}</Typography>}
            {menu?.map(({Icon, ...item}) => {
                const isActive = item.path ? location.pathname === item.path : selected?.id === item.id;
                return (
                    <Link
                        key={item.id}
                        {...(item.path ? {href: item.path} : {})}
                        variant='body2'
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            color: 'text.primary',
                            p: 2,
                            borderLeft: '3px solid transparent',
                            ...(isActive && {
                                fontWeight: 600,
                                borderColor: 'primary.main'
                            })
                        }}
                        {...(onClick ? {role: 'button'} : {})}
                        onClick={function() {
                            if (onClick) {
                                onClick(item);
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontWeight: isActive ? 600 : 400,
                                gap: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: 'border.dark'
                                }}
                            >
                                <Icon />
                            </Box>
                            {item.text}
                        </Box>
                        {count && item.countKey && (
                            <Typography
                                variant='body2'
                                sx={{
                                    backgroundColor: isActive ? 'primary.main' : '#f6f6f6',
                                    borderRadius: 21.5,
                                    minWidth: 22,
                                    height: 22,
                                    display: 'flex',
                                    alignItem: 'center',
                                    justifyContent: 'center',
                                    px: 0.875,
                                    color: isActive ? 'background.paper' : 'text.primary'
                                }}
                            >
                                {count[item.countKey]}
                            </Typography>
                        )}
                    </Link>
                )
            })}
        </>
    )
    if (withBackground) {
        return (
            <Paper
                sx={{
                    overflow: 'hidden'
                }}
            >
                {content}
            </Paper>
        )
    }
    return (
        <Box>
            {content}
        </Box>
    )
}

export default SideMenu