import React, { useState } from "react";
import Availability from "../../Availability";
import Container from "@mui/material/Container";
import LoadingButton from "@mui/lab/LoadingButton";
import { useOnboarding } from "../../../contexts/onboarding";
import { LOADING, treatment_program } from "../../../constants/common";
import { useSearchParams } from "react-router-dom";
import sortAgeRanges from "../../../utils/sortAges";
import "./style.css";
import { trackClickEvent } from "../../../mixpanel";

const OnboardingAvailability = () => {
  const {
    next,
    updateOnboardingState,
    onboardingState,
    uuid,
    signUp,
    registerStatus,
  } = useOnboarding();
  const [searchParams] = useSearchParams();
  const TP = searchParams.get("TP");
  const [error, setError] = useState(false);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (!onboardingState?.office_hours_day?.length) {
      setError(true);
      return;
    }
    if (!onboardingState?.office_hours_time?.length) {
      setError(true);
      return;
    }
    if (onboardingState.account_type === treatment_program) {
      const age_served = onboardingState.age_served
        ? sortAgeRanges(onboardingState.age_served).join(",")
        : onboardingState.age_served?.join(",");
      const payload = {
        ...onboardingState,
        office_hours_day: onboardingState.office_hours_day?.join(","),
        office_hours_time: onboardingState.office_hours_time?.join(","),
        age_served: age_served,
        uuid,
      };
      Object.keys(payload).forEach((key) => {
        if (!payload[key]) {
          delete payload[key]
        }
      });
      signUp(
        payload,
        onboardingState.account_type === treatment_program && TP !== "1"
      );
      trackClickEvent(
        'TP-Step 6 - Availability',
        { page: 'Register', button: 'Continue' }
      );
      return;
    }
    trackClickEvent(
      'U-Step 6 - Availability',
      { page: 'Register', button: 'Continue' }
    );
    next();
  }

  function handleAvailability(ev) {
    const frameAvalibility = onboardingState[ev.target.name] || [];
    if (frameAvalibility.includes(ev.target.value)) {
      updateOnboardingState({
        [ev.target.name]: frameAvalibility.filter(
          (item) => item !== ev.target.value
        ),
      });
      setError(false);
    } else {
      updateOnboardingState({
        [ev.target.name]: [...frameAvalibility, ev.target.value],
      });
      setError(false);
    }
  }

  return (
    <Container maxWidth="md">
      <Availability
        sx={{
          padding: "2rem 1rem",
          boxShadow: "0px 0px 64px rgba(0, 0, 0, 0.04)",
          border: 0,
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
        error={error}
        setData={updateOnboardingState}
        data={onboardingState}
        handleAvailability={handleAvailability}
      />

      <div className="buttons_education">
        <LoadingButton
          variant="contained"
          sx={{
            minWidth: 212,
          }}
          onClick={handleSubmit}
          loading={registerStatus === LOADING}
        >
          Continue
        </LoadingButton>
      </div>
    </Container>
  );
};

export default OnboardingAvailability;
