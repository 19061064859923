import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import Container from '@mui/material/Container'
import LoadingButton from '@mui/lab/LoadingButton'
// import Grid from '@mui/material/Grid'
import { useAuth } from '../contexts/Auth';
import { useLocation, useNavigate } from 'react-router-dom';
import paths from '../constants/paths';
import FeedBackModal from '../components/FeedBackModal';
import BreadCrumb from '../components/BreadCrumb';
import { ReactComponent as ShieldExclamation } from '../assets/Icons/shield-exclamation.svg';
import { useBasicInfo } from '../contexts/BasicInfo'
import { LOADING, treatment_program } from '../constants/common'
import SetGeoLocations from '../hooks/setGeoLocations'
import { useGeneralProfile } from '../contexts/Organization'
import InviteTherapistModal from '../components/InviteTherapistModal';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import navMenu from "../constants/navMenu"
import Paper from "@mui/material/Paper";
import Badge from "@mui/material/Badge";
import { useNotifcations } from '../contexts/Notifications';
import useResize from '../hooks/useResize'
import CustomButton from '../components/CustomButton'
import { trackClickEvent } from '../mixpanel'

const MainLayout = ({ children, currentPage, currentPageTitle, buttonProps, noGap, noWarning }) => {
  const { isLoggedIn, resendEmailVerification, resendEmailStatus, account_type } = useAuth();
  const { unSeenNotifications } = useNotifcations();
  const [openAlert, setOpenAlert] = useState(false);
  const [invitationModalVisible, setInvitationModalVisible] = useState(false);
  const navigate = useNavigate();
  const { data } = useBasicInfo();
  const { facilityInfo } = useGeneralProfile();
  const info = account_type === treatment_program ? facilityInfo : data;
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = React.useState("");
  const location = useLocation();
  const isMobileView = useResize(600);

  const handleOpenModal = () => {
    trackClickEvent(
      'Send Feedback',
      { buttonName: 'Feedback', page: location.pathname, component: 'Main layout' }
    )
    setOpen(true);
  }

  function handleCloseModal() {
    setOpen(false);
  }

  function toggleInvitationModalVisability() {
    setInvitationModalVisible(c => !c);
  }

  function handleOpenAlert() {
    setOpenAlert(true);
  }

  // function handleCloseAlert() {
  //   setOpenAlert(false);
  // }

  function resendEmail() {
    resendEmailVerification({ email: info?.email });
  }

  useEffect(() => {
    const tab = navMenu.findIndex(item => item.path === window.location.pathname);
    setSelectedTab(tab)
  }, [window.location.pathname])

  useEffect(() => {
    if (!isLoggedIn)
      navigate(paths.login);
  }, [isLoggedIn])

  useEffect(() => {
    if (Object.keys(info)?.length > 0 && !Boolean(info?.email_verified_at)) {
      handleOpenAlert();
    }
  }, [info])

  if (JSON.parse(process.env.REACT_APP_TRIGGER_GEO_LOCATION || 'false')) {
    if (!JSON.parse(localStorage.getItem('locations_set')))
      SetGeoLocations();
  }

  if (!isLoggedIn)
    return null

  return (
    <>
      <Header toggleInvitationModalVisability={toggleInvitationModalVisability} />
      {Boolean(currentPage) && (
        <BreadCrumb currentPage={currentPage} buttonProps={buttonProps} currentPageTitle={currentPageTitle} />
      )}
      <Box component='main' pt={noGap ? 0 : 3} pb={3} backgroundColor='background.default' minHeight={`calc(100% - 122px)`}>
        <Container>
          {/* <Grid container justifyContent='center' spacing={{ xs: 0, md: 3 }}> */}
          {/* <Grid xs={12} md={7} item> */}
          {openAlert && !noGap && !noWarning && (
            <Alert
              severity="error"
              sx={{
                backgroundColor: '#FAF4D4',
                borderRadius: 1,
                border: '1px solid #EECD63',
                mb: 1.5,
                mt: -1.5,
                py: 0.5,
                px: 3,
                alignItems: 'center'
              }}
              icon={<ShieldExclamation />}
            >
              Please check your inbox under {info?.email} to confirm your email. Can't find verification email? {' '}
              <LoadingButton
                size='small'
                sx={{
                  p: 0,
                  minWidth: 0,
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                }}
                onClick={resendEmail}
                loading={resendEmailStatus === LOADING}
              >
                Resend
              </LoadingButton>
            </Alert>
          )}
          {/* </Grid> */}
          {/* </Grid> */}
        </Container>
        {children}
      </Box>
      <CustomButton
        variant='contained'
        color='warning'
        customstyles={{
          borderRadius: 20,
          position: 'fixed',
          bottom: 16,
          right: 16
        }}
        onClick={handleOpenModal}
        page='Homepage'
        component='Main'
        button='Feedback'
        eventName='Send Feedback'
      >
        Feedback
      </CustomButton>
      <FeedBackModal
        open={open}
        onClose={handleCloseModal}
      />
      {invitationModalVisible ? <InviteTherapistModal
        open={invitationModalVisible}
        onClose={toggleInvitationModalVisability} /> : null}
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        {isMobileView ? <BottomNavigation
          showLabels
          value={selectedTab}
        >
          {navMenu.map((item, index) => {
            return <BottomNavigationAction onClick={() => navigate(item.path)} key={index} label={item.text} icon={item.text === "Notifications" ? (
              <Badge
                badgeContent={unSeenNotifications}
                color="error"
                size="large"
              >
                {selectedTab !== index ? <item.Icon /> : <item.DarkIcon />}
              </Badge>
            ) : selectedTab !== index ? <item.Icon /> : <item.DarkIcon />}
            />

          })}
        </BottomNavigation> : null}
      </Paper>

    </>
  )
}

export default MainLayout