import React  from 'react'
import Filter from './Filter'
import CheckBox from '../../../components/CheckBox'
import { ReactComponent as UsersIcon } from '../../../assets/Icons/users.svg';

const TrainingFilter = ({ filters, setFilters, reset, goBack }) => {

    function handleChange(ev) {
        setFilters((prevState) => {
            const arr = (prevState.trainings || [])
            const exists = Boolean(arr.find((item) => item === ev.target.value))
            return {
                ...prevState,
                trainings: (exists ? 
                    arr.filter((item) => item !== ev.target.value)
                :
                    [...arr, ev.target.value])
            }
        })
    }

    return (
        <Filter
            label='Training'
            icon={<UsersIcon />}
            goBack={goBack}
            isRigntIcon={true}
            reset={reset}
        >
            <CheckBox
                label='Certificate'
                value='certificate'
                onChange={handleChange}
                checked={Boolean((filters?.trainings || []).find((data) => data.toString() === 'certificate'))}
            />
            <CheckBox
                label='Fellowship'
                value='fellowship'
                onChange={handleChange}
                checked={Boolean((filters?.trainings || []).find((data) => data.toString() === 'fellowship'))}
            />
            <CheckBox
                label='Post-Doctoral'
                value='post-doctoral'
                onChange={handleChange}
                checked={Boolean((filters?.trainings || []).find((data) => data.toString() === 'post-doctoral'))}
            />

            <CheckBox
                label='Residency'
                value='residency'
                onChange={handleChange}
                checked={Boolean((filters?.trainings || []).find((data) => data.toString() === 'residency'))}
            />
        </Filter>
    )
}

export default TrainingFilter