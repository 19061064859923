import React, { useEffect, useState, useRef, useCallback } from 'react';
import Filter from './Filter';
import TextField from '@mui/material/TextField';
import { MenuItem, InputAdornment, IconButton, styled, Select, ListSubheader } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as MapPinIcon } from '../../../assets/Icons/map-pin.svg';
import { ReactComponent as SearchIcon } from '../../../assets/Icons/search.svg';
import { ReactComponent as ClearIcon } from '../../../assets/Icons/x.svg';
import { LOADING, treatment_program } from '../../../constants/common';
import { useCommon } from '../../../contexts/common';
import { useBasicInfo } from '../../../contexts/BasicInfo';
import { useGeneralProfile } from '../../../contexts/Organization';
import { useAuth } from '../../../contexts/Auth';
import { parseCityState } from '../../../helper/Validations';

const distances = [
  { value: 5, label: '5 mi' },
  { value: 10, label: '10 mi' },
  { value: 20, label: '20 mi' },
  { value: 30, label: '30 mi' },
  { value: 40, label: '40 mi' },
  { value: 80, label: '80 mi' },
  { value: 160, label: '160 mi' },
  { value: 320, label: '320 mi' },
  { value: 640, label: '640 mi' },
  { value: 1280, label: '1280 mi' }
];

const StyledExpandMoreIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(1),
}));


const CustomTextField = ({
  label, options = [], handler, value, name, itemName, itemValue, searchable = true, fetchData, stateId, onClear
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef();

  const filteredOptions = options.filter((option) =>
    option[itemName]?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    if (!stateId && newSearchTerm.length > 2) {
      if (fetchData) {
        fetchData(newSearchTerm);
      }
    }
  };

  useEffect(() => {
    if (inputRef.current && searchable) {
      inputRef.current.focus();
      if (!stateId && fetchData && searchTerm.length > 2) {
        fetchData(inputRef.current.value, stateId);
      }
    }
  }, [searchTerm, searchable, stateId]);

  return (
    <TextField
      select
      variant='outlined'
      fullWidth
      onChange={handler}
      value={value || ''}
      name={name || label.toLowerCase()}
      label={label}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 220,
              width: 250,
            },
          },
        },
        IconComponent: !value && StyledExpandMoreIcon,
      }}
      InputProps={{
        endAdornment: (
          <>
            {value && (
              <InputAdornment position="end" sx={{ marginRight: '-2px' }}>
                <IconButton
                  size="small"
                  onClick={() => onClear(name || label.toLowerCase())}
                  edge="end"
                  sx={{ padding: '6px' }}
                >
                  <ClearIcon width={18} height={18} />
                </IconButton>
              </InputAdornment>
            )}
          </>
        ),
      }}
    >
      {searchable && (
        <MenuItem onClick={(e) => e.stopPropagation()}>
          <TextField
            placeholder={`Search ${label}`}
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              inputRef: inputRef,
            }}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </MenuItem>
      )}

      {filteredOptions.map((item) => (
        <MenuItem key={item.value ?? item.zipcode ?? item.id} value={item[itemValue] ?? item.id}>
          {item[itemName]}
        </MenuItem>
      ))}
    </TextField>
  );
};



const LocationFilter = ({ setFilters, filters }) => {
  const { statesList, fetchCities, citiesList, fetchZipcodes, zipcodesList, getStateIdByAbbreviation } = useCommon();
  const { facilityInfo } = useGeneralProfile();
  const { account_type } = useAuth();
  const { data } = useBasicInfo();
  const [inputValue, setInputValue] = useState('');
  const [zip_code, setZipCode] = useState('');
  const debounceTimer = useRef(null);
  const info = account_type === treatment_program ? facilityInfo : data;

  const stateId = filters?.state;

  useEffect(() => {  
    if (filters?.state) {
      fetchCities({ state: filters.state });
    }
  }, [filters?.state]);
    

  useEffect(() => {
    const city = filters?.city;
    if (city) {
      clearTimeout(debounceTimer.current);
      debounceTimer.current = setTimeout(() => {
        setInputValue(city);
        fetchZipcodes({ city });
      }, 500);
    } else {
      setInputValue('');
    }

    const zipcode = filters?.zipcode;

    if (zipcode) {
      setZipCode(zipcode);
    } else {
      setZipCode('');
    }

    return () => clearTimeout(debounceTimer.current);
  }, [filters, filters?.city, filters?.zipcode]);


  const handleClear = (label) => {
    setFilters((prevState) => {
      const newState = { ...prevState };
      delete newState[label];
      return newState;
    });
  }

  const handleInput = useCallback((ev) => {
    let { name, value } = ev.target;
  
    if (name === "state") {
      setFilters(prevState => ({ ...prevState, city: '', zipcode: '' }));
      setInputValue('');
      setZipCode('');
      fetchCities({ state: value });
    } else if (name === "city") {
      const parsedState = parseCityState(value);
  
      if (parsedState.state) {
        const state = getStateIdByAbbreviation(parsedState.state);
        value = parsedState.city;
        setFilters(prevState => ({ ...prevState, state: state }));
      } else {
        setFilters(prevState => ({ ...prevState }));
      }
      setZipCode('');
    }

    setFilters(prevState => ({
      ...prevState,
      [name]: value || ''
    }));
  }, [fetchCities, setFilters]);

  const handleZipCodeInput = useCallback(ev => {
    setZipCode(ev.target.value);
    setFilters(prev => ({ ...prev, zipcode: ev.target.value || '' }));
  }, [fetchZipcodes, setFilters]);

  const fetchCitiesBySearch = (input) => {
    clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => {
      const params = { name: input };
      fetchCities(params);
    }, 700);
  }

  const fetchZipcodeBySearch = (input) => {
    clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => {
      const params = { search: input };
      fetchZipcodes(params);
    }, 700);
  }

  return (
    <Filter label='Location' icon={<MapPinIcon />} expandedDefault>
      <CustomTextField 
        label='State' 
        options={statesList} 
        handler={handleInput} 
        value={filters?.state}
        itemName='name'
        onClear={handleClear}
      />

      <CustomTextField 
        label='City' 
        options={citiesList} 
        handler={handleInput} 
        value={inputValue}
        itemName='name'
        itemValue='name'
        fetchData={fetchCitiesBySearch}
        stateId={filters?.state || undefined}
        onClear={handleClear}
      />

      <CustomTextField 
        label='Zipcode' 
        options={zipcodesList} 
        handler={handleZipCodeInput} 
        value={zip_code} 
        name='zipcode'
        itemName='zipcode'
        itemValue='zipcode'
        fetchData={fetchZipcodeBySearch}
        stateId={filters?.city || undefined}
        onClear={handleClear}
      />

      <CustomTextField 
        label='Distance' 
        options={distances} 
        handler={handleInput} 
        value={filters?.distance}
        itemName='label'
        itemValue='value'
        searchable={false}
        onClear={handleClear}
      />
    </Filter>
  );
};

export default LocationFilter;
