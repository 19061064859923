import { useCommon } from "../contexts/common";
import { useDemographics } from "../contexts/Demographics";
import { useFees } from "../contexts/Fees";
import { useLanguages } from "../contexts/Languages";
import { useSpecialties } from "../contexts/specialties";
import { useTreatment } from "../contexts/teamentModalities";

export const useMapFilter = (filters) => {
  const { statesList, clientTypesList, degreesList } = useCommon();
  const { insurances } = useFees();
  const { specialities } = useSpecialties();
  const { treatmentModalities } = useTreatment();
  const { gendersList, racesList, sexualOrientationsList, religionsList } = useDemographics();
  const { languagesList } = useLanguages();

  let newFilters = {
    ...filters
  };

  if (filters?.state) {
    newFilters.state = statesList?.find(state => state.id == filters.state)?.name;
  }

  if (filters?.insurance) {
    newFilters.insurance = filters.insurance.map(insurance =>
      insurances?.find(i => i.id == insurance)?.name
    );
  }

  if (filters?.population) {
    newFilters.population = filters.population.map(population =>
      clientTypesList?.find(c => c.id == population)?.name
    );
  }

  if (filters?.specialties) {
    newFilters.specialties = filters.specialties.map(specialty =>
      specialities?.find(c => c.id == specialty)?.name
    );
  }

  if (filters?.modalities) {
    newFilters.modalities = filters.modalities.map(modality =>
      treatmentModalities?.find(c => c.id == modality)?.name
    );
  }

  if (filters?.gender_ids) {
    newFilters.gender_ids = filters.gender_ids.map(gender =>
      gendersList?.find(c => c.id == gender)?.name
    );
  }

  if (filters?.races) {
    newFilters.races = filters.races.map(race =>
      racesList?.find(c => c.id == race)?.name
    );
  }

  if (filters?.sexualOrientationsList) {
    newFilters.sexualOrientationsList = filters.sexualOrientationsList.map(sexualOrientation =>
      sexualOrientationsList?.find(c => c.id == sexualOrientation)?.name
    );
  }

  if (filters?.religions) {
    newFilters.religions = filters.religions.map(religion =>
      religionsList?.find(c => c.id == religion)?.name
    );
  }

  if (filters?.degrees) {
    newFilters.degrees = filters.degrees.map(degree =>
      degreesList?.find(c => c.id == degree)?.name
    );
  }

  if (filters?.language_ids) {
    newFilters.language_ids = filters.language_ids.map(language =>
      languagesList?.find(c => c.id == language)?.name
    );
  }

  return newFilters;
};
