import { axiosInstance } from "./axiosInstance";

function updateAccount(data) {
    return axiosInstance.put('settings/basic-info', data);
}

function updatePassword(data) {
    return axiosInstance.put('password', data);
}

export {
    updateAccount,
    updatePassword
}