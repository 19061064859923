import React, { createContext, useContext, useEffect } from "react";
import Cookies from "universal-cookie";
import {
  changePassword,
  forgetPassword,
  login,
  verifyCode,
  resendEmail,
  resendCode,
  resetPassword,
} from "../services/auth.services";
import { useState } from "react";
import {
  ERROR,
  LOADING,
  SUCCESS,
  domain,
  treatment_program,
} from "../constants/common";
import { useAlert } from "./Alert";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import paths from "../constants/paths";
import { identifyUser } from '../mixpanel';

const AuthContext = createContext({});
const cookies = new Cookies();
let intervalId;

export const AuthContextProvider = ({ children }) => {
  const { sendAlert } = useAlert();
  const initialUserData = {
    first_name: "",
    last_name: "",
  };

  const [authState, setAuthState] = useState({
    loginStatus: "",
    userData: initialUserData,
    forgetPasswordStatus: "",
    email: "",
    resetPasswordStatus: "",
    isLoggedIn: JSON.parse(localStorage.getItem("isLoggedIn") || "false"),
    account_type: localStorage.getItem("account_type") || "",
    resendEmailStatus: "",
    isLogginBlocked: true,
    loginBlockDate: localStorage.getItem("loginBlockDate") || "",
    countDown: "30:00",
    updatePasswordStatus: "",
    isCodeSent: false,
    tempUserData: {},
    isVerificationStep: false,
  });
  const [step, setStep] = useState(parseInt(localStorage.getItem('step') || 1));

  const next = () => {
    setStep((prevState) => prevState + 1);
  }

  useEffect(() => {
    localStorage.setItem('step', step);
  }, [step]);

  const prev = () => {
    setStep((prevState) => {
      const newStep = prevState > 1 ? prevState - 1 : prevState;
      localStorage.removeItem('step');
      return newStep;
    });
  };

  const handleState = (obj) => {
    setAuthState((prevState) => ({
      ...prevState,
      ...obj,
    }));
  }

  const signIn = async (data) => {
    handleState({ loginStatus: LOADING });

    try {
      const response = await login(data);
      if (response?.data?.message === "success") {
        handleState({
          loginStatus: SUCCESS,
          isVerificationStep: true,
          email: data.email,
        });
        sendAlert(`${response.data?.data?.message}`, "success");
        setStep(2);
      } else if (response.data?.data?.error) {
        sendAlert(response.data.data.error, "error");
        handleBlockLogin(response.data.data.error);
      } else {
        // if (response.data?.error) {
        //   sendAlert(response.data?.error, "error");
        //   if(!localStorage.getItem("loginBlockDate")) {
        //     localStorage.setItem("loginBlockDate", moment());
        //     handleState({
        //       isLogginBlocked: true,
        //       loginBlockDate: moment(),
        //     });
        //   }
        // } else if (response?.data?.data?.access_token) {
        //   localStorage.setItem("accessToken", response.data.data.access_token);
        //   localStorage.setItem("userId", response?.data?.data?.user?.id);
        //   localStorage.setItem(
        //     "account_type",
        //     response.data.data?.user?.account_type
        //   );
        //   localStorage.setItem("isLoggedIn", "true");
        //   identifyUser(response.data.data?.user);
        //   const expirationDate = new Date();
        //   expirationDate.setDate(expirationDate.getDate() + 1);
    
        //   cookies.set("isLoggedIn", "true", {
        //     expires: expirationDate,
        //     domain: `.${domain}`,
        //     secure: true,
        //   });
        //   handleState({
        //     loginStatus: SUCCESS,
        //     userData: response?.data?.data?.user,
        //     isLoggedIn: true,
        //     account_type: response?.data?.data?.user?.account_type,
        //     isLogginBlocked: false,
        //   });
        // }
        // if ((days < 90 && days >= 85) && response?.data?.data?.user?.account_type === treatment_program) {
        //   sendAlert(`Password Expiration Notice: Your password will expire ${90 - Math.round(days)} days`, "error");
        // } else {
        //   sendAlert("Logged in successfully", "success");
        // }
        handleSuccessfulLogin(response);
      }
    } catch (error) {
      handleLoginError(error);
    }
  }

  const verifyLoginCode = async (code) => {
    handleState({ loginStatus: LOADING });
    try {
      const response = await verifyCode({ code, email: authState?.email });

      if (response.data?.message === "success") {
        handleSuccessfulLogin(response);
      } else {
        sendAlert("Invalid verification code. Please try again.", "error");
        handleState({ loginStatus: ERROR });
      }
    } catch (error) {
      handleLoginError(error);
    }
  }

  const resendCodeVerification = async () => {
    handleState({ resendEmailStatus: LOADING });
    try {
      const response = await resendCode({ email: authState?.email });
      console.log(response, response.status);
      if (response.status === 200) {
        sendAlert(response?.data?.data?.message || "Email has been sent", "success");
        handleState({ resendEmailStatus: SUCCESS });
      } else {
        handleState({ resendEmailStatus: ERROR });
      }
      return response;
    } catch (error) {
      handleState({ resendEmailStatus: ERROR });
      sendAlert(error.response?.data?.message, "error");
      return error;
    }
  }
  
  const handleSuccessfulLogin = (response) => {
    localStorage.setItem("accessToken", response.data.data.access_token);
    localStorage.setItem("userId", response?.data?.data?.user?.id);
    localStorage.setItem("account_type", response.data.data?.user?.account_type);
    localStorage.setItem("isLoggedIn", "true");

    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1);
    cookies.set("isLoggedIn", "true", {
      expires: expirationDate,
      domain: `.${domain}`,
      secure: true,
    });

    handleState({
      loginStatus: SUCCESS,
      userData: response.data.data.user,
      isLoggedIn: true,
      account_type: response.data.data.user.account_type,
      isLogginBlocked: false,
      isVerificationStep: false, // Reset verification step state
      tempUserData: {}, // Clear temporary data
    });
    setStep(1);
    sendAlert("Logged in successfully", "success");
  }

  const handleLoginError = (error) => {
    handleState({ loginStatus: ERROR });
    if (error.response?.data?.error == "") {
      sendAlert(null, "error", true);
    } else {
      sendAlert(
        error.response?.data?.error || error.response?.data?.message,
        "error"
      );
    }
  }

  const handleBlockLogin = (error) => {
    if (!localStorage.getItem("loginBlockDate")) {
      localStorage.setItem("loginBlockDate", moment());
      handleState({
        isLogginBlocked: true,
        loginBlockDate: moment(),
      });
    }
  }

  const forgotPassword = async (data) => {
    handleState({ forgetPasswordStatus: LOADING });
    try {
      const response = await forgetPassword(data);
      if (response.status === 200) {
        setStep(2);
        localStorage.setItem('timerStart', Date.now().toString());
        localStorage.setItem('step', 2);
        localStorage.setItem('email', data.email);
        sendAlert('Reset password email sent', "success");
        handleState({
          forgetPasswordStatus: SUCCESS,
        });
      }
      return response;
    } catch (error) {
      handleState({ forgetPasswordStatus: ERROR });
      sendAlert(error.response?.data?.message, "error");
      Promise.reject(error);
    }
  }

  const passwordReset = async (data) => {
    handleState({ resetPasswordStatus: LOADING });
    try {
      const response = await resetPassword(data);
      if (response.status === 200) {
        sendAlert("Password has been reset successfully!", "success");
        handleState({
          resetPasswordStatus: SUCCESS,
        });
        if (step === 2) {
          sendAlert("Your request has been sent successfully", "success");
        }
      }
      return response;
    } catch (error) {
      handleState({ resetPasswordStatus: ERROR });
      sendAlert(error.response?.data?.message, "error");
      Promise.reject(error);
    }
  }

  const resendEmailVerification = async (data) => {
    handleState({ resendEmailStatus: LOADING });
    try {
      const response = await resendEmail(data);
      sendAlert("Email has been sent", "success");
      handleState({ resendEmailStatus: SUCCESS });
      return response;
    } catch (error) {
      handleState({ resendEmailStatus: ERROR });
      sendAlert(error.response?.data?.message, "error");
      Promise.reject(error);
    }
  }

  const updatePassword = async (data) => {
    handleState({ updatePasswordStatus: LOADING });
    try {
      const response = await changePassword(data);
      sendAlert("Password has been changed successfully!", "success");
      handleState({
        updatePasswordStatus: SUCCESS,
      });
      return response;
    } catch (error) {
      handleState({ updatePasswordStatus: ERROR });
      const errorsValues =
        error.response?.data?.errors &&
        Object.keys(error.response?.data?.errors || {}).map((key) => {
          if (typeof error.response?.data?.errors[key] === "object") {
            if (Array.isArray(error.response?.data?.errors[key])) {
              return error.response?.data?.errors[key];
            }
            return "";
          }
          return error.response?.data?.errors[key];
        });
      sendAlert(
        errorsValues || error.response?.data?.message,
        "error"
      );
      Promise.reject(error);
    }
  }

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("step");
    localStorage.removeItem("account_type");
    localStorage.removeItem("onboardingData");
    cookies.remove("isLoggedIn", { domain: `.${domain}` });
    handleState({
      loginStatus: "",
      userData: initialUserData,
      isLoggedIn: false,
    });
  }

  const updateAuthState = (field, value) => {
    handleState({ [field]: value });
  }

  const goBackLogin = () => {
    setStep(1);
  }

  useEffect(() => {
    if (localStorage.getItem("loginBlockDate")) {
      intervalId = setInterval(() => {
        const fiveMinutesAfter = moment(authState.loginBlockDate).add(
          30,
          "minutes"
        );
        const isBlocked = moment().isBefore(fiveMinutesAfter);
        const duration = moment.duration(fiveMinutesAfter.diff(moment()));
        const sec_num = Math.round(duration.asSeconds());
        let minutes = Math.floor(sec_num / 60);
        let seconds = sec_num - minutes * 60;

        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        handleState({
          isLogginBlocked: isBlocked,
          countDown: minutes + ":" + seconds,
        });
        if (!isBlocked) {
          handleState({
            isLogginBlocked: false,
          });
          localStorage.removeItem("loginBlockDate");
          clearInterval(intervalId);
        }
      }, 1000);
    } else {
      handleState({
        isLogginBlocked: false,
      });
      clearInterval(intervalId);
    }
  }, [authState.loginBlockDate]);

  const state = {
    ...authState,
    signIn,
    forgotPassword,
    verifyLoginCode,
    step,
    next,
    prev,
    goBackLogin,
    passwordReset,
    logout,
    resendEmailVerification,
    resendCodeVerification,
    updatePassword,
    updateAuthState,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
