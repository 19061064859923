// src/components/Verification.jsx

import React, { useState, useEffect } from "react";
import { TextField, Button, Container, Typography, Box } from "@mui/material";
import { useAuth } from "../../contexts/Auth";
import { Link, useNavigate } from "react-router-dom";
import { SUCCESS, LOADING } from "../../constants/common";
import paths from "../../constants/paths";

const Verification = () => {
  const { verifyLoginCode, resendCodeVerification, loginStatus, updateAuthState, goBackLogin } = useAuth();
  const [code, setCode] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [resendTimer, setResendTimer] = useState(60);
  const [resendAttempts, setResendAttempts] = useState(null);
  const navigate = useNavigate();

  const handleCodeChange = (e) => {
    setCode(e.target.value);
    setIsInvalid(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (code.length === 6) {
      verifyLoginCode(code);
    } else {
      setIsInvalid(true);
    }
  };

  const handleResend = async () => {
    try {
      setIsResendDisabled(true);
      setResendTimer(60);
      const response = await resendCodeVerification();
      if (response?.data?.data?.message) {
        setResendAttempts(5 - response?.data?.data?.resend_attempts)
      } else {
        setIsResendDisabled(false);
      }
    } catch (err) {
      setIsResendDisabled(false);
      console.log(err);
    }
  };

  useEffect(() => {
    let timer;
    if (isResendDisabled) {
      timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev > 0) {
            return prev - 1;
          } else {
            clearInterval(timer);
            setIsResendDisabled(false);
            return 0;
          }
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isResendDisabled, resendTimer]);

  useEffect(() => {
    if (loginStatus === SUCCESS) {
      setCode("");
      setIsInvalid(false);
      navigate(paths.home);
      updateAuthState("loginStatus", "");
    }
  }, [loginStatus, navigate, updateAuthState]);

  return (
    <Container maxWidth="xs" sx={{ minHeight: "75vh" }}>
      <Typography variant="h3" mb={2}>
        Verify Your Account
      </Typography>
      <Typography variant="body2" mb={4}>
        Enter the six-digit code sent to your email.
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*$/.test(value) && value.length <= 6) {
              handleCodeChange(e);
            }
          }}
          required
          fullWidth
          id="code"
          label="Verification Code"
          name="code"
          autoComplete="off"
          value={code}
          error={isInvalid && code.length !== 6}
          helperText={isInvalid && code.length !== 6 ? "Invalid code. Please enter a 6-digit code." : ""}
          inputProps={{
            inputMode: "numeric",
          }}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={loginStatus === LOADING}
        >
          Verify Code
        </Button>
        <Button
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
          onClick={handleResend}
          disabled={isResendDisabled || loginStatus === LOADING}
        >
          Resend Code {isResendDisabled && resendTimer > 0 && `(${resendTimer}s)`}
        </Button>
        {resendAttempts ? <Typography>Resend Attempts left: {resendAttempts}</Typography> : ''}
        <Box sx={{display: 'flex', justifyContent: 'center', fontSize: 14}}>
          <Link
            onClick={() => goBackLogin()}
          >
            Go to Login Page
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default Verification;
