import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { usePlacesWidget } from "react-google-autocomplete";
import { Controller } from "react-hook-form";

/**
 * Component for Google Auto Complete Input
 * @param {object} props
 * @param {function} props.onChange - Handle the change event of Input
 * @component
 */


const PlaceAutoComplete = ({
  name,
  value,
  onChange,
  onKeyPress = null,
  error,
  label,
  helperText,
  control,
  placeholder = "",
  disable = false,
  defaultValue = "",
  ...rest
}) => {

  useEffect(() => {
    if (materialRef && defaultValue) {
      materialRef.current.value = defaultValue || "";
    }
  }, [defaultValue]);


  const { ref: materialRef } = usePlacesWidget({
    apiKey: "AIzaSyBO1ZlrBp68Oj02lrpoF4gJ7DbuzaFQLNA",
    onPlaceSelected: (place) => {
      let city = "";
      let state = "";
      let zipCode = "";

      if (place.address_components !== undefined) {
        let addrComp = place.address_components;
        for (let i = 0; i < addrComp.length; ++i) {
          let typ = addrComp[i].types;
          if (compIsType(typ, "administrative_area_level_1")) {
            state = addrComp[i].long_name;
          }
          if (compIsType(typ, "locality")) {
            city = addrComp[i].long_name;
          }
          if (compIsType(typ, "postal_code")) {
            zipCode = addrComp[i].long_name;
          }
        }
      }

      const selectedAddress = {
        location: place?.formatted_address || "",
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
        city,
        zipCode,
        state,
      };

      onChange(selectedAddress);
    },
    options: {
      types: ["geocode"],
      componentRestrictions: { country: "usa" },
    },
  });

  function compIsType(t, s) {
    for (let z = 0; z < t.length; ++z) if (t[z] === s) return true;
    return false;
  }

  const renderTextField = (fieldProps) => (
    <TextField
    {...fieldProps}
    label={label}
    name={name}
    error={error}
    helperText={helperText}
    inputRef={materialRef}
    placeholder={placeholder}
    disabled={disable}
    onKeyUp={onKeyPress}
    {...rest}
    />
  );

  return control ? (
    <Controller
      name={name}
      control={control}
      render={({ field }) => renderTextField(field)}
    />
  ) : (
    renderTextField()
  );
};

export default PlaceAutoComplete;
