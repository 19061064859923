import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import { ReactComponent as CloseIcon } from "../../../assets/Icons/close.svg";
import { ReactComponent as DaysIcon } from "../../../assets/Icons/30days.svg";
import { ReactComponent as SafeIcon } from "../../../assets/Icons/safe.svg";
import CheckoutForm from "./CheckoutForm";
import { useOnboarding } from "../../../contexts/onboarding";
import "./style.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY); // starts with pk_
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 622,
  p: 0,
  overflow: "hidden",
};

function CheckoutModal({ open, onClose }) {
  const { plans, onboardingState, Specialplans } = useOnboarding();
  function handleClose() {
    onClose();
  }

  const selectedPlan =
    plans.length > 0
      ? plans.find((item) => item.id === +onboardingState.payment_method_id)
      : Specialplans.find(
        (item) => item.id === +onboardingState.payment_method_id
      );

  return (
    <Elements stripe={stripePromise}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          zIndex: 999
        }}
      >
        <div className="modalCard" sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#F5F6F7",
              px: 3,
              py: 2,
            }}
          >
            <Box
              sx={{
                display: "block",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography variant="h4">{selectedPlan?.name}</Typography>
              {selectedPlan?.period == "monthly" && (
                <Typography variant="body2" color="text.secondary">
                  ${selectedPlan?.price}/ month (Billed monthly)
                </Typography>
              )}
              {selectedPlan?.period == "yearly" && (
                <Typography variant="body2" color="text.secondary">
                  ${(selectedPlan?.price / 12).toFixed(2)}/ month (Billed yearly $
                  {selectedPlan?.price})
                </Typography>
              )}
              {selectedPlan?.period == "half-yealy" && (
                <Typography variant="body2" color="text.secondary">
                  ${(selectedPlan?.price / 6 - 0.01).toFixed(2)}/ month (one-time payment $
                  {selectedPlan?.price})
                </Typography>
              )}
              <Typography variant="body3" color="text.secondary">
                (Plus Applicable Taxes)
              </Typography>
            </Box>
            <IconButton
              onClick={handleClose}
              sx={{
                p: 0.25,
                color: "border.dark",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <CheckoutForm open={open} />
          <Box
            sx={{
              backgroundColor: "#F5F6F7",
              px: 3.25,
              py: 2,
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                  }}
                >
                  <Box>
                    <DaysIcon />
                  </Box>
                  <Box>
                    <Typography
                      variant="caption"
                      component="p"
                      fontWeight={600}
                      mb={1}
                    >
                      30-DAY FREE TRIAL
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      Try us risk-free for 30 days. If you’re not satisfied
                      there’s no charge.
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                  }}
                >
                  <Box>
                    <SafeIcon />
                  </Box>
                  <Box>
                    <Typography
                      variant="caption"
                      component="p"
                      fontWeight={600}
                      mb={1}
                    >
                      SSL SECURE PAYMENT
                    </Typography>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      whiteSpace="word"
                    >
                      Your information is protected by 256-bit SSL encryption.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Modal>
    </Elements>
  );
}

export default CheckoutModal;
