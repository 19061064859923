import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const Login = () => {
 
    useEffect(()=>{
    localStorage.clear()
    },[])

    return (
        <Container className='hideShadow' sx={{minHeight:'75vh'}} maxWidth='xs'>
            <div className='formStyle'>
                <Typography variant='h4' sx={{marginBottom:'8px'}} mb={2} textAlign={"center"} >
                    Thank you for registering
                </Typography>
                <Typography variant="h4" sx={{margin:'46px',marginTop:"4px"}} textAlign={"center"} >
                    We will contact you within 72 hours
                </Typography>
            </div>
        </Container>
    )
}

export default Login