import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import FeesFilter from "./Fees";
import ClientTypesFilter from "./ClientTypes";
import SpecialtiesFilter from "./Specialties";
import TreatmentModalitiesFilter from "./TreatmentModalities";
import AvailabilityFilter from "./Availability";
import DemographicsFilter from "./Demographics";
import UserTypesFilter from "./UserTypes";
import LocationFilter from './Location'
import InsurancePlansFilter from "./InsurancePlans";
import CurrentStatusFilter from "./CurrentStatus";
import DegreesFilter from "./Degrees";
import CredentialsFilter from "./Credential";
import TrainingFilter from "./Training";
import ProviderFilter from "./Provider";
import LanguagesFilter from "./Languages";
import { ReactComponent as CloseFilterIcon } from "../../../assets/Icons/filter-close2.svg";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as ChevronRight } from "../../../assets/Icons/chevron-right.svg";

const Filters = ({
  setFilters,
  filters,
  reset,
  isMobileView,
  closeFilters,
  filterLength
}) => {
  const activeFiltersCount = Object.keys(filters)?.filter(
    (item) => !["sortBy", "search", "page"].includes(item)
  )?.length;
  const [activeMenu, setActiveMenu] = useState("main");

  return (
    <Paper>
      <CSSTransition
        in={activeMenu === "main"}
        unmountOnExit
        timeout={500}
        classNames="menu-primary"
      >
        <Box
          sx={{
            pt: 1.5,
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            mb:5
          }}
          className="menu"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: isMobileView ? "100vw" : "100%",
              py: 2,
              px:1.5,
              position:"fixed",
              zIndex:1000000,
              top:0,
              borderBottom:"1px solid #EEEFF0",
              backgroundColor:"#ffffff",
              mb:1.5

            }}
          >
            {isMobileView ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Button
                  sx={{
                    p: 0,
                    minWidth: 0,
                    textDecoration: !isMobileView ? "none" : "underline",
                    color: "#0C8CE9",
                  }}
                  onClick={closeFilters}
                >
                  <CloseFilterIcon />
                </Button>
              </Box>
            ) : null}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography variant="h5">
                Filter{" "}
                {Boolean(activeFiltersCount) && `(${activeFiltersCount})`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Button
                sx={{
                  p: 0,
                  minWidth: 0,
                  fontSize:"14px",
                  textDecoration: !isMobileView ? "none" : "underline",
                  color: "#0C8CE9",
                }}
                onClick={reset}
              >
                {!isMobileView ? " Reset all" : "Clear all"}
              </Button>
            </Box>
          </Box>
          <Divider />
          <Box sx={{mt:4}}>
            <ProviderFilter
              value={filters?.provider || ""}
              setFilters={setFilters}
            />
          </Box>
          {/* <Divider /> */}
          <LocationFilter   
           filters={filters || {}}
            setFilters={setFilters}
            />
          <Divider />
          <UserTypesFilter
            value={filters?.user_types || []}
            setFilters={setFilters}
          />
          <Divider />
          <CurrentStatusFilter
            value={filters.accepting_clients || ""}
            setFilters={setFilters}
          />
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
              py:1.5,
              cursor: "pointer",
            }}
            onClick={() => setActiveMenu("insurence")}
          >
            <Typography variant="body2" fontWeight={600}>
            Insurance Plans
            </Typography>
            <ChevronRight />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
              py:1.5,
              cursor: "pointer",
            }}
            onClick={() => setActiveMenu("fees")}
          >
            <Typography variant="body2" fontWeight={600}>
              Fees
            </Typography>
            <ChevronRight />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
              py:1.5,
              cursor: "pointer",
            }}
            onClick={() => setActiveMenu("ClientsServed")}
          >
            <Typography variant="body2" fontWeight={600}>
              Clients Served
            </Typography>
            <ChevronRight />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
              py:1.5,
              cursor: "pointer",
            }}
            onClick={() => setActiveMenu("Specialities")}
          >
            <Typography variant="body2" fontWeight={600}>
              Specialities
            </Typography>
            <ChevronRight />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
              py:1.5,
              cursor: "pointer",
            }}
            onClick={() => setActiveMenu("Treatement")}
          >
            <Typography variant="body2" fontWeight={600}>
              Treatment Modalities
            </Typography>
            <ChevronRight />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
              py:1.5,
              cursor: "pointer",
            }}
            onClick={() => setActiveMenu("Availability")}
          >
            <Typography variant="body2" fontWeight={600}>
              Office Hours
            </Typography>
            <ChevronRight />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
              py:1.5,
              cursor: "pointer",
            }}
            onClick={() => setActiveMenu("Demographics")}
          >
            <Typography variant="body2" fontWeight={600}>
              Provider Demographics
            </Typography>
            <ChevronRight />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
              py:1.5,
              cursor: "pointer",
            }}
            onClick={() => setActiveMenu("degree")}
          >
            <Typography variant="body2" fontWeight={600}>
              Education
            </Typography>
            <ChevronRight />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
              py:1.5,
              cursor: "pointer",
            }}
            onClick={() => setActiveMenu("credentials")}
          >
            <Typography variant="body2" fontWeight={600}>
              Credentials
            </Typography>
            <ChevronRight />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
              py:1.5,
              cursor: "pointer",
            }}
            onClick={() => setActiveMenu("training")}
          >
            <Typography variant="body2" fontWeight={600}>
              Training
            </Typography>
            <ChevronRight />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
              py:1.5,
              cursor: "pointer",
            }}
            onClick={() => setActiveMenu("languages")}
          >
            <Typography variant="body2" fontWeight={600}>
              Languages
            </Typography>
            <ChevronRight />
          </Box>
        </Box>
       
      </CSSTransition>
{/** //////////////////////////////////////////////////////////////////////////////////////////////// */}
      <Box sx={{mb:3}}>
        <CSSTransition
          in={activeMenu === "fees"}
          unmountOnExit
          timeout={500}
          classNames="menu-fees"
        >
          <FeesFilter
            filters={filters}
            setFilters={setFilters}
            goBack={() => setActiveMenu("main")}
            reset={reset}
          />
        </CSSTransition>
        <Divider />
        <CSSTransition
          in={activeMenu === "insurence"}
          unmountOnExit
          timeout={500}
          classNames="menu-fees"
        >
          <InsurancePlansFilter
            isMobileView={isMobileView}
            value={filters.insurance || []}
            setFilters={setFilters}
            goBack={() => setActiveMenu("main")}
            reset={reset}
          />
        </CSSTransition>
        <Divider />
        <CSSTransition
          in={activeMenu === "ClientsServed"}
          unmountOnExit
          timeout={500}
          classNames="menu-ClientsServed"
        >
          <ClientTypesFilter
            filters={filters}
            setFilters={setFilters}
            goBack={() => setActiveMenu("main")}
            reset={reset}
          />
        </CSSTransition>
        <Divider />
        <CSSTransition
          in={activeMenu === "Specialities"}
          unmountOnExit
          timeout={500}
          classNames="menu-Specialities"
        >
          <SpecialtiesFilter
            isMobileView={isMobileView}
            value={filters.specialties || []}
            setFilters={setFilters}
            goBack={() => setActiveMenu("main")}
            reset={reset}
          />
        </CSSTransition>
        <Divider />
        <CSSTransition
          in={activeMenu === "Treatement"}
          unmountOnExit
          timeout={500}
          classNames="menu-Treatement"
        >
          <TreatmentModalitiesFilter
            value={filters.modalities || []}
            setFilters={setFilters}
            isMobileView={isMobileView}
            goBack={() => setActiveMenu("main")}
            reset={reset}
          />
        </CSSTransition>
        <Divider />
        <CSSTransition
          in={activeMenu === "Availability"}
          unmountOnExit
          timeout={500}
          classNames="menu-Availability"
        >
          <AvailabilityFilter
            filters={filters}
            setFilters={setFilters}
            goBack={() => setActiveMenu("main")}
            reset={reset}
          />
        </CSSTransition>
        <Divider />
        <CSSTransition
          in={activeMenu === "Demographics"}
          unmountOnExit
          timeout={500}
          classNames="menu-Demographics"
        >
          <DemographicsFilter
            filters={filters}
            setFilters={setFilters}
            goBack={() => setActiveMenu("main")}
            reset={reset}
          />
        </CSSTransition>
        <Divider />
        <CSSTransition
          in={activeMenu === "degree"}
          unmountOnExit
          timeout={500}
          classNames="menu-degree"
        >
          <DegreesFilter
            filters={filters}
            setFilters={setFilters}
            isMobileView={isMobileView}
            goBack={() => setActiveMenu("main")}
            reset={reset}
          />
        </CSSTransition>
        <Divider />
        <CSSTransition
          in={activeMenu === "credentials"}
          unmountOnExit
          timeout={500}
          classNames="menu-credentials"
        >
          <CredentialsFilter
            filters={filters}
            setFilters={setFilters}
            isMobileView={isMobileView}
            goBack={() => setActiveMenu("main")}
            reset={reset}
          />
        </CSSTransition>

        <Divider />
        <CSSTransition
          in={activeMenu === "training"}
          unmountOnExit
          timeout={500}
          classNames="menu-training"
        >
          <TrainingFilter
            filters={filters}
            setFilters={setFilters}
            isMobileView={isMobileView}
            goBack={() => setActiveMenu("main")}
            reset={reset}
          />
        </CSSTransition>

        <Divider />
        <CSSTransition
          in={activeMenu === "languages"}
          unmountOnExit
          timeout={500}
          classNames="menu-languages"
        >
          <LanguagesFilter
            filters={filters}
            setFilters={setFilters}
            goBack={() => setActiveMenu("main")}
            reset={reset}
          />
        </CSSTransition>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: isMobileView ? "100vw" : "100%",
          py: 1,
          px:3,
          position:"fixed",
          zIndex:1000000,
          bottom:0,
          borderBottom:"0.33px solid #A3A6A8",
          backgroundColor:"#ffffff",
          mt:3

        }}
      >
        <Button
          variant="contained"
          onClick={closeFilters}
          sx={{
            py: 0.75,
            whiteSpace: "nowrap",
            maxWidth: { sm: 140, md: "none" },
            flex: 1,
          }}
        >
          Show Results {`(${filterLength})`}
        </Button>
      </Box>
    </Paper>
  );
};

export default Filters;
