import React, { createContext, useContext, useState } from 'react';
import { ERROR, LOADING, SUCCESS, received, sent } from '../constants/common';
import { useAlert } from './Alert';
import { acceptReferral, getArchivedReferrals, getReferrals, getReferralsCount, getSentReferrals, markAsRead, rejectReferral, sendReferral } from '../services/referral.services';


const ReferralsContext = createContext({});

export const ReferralsContextProvider = ({ children }) => {
    const { sendAlert } = useAlert();

    const [referralsState, setReferralsState] = useState({
        referrals: {
            data: []
        },
        getReferralCountStatus: '',
        count: {
            received_count: 0,
            sent_count: 0
        },
        fetchStatus: '',
        sendReferralStatus: '',
        acceptReferralStatus: '',
        rejectReferralStatus: '',
        markReferralStatus: ''
    });

    function handleState(obj) {
        setReferralsState((prevState) => ({
            ...prevState,
            ...obj
        }))
    }
 
    async function fetchReferrals(params = {}, type) {
        handleState({ fetchStatus: LOADING })
        try {
            let response
            if (!type || type === received)
                response = await getReferrals(params);
            if (type === sent)
                response = await getSentReferrals(params);
            // if (type === 3)
            //     response = await getArchivedReferrals(params);
                
            handleState({
                // referrals: params.page > 1 ? {
                //     ...response.data.data,
                //     data: [...referralsState.referrals.data, ...response.data.data.data]
                // } : response.data.data,
                referrals: { data: response.data.data },
                fetchStatus: SUCCESS,
            })
        } catch (error) {
            handleState({ fetchStatus: ERROR })
            Promise.reject(error)
        }
    }

    async function fetchReferralsCount(data) {
        handleState({ getReferralCountStatus: LOADING })
      try {
        const response = await getReferralsCount(data);
        handleState({
            getReferralCountStatus: SUCCESS,
            count: response.data.data
        })
      } catch (error) {
        handleState({ getReferralCountStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }
    

    async function sendReferal(data) {
        handleState({ sendReferralStatus: LOADING })
      try {
        await sendReferral(data);
        handleState({
            sendReferralStatus: SUCCESS
        })
        sendAlert('Sent successfully!', 'success')
      } catch (error) {
        handleState({ sendReferralStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }

    async function acceptReferal(data) {
        handleState({ acceptReferralStatus: LOADING })
      try {
        await acceptReferral(data);
        handleState({
            acceptReferralStatus: SUCCESS
        })
        sendAlert('Accepted successfully!', 'success')
      } catch (error) {
        handleState({ acceptReferralStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }

    async function rejectReferal(data) {
        handleState({ rejectReferralStatus: LOADING })
      try {
        await rejectReferral(data);
        handleState({
            rejectReferralStatus: SUCCESS
        })
        sendAlert('Rejected successfully!', 'success')
      } catch (error) {
        handleState({ rejectReferralStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }

    async function markReferralAsRead(referral_id) {
        handleState({ markReferralStatus: LOADING })
      try {
        await markAsRead({ referral_id });
        handleState({
            markReferralStatus: SUCCESS,
            referrals: {
                ...referralsState.referrals, 
                data: referralsState.referrals.data.map((item) => {
                    if (item.id === referral_id) {
                        return {
                            ...item,
                            status: 'seen'
                        }
                    }
                    return item;
                })
            }
        })
      } catch (error) {
        handleState({ markReferralStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }

    function updateReferralsState(field, value) {
        handleState({ [field]: value })
    }

    const state = {
        ...referralsState,
        fetchReferrals,
        sendReferal,
        acceptReferal,
        rejectReferal,
        markReferralAsRead,
        fetchReferralsCount,
        updateReferralsState
    };

    return (
        <ReferralsContext.Provider value={state}>
            {children}
        </ReferralsContext.Provider>
    );
};

export const useReferrals = () => useContext(ReferralsContext);