import { axiosInstance } from "./axiosInstance";

function getCredentials() {
    return axiosInstance.get('credential-types');
}

function createCredential(data) {
    return axiosInstance.post('credential-types', data);
}

function updateCredential(data) {
    return axiosInstance.put('credential-types', data);
}

function deleteCredential(data) {
    return axiosInstance.delete('credential-types', {params: data});
}


export {
    getCredentials,
    createCredential,
    updateCredential,
    deleteCredential
}