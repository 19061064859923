import React, { createContext, useContext, useState } from 'react';
import { ERROR, LOADING, sortData, SUCCESS } from '../constants/common';
import { createGenders, getDemographics, getGendersList, getRacesList, getReligionsList, getSexualOrientationsList, updateGenders, updateRaces, updateReligions, updateSexualOrientations } from '../services/demographics.services';
import {  getLanguagesList, updateLanguages } from '../services/languages.service';



const DemographicsContext = createContext({});

export const DemographicsContextProvider = ({ children }) => {
  
    const [demographicsState, setDemographicsState] = useState({
      gendersList: [],
      languagesList: [],
      fetchGendersStatus: '',
      fetchLanguagesStatus: '',
      racesList: [],
      fetchRacesStatus: '',
      religionsList: [],
      fetchReligionsStatus: '',
      sexualOrientationsList: [],
      fetchSexualOrientationsStatus: '',
      demographics: null,
      fetchDemographicsStatus: '',
      updateGendersStatus: '',
      updateLanguageStatus: '',
      updateRacesStatus: '',
      updateReligionsStatus: '',
      updateSexualOrientationsStatus: '',
    });

    function handleState(obj) {
        setDemographicsState((prevState) => ({
            ...prevState,
            ...obj
        }))
    }

    async function fetchGendersList() {
      if(demographicsState.gendersList.length === 0) {
        handleState({ fetchLanguagesStatus: LOADING })
        try {
          const response = await getGendersList();
          handleState({
              gendersList: response.data.data,
              fetchGendersStatus: SUCCESS
          })
        } catch (error) {
          handleState({ fetchGendersStatus: ERROR })
          Promise.reject(error)
        }
     }
    }

    async function fetchLanguageList() {
      if(demographicsState.languagesList.length === 0) {
      handleState({ fetchLanguagesStatus: LOADING })
      try {
        const response = await getLanguagesList();
        handleState({
            languagesList: response.data.data,
            fetchLanguagesStatus: SUCCESS
        })
      } catch (error) {
        handleState({ fetchLanguagesStatus: ERROR })
        Promise.reject(error)
      }
    }
    }

    async function fetchRacesList(load = false) {
      if(demographicsState.racesList.length === 0 || load) {
        handleState({ fetchRacesStatus: LOADING })
      try {
        const response = await getRacesList();
        handleState({
            racesList: sortData(response.data.data),
            fetchRacesStatus: SUCCESS
        })
      } catch (error) {
        handleState({ fetchRacesStatus: ERROR })
        Promise.reject(error)
      }
    }
    }

    async function fetchReligionsList(load = false) {
      if(demographicsState.religionsList.length === 0 || load) {
        handleState({ fetchStatesStatus: LOADING })
      try {
        const response = await getReligionsList();
        if (response && response.data?.data) {
          handleState({
            religionsList: sortData(response.data.data),
            fetchReligionsStatus: SUCCESS
          })
        } 
      } catch (error) {
        handleState({ fetchStatesStatus: ERROR })
        Promise.reject(error)
      }
    }
    }

    async function fetchSexualOrientationsList(load = false) {
      if(demographicsState.sexualOrientationsList.length === 0 || load) {
        handleState({ fetchSexualOrientationsStatus: LOADING })
      try {
        const response = await getSexualOrientationsList();
        handleState({
            sexualOrientationsList: sortData(response.data.data),
            fetchSexualOrientationsStatus: SUCCESS
        })
      } catch (error) {
        handleState({ fetchSexualOrientationsStatus: ERROR })
        Promise.reject(error)
      }
    }
  }

    async function fetchDemographics( load = false) {
      if(!demographicsState.demographics || load) {
      handleState({ fetchDemographicsStatus: LOADING })
      try {
        const response = await getDemographics();
        handleState({
            demographics: response.data.data,
            fetchDemographicsStatus: SUCCESS
        })
      } catch (error) {
        handleState({ fetchDemographicsStatus: ERROR })
        Promise.reject(error)
      }
    }
    }

    async function editGenders(data) {
      handleState({ updateGendersStatus: LOADING })
      try {
        await updateGenders(data);        
        handleState({ updateGendersStatus: SUCCESS })
      } catch (error) {
        handleState({ updateGendersStatus: ERROR })

        Promise.reject(error)
      }
    }
    async function editLanguages(data) {
      handleState({ updateLanguageStatus: LOADING })
      try {
        await updateLanguages(data);
        handleState({ updateLanguageStatus: SUCCESS })
      } catch (error) {
        handleState({ updateLanguageStatus: ERROR })
        Promise.reject(error)
      }
    }

    async function editRaces(data) {
      handleState({ updateRacesStatus: LOADING })
      try {
        await updateRaces(data);
        handleState({ updateRacesStatus: SUCCESS })
      } catch (error) {
        handleState({ updateRacesStatus: ERROR })
        Promise.reject(error)
      }
    }

    async function editReligions(data) {
      handleState({ updateReligionsStatus: LOADING })
      try {
        await updateReligions(data);
        handleState({ updateReligionsStatus: SUCCESS })
      } catch (error) {
        handleState({ updateReligionsStatus: ERROR })
        Promise.reject(error)
      }
    }

    async function editSexualOrientations(data) {
      handleState({ updateSexualOrientationsStatus: LOADING })
      try {
        await updateSexualOrientations(data);
        handleState({ updateSexualOrientationsStatus: SUCCESS })
      } catch (error) {
        handleState({ updateSexualOrientationsStatus: ERROR })
        Promise.reject(error)
      }
    }

    const state = {
      ...demographicsState,
      fetchGendersList,
      fetchRacesList,
      fetchReligionsList,
      fetchSexualOrientationsList,
      fetchDemographics,
      editGenders,
      editRaces,
      editReligions,
      editSexualOrientations,
      handleState,
      fetchLanguageList,
      editLanguages
    };

    return (
        <DemographicsContext.Provider value={state}>
        {children}
        </DemographicsContext.Provider>
    );
};

export const useDemographics = () => useContext(DemographicsContext);