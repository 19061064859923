import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import { useCommon } from '../../contexts/common';
import isEmail from 'validator/lib/isEmail';
import { LOADING, SUCCESS, treatment_program } from '../../constants/common';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { useBasicInfo } from '../../contexts/BasicInfo';
import { useSettings } from '../../contexts/Settings';
import LoadingButton from '@mui/lab/LoadingButton';
import { ReactComponent as ShieldExclamation } from '../../assets/Icons/shield-exclamation.svg';
import { useAuth } from '../../contexts/Auth';
import { useGeneralProfile } from '../../contexts/Organization';
import { validateName, validatePhoneNumber } from '../../helper/Validations';
import PhoneNumberInput from '../../components/PhoneNumberInput';

const AccountSettings = () => {
  const initialState = {
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
  }
  const [state, setState] = useState(initialState)
  const [isInvalid, setIsInvalid] = useState(false)
  const [openAlert, setOpenAlert] = useState(false);

  const { resendEmailVerification, resendEmailStatus, account_type } = useAuth();
  const { checkIfEmailExists, emailExists, handleState, emailExistsStatus } = useCommon();
  const { data, fetchInfo } = useBasicInfo();
  const { facilityInfo, fetchFacilityInfo } = useGeneralProfile();
  const { updateAccountStatus, updateAccountSettings, handleSettingsState } = useSettings();
  const info = account_type !== treatment_program ? data : facilityInfo

  const handleOpenAlert = () => {
    setOpenAlert(true);
  }

  const resendEmail = () => {
    resendEmailVerification({ email: info?.email });
  }

  const handleInput = (ev) => {
    if (ev.target.name === 'email') {
      handleState({ emailExists: false })
    }
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value
    }))
  }

  const handlePhoneNumber = (ev) => {
    setState((prevState) => ({
      ...prevState,
      ...ev
    }))
  }

  const isValid = () => {
    return validateName(state.first_name)
      && validateName(state.last_name)
      && state.email
      && isEmail(state.email)
      && (info?.email !== state.email ? !Boolean(emailExists) : true)
      && emailExistsStatus !== LOADING
      && (validatePhoneNumber(state.phone_number))
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    let response = { data: { code: 0 } }
    if (info?.email !== state.email) {
      response = await checkIfEmailExists(state.email);
    }
    if (isValid() && !Boolean(response.data.code)) {
      updateAccountSettings(state);
    } else
      setIsInvalid(true)
  }

  useEffect(() => {
    if (info) {
      setState((prevState) => ({
        ...prevState,
        first_name: info?.first_name,
        last_name: info?.last_name,
        phone_number: info?.info?.phone_number,
        email: info?.email,
      }))
    }
  }, [info])

  useEffect(() => {
    if (updateAccountStatus === SUCCESS) {
      if (account_type !== treatment_program)
        fetchInfo();
      else
        fetchFacilityInfo();
      handleSettingsState({ updateAccountStatus: '' })
    }
  }, [updateAccountStatus, account_type])


  useEffect(() => {
    if (Object.keys(info)?.length > 0 && !Boolean(info?.email_verified_at)) {
      handleOpenAlert();
    }
  }, [info])

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        p: 3
      }}
      component='form'
      onSubmit={handleSubmit}
    >
      <Box>
        <Typography variant='h5' fontWeight={600} mb={1}>Account</Typography>
        <Typography variant='body1' color='text.secondary'>Manage your account name, email, and password.</Typography>
      </Box>

      <Box>
        <Typography variant='body1' mb={1.5}>Account name</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='First name'
              onChange={handleInput}
              value={state.first_name || ''}
              name='first_name'
              error={isInvalid && !validateName(state.first_name)}
              helperText={
                isInvalid
                && !validateName(state.first_name)
                && "Please add valid first name"
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Last name'
              onChange={handleInput}
              value={state.last_name || ''}
              name='last_name'
              error={isInvalid && !validateName(state.last_name)}
              helperText={
                isInvalid
                && !validateName(state.last_name)
                && "Please add validated last name"
              }
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Typography variant='body1' mb={1.5}>Phone</Typography>
        <PhoneNumberInput
          fullWidth
          label='Phone Number'
          onChange={handlePhoneNumber}
          value={state.phone_number || ''}
          name='phone_number'
          error={isInvalid && !validatePhoneNumber(state.phone_number)}
          helperText={isInvalid
            && !validatePhoneNumber(state.phone_number)
            && "Please add valid phone number"
          }
        />
      </Box>

      <Box>
        <Typography variant='body1' mb={1.5}>Email</Typography>
        <TextField
          variant='outlined'
          label='Email'
          type='email'
          fullWidth
          onChange={handleInput}
          value={state.email || ''}
          name='email'
          error={Boolean(emailExists) || (isInvalid && (!state.email || !isEmail(state.email)))}
          helperText={Boolean(emailExists) && 'Email already exists please choose another one'}
        />
      </Box>

      {openAlert && (
        <Alert
          severity="error"
          sx={{
            backgroundColor: '#FAF4D4',
            borderRadius: 1,
            border: '1px solid #EECD63',
            py: 0.5,
            px: 3,
            alignItems: 'center'
          }}
          icon={<ShieldExclamation />}
        >
          Please check your inbox under {info?.email} to confirm your email. Can't find verification email? {' '}
          <LoadingButton
            size='small'
            sx={{
              p: 0,
              minWidth: 0,
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
            onClick={resendEmail}
            loading={resendEmailStatus === LOADING}
          >
            Resend
          </LoadingButton>
        </Alert>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <LoadingButton
          variant='contained'
          size='large'
          sx={{
            minWidth: 110
          }}
          type='submit'
          loading={updateAccountStatus === LOADING}
        >
          Save
        </LoadingButton>
      </Box>
    </Paper>
  )
}

export default AccountSettings;