import React, { useEffect } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckBox from "../../components/CheckBox";

import { useDemographics } from '../../contexts/Demographics';
import { useState } from 'react';
import { LOADING, SUCCESS } from '../../constants/common';

const GenderDrawer = ({ onClose, open }) => {
  const [genders, setGenders] = useState([]);

  const {
    demographics,
    gendersList,
    fetchGendersStatus,
    fetchGendersList,
    editGenders,
    updateGendersStatus,
    fetchDemographics,
    handleState
  } = useDemographics();

  function handleClose() {
    setGenders([]);
    onClose();
  }



  function handleSave() {
    editGenders({ gender_ids: genders })
    //fetchGendersList();
  }

  useEffect(() => {
    if (open)
      fetchGendersList();
  }, [open])


  useEffect(() => {

    if (updateGendersStatus === SUCCESS) {
      handleState({ updateGendersStatus: '' });
      fetchDemographics(true);
      handleClose();
    }
  }, [updateGendersStatus])


  useEffect(() => {
    let tempGender = [];
    demographics?.user_gender.map((item) => {
      let childItem = gendersList.find((childitem) => childitem.name === item);
      if (childItem) {
        tempGender.push(childItem.id)
      }
    })
    setGenders(tempGender)
  }, [gendersList, open])


  function handleCheckBox(ev) {
    const id = parseInt(ev.target.name)
    setGenders(genders => {
      let updatedGenders
      if (genders?.length === 0) {
        updatedGenders = [...genders, id]
      } else if (!genders.includes(id)) {
        updatedGenders = [...genders, id]
      } else {
        updatedGenders = genders.filter(item => item !== id)
      }
      return updatedGenders
    });
  }

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Provider Demographics</Typography>
          <Typography variant='body1' mb={1}>Please select your gender.</Typography>
          <Divider sx={{ mb: 4 }} />
          <Grid container spacing={2.5}>
            {fetchGendersStatus === LOADING ? (
              <>
                {[...Array(6)].map((_, index) => (
                  <Grid item xs={6} key={index}>
                    <Skeleton animation="wave" />
                  </Grid>
                ))}
              </>
            ) : (
              gendersList.map((item) => (
                <Grid item xs={6} key={item.id}>
                  <CheckBox
                    label={item.name}
                    name={item.id}
                    onChange={handleCheckBox}
                    checked={genders.includes(item.id)}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Box>
        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            onClick={handleSave}
            loading={updateGendersStatus === LOADING}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default GenderDrawer;