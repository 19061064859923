import { useEffect, useState } from 'react';
import axios from 'axios';
import { axiosInstance } from '../services/axiosInstance';
import { geoKey } from '../constants/common';
let timer;
const SetGeoLocations = () => {
  const [locations, setLocations] = useState([]);

  const handleGeocode = (zipCode, user_id) => {
    const API_KEY = geoKey; // Replace with your API key
    const geocodeUrl = `https://api.opencagedata.com/geocode/v1/json?q=${zipCode}&key=${API_KEY}`;

    axios
      .get(geocodeUrl)
      .then((response) => {
        if (response.data.status.code === 200) {
          const location = response.data.results[0].geometry;
          setLocations((prevState) => ([
            ...prevState,
            {
              latitude: location.lat,
              longitude: location.lng,
              user_id
            }
          ]));
        }
      })
      .catch((error) => {
        console.error('Error getting geocoding information:', error);
      });
  };

  useEffect(() => {
    axiosInstance
      .get('users')
      .then((response) => {
       response.data.data.forEach((item) => {
          if (item.zip_code) {
            handleGeocode(item.zip_code, item.user_id)
          }
       })
      })
      .catch((error) => {
        console.error('Error getting geocoding information:', error);
      });
  }, [])

  useEffect(() => {
    if (locations.length > 0) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        axiosInstance.put('geo', { locations }).then((response) => {
          if (response.data.status === 200) {
            localStorage.setItem('locations_set', 'true')
          }
        }).catch((error) => {
          console.error('Error getting geocoding information:', error);
        });
      }, 2000);
    }
  }, [locations])
  
  
  return null
};

export default SetGeoLocations;