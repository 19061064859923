import React, { createContext, useContext, useState } from 'react';
import { ERROR, LOADING, sortData, SUCCESS } from '../constants/common';
import {  getLanguagesList, updateLanguages } from '../services/languages.service';


const LanguagesContext = createContext({});

export const LanguagesContextProvider = ({ children }) => {
  
    const [languagesState, setLanguagesState] = useState({
      languagesList: [],
      fetchLanguagesStatus: '',
      updateLanguageStatus: '',
    });

    function handleState(obj) {
        setLanguagesState((prevState) => ({
            ...prevState,
            ...obj
        }))
    }



    async function fetchLanguageList(load = false) {
      if(languagesState.languagesList.length === 0 || load){
        handleState({ fetchLanguagesStatus: LOADING })
        try {
          const response = await (getLanguagesList)();
          handleState({
              languagesList: sortData(response.data.data),
              fetchLanguagesStatus: SUCCESS
          })
        } catch (error) {
          handleState({ fetchLanguagesStatus: ERROR })
          Promise.reject(error)
        }
      }
    }

    
   
    async function editLanguages(data) {
      handleState({ updateLanguageStatus: LOADING })
      try {
        await updateLanguages(data);
        handleState({ updateLanguageStatus: SUCCESS })
      } catch (error) {
        handleState({ updateLanguageStatus: ERROR })
        Promise.reject(error)
      }
    }
  
    const state = {
      ...languagesState,
      handleState,
      fetchLanguageList,
      editLanguages
    };

    return (
        <LanguagesContext.Provider value={state}>
        {children}
        </LanguagesContext.Provider>
    );
};

export const useLanguages = () => useContext(LanguagesContext);