import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import RadioGroup from '@mui/material/RadioGroup';
import CheckBox from "../../components/CheckBox";

import { ReactComponent as DollarSignIcon } from '../../assets/Icons/dollar-sign.svg'
import { ReactComponent as EditIcon } from '../../assets/Icons/edit-3.svg'
import InsuranceDrawer from '../../components/InsuranceDrawer';
import { useFees } from '../../contexts/Fees';
import { LOADING, treatment_program } from '../../constants/common';
import { useAuth } from '../../contexts/Auth';
import { useBasicInfo } from '../../contexts/BasicInfo';

const ProfileFees = () => {
  const { fetchFees, fetchFeesStatus, fees, userInsurances, fetchUserInsurances, fetchUserInsurancesStatus, handleOpenFeesDrawer, editInsurances } = useFees();
  const [openInsuranceDrawer, setOpenInsuranceDrawer] = useState(false);
  const [paymentType, setPaymentType] = useState([]);
  const { account_type } = useAuth();
  const { data, fetchInfo } = useBasicInfo();

  function handleOpenInsuranceDrawer() {
    setOpenInsuranceDrawer(true)
  }

  function handleCloseInsuranceDrawer() {
    setOpenInsuranceDrawer(false)
  }

  function handleInput(index) {
    if (index === '2') {
      editInsurances({
        insurances: data?.insurance.map(item => item.id),
        private_pay_only: paymentType?.includes("2") ? false : true,
        account_type: account_type === treatment_program ? treatment_program : 'user'
      })
    } else if (index === "1" && paymentType?.includes("1")) {
      editInsurances({
        insurances: [],
        private_pay_only: paymentType?.includes("2"),
        account_type,
      })
    }
    setPaymentType(data => {
      if (data?.includes(index)) {
        return data?.filter(item => item !== index)
      } else {
        return [...data, index]
      }
    })
  }

  useEffect(() => {
    fetchInfo();
  }, []);

  useEffect(() => {
    if (account_type !== treatment_program)
      fetchFees();
    fetchUserInsurances({ account_type: account_type === treatment_program ? treatment_program : 'user' });
  }, [account_type, paymentType])

  useEffect(() => {
    if (data.private_pay_only === 1 && data?.insurance?.length > 0) {
      setPaymentType(["1", "2"]);
    } else if (data.private_pay_only === 1) {
      setPaymentType(["2"]);

    } else if (data.insurance?.length > 0) {
      setPaymentType(["1"]);
    }
  }, [data])
  return (
    <>
      <Paper
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <DollarSignIcon width={24} height={24} />
              <Typography variant='h4' ml={1}>Fees</Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'border.main',
            p: 3,
            borderRadius: 2
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 1.5
            }}
          >
            <Typography variant='h5'>Insurance Plans Accepted</Typography>
          </Box>
          <RadioGroup>
            <Box
              sx={{
                mb: 1.5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <CheckBox
                label='Select Insurance Plans'
                onChange={() => handleInput("1")}
                checked={paymentType?.includes('1')}
              />
              {paymentType?.includes('1') && (
                <IconButton
                  sx={{ p: 0.375 }}
                  onClick={handleOpenInsuranceDrawer}
                >
                  <EditIcon />
                </IconButton>
              )}
            </Box>
            <Box mb={1.5}>
              <CheckBox
                label='Private Pay'
                onChange={() => handleInput("2")}
                checked={paymentType?.includes('2')}
              />
            </Box>
          </RadioGroup>
          {paymentType?.includes('1') && userInsurances.length > 0 && (
            <>
              <Divider
                sx={{ mb: 2 }}
              />
              {fetchUserInsurancesStatus === LOADING ? (
                <>
                  <Box mb={1.5}>
                    <Skeleton animation="wave" />
                  </Box>
                  <Box mb={1.5}>
                    <Skeleton animation="wave" />
                  </Box>
                  <Box mb={1.5}>
                    <Skeleton animation="wave" />
                  </Box>
                  <Box mb={1.5}>
                    <Skeleton animation="wave" />
                  </Box>
                  <Box mb={1.5}>
                    <Skeleton animation="wave" />
                  </Box>
                  <Box mb={1.5}>
                    <Skeleton animation="wave" />
                  </Box>
                </>
              ) : (
                <Box component='ul' pl={2.2}>
                  {userInsurances.map((item) => (
                    <Box
                      component='li'
                      sx={{
                        mb: 1.5,
                      }}
                      key={item.id}
                    >
                      {item.name}
                    </Box>
                  ))}
                </Box>
              )}
            </>
          )}
        </Box>

        {account_type !== treatment_program && (
          <Box
            sx={{
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'border.main',
              p: 3,
              borderRadius: 2
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 1.5
              }}
            >
              <Typography variant='h5'>Session Fees:</Typography>
              <IconButton
                sx={{ p: 0.375 }}
                onClick={handleOpenFeesDrawer}
              >
                <EditIcon />
              </IconButton>
            </Box>
            {fetchFeesStatus === LOADING ? (
              <>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </>
            ) : (
              fees.reduce((acc, item) => {
                if (item.fee) {
                  return [...acc, (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1.5,
                        gap: 1
                      }}
                      key={item.id}
                    >
                      <Typography variant='body2' fontWeight={600} minWidth={80}>{item.name}</Typography>
                      <Typography variant='body2' color='text.secondary' display='flex' alignItems='center'>{item.fee?.includes(',') ? item.fee?.split(',').map((item, index) => (<Box component='span' mr={1} display='flex' alignItems='center'>{index === 0 ? 'From:' : 'To:'}<DollarSignIcon />{item}</Box>)) : <><DollarSignIcon />{item.fee}</>}</Typography>
                    </Box>
                  )]
                }
                return acc
              }, [])
            )}
          </Box>
        )}
      </Paper>
      <InsuranceDrawer
        open={openInsuranceDrawer}
        onClose={handleCloseInsuranceDrawer}
      />
    </>
  )
}

export default ProfileFees;