import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import { useAuth } from '../../contexts/Auth';
import isEmail from 'validator/lib/isEmail';
import { LOADING, SUCCESS } from '../../constants/common';
import LoadingButton from '@mui/lab/LoadingButton';
import PasswordRecovery from '../../components/PasswordRecovery';

const ForgetPassword = () => {
  const { forgotPassword, forgetPasswordStatus, updateAuthState, step } = useAuth();
  const initialState = { email: localStorage.getItem('email') || '' };
  const [state, setState] = useState(initialState);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  const handleInput = (ev) => {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value
    }))
  }

  const isValid = () => {
    return state.email && isEmail(state.email);
  }

  const handleForgotPassword = () => {
    if (isValid())
      forgotPassword(state);
    else
      setIsInvalid(true);
  }

  const handleSubmit = (ev) => {
    ev.preventDefault();
    handleForgotPassword();
  }

  useEffect(() => {
    if (forgetPasswordStatus === SUCCESS) {
      setIsInvalid(false);
      updateAuthState('forgetPasswordStatus', '');
    }
  }, [forgetPasswordStatus]);

  if (step === 2) {
    return <PasswordRecovery resend={handleForgotPassword} email={state.email} />
  }

  return (
    <Container maxWidth='xs'>
      <Paper
        sx={{
          p: 4,
          boxShadow: '0px 0px 64px rgba(0, 0, 0, 0.04)',
          border: 0
        }}
      >
        <Typography variant='h3' mb={3}>
          Forgot your password?
        </Typography>

        <Typography variant='body1' mb={3}>
          We'll email you instructions on how to reset your password
        </Typography>

        <Box component='form' onSubmit={handleSubmit}>
          <TextField
            variant='outlined'
            label='Please enter your email account'
            type='email'
            fullWidth
            name='email'
            sx={{
              mb: 3
            }}
            onChange={handleInput}
            value={state.email}
            error={isInvalid && (!state.email || !isEmail(state.email))}
            helperText={
              isInvalid
              && !isEmail(state.email)
              && "Please enter valid email"
            }
          />

          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            type='submit'
            loading={forgetPasswordStatus === LOADING}
          >
            Reset password
          </LoadingButton>
        </Box>
      </Paper>
    </Container>
  )
}

export default ForgetPassword