import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { ReactComponent as CoupleIcon } from '../../../../assets/Icons/couple.svg'
import { useUserProfile } from '../../../../contexts/UserProfile'

const AgeServed = () => {
    const { data } = useUserProfile();
    return (
        <Box
            sx={{
                p: 1.5,
                border: '1px solid',
                borderColor: 'border.main',
                borderRadius: 2,
                height: '100%'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                    mb: 1
                }}
            >
                <CoupleIcon />
                <Typography variant='h5' fontWeight={400}>Ages Served:</Typography>
            </Box>
            <Typography variant='body1'>
                {data.info?.age_served?.split(',').filter((item) => Boolean(item))?.join(', ')}
            </Typography>
        </Box>
    )
}

export default AgeServed