import { axiosInstance } from "./axiosInstance";

const emailExists = (email) => {
  return axiosInstance.get('email/exists', { params: { email } });
}

const getStatesList = () => {
  return axiosInstance.get('states');
}

const getZipcodesList = (data) => {
  return axiosInstance.get('zipcodes', { params: data || {} });
}

const getDegreesList = () => {
  return axiosInstance.get('degrees');
}

const getClientTypesList = () => {
  return axiosInstance.get('client-types');
}

const sendFeedback = (data) => {
  return axiosInstance.post('feedback', data);
}

const uploadImage = (data) => {
  const formData = new FormData();
  formData.append('file', data);
  return axiosInstance.post('attachments/upload-image', formData);
}

const uploadFile = (data) => {
  const formData = new FormData();
  formData.append('file', data);
  return axiosInstance.post('attachments/upload-file', formData);
}

const deleteAttachments = (data) => {
  return axiosInstance.delete('attachments/delete', { params: data });
}


const getTratmentProgramConstants = (type) => {
  return axiosInstance.get(`treatment-program-constants/${type}`);
}

const getCities = (data) => {
  return axiosInstance.get('cities', { params: data || {} });
}

export {
  emailExists,
  getStatesList,
  getDegreesList,
  getClientTypesList,
  sendFeedback,
  uploadImage,
  uploadFile,
  getTratmentProgramConstants,
  deleteAttachments,
  getCities,
  getZipcodesList
}