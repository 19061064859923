import { axiosInstance } from "./axiosInstance";

function getNetwork(params) {
    return axiosInstance.get('network', {
        params
    });
}

function getConnections(params) {
    return axiosInstance.get('connections', { params });
}

function getNetworkConnections(params) {
    return axiosInstance.get('connections/list', { params });
}


function deleteConnection(id, userType) {
    return axiosInstance.delete(`connections/${id}?account_type=${userType}`);
}


function getFavorites(params) {
    return axiosInstance.get('favorites', { params });
}

function connectUser(data) {
    return axiosInstance.post('connections/connect', data);
}
export {
    getNetwork,
    getConnections,
    getNetworkConnections,
    getFavorites,
    connectUser,
    deleteConnection
}