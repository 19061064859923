import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { SUCCESS } from '../../constants/common'
import { useNetwork } from '../../contexts/NetWork'
import { useHomePage } from '../../contexts/HomePage'
import paths from '../../constants/paths'
import Button from "@mui/material/Button";
import { ReactComponent as AddIcon } from "../../assets/Icons/add.svg";
import { trackClickEvent } from '../../mixpanel'

const UserVerticalCard = ({ data }) => {
  const { connectWithOthers, connectStatus, updateNetworkState } = useNetwork();
  const { fetchSuggestedPeople } = useHomePage();

  const connect = () => {
    connectWithOthers({ connected_user_id: data.id });
    trackClickEvent(
      'Create Connection',
      { page: 'Homepage', component: 'Connection card', button: 'connect' }
    );
  }

  useEffect(() => {
    if (connectStatus === SUCCESS) {
      updateNetworkState('connectStatus', '');
      fetchSuggestedPeople();
    }
  }, [connectStatus])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 1
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}
      >
        <Avatar
          sx={{
            width: 48,
            height: 48,
          }}
          src={data?.main_image}
        />
        <Box>
          <Link href={paths.userProfile(data?.uuid, data?.account_type)} variant='body2' fontWeight={600} color='text.primary' textTransform='capitalize'>{data.first_name} {data.last_name}</Link>
          <Typography variant='body2' color='text.secondary'>{data?.info?.headline}</Typography>
        </Box>
      </Box>
      {/* <LoadingButton
                size='small'
                variant='contained'
                sx={{
                    py: 0.75,
                    borderRadius: 2
                }}
                onClick={connect}
                loading={loading}
            >
                Connect
            </LoadingButton> */}
      <Button
        variant="outlined"
        style={{ borderRadius: "25px" }}
        onClick={connect}
        startIcon={<AddIcon />}
      >
        Connect
      </Button>
    </Box>
  )
}

export default UserVerticalCard