import React from 'react'
import Filter from './Filter'
import CheckBox from '../../../components/CheckBox'
import { ReactComponent as UsersIcon } from '../../../assets/Icons/users.svg';

const CredentialsFilter = ({ setFilters, filters ,goBack ,reset  }) => {

    function handleChange(ev) {
        setFilters((prevState) => {
            const arr = (prevState.credentials|| [])
            const exists = Boolean(arr.find((item) => item === ev.target.value))
            return {
                ...prevState,
                credentials: (exists ? 
                    arr.filter((item) => item !== ev.target.value)
                :
                    [...arr, ev.target.value])
            }
        })
    }

    return (
        <Filter
            label='Credentials'
            icon={<UsersIcon />}
            goBack={goBack}
            reset={reset}
            isRigntIcon={true}
        >
            <CheckBox
                label='Membership'
                value='membership'
                onChange={handleChange}
                checked={Boolean((filters?.credentials || []).find((data) => data.toString() === 'membership'))}
            />
            <CheckBox
                label='License'
                value='licence'
                onChange={handleChange}
                checked={Boolean((filters?.credentials || []).find((data) => data.toString() === 'licence'))}
            />
            <CheckBox
                label='Certificate'
                value='certificate'
                onChange={handleChange}
                checked={Boolean((filters?.credentials || []).find((data) => data.toString() === 'certificate'))}
            />
        </Filter>
    )
}

export default CredentialsFilter