import { axiosInstance } from "./axiosInstance";

function getGendersList() {
    return axiosInstance.get('genders');
}

function getRacesList() {
    return axiosInstance.get('races');
}

function getReligionsList() {
    return axiosInstance.get('religions');
}

function getSexualOrientationsList() {
    return axiosInstance.get('sexual-orientations');
}

function updateGenders(data) {
    return axiosInstance.post('genders', data);
}

function updateRaces(data) {
    return axiosInstance.post('races', data);
}

function updateReligions(data) {
    return axiosInstance.post('religions', data);
}

function updateSexualOrientations(data) {
    return axiosInstance.post('sexual-orientations', data);
}


function getDemographics() {
    return axiosInstance.get('demographics');
}

export {
    getGendersList,
    getRacesList,
    getReligionsList,
    getSexualOrientationsList,
    getDemographics,
    updateGenders,
    updateRaces,
    updateReligions,
    updateSexualOrientations,
}