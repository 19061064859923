import React from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { received } from '../../constants/common';
import { useReferrals } from '../../contexts/Referral';

const ReferalCard = ({ data, setActiveReferral, isActive, tabId }) => {
    const { markReferralAsRead } = useReferrals();

    function selectReferral() {
        if (data.status === "unseen" && tabId === received)
            markReferralAsRead(data.id)
        setActiveReferral(data)
    }

    const isTreatmentProgram =  tabId === received ? data.referrable_type === "App\\Models\\TreatmentProgram" : data.referral_user_type === "App\\Models\\TreatmentProgram";
    return (
        <Box
            sx={{
                px: 3,
                borderLeft: '4px solid transparent',
                borderColor: isActive || (data.status === "unseen" && tabId === received)  ? 'primary.main' : 'transparent',
                backgroundColor: isActive ? '#F2F5F7' : 'background.paper',
                mb: 1,
                cursor: 'pointer'
            }}
            onClick={selectReferral}
        >
            <Box
                sx={{
                    py: 3
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        mb: 1.5
                    }}
                >
                    <Avatar
                        sx={{
                            width: 48,
                            height: 48,
                        }}
                        src={isTreatmentProgram ? data?.facility_image : data?.profile_image}
                    />
                    <Box flexGrow={1}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography variant='body2' fontWeight={700} textTransform='capitalize'>{isTreatmentProgram ? data.facility_name : data?.name}</Typography>
                            <Typography variant='caption' display='block' lineHeight='22px'>{moment(data?.created_at).format("DD MMMM 'YY")}</Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography variant='caption' color='text.secondary' component='p' lineHeight='22px' display='block'>{data?.headline}</Typography>
                            {(['rejected', 'accepted'].includes(data.status) && tabId === received) && (
                            <Typography variant='caption' color='disabled' px={1.5} border='1px solid' borderColor='disabled' borderRadius={1}>
                                {data.status === 'rejected' ? 'Not Accepted' : '' }
                                {data.status === 'accepted' ? 'Accepted' : '' }
                            </Typography>
                        )}
                        </Box>
                    </Box>
                </Box>
                <Typography variant='body2' fontWeight={600} mb={1.5}>{data.subject}</Typography>
                <Typography
                    variant='body2'
                    mb={1.75}
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical',
                    }}
                >
                    {data.message}
                </Typography>
            </Box>
            {(!isActive) && <Divider />}
        </Box>
    )
}

export default ReferalCard;