import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import { getNotificationSetting , updateNotificationSetting } from "../../services/home.services";
import { useAlert } from "../../contexts/Alert";

const notificationList = [
  { label: "Colleague Posts", type: "general_posts" },
  {
    label: "Reactions on your posts (Replies, Likes, Shares)",
    type: "reactions",
  },
  { label: "Referrals (Sent, Received)", type: "referals" },
  { label: "Connection requests", type: "connections" },
];
const NotificationSettings = () => {
  const [notificationSettings, setNotificationSettings] = useState(null);
  const [disableSwitch, setDisableSwitch] = useState(false);

  const { sendAlert } = useAlert();

  const handleChange =  (type) => {
    setDisableSwitch(true)
          updateNotificationSetting({
          ...notificationSettings,
          [type]: notificationSettings[type]=== 0 ? 1 : 0,
        }).then((res) => {
          sendAlert(res.data.message, 'success')
          setNotificationSettings((oldSetting) => {
            return {
              ...oldSetting,
              [type]: oldSetting[type]=== 0 ? 1 : 0,
            };
          });
          setDisableSwitch(false)  
        })
        .catch((error) => {
          setDisableSwitch(false)  
          sendAlert(error.response?.data?.message, 'error')
        });
  };

  useEffect(() => {
    getNotificationSetting()
      .then((res) => {
        if (!res.data.data) {
          setNotificationSettings({
            general_posts_email: 1,
            general_posts_notification: 1,
            general_posts_push: 1,
            reactions_email: 1,
            reactions_notification: 1,
            reactions_push: 1,
            referals_email: 1,
            referals_notification: 1,
            referals_push: 1,
            connections_email: 1,
            connections_notification: 1,
            connections_push: 1,
          });
        } else {
          setNotificationSettings(res.data.data);
        }
    })
      .catch((error) => {
        sendAlert(error.response?.data?.message, "error");
      });
  }, []);

  return (
    <Paper sx={{ display: "flex", flexDirection: "column", p: 3, pt: 1 }}>
      {notificationList.map((category, categoryIndex) => {
        return (
          <div index={categoryIndex}>
            <Box
              sx={{ display: "flex", flexDirection: "column", marginTop: 2,marginLeft:1 }}
            >
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {category.label}
              </Typography>
            </Box>
            <Box
              sx={{
                p: 2,
                m: 1,
                border: "1px solid #EEEFF0",
                borderRadius: 2,
              }}
            >
              {[
                {
                  label: "Email",
                  type: "email",
                },
                {
                  label: "Push",
                  type: "push",
                },
                {
                  label: "Allow notification",
                  type: "notification",
                },
              ].map((subCategory, index) => {
                return (
                  <Box
                    index={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">{subCategory.label}</Typography>
                    <Switch
                      disabled={disableSwitch}
                      checked={notificationSettings &&  !!notificationSettings[`${category.type}_${subCategory.type}`]}
                      onChange={() =>
                        handleChange(`${category.type}_${subCategory.type}`)
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                );
              })}
            </Box>
          </div>
        );
      })}
    </Paper>
  );
};

export default NotificationSettings;
