import { axiosInstance } from "./axiosInstance";


function sendReferral(data) {
    return axiosInstance.post('referrals/send', data);
}

function getReferrals(params) {
    return axiosInstance.get('referrals', { params });
}

function getSentReferrals(params) {
    return axiosInstance.get('referrals/sent', { params });
}

function getArchivedReferrals(params) {
    return axiosInstance.get('referrals/archive', { params });
}

function acceptReferral(data) {
    return axiosInstance.put('referrals/accept', data);
}

function rejectReferral(data) {
    return axiosInstance.put('referrals/reject', data);
}

function markAsRead(data) {
    return axiosInstance.put('referrals/seen', data);
}

function getReferralsCount(params) {
    return axiosInstance.get('referrals/count', { params });
}

export {
    sendReferral,
    getReferrals,
    getSentReferrals,
    getArchivedReferrals,
    acceptReferral,
    rejectReferral,
    getReferralsCount,
    markAsRead
}


