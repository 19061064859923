import axios from 'axios';
import getAccessToken from '../utils/getToken';
import paths from '../constants/paths';
import { apiUrl, domain, rest_api_key } from '../constants/common';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// create axios instance
export const axiosInstance = (() => {
  const instance = axios.create({
    baseURL: apiUrl,
  });

  instance.interceptors.request.use(
    async (config) => {
      try {
        const updatedConfig = config;
        if (!updatedConfig.ignoreToken) {
          if (config.useUserToken) {
            const { accessToken } = localStorage;
            if (accessToken) updatedConfig.headers.Authorization = `Bearer ${accessToken}`;
          } else {
            const accessToken = getAccessToken();
            // Do something before request is sent
            if (accessToken) updatedConfig.headers.Authorization = `Bearer ${accessToken}`;
          }
        }
        updatedConfig.headers['rest-api-key'] = rest_api_key || undefined;
        return updatedConfig;
      } catch (err) {
        return Promise.reject(err);
      }
    },
    (error) => {
      // Do something with request error
      Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if ([401, 442].includes(error.response.status) && (window.location.pathname !== paths.login)) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('isLoggedIn');
        cookies.remove('isLoggedIn', { domain: `.${domain}` })
        window.location.href = paths.login;
      }
      return Promise.reject(error);
    },
  );
  return instance;
})();
