import React from "react";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemButton from "@mui/material/ListItemButton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useLocation, useNavigate } from "react-router-dom";

function NavMenu({ data }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
        setAnchorEl(event.currentTarget);
        }
    }

    function handleClose(ev) {
        setAnchorEl(null);
    }

    function handleListClick(ev) {
        ev.stopPropagation();
    }

    function handlePaperClick() {
        navigate(data.path)
    }

    const isActive = Boolean(data?.children?.find((item) => item.path === location.pathname))
    return (
        <div style={{ display: "flex", flexDirection: "column" ,justifyContent:"center",alignItems:"center"}}>
             <data.Icon />
            <Link
                sx={{
                    color: isActive ? 'text.primary' : 'text.secondary',
                    borderBottom: isActive ? '2px solid #0C8CE9' : '0',
                    lineHeight: '22px',
                    display: 'block',
                    cursor: 'pointer'
                }}
                role='button'
                onClick={handleClick}
                onMouseOver={handleClick}
            >
                {data.text}
            </Link>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                transitionDuration={0}
                MenuListProps={{
                    sx: {
                        mt: 4,
                        border: '1px solid #F5F6F7',
                        boxShadow: '0px 0px 128px rgba(0, 0, 0, 0.02)',
                        borderRadius: 1.5,
                        backgroundColor: 'background.paper',
                        cursor: 'auto',
                        width: 184
                    },
                    onClick: handleListClick
                }}
                PaperProps={{
                    sx: {
                        mt: -4,
                        backgroundColor: 'transparent',
                        border: 0,
                        boxShadow: 'none',
                        width: 56,
                        overflow: 'visible',
                        cursor: 'pointer'
                    },
                    onMouseLeave: handleClose,
                    onClick: handlePaperClick
                }}
            >
                {data?.children?.map((item, index) => (
                    <Box key={item.id}>
                        <MenuItem sx={{ p: 0 }}>
                            <ListItemButton
                                onClick={handleClose}
                                href={item.path}
                                sx={{
                                    color: 'text.primary',
                                    borderRight: item.path === location.pathname ? '2px solid #0C8CE9' : '0',
                                    fontWeight: 600,
                                    lineHeight: '22px',
                                    px: 2,
                                    py: 1
                                }}
                            >
                                {item.text}
                            </ListItemButton>
                        </MenuItem>
                        {index < (data?.children?.length - 1) && (
                            <Divider sx={{ my: 1 }} />
                        )}
                    </Box>
                ))}
            </Menu>
        </div>
    );
}

export default NavMenu;