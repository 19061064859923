import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { full_and_need_to_refer_out } from '../../constants/common';
import { useUserProfile } from '../../contexts/UserProfile';
import { ReactComponent as UserCheckIcon } from '../../assets/Icons/user-check.svg';
import { ReactComponent as GroupIcon } from '../../assets/Icons/no-poverty.svg';
import { ReactComponent as PhoneIcon } from '../../assets/Icons/phone.svg';
import { ReactComponent as GlobeIcon } from '../../assets/Icons/globe.svg';
import { ReactComponent as InboxIcon } from '../../assets/Icons/inbox.svg';
import { ReactComponent as LocationIcon } from '../../assets/Icons/location.svg';
import { ReactComponent as FacebookIcon } from '../../assets/Icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../assets/Icons/twitter.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/Icons/linkedin.svg';
import { ReactComponent as CoupleSmallIcon } from '../../assets/Icons/couple-small.svg';

const InfoItem = ({ icon: Icon, label = '', value, isLink = false }) => (
  <>
    <Divider />
    <Box
      sx={{
        py: 1.5,
        px: 3,
        display: 'flex',
        alignItems: 'center',
        gap: 1.5,
        overflow: 'wrap',
        wordBreak: 'break-all'
      }}
    >
      <Box
        sx={{
          width: 22,
          height: 26,
          display: 'flex',
          alignItems: 'center',
          color: 'text.secondary',
        }}
      >
        <Icon />
      </Box>
      <Typography variant='body1'>
        {label && <Box component='span' fontWeight={600}>{label}: </Box>}
        {isLink ? (
          <Link href={value.startsWith('http') ? value : `https://${value}`} target='_blank'>
            {value.toLowerCase()}
          </Link>
        ) : (
          value
        )}
      </Typography>
    </Box>
  </>
);

const SideComponents = () => {
  const { data } = useUserProfile();

  const availabilityStatus = data?.info?.current_status === full_and_need_to_refer_out
    ? full_and_need_to_refer_out.split('_').join(' ')
    : 'Accepting New Clients';

  return (
    <>
      <Paper sx={{ mb: 2 }}>
        <Box sx={{ py: 1.5, px: 3 }}>
          <Typography variant='h5' fontWeight={600}>My Availability</Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            py: 1.5,
            px: 3,
            display: 'flex',
            alignItems: 'center',
            gap: 1.5,
          }}
        >
          <Box
            sx={{
              width: 22,
              height: 26,
              display: 'flex',
              alignItems: 'center',
              color: 'text.secondary',
            }}
          >
            <UserCheckIcon />
          </Box>
          <Typography
            variant='body1'
            color={data?.info?.current_status !== full_and_need_to_refer_out ? 'success.main' : 'error.main'}
            textTransform='capitalize'
          >
            {availabilityStatus}
          </Typography>
        </Box>
        {data?.info?.current_status !== full_and_need_to_refer_out && (
          <>
            {data.client_types?.length > 0 && (
              <InfoItem
                icon={GroupIcon}
                value={data.client_types.map((item) => item?.name).join(', ')}
              />
            )}
            {data.info?.age_served && (
              <InfoItem
                icon={CoupleSmallIcon}
                value={data.info.age_served.split(',').filter(Boolean).join(', ')}
              />
            )}
          </>
        )}
      </Paper>

      {(data?.info?.address?.city || data?.info?.address?.address1) && (
        <Paper sx={{ mb: 2 }}>
          <Box sx={{ py: 1.5, px: 3 }}>
            <Typography variant='h5' fontWeight={600}>Address</Typography>
          </Box>
          <Divider />
          <Box sx={{ py: 1.5, px: 3, display: 'flex', alignItems: 'center', gap: 1.5 }}>
            <Box sx={{ width: 22, height: 26, display: 'flex', alignItems: 'center', color: 'border.dark' }}>
              <LocationIcon />
            </Box>
            <Typography variant='body1'>
              {`${data?.info?.address?.address1}${data?.info?.address?.city ? `, ${data?.info?.address?.city}` : ''}${data?.info?.address?.state?.abbreviation ? `, ${data?.info?.address?.state?.abbreviation}` : ''}${data?.info?.address?.zip_code ? `, ${data?.info?.address?.zip_code}` : ''}`}
            </Typography>
          </Box>
        </Paper>
      )}

      {(data?.info?.phone_number || data?.email) && (
        <Paper sx={{ mb: 2 }}>
          <Box sx={{ py: 1.5, px: 3 }}>
            <Typography variant='h5' fontWeight={600}>Contact Information</Typography>
          </Box>
          {data?.info?.phone_number && (
            <InfoItem icon={PhoneIcon} label="Phone" value={data.info.phone_number} />
          )}
          {data?.email && (
            <InfoItem
              icon={InboxIcon}
              label="Email"
              value={data.email}
              onClick={() => {
                window.location.href = `mailto:${data.email}?subject=&body=Hi ${data.first_name} ${data.last_name}`;
              }}
            />
          )}
        </Paper>
      )}

      {(data?.info?.office_number || data?.info?.fax_number || data?.info?.website_link) && (
        <Paper sx={{ mb: 2 }}>
          <Box sx={{ py: 1.5, px: 3 }}>
            <Typography variant='h5' fontWeight={600}>Office Information</Typography>
          </Box>
          {data?.info?.office_number && (
            <InfoItem icon={PhoneIcon} label="Office" value={data.info.office_number} />
          )}
          {data?.info?.fax_number && (
            <InfoItem icon={InboxIcon} label="Fax" value={data.info.fax_number} />
          )}
          {data?.info?.website_link && (
            <InfoItem
              icon={GlobeIcon}
              label="Website"
              value={data.info.website_link.toLowerCase()}
              isLink={true}
              target='_blank'
            />
          )}
        </Paper>
      )}

      {(data?.info?.facebook || data?.info?.twitter || data?.info?.linkedin) && (
        <Paper sx={{ mb: 2 }}>
          <Box sx={{ py: 1.5, px: 3 }}>
            <Typography variant='h5' fontWeight={600}>Social Media</Typography>
          </Box>
          {data?.info?.facebook && (
            <InfoItem
              icon={FacebookIcon}
              label="Facebook"
              value={data.info.facebook.toLowerCase()}
              isLink={true}
              target='_blank'
            />
          )}
          {data?.info?.twitter && (
            <InfoItem
              icon={TwitterIcon}
              label="Twitter"
              value={data.info.twitter.toLowerCase()}
              isLink={true}
              target='_blank'
            />
          )}
          {data?.info?.linkedin && (
            <InfoItem
              icon={LinkedinIcon}
              label="LinkedIn"
              value={data.info.linkedin.toLowerCase()}
              isLink={true}
              target='_blank'
            />
          )}
        </Paper>
      )}
    </>
  );
};

export default SideComponents;
