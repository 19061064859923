import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import { useAuth } from "../../contexts/Auth";
import { LOADING, SUCCESS } from "../../constants/common";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import paths from "../../constants/paths";
import { validatePassword } from "../../helper/Validations";
import { IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ReactComponent as Logo } from '../../assets/Icons/logo-large.svg';
import getAccessToken from "../../utils/getToken";

const ChangePassword = () => {
  const { updatePassword, updatePasswordStatus, updateAuthState } = useAuth();
  const navigate = useNavigate();

  const initialState = {
    password: "",
    password_confirmation: "",
  };
  const [state, setState] = useState(initialState);
  const [isInvalid, setIsInvalid] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () =>
    setConfirmShowPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  function handleInput(ev) {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value,
    }));
  }

  function isValid() {
    return (
      validatePassword(state.password) &&
      validatePassword(state.password_confirmation) &&
      state.password === state.password_confirmation
    );
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (isValid())
      updatePassword(state);
    else setIsInvalid(true);
  }

  useEffect(() => {
    if (updatePasswordStatus === SUCCESS) {
      setIsInvalid(false);
      updateAuthState("updatePasswordStatus", "");
      setState(initialState);
      navigate(paths.login);
    }
  }, [updatePasswordStatus]);

  useEffect(() => {
    if (!getAccessToken()) {
      navigate(paths.login);
    }
    return () => {
        localStorage.removeItem('accessToken')
    }
  }, [getAccessToken()]);
  return (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: '100%',
        }}
    >
        <div className='styleHeader'>
            <Container sx={{position:'sticky',top:'0',zIndex:'5',background:'white',paddingBottom:'15px'}} maxWidth='md'>
                <div className='layoutForm'>
                    <Box width={32} height={32} />
                    <Logo />
                    <Box width={32} height={32} />
                </div>
            </Container>
        </div>
        <Box component='main' sx={{paddingTop:'0px'}} py={5} width='100%'>
            <div className='paddingLarge'>
                <Container maxWidth="xs">
                    <Paper
                        sx={{
                        p: 4,
                        boxShadow: "0px 0px 64px rgba(0, 0, 0, 0.04)",
                        border: 0,
                        }}
                    >
                        <Typography variant="h3" mb={3} color='error'>
                          Password Expired
                        </Typography>
                        <Typography variant="body2" sx={{ marginBottom: "46px" }}>
                          Your password has expired. Change your password now:
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            label="New Password"
                            type={showPassword ? "text" : "password"}
                            fullWidth
                            name="password"
                            sx={{
                            mb: 3,
                            }}
                            onChange={handleInput}
                            value={state.password}
                            error={
                            isInvalid &&
                            (!state.password || !validatePassword(state.password))
                            }
                            helperText={
                            isInvalid
                            && !validatePassword(state.password) && (
                                <ul>
                                <li>A minimum of 12 characters</li>
                                <li>At least one uppercase letter</li>
                                <li>At least one lowercase letter</li>
                                <li>At least one number</li>
                                <li>
                                    At least one special character from this list: #.-?!@$%^&*
                                </li>
                                </ul>
                            )
                            }
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                                </InputAdornment>
                            ),
                            }}
                        />

                        <TextField
                            variant="outlined"
                            label="Confirm New Password"
                            type={showConfirmPassword ? "text" : "password"}
                            fullWidth
                            name="password_confirmation"
                            sx={{
                            mb: 3,
                            }}
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownConfirmPassword}
                                >
                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                                </InputAdornment>
                            ),
                            }}
                            onChange={handleInput}
                            value={state.password_confirmation}
                            error={
                            isInvalid &&
                            (!state.password_confirmation ||
                                state.password !== state.password_confirmation ||
                                !validatePassword(state.password_confirmation))
                            }
                            helperText={
                              isInvalid
                              && (!state.password_confirmation 
                                || state.password !== state.password_confirmation 
                                || !validatePassword(state.password_confirmation))
                              && "Password does not match"
                            }
                        />

                        <LoadingButton
                            variant="contained"
                            size="large"
                            fullWidth
                            type="submit"
                            loading={updatePasswordStatus === LOADING}
                        >
                            Change my password
                        </LoadingButton>
                        </Box>
                    </Paper>
                    </Container>
            </div>
        </Box>
        <Box
            sx={{
                textAlign: 'center',
                pb: 4.25,
            }}
            component='footer'
        >   
            <div className='hideSmall'>
                <Typography sx={{padding:"2px 20px"}} variant='body2'>
                    <Typography variant='body2' color='text.secondary' component='span'>
                        ©2023 Word of Mouth
                    </Typography>
                    {' '}
                    - Privacy Cookies  Legal  Contact  Feedback
                </Typography>
            </div>
        </Box>
    </Box>
  );
};

export default ChangePassword;
