import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ReactComponent as CheckIcon } from '../../assets/Icons/check-box.svg'
import { ReactComponent as CheckedIcon } from '../../assets/Icons/checked-box.svg'

const CheckBox = ({ label, sx, ...inputProps }) => {
  return (
    <FormControlLabel
        sx={{ gap: 1, mx: 0, ...{...sx || {}} }}
        control={<Checkbox icon={<CheckIcon />} checkedIcon={<CheckedIcon />} sx={{ p: 0 }} {...inputProps} />}
        label={label}
    />
  )
}

export default CheckBox