import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useUserProfile } from '../../../../contexts/UserProfile';
import { ReactComponent as SubtractIcon } from '../../../../assets/Icons/subtract.svg'
import { ReactComponent as PlusIcon } from '../../../../assets/Icons/plus.svg'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: '#FAFBFC',
    marginBottom: 5,
    boxShadow: 'none',
    '&:not(:last-child)': {
        border: '0.5px solid',
        borderColor: '#A3A6A8',
    },
    '& .MuiAccordionSummary-root': {
        minHeight: 0,
        padding: theme.spacing(1.25),
    },
    '& .MuiAccordionSummary-content': {
        padding: 0,
        margin: 0,
    },
    '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.border.main}`,
    },
    '&:before': {
        display: 'none',
    },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: `${theme.spacing(0.25)} ${theme.spacing(1.25)} ${theme.spacing(1.25)}`,
}));

export default function Faq() {
    const { data } = useUserProfile();
    const [expanded, setExpanded] = React.useState('');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Paper
            sx={{
                p: 3,
                mb: 2
            }}
        >
            <Typography variant='h5' fontWeight={600} mb={3}>Common Questions About {data?.info?.facility_name}</Typography>
            {/* <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <MuiAccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={(
                        <Box
                            sx={{
                                color: 'border.dark',
                            }}
                        >
                            {expanded === 'panel1' ? <SubtractIcon /> : <PlusIcon /> }
                        </Box>
                    )}
                >
                <Typography variant='body1' fontWeight={600}>{`What insurance does ${data?.info?.facility_name} accept?`}</Typography>
                </MuiAccordionSummary>
                <AccordionDetails>
                <Typography variant='body1'>
                    {data?.info?.faq?.question_1}
                </Typography>
                </AccordionDetails>
            </Accordion> */}
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <MuiAccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                    expandIcon={(
                        <Box
                            sx={{
                                color: 'border.dark',
                            }}
                        >
                            {expanded === 'panel2' ? <SubtractIcon /> : <PlusIcon /> }
                        </Box>
                    )}
                >
                <Typography variant='body1' fontWeight={600}>{`Where is ${data?.info?.facility_name} located?`}</Typography>
                </MuiAccordionSummary>
                <AccordionDetails>
                <Typography variant='body1'>
                    {data?.info?.faq?.question_2}
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <MuiAccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                    expandIcon={(
                        <Box
                            sx={{
                                color: 'border.dark',
                            }}
                        >
                            {expanded === 'panel3' ? <SubtractIcon /> : <PlusIcon /> }
                        </Box>
                    )}
                >
                <Typography variant='body1' fontWeight={600}>{`What insurance does ${data?.info?.facility_name} accept?`}</Typography>
                </MuiAccordionSummary>
                <AccordionDetails>
                <Typography variant='body1'>
                    {data?.info?.faq?.question_3}
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <MuiAccordionSummary
                    aria-controls="panel4d-content"
                    id="panel4d-header"
                    expandIcon={(
                        <Box
                            sx={{
                                color: 'border.dark',
                            }}
                        >
                            {expanded === 'panel4' ? <SubtractIcon /> : <PlusIcon /> }
                        </Box>
                    )}
                >
                <Typography variant='body1' fontWeight={600}>{`How long is treatment?`}</Typography>
                </MuiAccordionSummary>
                <AccordionDetails>
                <Typography variant='body1'>
                    {data?.info?.faq?.question_4}
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <MuiAccordionSummary
                    aria-controls="panel5d-content"
                    id="panel5d-header"
                    expandIcon={(
                        <Box
                            sx={{
                                color: 'border.dark',
                            }}
                        >
                            {expanded === 'panel5' ? <SubtractIcon /> : <PlusIcon /> }
                        </Box>
                    )}
                >
                <Typography variant='body1' fontWeight={600}>{`Does ${data?.info?.facility_name} offer detox?`}</Typography>
                </MuiAccordionSummary>
                <AccordionDetails>
                <Typography variant='body1'>
                    {data?.info?.faq?.question_5}
                </Typography>
                </AccordionDetails>
            </Accordion>
        </Paper>
    );
}