import React from 'react'
import Paper from '@mui/material/Paper';


const ProfileBilling= () => {
    return (
        <Paper
            sx={{
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                gap: 3
            }}
        >
            
        </Paper>
    )
}

export default ProfileBilling;