import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { ReactComponent as FileIcon } from '../../../assets/Icons/file-text.svg';
import Filter from './Filter';
import CheckBox from '../../../components/CheckBox';
import { useCommon } from '../../../contexts/common';
import { medication_prescriber } from '../../../constants/common';

const DegreesFilter = ({ setFilters, filters, isMobileView, goBack, reset }) => {
  
  const { fetchDegreesList, degreesList } = useCommon();
  const [expanded, setExpanded] = useState(false);
  const [search, setSearch] = useState('');
  
  useEffect(() => {
    if (goBack && reset) {
      setExpanded(true);
      setSearch('');
    }
  }, [goBack, reset]);
  
  useEffect(() => {
    fetchDegreesList();
  }, []);

  const handleSearch = (ev) => setSearch(ev.target.value);

  const toggleExpand = () => setExpanded((prevState) => !prevState);

  const handleChange = (ev) => {
    setFilters((prevState) => {
      const arr = prevState.degrees || [];
      const exists = arr.includes(ev.target.value);
      return {
        ...prevState,
        degrees: exists 
          ? arr.filter((item) => item !== ev.target.value)
          : [...arr, ev.target.value],
      };
    });
    setSearch('');
  };

  const handleCheckBox = (ev) => {
    setFilters((prevState) => ({
      ...prevState,
      has_medication_prescriber: ev.target.checked ? 1 : 0,
    }));
  };

  return (
    <Filter
      isRigntIcon
      label="Education"
      icon={<FileIcon />}
      goBack={goBack}
      reset={reset}
    >
      <CheckBox
        label={medication_prescriber.label}
        name={medication_prescriber.value}
        onChange={handleCheckBox}
        checked={Boolean(filters?.has_medication_prescriber)}
      />

      <TextField
        fullWidth
        placeholder="Search"
        sx={{
          'input:not(textarea)': {
            pt: { xs: 1.75, sm: 1.5 },
            pb: 1.5,
            fontSize: { xs: 12, sm: 14 },
            px: 1,
            '&::placeholder': {
              fontWeight: 700,
            },
            height: isMobileView ? '40px' : '25px',
          },
        }}
        name="search"
        onChange={handleSearch}
        value={search}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          maxHeight: expanded ? 'none' : 112,
          overflow: 'hidden',
        }}
      >
        {degreesList.reduce((acc, item) => {
          const isChecked = (filters?.degrees || []).includes(item.id.toString());
          if (search && !item.name.toLowerCase().includes(search.toLowerCase())) {
            return acc;
          }

          const checkBox = (
            <CheckBox
              key={item.id}
              label={item.name}
              value={item.id}
              onChange={handleChange}
              checked={isChecked}
            />
          );

          return isChecked ? [checkBox, ...acc] : [...acc, checkBox];
        }, [])}
      </Box>

      <Button
        sx={{
          p: 0,
          justifyContent: 'flex-start',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        onClick={toggleExpand}
        disableRipple
      >
        {expanded ? 'See less' : 'See more'}
      </Button>
    </Filter>
  );
};

export default DegreesFilter;
