import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { useBasicInfo } from '../../../../contexts/BasicInfo';
import { LOADING, SUCCESS, treatment_program } from '../../../../constants/common';
import { useGeneralProfile } from '../../../../contexts/Organization';
import { useAuth } from '../../../../contexts/Auth';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { aboutMeValidation } from '../../../../validations/profileValidations';

const AboutMeDrawer = ({ onClose, open }) => {
  const { editAboutMe, updateInfoState, updateAboutMeStatus, fetchInfo, data } = useBasicInfo();
  const { editFacilityAboutMe, updateFacilityAboutMeStatus, updateGeneralProfileState, fetchFacilityInfo, facilityInfo } = useGeneralProfile();
  const { account_type } = useAuth();
  const info = account_type === treatment_program ? facilityInfo : data;

  const initialState = {
    about_me: ''
  }
  const [state, setState] = useState(initialState);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
    setValue,
  } = useForm({
    resolver: zodResolver(aboutMeValidation),
    mode: 'onChange',
    defaultValues: {
      about_me: info?.info?.about_me || '',
    },
  });

  const formValues = useWatch({ control });

  useEffect(() => {
    const transformedAboutMe =
      formValues.about_me || '';
    setState({ about_me: transformedAboutMe });
  }, [formValues]);

  useEffect(() => {
    if (updateAboutMeStatus === SUCCESS) {
      updateInfoState('updateAboutMeStatus', '')
      fetchInfo();
      handleClose();
    }
  }, [updateAboutMeStatus]);

  useEffect(() => {
    if (updateFacilityAboutMeStatus === SUCCESS) {
      updateGeneralProfileState('updateFacilityAboutMeStatus', '')
      fetchFacilityInfo();
      handleClose();
    }
  }, [updateFacilityAboutMeStatus]);

  useEffect(() => {
    if (data && open) {
      setValue('about_me', info.info.about_me || '')
    }
  }, [data, open])


  const handleClose = () => {
    setState(initialState);
    onClose();
  }

  const handleInput = (ev) => {
    setValue(`${ev.target.name}`, ev.target.value)
    trigger();
  }

  const onSubmit = () => {
    if (isValid) {
      if (account_type === treatment_program)
        editFacilityAboutMe(state);
      else
        editAboutMe(state);
    }
  }

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>About Me</Typography>
          <Divider sx={{ mb: 4 }} />
          <Controller
            name='about_me'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant='outlined'
                label='About Me'
                multiline
                rows={4}
                fullWidth
                error={!!errors.about_me}
                helperText={errors.about_me?.message}
                value={field.value}
                onChange={handleInput}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            loading={updateAboutMeStatus === LOADING}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default AboutMeDrawer;