import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

const formatPhoneNumber = (value) => {
  let formatted = "";
  if (value?.length > 0) {
    if (value.length === 11 && value.startsWith("1")) {
      formatted = `+1 (${value.slice(1, 4)}) ${value.slice(4, 7)}-${value.slice(7, 11)}`;
    } else if (value.length <= 10) {
      formatted = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }
  }
  return formatted;
};

const PhoneNumberInput = ({
  name,
  value,
  onChange,
  error,
  label,
  helperText,
  control,
  trigger,
  ...rest
}) => {
  const [displayValue, setDisplayValue] = useState(formatPhoneNumber(value));

  useEffect(() => {
    setDisplayValue(formatPhoneNumber(value));
  }, [value]);

  const handleInput = (e) => {
    e.preventDefault();
    const input = e.target.value;
    const cleaned = input.replace(/\D/g, "");

    if (input.length < displayValue.length) {
      const newCleaned = cleaned.slice(0, cleaned.length - 1);
      setDisplayValue(formatPhoneNumber(newCleaned));
      onChange({ [name]: newCleaned });
      trigger && trigger(name);
      return;
    }

    if (cleaned.length > 11 || (cleaned.length > 10 && !cleaned.startsWith("1"))) return;

    setDisplayValue(formatPhoneNumber(cleaned));
    onChange({ [name]: cleaned });
    trigger && trigger(name);
  };

  const renderTextField = (fieldProps) => (
    <TextField
      {...rest}
      {...fieldProps}
      label={label}
      name={name}
      onChange={handleInput}
      error={!!error}
      value={displayValue}
      helperText={error?.message || helperText}
    />
  );

  return control ? (
    <Controller
      name={name}
      control={control}
      render={({ field }) => renderTextField(field)}
    />
  ) : (
    renderTextField()
  );
};

export default PhoneNumberInput;
