import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import { ReactComponent as ChevronDown } from "../../../assets/Icons/chevron-down.svg";
 import { ReactComponent as BackArrow } from "../../../assets/Icons/filter-back-arrow.svg";
import useResize from "../../../hooks/useResize";

const Filter = ({
  label,
  isRigntIcon,
  expandedDefault,
  children,
  goBack,
  reset,
}) => {
  const [expanded, setExpanded] = React.useState(Boolean(expandedDefault));
  const isMobileView = useResize(600);

  useEffect(() => {
    if (isMobileView && isRigntIcon) setExpanded(true);
  }, []);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  return (
    <Box
      sx={{
        px: 3,
        py: isMobileView ? 0 : 1.5,
      }}
    >
      {isMobileView && isRigntIcon ? null : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: "12px !important",
            cursor: "pointer",
          }}
          onClick={handleExpandClick}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.5,
            }}
          >
            <Typography variant="body2" fontWeight={600}>
              {label}
            </Typography>
          </Box>
          <Box
            sx={{
              transform: `rotate(${expanded ? 180 : 0}deg)`,
              transition: "all 0.1s",
              color: "text.primary",
            }}
          >
            <ChevronDown />
          </Box>
        </Box>
      )}
      {isMobileView && !isRigntIcon ? (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box
            sx={{
              pt: 1.5,
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
            }}
          >
            {children}
          </Box>
        </Collapse>
      ) : !isMobileView ? (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box
            sx={{
              pt: 1.5,
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
            }}
          >
            {children}
          </Box>
        </Collapse>
      ) : (
        <Box
          sx={{
            pt: 1.5,
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
          }}
          className="menu"
        >
          {isMobileView && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100vw",
                position: "fixed",
                zIndex: 1000000,
                top: 0,
                left: 0,
                borderBottom: "1px solid #EEEFF0",
                backgroundColor: "#ffffff",
                p: 1,
              }}
            >
              <Box>
                <Button
                  sx={{
                    p: 1,
                    minWidth: 0,
                    textDecoration: !isMobileView ? "none" : "underline",
                    color: "#0C8CE9",
                  }}
                  onClick={goBack}
                >
                  <BackArrow />
                </Button>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  style={{ fontSize: "18px", fontWeight: 500 }}
                >
                  {label}
                </Typography>
              </Box>
              <Box>
                <Button
                  sx={{
                    p: 0,
                    minWidth: 0,
                    textDecoration: !isMobileView ? "none" : "underline",
                    color: "#0C8CE9",
                  }}
                  onClick={reset}
                >
                  Reset all
                </Button>
              </Box>
            </Box>
          )}
          <Divider />
          <Box
            sx={{
              pt: "50px",
              pb: "54px",
              display:"flex",
              flexDirection:"column",
              gap:1.5
            }}
          >
            {children}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Filter;
