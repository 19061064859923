import React, { createContext, useContext, useState } from 'react';
import { getFees, updateFees, getInsurances, updateInsurances, getUserInsurances } from '../services/fees.services';
import { ERROR, LOADING, sortData, SUCCESS } from '../constants/common';
import { useAlert } from './Alert';
import FeesDrawer from '../components/FeesDrawer';


const FeesContext = createContext({});

export const FeesContextProvider = ({ children }) => {
    const { sendAlert } = useAlert();
    
    const [feesState, setFeesState] = useState({
        fetchInsurancesStatus: '',
        insurances: [],
        updateInsurancesStatus: '',
        fetchUserInsurancesStatus: '',
        userInsurances: [],
        fetchFeesStatus: '',
        fees: [],
        updateFeesStatus: '',
    });

    const [openFeesDrawer, setOpenFeesDrawer] = useState(false);

    function handleOpenFeesDrawer() {
        setOpenFeesDrawer(true)
    }

    function handleCloseFeesDrawer() {
        setOpenFeesDrawer(false)
    }

    function handleState(obj) {
        setFeesState((prevState) => ({
            ...prevState,
            ...obj
        }))
    }
    
    async function fetchInsurances(data) {
      if(feesState.insurances.length === 0){
        handleState({ fetchInsurancesStatus: LOADING })
      try {
        const response = await getInsurances(data);
        handleState({
            insurances: sortData(response.data.data),
            fetchInsurancesStatus: SUCCESS
        })
      } catch (error) {
        handleState({ fetchInsurancesStatus: ERROR })
        Promise.reject(error)
      }
    }
    }

    async function editInsurances(data) {
        handleState({ updateInsurancesStatus: LOADING })
      try {
        await updateInsurances(data);
        handleState({
            updateInsurancesStatus: SUCCESS
        })
        sendAlert('Updated plans successfully', 'success')
      } catch (error) {
        handleState({ updateInsurancesStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }
 
    async function fetchUserInsurances(data) {
        handleState({ fetchUserInsurancesStatus: LOADING })
      try {
        const response = await getUserInsurances(data);
        handleState({
            userInsurances: response.data.data,
            fetchUserInsurancesStatus: SUCCESS
        })
      } catch (error) {
        handleState({ fetchUserInsurancesStatus: ERROR })
        Promise.reject(error)
      }
    }

    async function fetchFees() {
      handleState({ fetchFeesStatus: LOADING })
      try {
        const response = await getFees();
        handleState({
            fees: response.data.data,
            fetchFeesStatus: SUCCESS
        })
      } catch (error) {
        handleState({ fetchFeesStatus: ERROR })
        Promise.reject(error)
      }
    }

    async function editFees(data) {
        handleState({ updateFeesStatus: LOADING })
      try {
        await updateFees(data);
        handleState({
            updateFeesStatus: SUCCESS
        })
        sendAlert('Updated fees successfully', 'success')
      } catch (error) {
        handleState({ updateFeesStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }

    function updateFeesState(field, value) {
        handleState({ [field]: value })
    }
    

    const state = {
        ...feesState,
        fetchInsurances,
        editInsurances,
        fetchUserInsurances,
        fetchFees,
        editFees,
        openFeesDrawer,
        handleOpenFeesDrawer,
        handleCloseFeesDrawer,
        updateFeesState
    };

    return (
        <FeesContext.Provider value={state}>
          {children}
          <FeesDrawer
              open={openFeesDrawer}
              onClose={handleCloseFeesDrawer}
          />
        </FeesContext.Provider>
    );
};

export const useFees = () => useContext(FeesContext);