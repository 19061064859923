import React, { useEffect, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ReactComponent as GlobeIcon } from '../../../assets/Icons/globe.svg';
import Filter from './Filter';
import CheckBox from '../../../components/CheckBox';
import { useLanguages } from '../../../contexts/Languages';

const LanguagesFilter = ({ filters, setFilters, goBack, reset }) => {
  const { languagesList, fetchLanguageList } = useLanguages();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (goBack && reset) {
      setExpanded(true);
    }
  }, [goBack, reset]);

  useEffect(() => {
    fetchLanguageList();
  }, []);

  const toggleExpand = useCallback(() => {
    setExpanded((prevState) => !prevState);
  }, []);

  const handleCheckBox = useCallback((ev) => {
    const id = ev.target.name;
    setFilters((filters) => {
      const currentLanguageIds = filters?.language_ids || [];
      const updatedLanguages = currentLanguageIds.includes(id)
        ? currentLanguageIds.filter((item) => item !== id)
        : [...currentLanguageIds, id];
      return { ...filters, language_ids: updatedLanguages };
    });
  }, [setFilters]);

  return (
    <Filter
      label='Languages'
      icon={<GlobeIcon />}
      isRigntIcon={true}
      goBack={goBack}
      reset={reset}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          maxHeight: expanded ? 'auto' : 90,
          overflow: 'hidden',
        }}
      >
        {languagesList.map((item) => (
          <CheckBox
            key={item.id}
            label={item.name}
            name={item.id.toString()}
            onChange={handleCheckBox}
            checked={filters?.language_ids?.includes(item.id.toString()) || false}
          />
        ))}
      </Box>
      <Button
        sx={{
          p: 0,
          justifyContent: 'flex-start',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        onClick={toggleExpand}
        disableRipple
      >
        {expanded ? 'See less' : 'See more'}
      </Button>
    </Filter>
  );
};

export default LanguagesFilter;
