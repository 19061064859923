import { axiosInstance } from "./axiosInstance";

function getInterests() {
    return axiosInstance.get('interests');
}

function updateInterests(data) {
    return axiosInstance.post('interests', data);
}

export {
    getInterests,
    updateInterests
}