import React, { createContext, useContext, useEffect, useState } from 'react';
import { getUserDegrees, updateDegree, createDegree, deleteDegree, updateMedicationPrescriber } from '../services/degrees.services';
import { ERROR, LOADING, SUCCESS } from '../constants/common';
import { useAlert } from './Alert';
import DegreeDrawer from '../components/DegreeDrawer';
import ConfirmationModal from '../components/ConfirmationModal';
import { useOnboarding } from './onboarding';


const DegreesContext = createContext({});

export const DegreesContextProvider = ({ children }) => {
  const { sendAlert } = useAlert();
  const { setOnboardingState } = useOnboarding()
  const [degreesState, setDegreesState] = useState({
    fetchStatus: '',
    createStatus: '',
    updateStatus: '',
    deleteStatus: '',
    list: []
  });

  const [open, setOpen] = useState(false)
  const [isLocalEditing, setIsLocalEditing] = useState(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [activeDgree, setActiveDgree] = useState({});

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpen = (data, localEditing) => {
    setActiveDgree(data);
    setOpen(true);
    setIsLocalEditing(Boolean(localEditing));
  }

  const handleOpenConfirmationModal = () => {
    setOpenConfirmationModal(true)
  }

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false)
  }

  const handleState = (obj) => {
    setDegreesState((prevState) => ({
      ...prevState,
      ...obj
    }))
  }

  const fetchUserDegrees = async () => {
    handleState({ fetchStatus: LOADING })
    try {
      const response = await getUserDegrees();
      handleState({
        list: response.data.data,
        fetchStatus: SUCCESS
      })
    } catch (error) {
      handleState({ fetchStatus: ERROR })
      Promise.reject(error)
    }
  }

  const createUserDegree = async (data) => {
    handleState({ createStatus: LOADING })
    try {
      await createDegree(data);
      handleState({
        createStatus: SUCCESS
      })
      sendAlert('Created successfully', 'success')
    } catch (error) {
      handleState({ createStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  const editUserDegree = async (data) => {
    handleState({ updateStatus: LOADING })
    try {
      await updateDegree(data);
      handleState({
        updateStatus: SUCCESS
      })
      sendAlert('Created successfully', 'success')
    } catch (error) {
      handleState({ updateStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  const removeDegree = async (data) => {
    handleState({ deleteStatus: LOADING })
    try {
      await deleteDegree(data);
      handleState({
        deleteStatus: SUCCESS
      })
      sendAlert('Removed successfully', 'success')
    } catch (error) {
      handleState({ deleteStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  const updateDegreeState = (field, value) => {
    handleState({ [field]: value })
  }

  const removeDegreeFunction = () => {
    if (isLocalEditing) {
      setOnboardingState((prevState) => ({
        ...prevState,
        degrees: (prevState.degrees || []).filter((_, index) => activeDgree?.index !== index)
      }))
      handleCloseConfirmationModal();
      setActiveDgree(undefined);
      handleClose();
    } else {
      removeDegree({ id: activeDgree.id })
    }
  }

  useEffect(() => {
    if (degreesState.deleteStatus === 'SUCCESS') {
      updateDegreeState('deleteStatus', '');
      setActiveDgree(undefined);
      handleCloseConfirmationModal();
      handleClose();
      fetchUserDegrees();
    }
  }, [degreesState.deleteStatus])

  const state = {
    ...degreesState,
    fetchUserDegrees,
    createUserDegree,
    editUserDegree,
    open,
    handleClose,
    handleOpen,
    removeDegree,
    openConfirmationModal: handleOpenConfirmationModal,
    updateDegreeState
  };

  return (
    <DegreesContext.Provider value={state}>
      {children}
      <DegreeDrawer
        open={open}
        onClose={handleClose}
        data={activeDgree}
        isLocalEditing={isLocalEditing}
      />
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={handleCloseConfirmationModal}
        message={`Are you sure you want to delete ${activeDgree?.name}?`}
        deleteFunction={removeDegreeFunction}
        loading={degreesState.deleteStatus === LOADING}
      />
    </DegreesContext.Provider>
  );
};

export const useDegrees = () => useContext(DegreesContext);