import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { LOADING, SUCCESS } from '../../../../constants/common';
import { useBasicInfo } from '../../../../contexts/BasicInfo';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { formatUrl } from '../../../../utils/helper';
import { profileLinksValidation } from '../../../../validations/profileValidations';
import { zodResolver } from '@hookform/resolvers/zod';

const LinksDrawer = ({ onClose, open }) => {
  const { editLinksInfo, updateInfoState, updateLinkstatus, fetchInfo, data } = useBasicInfo();
  const initialState = {
    website_link: '',
    facebook: '',
    twitter: '',
    linkedin: ''
  }
  const [state, setState] = useState(initialState);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    resolver: zodResolver(profileLinksValidation),
    mode: "onChange",
    defaultValues: {
      website_link: data?.info?.website_link || '',
      facebook: data?.info?.facebook || '',
      twitter: data?.info?.twitter || '',
      linkedin: data?.info?.linkedin || '',
    },
  });

  const formValues = useWatch({ control });

  useEffect(() => {
    setState(prev => ({ ...prev, ...formValues }));
  }, [formValues]);

  const handleClose = () => {
    setState(initialState);
    onClose();
  }

  const handleInput = (ev, field) => {
    const { name, value } = ev.target;
    if (value === '' || value === 'https://') {
      field.onChange('');
      setState(prev => ({ ...prev, [name]: '' }))
      return;
    }
    const formattedValue = formatUrl(value);
    field.onChange(formattedValue);
    setState(prev => ({ ...prev, [name]: value }))
  }

  const onSubmit = () => {
    if (isValid)
      editLinksInfo(state);
  };

  useEffect(() => {
    if (updateLinkstatus === SUCCESS) {
      updateInfoState('updateLinkstatus', '')
      fetchInfo();
      handleClose();
    }
  }, [updateLinkstatus]);

  useEffect(() => {
    if (data && open) {
      setValue('website_link', data?.info?.website_link || '');
      setValue('facebook', data?.info?.facebook || '');
      setValue('twitter', data?.info?.twitter || '');
      setValue('linkedin', data?.info?.linkedin || '');
    }
  }, [data, open]);

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Links</Typography>
          <Divider sx={{ mb: 4 }} />
          <Controller
            name="website_link"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                label='Website'
                fullWidth
                value={state?.website_link}
                sx={{ mb: 4 }}
                error={!!errors.website_link}
                helperText={errors.website_link?.message}
                onChange={(e) => { handleInput(e, field) }}
              />
            )}
          />
          <Controller
            name='facebook'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                label='Facebook'
                fullWidth
                value={state?.facebook}
                sx={{ mb: 3 }}
                error={!!errors.facebook}
                helperText={errors.facebook?.message}
                onChange={(e) => { handleInput(e, field) }}
              />
            )}
          />
          <Controller
            name='twitter'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                label='X'
                fullWidth
                sx={{ mb: 3 }}
                error={!!errors.twitter}
                helperText={errors.twitter?.message}
                onChange={(e) => { handleInput(e, field) }}
              />
            )}
          />
          <Controller
            name='linkedin'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                label='LinkedIn'
                fullWidth
                sx={{ mb: 3 }}
                error={!!errors.linkedin}
                helperText={errors.linkedin?.message}
                onChange={(e) => { handleInput(e, field) }}
              />
            )}
          />
        </Box>

        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            type='submit'
            loading={updateLinkstatus === LOADING}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default LinksDrawer;