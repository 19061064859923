import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { ReactComponent as CheckIcon } from '../../assets/Icons/radio-button.svg'
import { ReactComponent as CheckedIcon } from '../../assets/Icons/radio-button-marked.svg'

const RadioButton = ({ label, ...inputProps }) => {
  return (
    <FormControlLabel
        sx={{ gap: 1, mx: 0 }}
        control={<Radio icon={<CheckIcon />} checkedIcon={<CheckedIcon />} sx={{ p: 0 }} {...inputProps} />}
        label={label}
    />
  )
}

export default RadioButton