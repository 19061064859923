import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import image from '../../assets/imgs/create-announcement-image.png';
import { useHomePage } from '../../contexts/HomePage';

const CreateAnnouncementCard = () => {
    const { handleOpenCreatePostModal }= useHomePage();

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 3,
                gap: 2
            }}
        >
            <CardMedia src={image} alt='' sx={{ width: 260, height: 170, maxWidth: '100%' }} component='img' />
            <Typography variant='h5'>
                Create an Announcement
            </Typography>
            <Typography variant='body2' sx={{ color: 'text.secondary', fontWeight: 600 }}>
                Calling All Therapists! Discover What's Next
            </Typography>
            <Button
                color='secondary'
                size='medium'
                sx={{ px: 3.75, color: 'primary.main' }}
                variant='outlined'
                onClick={handleOpenCreatePostModal}
            >
                Create Announcement
            </Button>
        </Paper>
    )
}

export default CreateAnnouncementCard;