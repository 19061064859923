import { axiosInstance } from "./axiosInstance";

function getUserDegrees() {
    return axiosInstance.get('degrees/user');
}

function createDegree(data) {
    return axiosInstance.post('degrees', data);
}

function updateDegree(data) {
    return axiosInstance.put('degrees', data);
}

function deleteDegree(data) {
    return axiosInstance.delete('degrees', {params: data});
}

export {
    getUserDegrees,
    createDegree,
    updateDegree,
    deleteDegree
}