import React, { useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { validateText } from '../../helper/Validations';
import { useInterests } from '../../contexts/Interests';
import { useEffect } from 'react';
import { SUCCESS } from '../../constants/common';

const InterestsDrawer = ({ onClose, open }) => {
    const { fetchInterests, editInterests, updateInterestsStatus, interests } = useInterests();
    const initialState = {
        books: { title: '', body: '' },
        courses: { title: '', body: '' },
        facilities: { title: '', body: '' },
    }

    const [state, setState] = useState(initialState)
    const [isInvalid, setIsInvalid] = useState(false)

    function handleClose() {
        onClose();
    }

    function handleInput(ev) {
        setState((prevState) => ({
            ...prevState,
            [ev.target.name]: {title: ev.target.title, body: ev.target.value}
        }))
    }

    function isValid() {
        return validateText(state.books?.body)
        && validateText(state.courses?.body)
        && validateText(state.facilities?.body)
    }

    function handleSubmit(ev) {
        ev.preventDefault();
        if (isValid())
            editInterests({ interests: Object.keys(state).map((item) => (state[item])) });
        else
            setIsInvalid(true);
    }

    useEffect(() => {
      if (updateInterestsStatus === SUCCESS) {
        handleClose();
        fetchInterests();
        setIsInvalid(false);
      }
    }, [updateInterestsStatus])
    
    useEffect(() => {
        const data = interests.reduce((acc, item) => {
            const titleArr = item.title?.split(' ');
            if (titleArr) {
                // eslint-disable-next-line no-unused-expressions
                return { ...acc, [titleArr[titleArr.length - 1].toLowerCase()]: { title: item.title, body: item.body } }
            }
            return acc;
        }, {})

        setState(data)
    }, [interests])
    

    return (
        <Drawer
            open={open}
            onClose={handleClose}
            anchor='right'
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                    pt: 8
                }}
                component='form'
                onSubmit={handleSubmit}
            >
                <Box pb={3} px={3}>
                    <Typography variant='h4' mb={1}>Interests:</Typography>
                    <Typography variant='body1' mb={1}>Fill your interests.</Typography>

                    <Box mb={3}>
                        <TextField
                            variant='outlined'
                            label='Books'
                            fullWidth
                            name='books'
                            sx={{
                                mb: 2
                            }}
                            multiline
                            rows={3}
                            onChange={handleInput}
                            value={state.books?.body || ''}
                            error={isInvalid && !validateText(state.books?.body)}
                            inputProps={{
                                title: 'Books'
                            }}
                            helperText={
                                isInvalid
                                && !validateText(state.books?.body)
                                && "Please enter valid text"
                            }
                        />

                        <TextField
                            variant='outlined'
                            label='Continuing Education Courses'
                            fullWidth
                            name='courses'
                            sx={{
                                mb: 2
                            }}
                            multiline
                            rows={3}
                            onChange={handleInput}
                            value={state.courses?.body || ''}
                            error={isInvalid && !validateText(state.courses?.body)}
                            inputProps={{
                                title: 'Continuing Education Courses'
                            }}
                            helperText={
                                isInvalid
                                && !validateText(state.courses?.body)
                                && "Please enter valid text"
                            }
                        />


                        <TextField
                            variant='outlined'
                            label='Treatment Facilities'
                            fullWidth
                            name='facilities'
                            sx={{
                                mb: 2
                            }}
                            multiline
                            rows={3}
                            onChange={handleInput}
                            value={state.facilities?.body || ''}
                            error={isInvalid && !validateText(state.facilities.body)}
                            inputProps={{
                                title: 'Treatment Facilities'
                            }}
                            helperText={
                                isInvalid
                                && !validateText(state.facilities?.body)
                                && "Please enter valid text"
                            }
                        />
                    </Box>
                </Box>
                
                <Box
                    sx={{
                        boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
                        px: 1,
                        pt: 3,
                        pb: 5.625,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        position: 'sticky',
                        width: '100%',
                        backgroundColor: 'background.paper',
                        bottom: 0,
                        zIndex: 9
                    }}
                >
                    <Button
                        variant='outlined'
                        color='secondary'
                        sx={{
                            color: 'primary.main'
                        }}
                        size='large'
                        fullWidth
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant='contained'
                        size='large'
                        type='submit'
                        fullWidth
                    >
                        Save
                    </LoadingButton>
                </Box>
            </Box>
        </Drawer>
    )
}

export default InterestsDrawer;