import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import RegisterForm from "../../components/Onboarding/RegisterForm";
import BusinessDescription from "../../components/Onboarding/BusinessDescription";
import BasicInfo from "../../components/Onboarding/BasicInfo";
import CurrentPractice from "../../components/Onboarding/CurrentPractice";
import { useOnboarding } from "../../contexts/onboarding";
import Education from "../../components/Onboarding/Education";
import Availability from "../../components/Onboarding/Availability";
import ClientServed from "../../components/Onboarding/ClientServed";
import Subsciption from "../../components/Onboarding/Subsciption";
import { promoFormURL, promoURL, treatment_program } from "../../constants/common";
import TreatmentProgramProfile from "../../components/Onboarding/TreatmentProgramProfile";
import ServicesAvailable from "../../components/Onboarding/ServicesAvailable";
import { useSearchParams } from "react-router-dom";
import "./style.css";
import { validatePromoCity } from "../../utils/helper";
import Loader from "../../components/Loader";
// import { fetchCurrentCity } from "../../googleAPI";

const Onboarding = () => {
  const { step, onboardingState } = useOnboarding();
  const isTreatmentProgram = onboardingState.account_type === treatment_program;

  const [searchParams] = useSearchParams();
  const payment = searchParams.get("payment");
  const promoCity = searchParams.get("city");
  const TP = searchParams.get("TP");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const validateAndRedirect = async () => {
      if (!(promoCity && validatePromoCity(promoCity))) {
        setTimeout(() => {
          window.location.href = promoURL;
          return;
        }, 1000);
        return;
      }

      // try {
      //   const userCity = await fetchCurrentCity();

      //   if (!(userCity && userCity.toLowerCase() === promoCity)) {
      //     window.location.href = promoFormURL;
      //     return;
      //   }
      // } catch (err) {
      //   console.error("There is an issue while fetching user city:", err);
      //   window.location.href = "https://share.hsforms.com/1RMzraFAaRRyIYqGtM7slegs25wk";
      //   return;
      // }
      setIsLoading(false);
    };

    validateAndRedirect();
  }, [promoCity]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box sx={{ minHeight: "75vh" }}>
      {step > 1 && step < 8 && (
        <div className="alignmentText">
          <Typography variant="h3" mb={0.5} textTransform="capitalize">
            {isTreatmentProgram && step > 3 ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Avatar
                  src={onboardingState.facility_image}
                  sx={{
                    width: 33,
                    height: 33,
                  }}
                />
                {onboardingState.facility_name}
              </Box>
            ) : (
              `Welcome, ${onboardingState.first_name}!`
            )}
          </Typography>
          <Typography variant="body2" mb={1} color="text.secondary">
            {isTreatmentProgram && step > 3
              ? "Let's start your business profile"
              : "Let's start your profile"}
          </Typography>
          {step > 1 && step < 8 ? (
            <div className="onboard-progress-bar">
              {new Array(7).fill("")?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={
                      step > index + 1
                        ? "active-onboard-progress-bar-item"
                        : "onboard-progress-bar-item"
                    }
                  ></div>
                );
              })}
            </div>
          ) : null}
        </div>
      )}

      {step === 1 && <RegisterForm />}

      {step === 2 && <BusinessDescription payment={payment} TP={TP} />}

      {isTreatmentProgram ? (
        <>
          {step === 3 && <TreatmentProgramProfile />}

          {step === 4 && <ServicesAvailable />}

          {step === 5 && <ClientServed />}

          {step === 6 && <Availability />}
        </>
      ) : (
        <>
          {step === 3 && <BasicInfo />}

          {step === 4 && <CurrentPractice />}

          {step === 5 && <Education />}

          {step === 6 && <Availability />}

          {step === 7 && <ClientServed />}

          {step === 8 && <Subsciption />}
        </>
      )}
    </Box>
  );
};

export default Onboarding;
