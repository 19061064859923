import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Grid,
  Button,
  Link,
  Container,
  Avatar,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ReactComponent as UserIcon } from "../../../assets/Icons/userinvited.svg";
import { useOnboarding } from "../../../contexts/onboarding";
import { useCommon } from "../../../contexts/common";
import { validateLinkToken } from "../../../services/auth.services";
import paths from "../../../constants/paths";
import { LOADING } from "../../../constants/common";
import "./style.css";
import { registerValidationSchema } from "../../../validations/registerValidation";
import { trackClickEvent } from "../../../mixpanel";

const RegisterForm = () => {
  const {
    next,
    onboardingState,
    updateOnboardingState,
    updatesetInvitatedUser,
  } = useOnboarding();
  const {
    checkIfEmailExists,
    emailExists,
    handleState,
    emailExistsStatus
  } = useCommon();
  const [invitatedUser, setInvitatedUser] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const { token } = useParams();
  const defaultFormValues = {
    first_name: onboardingState.first_name || "",
    last_name: onboardingState.last_name || "",
    email: onboardingState.email || "",
    password: onboardingState.password || "",
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(registerValidationSchema),
    mode: "onChange",
    defaultValues: defaultFormValues
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (token) {
      validateLinkToken(token)
        .then((res) => {
          updatesetInvitatedUser({ ...res?.data?.data?.user, token });
          setInvitatedUser(res?.data?.data?.user);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  const onSubmit = async (data) => {
    const response = await checkIfEmailExists(data.email);
    if (!Boolean(response.data.code)) {
      updateOnboardingState({ ...data });
      trackClickEvent('Step 1 - Basic', { page: 'Register', button: 'Signup' })
      next();
    } else {
      handleState({ emailExists: true });
    }
  };

  return (
    <Container maxWidth="sm">
      {invitatedUser && (
        <Box
          sx={{
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            p: 4,
            backgroundColor: "#E7F4FD",
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            mb: -4,
          }}
        >
          <Typography variant="body1" align="center" color={"#19CB6F"}>
            1 Month Free
          </Typography>
          <Typography
            variant="body1"
            align="center"
            mt={2}
            sx={{ fontSize: "30px", lineHeight: "38px", fontWeight: "normal" }}
          >
            Join Word of Mouth
          </Typography>
          <Box sx={{ alignItems: "center", justifyContent: "center" }}>
            <Typography
              variant="body1"
              align="center"
              mt={2}
              sx={{ fontSize: "15px" }}
            >
              Invited by:
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {invitatedUser.main_image ? (
                <Avatar
                  alt="user Image"
                  src={invitatedUser.main_image}
                  align="center"
                  style={{ width: "60px", height: "60px", marginTop: "8px" }}
                />
              ) : (
                <div
                  style={{
                    backgroundColor: "#FFF",
                    borderRadius: "50%",
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <UserIcon />
                </div>
              )}
            </div>
          </Box>
          <Typography
            variant="body1"
            align="center"
            mt={2}
            sx={{ fontSize: "15px", fontWeight: 700 }}
          >
            {invitatedUser.first_name} {invitatedUser.last_name}
          </Typography>
        </Box>
      )}
      <div className="hideShadow">
        <Paper sx={{ minHeight: "75vh" }} maxwidth="xs">
          {!invitatedUser ? (
            <div className="createAccount">
              <Typography variant="h3" mb={1}>
                Create your Account
              </Typography>
            </div>
          ) : (
            <Typography
              variant="body1"
              align="center"
              mt={2}
              sx={{ fontSize: "15px" }}
            />
          )}
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="marginForm">
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Controller
                    name="first_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        label="First Name"
                        fullWidth
                        error={!!errors.first_name}
                        helperText={errors.first_name?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    name="last_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        label="Last Name"
                        fullWidth
                        error={!!errors.last_name}
                        helperText={errors.last_name?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        label="Email"
                        type="email"
                        fullWidth
                        error={!!errors.email || Boolean(emailExists)}
                        helperText={
                          errors.email?.message ||
                          (Boolean(emailExists) && "This email already exists")
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        label="Password"
                        fullWidth
                        error={!!errors.password}
                        helperText={errors.password?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Box
                mt={5}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  type="submit"
                  sx={{ marginBottom: "39px", padding: "15px 22px" }}
                  disabled={!isValid || emailExistsStatus === LOADING}
                >
                  Sign up
                </Button>
                <Typography
                  className="onboardingPage_footerLink"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  Already have an account?&nbsp;
                  <Link
                    onClick={() => trackClickEvent('Sign in link clicked',
                      { button: 'Sign In', page: 'Register', component: 'Register' }
                    )}
                    color="primary"
                    underline="hover"
                    href={paths.login}
                  >
                    Sign In
                  </Link>
                </Typography>
                <Typography
                  variant='body2'
                  sx={{
                    color: 'text.secondary',
                    maxWidth: 300,
                    mx: 'auto',
                    textAlign: 'center',
                    mt: 2,
                    fontWeight: 'bold'
                  }}
                >
                  By signing up, you agree to the{" "}
                  <a sx={{ color: "#0C8CE9 !important" }} href={"https://www.therapywordofmouth.com/terms"} target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and{" "}
                  <a sx={{ color: "#0C8CE9 !important" }} href={"https://www.therapywordofmouth.com/privacy"} target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                </Typography>
              </Box>
            </div>
          </Box>
        </Paper>
      </div>
    </Container>
  );
};

export default RegisterForm;
