import React, { useEffect, useState } from 'react'
import Filter from './Filter'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import CheckBox from '../../../components/CheckBox'
import { ReactComponent as AwardIcon } from '../../../assets/Icons/award.svg';
import { useFees } from '../../../contexts/Fees'

const InsurancePlansFilter = ({ setFilters, value, isMobileView, goBack }, reset) => {

    const { fetchInsurances, insurances } = useFees();
    const [paymentType, setPaymentType] = useState('1');
    const [search, setSearch] = useState('');
    const [expandedInsurancePlans, expandInsurancePlans] = useState(false);



    useEffect(() => {
        if (goBack && reset) {
            expandInsurancePlans(true)
        }
    }, [])

    function handlePaymentType(ev) {
        setPaymentType(ev.target.value)
        setFilters((prevState) => {
            if (ev.target.value === '2') {
                return {
                    ...prevState,
                    private_pay_only: true,
                    insurance: []
                }
            } else {
                const payload = { ...prevState }
                delete payload.private_pay_only;
                return payload;
            }
        })
    }

    function toggleInsurancePlans() {
        expandInsurancePlans((prevState) => !prevState)
    }

    function handleSearch(ev) {
        setSearch(ev.target.value)
    }

    function handleChange(ev) {
        setFilters((prevState) => {
            const arr = (prevState.insurance || [])
            const exists = Boolean(arr.find((item) => item === ev.target.value))
            return {
                ...prevState,
                insurance: (exists ?
                    arr.filter((item) => item !== ev.target.value)
                    :
                    [...arr, ev.target.value])
            }
        })
        setSearch('');
    }

    useEffect(() => {
        // if(isMobileView)expandInsurancePlans(true)
        fetchInsurances();
    }, [])

    return (
        <>
            {/* <Filter
        label='Accepted Payment'
        icon={<AwardIcon />}
        expandedDefault={isMobileView ? false : true}
    >
        <RadioGroup>
                <Box mb={1.5}>
                    <FormControlLabel
                        sx={{
                            gap: 1,
                            mx: 0,
                        }}
                        control={
                            <Radio
                                sx={{
                                    p: 0,
                                }} 
                                value='1'
                                onChange={handlePaymentType}
                                checked={paymentType === '1'}
                            />
                        }
                        label='Select Insurance Plans'
                    />
                </Box>
                <Box mb={1.5}>
                    <FormControlLabel
                        sx={{
                            gap: 1,
                            mx: 0,
                        }}
                        control={
                            <Radio
                                sx={{ p: 0 }} 
                                value='2'
                                onChange={handlePaymentType}
                                checked={paymentType === '2'}
                            />
                        }
                        label='Private Pay'
                    />
                </Box>
            </RadioGroup>
        </Filter> */}
            <Filter
                label={'Plans Accepted'}
                icon={<AwardIcon />}
                expandedDefault={isMobileView ? false : true}
                isRigntIcon={isMobileView ? true : false}
                goBack={goBack}
                reset={reset}
            >
                {isMobileView ? null : <RadioGroup>
                    <Box mb={1.5}>
                        <FormControlLabel
                            sx={{
                                gap: 1,
                                mx: 0,
                            }}
                            control={
                                <Radio
                                    sx={{
                                        p: 0,
                                    }}
                                    value='1'
                                    onChange={handlePaymentType}
                                    checked={paymentType === '1'}
                                />
                            }
                            label='Select Insurance Plans'
                        />
                    </Box>
                    <Box mb={1.5}>
                        <FormControlLabel
                            sx={{
                                gap: 1,
                                mx: 0,
                            }}
                            control={
                                <Radio
                                    sx={{ p: 0 }}
                                    value='2'
                                    onChange={handlePaymentType}
                                    checked={paymentType === '2'}
                                />
                            }
                            label='Private Pay'
                        />
                    </Box>
                </RadioGroup>}
                {paymentType === '1' && (
                    <>
                        {isMobileView ? null : <Typography variant='body2' fontWeight={600}>Insurance Plans</Typography>}
                        <TextField
                            fullWidth
                            placeholder='Search'
                            sx={{
                                'input:not(textarea)': {
                                    pt: { xs: 1.75, sm: 1.5 },
                                    pb: 1.5,
                                    fontSize: { xs: 12, sm: 14 },
                                    width: "90%",
                                    px: 1,
                                    '&::placeholder': {
                                        fontWeight: 700,
                                    },
                                    height: isMobileView ? "40px" : "25px",

                                },
                            }}
                            name='search'
                            onChange={handleSearch}
                            value={search}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1.5,
                                maxHeight: expandedInsurancePlans ? 'auto' : 90,
                                overflow: 'hidden',
                                pt: isMobileView ? 2 : 0,
                            }}
                        >
                            {insurances.reduce((acc, item) => {
                                const isChecked = Boolean(value?.find((data) => data.toString() === item.id.toString()))
                                if (search && !item.name.toLowerCase().includes(search.toLowerCase()))
                                    return acc
                                if (isChecked) {
                                    return [
                                        (
                                            <CheckBox
                                                key={item.id}
                                                label={item.name}
                                                value={item.id}
                                                onChange={handleChange}
                                                checked={true}
                                            />
                                        ),
                                        ...acc
                                    ]
                                }
                                return [
                                    ...acc,
                                    (
                                        <CheckBox
                                            key={item.id}
                                            label={item.name}
                                            value={item.id}
                                            onChange={handleChange}
                                            checked={false}
                                        />
                                    )
                                ]
                            }, [])}
                        </Box>

                        <Button
                            sx={{
                                p: 0,
                                justifyContent: 'flex-start',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                }
                            }}
                            onClick={toggleInsurancePlans}
                            disableRipple
                        >
                            {expandedInsurancePlans ? 'See less' : 'See more'}
                        </Button>
                    </>
                )}
            </Filter>
        </>
    )
}

export default InsurancePlansFilter