import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import LoadingButton from '@mui/lab/LoadingButton'
import { LOADING } from '../../constants/common'
import { useAuth } from '../../contexts/Auth'
import paths from '../../constants/paths'

const PasswordRecovery = ({ resend, email }) => {
  const { forgetPasswordStatus, prev } = useAuth();
  const [remainingTime, setRemainingTime] = useState(60);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const savedTime = localStorage.getItem('timerStart');
    if (savedTime) {
      const timeElapsed = Math.floor((Date.now() - parseInt(savedTime, 10)) / 1000);
      if (timeElapsed < 60) {
        setIsDisabled(true);
        setRemainingTime(60 - timeElapsed);
      } else {
        setIsDisabled(false);
      }
    }
  }, []);

  useEffect(() => {
    let timerInterval;

    if (isDisabled) {
      timerInterval = setInterval(() => {
        const savedTime = localStorage.getItem('timerStart');
        const timeElapsed = Math.floor((Date.now() - parseInt(savedTime, 10)) / 1000);

        if (timeElapsed >= 60) {
          setIsDisabled(false);
          localStorage.removeItem('timerStart');
          localStorage.removeItem('email');
          localStorage.removeItem('step');
          clearInterval(timerInterval);
        } else {
          setRemainingTime(60 - timeElapsed);
        }
      }, 1000);
    }

    return () => clearInterval(timerInterval);
  }, [isDisabled]);

  useEffect(() => {
    if (forgetPasswordStatus === 'SUCCESS') {
      setIsDisabled(true);
      setRemainingTime(60);
    }
  }, [forgetPasswordStatus])

  return (
    <Box
      sx={{
        maxWidth: 600,
        px: 2,
        mx: 'auto'
      }}
    >
      <Paper
        sx={{
          p: 4,
          boxShadow: '0px 0px 64px rgba(0, 0, 0, 0.04)',
          border: 0
        }}
      >
        <Typography variant='h3' mb={3}>
          Password Recovery
        </Typography>

        <Typography variant='body1' mb={4}>
          We’ve sent an email to {email} with instructions to reset your password.
        </Typography>

        <Typography variant='body1' mb={4}>
          If you don’t see the email within a few minutes, please check your junk/spam folder or try <Link onClick={resend}>resending the email</Link>.
        </Typography>

        <Typography variant='body1' mb={4}>
          Still having trouble or unsure which email is linked to your account? <Link href='mailto:team@therapywordofmouth.com'>Contact us</Link> for support.
        </Typography>

        <Button
          fullWidth
          variant='contained'
          size='large'
          sx={{ mb: 1 }}
          href={paths.login}
          onClick={prev}
        >
          Go back to login
        </Button>
        <LoadingButton
          fullWidth
          size='large'
          onClick={resend}
          disabled={isDisabled}
          loading={forgetPasswordStatus === LOADING}
        >
          {isDisabled ? `Retry after ${remainingTime}s` : 'Resend password reset email'}
        </LoadingButton>
      </Paper>
    </Box>
  )
}

export default PasswordRecovery