import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAlert } from './Alert';
import TrainingDrawer from '../components/TrainingDrawer';
import { ERROR, LOADING, SUCCESS } from '../constants/common';
import { createTraining, deleteTraining, getTraining, updateTraining } from '../services/Training.services';
import ConfirmationModal from '../components/ConfirmationModal';


const TrainingContext = createContext({});

export const TrainingContextProvider = ({ children }) => {
    const { sendAlert } = useAlert();
    const initialState = {
        fetchStatus: '',
        list: [],
        createStatus: '',
        updateStatus: '',
        deleteStatus: ''
    }

    const [credentailsState, setCredentailsState] = useState(initialState);
    const [activeTraining, setActiveTraining] = useState({});
    const [open, setOpen] = useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    
    function handleClose() {
        setOpen(false)
    }

    function handleOpen(data) {
        setActiveTraining(data);
        setOpen(true)
    }

    function handleOpenConfirmationModal() {
        setOpenConfirmationModal(true)
    }
    
    function handleCloseConfirmationModal() {
        setOpenConfirmationModal(false)
    }

    function updateTrainingtate(obj) {
        setCredentailsState((prevState) => ({
            ...prevState,
            ...obj
        }))
    }

    async function fetchTraining() {
        updateTrainingtate({ fetchStatus: LOADING })
        try {
            const response = await getTraining();
            updateTrainingtate({
                list: response.data.data,
                fetchStatus: SUCCESS
            })
        } catch (error) {
            updateTrainingtate({ fetchStatus: ERROR })
            Promise.reject(error)
        }
    }

    async function generateTraining(data) {
        updateTrainingtate({ createStatus: LOADING })
        try {
            await createTraining(data);
            updateTrainingtate({
                createStatus: SUCCESS
            })
            sendAlert('Created successfully', 'success')
        } catch (error) {
            updateTrainingtate({ createStatus: ERROR })
            sendAlert(error.response?.data?.message, 'error')
            Promise.reject(error)
        }
    }

    async function editTraining(data) {
        updateTrainingtate({ updateStatus: LOADING })
        try {
            await updateTraining(data);
            updateTrainingtate({
                updateStatus: SUCCESS
            })
            sendAlert('Updated successfully', 'success')
        } catch (error) {
            updateTrainingtate({ updateStatus: ERROR })
            sendAlert(error.response?.data?.message, 'error')
            Promise.reject(error)
        }
    }

    async function removeTraining(data) {
        updateTrainingtate({ deleteStatus: LOADING })
        try {
            await deleteTraining(data);
            updateTrainingtate({
                deleteStatus: SUCCESS
            })
            sendAlert('Removed successfully', 'success')
        } catch (error) {
            updateTrainingtate({ deleteStatus: ERROR })
            sendAlert(error.response?.data?.message, 'error')
            Promise.reject(error)
        }
    }

    function removeTrainingFunction() {
        removeTraining({ id: activeTraining?.id })
    }

    useEffect(() => {
        if (credentailsState.deleteStatus === 'SUCCESS') {
            updateTrainingtate('deleteStatus', '');
            setActiveTraining(undefined);
            handleCloseConfirmationModal();
            handleClose();
            fetchTraining();
        }
    }, [credentailsState.deleteStatus])

    const state = {
        ...credentailsState,
        open,
        handleClose,
        handleOpen,
        fetchTraining,
        generateTraining,
        editTraining,
        removeTraining,
        openConfirmationModal: handleOpenConfirmationModal,
        updateTrainingtate
    };

    return (
        <TrainingContext.Provider value={state}>
            {children}

            <TrainingDrawer
                onClose={handleClose}
                open={open}
                activeTraining={activeTraining}
            />
            <ConfirmationModal
                open={openConfirmationModal}
                onClose={handleCloseConfirmationModal}
                message={`Are you sure you want to delete ${activeTraining?.data?.title}?`}
                deleteFunction={removeTrainingFunction}
                loading={credentailsState.deleteStatus === LOADING}
            />
        </TrainingContext.Provider>
    );
};

export const useTraining = () => useContext(TrainingContext);