import React, { useEffect, useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Drawer,
  Typography,
  Divider,
  Grid,
  Box,
  Button,
  Skeleton
} from '@mui/material'
import CheckboxListItem from '../CheckboxListItem';
import { LOADING, SUCCESS, treatment_program } from '../../constants/common';
import { useFees } from '../../contexts/Fees';
import { useAuth } from '../../contexts/Auth';
import { useBasicInfo } from '../../contexts/BasicInfo';


const InsuranceDrawer = ({ onClose, open }) => {
  const {
    fetchInsurances,
    fetchInsurancesStatus,
    editInsurances,
    updateInsurancesStatus,
    fetchUserInsurances,
    updateFeesState,
    insurances,
    userInsurances
  } = useFees();

  const [selectedInsurances, setSelectedInsurances] = useState([]);
  const { account_type } = useAuth();
  const { data, fetchInfo } = useBasicInfo();


  useEffect(() => {
    if (open)
      fetchInsurances({ account_type: account_type === treatment_program ? treatment_program : 'user' });
  }, [open])

  useEffect(() => {
    if (updateInsurancesStatus === SUCCESS) {
      updateFeesState('createStatus', '');
      fetchUserInsurances({ account_type: account_type === treatment_program ? treatment_program : 'user' });
      handleClose();
    }
  }, [updateInsurancesStatus])


  useEffect(() => {
    if (insurances?.length > 0) {
      const selectedList = userInsurances.map(item => item?.id)
      setSelectedInsurances(selectedList)
    }
  }, [insurances, open]);

  const handleClose = () => {
    onClose();
    setSelectedInsurances([]);
  }

  const selectSpecialty = (data) => {
    setSelectedInsurances((prevState) => {
      const exists = !!prevState.find((item) => +item === data.id);
      if (exists) {
        return (
          prevState.filter((item) => +item !== data.id)
        )
      }
      return (
        [
          ...prevState,
          data?.id
        ]
      )
    })
  }

  const saveInsurances = () => {
    editInsurances({
      insurances: selectedInsurances,
      private_pay_only: data.private_pay_only,
      account_type,
    })
    fetchInfo();
  }

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Insurance Plans Accepted</Typography>
          <Typography variant='body1' mb={1}>Please select the insurances you accept.</Typography>
          <Divider sx={{ mb: 4 }} />

          <Grid container spacing={2.5}>
            {fetchInsurancesStatus === LOADING ?
              Array.from({ length: 6 }).map((_, index) => (
                <Grid item xs={6} key={index}>
                  <Skeleton animation="wave" />
                </Grid>
              )) : (
                insurances.map((item) => (
                  <CheckboxListItem
                    key={item.id}
                    data={item}
                    selectItem={selectSpecialty}
                    selectedItems={selectedInsurances}
                  />
                ))
              )}
          </Grid>
        </Box>
        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            onClick={saveInsurances}
            loading={updateInsurancesStatus === LOADING}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default InsuranceDrawer;