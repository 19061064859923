import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { ReactComponent as SearchIcon } from '../../assets/Icons/search.svg';
import { ReactComponent as CloseIcon } from '../../assets/Icons/close.svg';
import { useNetwork } from '../../contexts/NetWork';
import { LOADING, connections } from '../../constants/common';
import CardLoader from '../../components/CardLoader';
import UserCard from '../../components/UserCard';
import ReferalModal from '../../components/ReferalModal';
import { useUserProfile } from '../../contexts/UserProfile';

let timer;
const UserConnections = () => {
  const { fetchNetwork, fetchStatus, network, } = useNetwork();
  const { data: userData } = useUserProfile();

  const [params, setParams] = useState({
    page: 1,
    search: ''
  });

  const [activeUser, setActiveUser] = useState({});
  const [openReferral, setOpenReferral] = useState(false);

  function handleOpenReferralModal(data) {
    setActiveUser(data)
    setOpenReferral(true);
  }

  function handleCloseReferralModal() {
    setOpenReferral(false);
  }

  function handleSearch(ev) {
    setParams((prevState) => ({
      ...prevState,
      search: ev.target.value,
      page: 1
    }))
  }

  function clearSearch() {
    setParams((prevState) => ({
      ...prevState,
      search: '',
      page: 1
    }));
    fetchNetwork({ ...params, search: '', user_id: userData.id }, connections, undefined, true)
  }


  function handlePagination(_, page) {
    setParams((prevState) => ({
      ...prevState,
      page
    }))
  }

  useEffect(() => {
    if (params.search?.length > 2) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fetchNetwork({ ...params, user_id: userData.id }, connections, undefined, true)
      }, 1000);
    }
  }, [params.search])

  useEffect(() => {
    fetchNetwork({ ...params, user_id: userData.id }, connections, undefined, true)
  }, [params.page])

  return (
    <Paper
      sx={{
        boxShadow: 'none',
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
    >
      <TextField
        fullWidth
        placeholder='Search by Therapist Name, Email or Address'
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: params.search && (
            <InputAdornment position="end">
              <IconButton
                onClick={clearSearch}
                sx={{
                  p: 0,
                  color: 'border.dark'
                }}
              >
                <CloseIcon width={20} height={20} />
              </IconButton>
            </InputAdornment>
          )
        }}
        sx={{
          'input:not(textarea)': {
            pt: { xs: 1.75, sm: 1.5 },
            pb: 1.5,
            fontSize: { xs: 12, sm: 14 },
            pl: 0
          }
        }}
        onChange={handleSearch}
        value={params.search || ''}
        name='search'
      />

      {fetchStatus === LOADING ? (
        <>
          <CardLoader />
          <CardLoader />
          <CardLoader />
          <CardLoader />
        </>
      ) : (
        network.data.map((item) => (
          <UserCard
            key={item.id}
            data={item}
            onReferralClick={handleOpenReferralModal}
            clearSearch={clearSearch}
          />
        ))
      )}

      {network.last_page > 1 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            py: 3
          }}
        >
          <Pagination
            count={network.last_page}
            shape="rounded"
            color='primary'
            onChange={handlePagination}
            page={params.page}
          />
        </Box>
      )}

      <ReferalModal
        open={openReferral}
        onClose={handleCloseReferralModal}
        data={activeUser}
      />
    </Paper>
  )
}

export default UserConnections