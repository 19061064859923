import { axiosInstance } from "./axiosInstance";

function getLanguagesList() {
    return axiosInstance.get('languages');
}

function updateLanguages(data) {
    return axiosInstance.post('languages', data);
}

export {
    getLanguagesList,
    updateLanguages
}