import { z } from 'zod';
import {
  cityValidation,
  educationSchema,
  emailValidation,
  firstNameValidation,
  lastNameValidation,
  requiredPhoneNumberValidation,
  stateValidation,
  streetAddressValidation,
  uploadLogoValidation,
  websiteLinkValidation,
  zipCodeValidation
} from './validations';

export const registerValidationSchema = z.object({
  first_name: firstNameValidation,
  last_name: lastNameValidation,
  email: emailValidation,
  password: z
    .string()
    .min(1, { message: 'Please enter a password.' })
    .min(12, { message: 'Password must be at least 12 characters long.' })
    .regex(
      /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~]).{12,}$/,
      { message: 'Password must include at least one uppercase letter, one lowercase letter, one number, and one special character (#.-?!@$%^&*).' }
    ),
});

const treatmentFacilityValidation = z
  .string()
  .min(1, { message: 'Please add a facility name.' })
  .min(2, { message: 'Name must be at least 2 characters long.' })
  .max(50, { message: 'Name must be less than 50 characters long.' })
  .regex(/^[a-zA-Z0-9-' ]+$/, {
    message: "Name can only contain letters, numbers, spaces, or special characters ‘ and -",
  });

const treatmentFacilityTypeValidation = z
  .array(z.number().int(), { message: 'Please select at least one treatment facility type.' })
  .min(1, { message: 'Please select a treatment facility type.' })


const registerFormValidation = z.object({
  phone_number: requiredPhoneNumberValidation,
  website_link: websiteLinkValidation,
  address1: streetAddressValidation,
  state: stateValidation,
  city: cityValidation,
  zip_code: zipCodeValidation,
});

export const basicInfoValidationSchema = z.object({
  first_name: firstNameValidation,
  last_name: lastNameValidation,
  ...registerFormValidation.shape,
});

export const treatmentProgramProfileValidationSchema = z.object({
  facility_name: treatmentFacilityValidation,
  facility_type_ids: treatmentFacilityTypeValidation,
  facility_image: uploadLogoValidation,
  ...registerFormValidation.shape,
});

export const additionalEducationSchema = z.object({
  degrees: z.array(educationSchema),
});


export const officeHoursDaySchema = z
  .array(z.string())
  .nonempty("Please select at least one day.")
  .refine((days) => {
    if (days.includes("All days")) return days.length === 8;
    if (days.includes("All weekdays")) return days.length === 6;
    return true;
  }, "Please ensure the correct days are selected.")

export const officeHoursTimeSchema = z
  .array(z.string())
  .nonempty("Please select at least one time.")
  .refine((times) => {
    if (times.includes("All times")) return times.length === 4;
    return true;
  }, "Please ensure the correct times are selected.")


export const clientTypeSchema = z.object({
  client_types: z.array(z.number().int())
    .nonempty("Please select a client type.")
});


export const cardHolderNameSchema = z
  .string()
  .min(1, { message: "Please add a card holder name" })
  .min(2, { message: "Cardholder name must be at least 2 characters long." })
  .max(50, { message: "Cardholder name must be less than 50 characters long." })
  .regex(/^[A-Za-z'. -]+$/, {
    message:
      "Cardholder name can only contain letters or special characters ‘ - and . ",
  })
  .refine((val) => val.trim() !== "", { message: "Please add cardholder name." })

export const billingStreetAddressSchema = z
  .string()
  .min(1, { message: "Please enter your street address" })
  .max(100, "Street address cannot exceed 100 characters.")
  .regex(
    /^[A-Za-z0-9 ,.-]+$/,
    "Please enter a valid street address."
  )

export const paymentFormSchema = z.object({
  name: cardHolderNameSchema,
  address1: billingStreetAddressSchema,
  state: stateValidation,
  city: cityValidation,
  zip_code: zipCodeValidation,
});

export const servicesAvailableSchema = z.object({
  payment_options: z.array(z.number().int())
    .nonempty("Please select at least one payment option."),
  available_services: z.array(z.number().int())
    .nonempty("Please select at least one available service.")
})