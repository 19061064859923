import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Avatar, Button, Typography } from '@mui/material'
import UploadInput from '../../../assets/imgs/upload-input.png'
import { useBasicInfo } from '../../../contexts/BasicInfo'
import { SUCCESS, domain } from '../../../constants/common'
import { useAuth } from '../../../contexts/Auth'
import Cookies from 'universal-cookie'
import { useTeams } from '../../../contexts/Teams'
const CompleteProfile = () => {
    const { editProfilePhoto, updatePhotoStatus, updateInfoState } = useBasicInfo();
    const { updateAuthState, userData } = useAuth();
    const { organizationData } = useTeams();
    const [state, setState] = useState(null);
    const cookies = new Cookies();
    function handleFileInput(ev) {
        if (ev.target.files && ev.target.files[0]) {
            setState(ev.target.files[0]);
        }
    }

    function handleSaveImage() {
        if (state)
            editProfilePhoto(state);
    }

    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1);

    function handleGoToLogin() {
        localStorage.setItem('isLoggedIn', 'true');
        updateAuthState('isLoggedIn', true);
        cookies.set('isLoggedIn', 'true', {expires: expirationDate, domain: `.${domain}` , secure: true});
        setState(null);
    }

    function renderRole() {
        if (organizationData?.params?.role === 'team_admin')
            return 'Admin'
        if (organizationData?.params?.role === 'team_assistant')
            return 'Assistant'
        return ''
    }

    const handleDrop = (event) => {
        event.preventDefault();
        const { files } = event.dataTransfer;
        if (files && files[0]) {
            setState(files[0]);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault()
    }

    useEffect(() => {
        if (updatePhotoStatus === SUCCESS) {
            updateInfoState('updatePhotoStatus', '')
            handleGoToLogin();
        }
    }, [updatePhotoStatus])

    return (
        <Container maxWidth='sm'>
            <Paper
                sx={{
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                    borderRadius: 2
                }}
            >
                <Typography variant='h3' fontWeight={600}>Add a profile photo</Typography>
                <Box
                    sx={{
                        border: '1px solid',
                        borderColor: 'secondary.main',
                        borderRadius: 2,
                        backgroundColor: 'background.default',
                        p: 4,
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                    component='label'
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    <Box component='img' src={UploadInput} width={124} height={140} />
                    <Typography variant='body2'>Drag your photo here, or...</Typography>
                    <Button
                        color='secondary'
                        variant='outlined'
                        sx={{
                            color: 'primary.main'
                        }}
                        component='span'
                        disableRipple
                    >
                        Browse Files
                    </Button>
                    <Box component='input' sx={{ display: 'none' }} type='file' accept='image/*' onChange={handleFileInput} />
                </Box>
                <Box>
                    <Typography variant='captionTitle' mb={2} component='p' fontWeight={600}>Preview</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            alignItems: 'center'
                        }}
                    >
                        <Avatar 
                            sx={{
                                width: 60,
                                height: 60
                            }}
                            src={state && URL.createObjectURL(state)}
                        />
                        <Box>
                            <Typography variant='body2' fontWeight={600}>{userData.first_name} {userData.last_name}</Typography>
                            <Typography variant='caption' color='text.secondary'>{organizationData?.params?.program_name} I {renderRole()}</Typography>
                        </Box>

                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: 1
                    }}
                >
                    <Button
                        color='secondary'
                        variant='outlined'
                        sx={{
                            color: 'primary.main',
                            minWidth: 90
                        }}
                        component='span'
                        onClick={handleGoToLogin}
                    >
                        Skip
                    </Button>
                    <Button
                        variant='contained'
                        sx={{
                            minWidth: 90
                        }}
                        onClick={handleSaveImage}
                        disabled={!state}
                    >
                        Save
                    </Button>
                </Box>
            </Paper>
        </Container>
    )
}

export default CompleteProfile