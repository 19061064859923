import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { ReactComponent as CloseIcon } from "../../assets/Icons/close.svg";
import { LOADING, SUCCESS } from "../../constants/common";
import LoadingButton from "@mui/lab/LoadingButton";
import { useCommon } from "../../contexts/common";
import { useOnboarding } from "../../contexts/onboarding";
import { validateAbout } from "../../helper/Validations";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 445,
  p: 3,
  overflow: 'hidden',
};

function FeedBackModal({ open, onClose }) {
  const initialState = {
    feedback: null,
  }
  const [state, setState] = useState(initialState);
  const [isInvalid, setIsInvalid] = useState(false);
  const {
    createFeedback,
    sendFeedbackStatus,
    handleState
  } = useCommon();

  const { uuid } = useOnboarding();

  function handleClose() {
    onClose();
    setState(initialState);
    setIsInvalid(false);
  }

  function handleInput(ev) {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value
    }))
  }

  function isValid() {
    return !!state.feedback
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (isValid()) {
      createFeedback({ ...state, uuid });
    } else {
      setIsInvalid(true);
    }
  }

  useEffect(() => {
    if (sendFeedbackStatus === SUCCESS) {
      handleState('sendFeedbackStatus', '')
      setState({ feedback: '' })
      handleClose()
    }
  }, [sendFeedbackStatus])


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pb: 2
          }}
        >

          <Typography variant='h3'>Feedback</Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              p: 0.25,
              color: 'border.dark'
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="h5" mb={2}>Your feedback:</Typography>
        <Box
          component='form'
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <TextField
            multiline
            rows={5}
            name='feedback'
            onChange={handleInput}
            value={state.feedback}
            error={isInvalid}
            helperText={
              isInvalid && 
              "Please provide valid input for feedback"
            }
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <LoadingButton
              variant="contained"
              sx={{
                minWidth: 150
              }}
              loading={sendFeedbackStatus === LOADING}
              type='submit'
              disabled={!state.feedback}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
}

export default FeedBackModal;