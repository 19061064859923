import React, { createContext, useContext, useEffect, useState } from 'react';
import { ERROR, LOADING, SUCCESS } from '../constants/common';
import { useAlert } from './Alert';
import { checkedAssignUrl, deleteMember, getTeams, inviteTeamMemeber, registerMember, resendMemberInvitation, revokeMemberInvitation } from '../services/teams.services';
import { useAuth } from './Auth';
import copy from "copy-to-clipboard";
import moment from 'moment';

const TeamsContext = createContext({});

export const TeamsContextProvider = ({ children }) => {
  const { updateAuthState } = useAuth();
  const { sendAlert } = useAlert();
  const [step, setStep] = useState(1);

  const [teamsState, setTeamsState] = useState({
      inviteMemberStatus: '',
      checkUrlStatus: '',
      organizationData: {},
      registerMemberStatus: '',
      fetchTeamsStatus: '',
      userData: {},
      copyInvitationLinkStatus: {},
      removeMemberStatus: '',
      revokeInvitationStatus: '',
      resendInvitationStatus: '',
      teams: {
        accepted: [],
        pending: []
      }
  });

  const sortArray = (arr) => {
    return arr.sort((a, b) => {
      // Sort by 'revoke' flag first: false should come before true
      if (a.revoke !== b.revoke) {
        return a.revoke - b.revoke;
      }
      // If 'revoke' flag is the same, sort by 'created_at' using moment
      return moment(b.created_at) - (moment(a.created_at));
    });
  }

  function next() {
    setStep((prevState) => prevState + 1)
  }

  function prev() {
      setStep((prevState) => prevState > 1 ? prevState - 1 : prevState)
  }

  function handleState(obj) {
      setTeamsState((prevState) => ({
          ...prevState,
          ...obj
      }))
  }

  async function fetchTeams() {
    handleState({
      fetchTeamsStatus: LOADING,
      teams: {
        accepted: [],
        pending: []
      } 
    })
    try {
      const response = await getTeams();
      const teams = response.data?.data;
      if (teams) {
        teams.pending = sortArray(teams.pending)
      }
      handleState({
          fetchTeamsStatus: SUCCESS,
          teams
      })
    } catch (error) {
      handleState({ fetchTeamsStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  async function inviteMember(data) {
      handleState({ inviteMemberStatus: LOADING })
    try {
      await inviteTeamMemeber(data);
      handleState({
          inviteMemberStatus: SUCCESS
      })
      sendAlert('Invited successfully', 'success')
    } catch (error) {
      handleState({ inviteMemberStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  async function copyInvitationLink(data) {
    handleState({ copyInvitationLinkStatus: LOADING })
    try {
      const response = await inviteTeamMemeber(data);
      handleState({
          copyInvitationLinkStatus: SUCCESS,
      })
      sendAlert('Link has been copied successfully', 'success')
      copy(response?.data?.data)
      return response
    } catch (error) {
      handleState({ copyInvitationLinkStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  async function checkUrl({ route, expires, signature, signed_url }) {
    handleState({ checkUrlStatus: LOADING });
    try {
      const response = await checkedAssignUrl({ route, expires, signature, signed_url });
      handleState({
        checkUrlStatus: SUCCESS,
        organizationData: response.data.data,
      });
      return response;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        throw new Error('Link expired');
      } else {
        handleState({ checkUrlStatus: ERROR });
        throw new Error('Unexpected error occurred');
      }
    }
  }
  

  async function registerTeamMember(data) {
    handleState({ registerMemberStatus: LOADING })
    try {
      const response = await registerMember(data);
      const userData = response.data.data;
      localStorage.setItem('account_type', userData?.account_type);
      localStorage.setItem('accessToken', userData.token);
      // TODO localStorage.setItem('userId', userData.user.id);
      updateAuthState('account_type', userData?.account_type);
      updateAuthState('userData', userData);
      handleState({
          registerMemberStatus: SUCCESS,
          userData
      })
      sendAlert('Registerd successfully', 'success')
      return response;
    } catch (error) {
      handleState({ registerMemberStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  async function removeMember(data) {
    handleState({ removeMemberStatus: LOADING })
    try {
      await deleteMember(data);
      handleState({
          removeMemberStatus: SUCCESS
      })
      sendAlert('Removed successfully', 'success')
    } catch (error) {
      handleState({ removeMemberStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  async function revokeInvitation(data) {
    handleState({ revokeInvitationStatus: LOADING })
    try {
      await revokeMemberInvitation(data);
      handleState({
          revokeInvitationStatus: SUCCESS
      })
      sendAlert('Revoked successfully', 'success')
    } catch (error) {
      handleState({ revokeInvitationStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }
  
  async function resendInvitation(data) {
    handleState({ resendInvitationStatus: LOADING })
    try {
      await resendMemberInvitation(data);
      handleState({
          resendInvitationStatus: SUCCESS
      })
      sendAlert('Resent successfully', 'success')
    } catch (error) {
      handleState({ resendInvitationStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  function updateTeamsState(field, value) {
      handleState({ [field]: value })
  }
  

  useEffect(() => {
    if (Boolean(localStorage?.getItem('accessToken'))) {
      setStep(2)
    }
  }, [localStorage])
  
  const state = {
    ...teamsState,
    step,
    next,
    prev,
    fetchTeams,
    inviteMember,
    registerTeamMember,
    checkUrl,
    copyInvitationLink,
    removeMember,
    revokeInvitation,
    resendInvitation,
    updateTeamsState
  };

  return (
      <TeamsContext.Provider value={state}>
      {children}
      </TeamsContext.Provider>
  );
};

export const useTeams = () => useContext(TeamsContext);