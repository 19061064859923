import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { ReactComponent as ThermometerIcon } from '../../../assets/Icons/thermometer.svg';
import Filter from './Filter';
import CheckBox from '../../../components/CheckBox';
import { useTreatment } from '../../../contexts/teamentModalities';

const TreatmentModalitiesFilter = ({ setFilters, value, isMobileView, goBack, reset }) => {
    const { fetchTreatmentModalities, treatmentModalities } = useTreatment();
    const [expanded, setExpanded] = useState(false);
    const [search, setSearch] = useState('');


    useEffect(()=>{
        if(goBack && reset){
            setExpanded(true)
            setSearch("")
        }
    },[])

    function handleSearch(ev) {
        setSearch(ev.target.value)
    }

    function toggleExpand() {
        setExpanded((prevState) => !prevState)
    }

    function handleChange(ev) {
        setFilters((prevState) => {
            const arr = (prevState.modalities || [])
            const exists = Boolean(arr.find((item) => item === ev.target.value))
            return {
                ...prevState,
                modalities: (exists ? 
                    arr.filter((item) => item !== ev.target.value)
                :
                    [...arr, ev.target.value])
            }
        })
        setSearch('');
    }

    useEffect(() => {
        fetchTreatmentModalities();
    }, [])
    
    return (
        <Filter
            label='Treatment Modalities'
            icon={<ThermometerIcon />}
            isRigntIcon={true}
            reset={reset}
            goBack={goBack}
        >
            <TextField
                fullWidth
                placeholder='Search'
                sx={{
                    'input:not(textarea)': {
                        pt: { xs: 1.75, sm: 1.5 },
                        pb: 1.5,
                        fontSize: { xs: 12, sm: 14 },
                        px: 1,
                        '&::placeholder': {
                            fontWeight: 700,
                        },
                        height:isMobileView ? "40px" :"25px"
                    },
                }}
                name='search'
                onChange={handleSearch}
                value={search}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                    maxHeight: expanded ? 'auto' : 90,
                    overflow: 'hidden',
                }}
            >
                {treatmentModalities.reduce((acc, item) => {
                    const isChecked = Boolean(value.find((data) => data.toString() === item.id.toString()))
                    if (search && !item.name.toLowerCase().includes(search.toLowerCase()))
                        return acc

                    if (isChecked) {
                        return [
                            (
                                <CheckBox
                                    key={item.id}
                                    label={item.name}
                                    value={item.id}
                                    onChange={handleChange}
                                    checked={true}
                                /> 
                            ),
                            ...acc
                        ]
                    }
                    return [
                        ...acc,
                        (
                            <CheckBox
                                key={item.id}
                                label={item.name}
                                value={item.id}
                                onChange={handleChange}
                                checked={false}
                            /> 
                        )
                    ]
                }, [])}
            </Box>
            
            <Button
                sx={{
                    p: 0,
                    justifyContent: 'flex-start',
                    backgroundColor: 'transparent', 
                    '&:hover': {
                        backgroundColor: 'transparent', 
                    }
                }}
                onClick={toggleExpand}
                disableRipple
            >
                {expanded ? 'See less' : 'See more'}
            </Button>

        </Filter>
    )
}

export default TreatmentModalitiesFilter