import { axiosInstance } from "./axiosInstance";

function getSpecialties() {
    return axiosInstance.get('specialties');
}

function updateSpecialties(data) {
    return axiosInstance.post('specialties', data);
}

function getUserSpecialties() {
    return axiosInstance.get('specialties/user');
}

export {
    getSpecialties,
    updateSpecialties,
    getUserSpecialties
}