import { z } from 'zod';
import { optionalPhoneNumberValidation, firstNameValidation, lastNameValidation, requiredPhoneNumberValidation, professionalDegreeValidation, websiteLinkValidation, streetAddressValidation, stateValidation, cityValidation, zipCodeValidation, emailValidation, uploadLogoValidation, sessionFeeRange } from './validations';

const regexPattern = /^[a-zA-Z0-9\s\-’']*$/;
const professionalHeadlineValidation = z
  .string()
  .optional()
  .superRefine((value, ctx) => {
    if (value === null || value.length === 0) {
      return;
    }
    if (value.length > 0 && value.length < 2) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Professional Headline must be at least 2 characters long.',
      });
    }
    if (value.length > 100) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Professional Headline must be less than 100 characters long.',
      });
    }
    else if (!regexPattern.test(value)) {
      ctx.addIssue({
        code: 'custom',
        message: "Professional Headline can only contain letters, numbers, and special characters - and ‘",
      });
    }
  })


export const profileBasicInfoSchema = z.object({
  first_name: firstNameValidation,
  last_name: lastNameValidation,
  headline: professionalHeadlineValidation,
  professional_degree_id: professionalDegreeValidation
});

export const currentPracticeSchema = z.object({
  current_status: z.enum([
    "accepting_new_clients",
    "not_accepting_clients",
    "somewhere_in_between",
  ], {
    errorMap: () => ({ message: "Please select any one option to continue." }),
  }),
});


export const aboutMeValidation = z.object({
  about_me: z.string()
    .regex(/^[A-Za-z0-9.,!'’/"&:+()-\s]*$/, {
      message: "Bio can only contain letters, numbers, spaces, and . , ! ' ’ / \" & : + ( ) - characters."
    })
    .nullable()
    .transform((val) => val.replace(/\n/g, '').trim())
    .superRefine((value, ctx) => {
      if (value === null || value.length === 0) {
        return;
      }
      if (value.length > 0 && value.length < 5) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Bio must be at least 5 characters long.',
        });
      }
      if (value.length > 1000) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Bio must be less than 1000 characters long.',
        });
      }
    })
})

export const contactValidation = z.object({
  phone_number: requiredPhoneNumberValidation,
  business_number: optionalPhoneNumberValidation
})

export const additionalOfficeValidation = z.object({
  fax_number: optionalPhoneNumberValidation,
  office_number: optionalPhoneNumberValidation
})

export const profileLinksValidation = z.object({
  website_link: websiteLinkValidation,
  facebook: websiteLinkValidation,
  twitter: websiteLinkValidation,
  linkedin: websiteLinkValidation
});

export const profileAddressValidation = z.object({
  address1: streetAddressValidation,
  state: stateValidation,
  city: cityValidation,
  zip_code: zipCodeValidation
})

const credentialsName = z
  .enum(["membership", "licence"], {
    errorMap: () => ({ message: "Please select a credential type." }),
  });

const credentialsNumber = z
  .string()
  .trim()
  .min(1, 'Please add an ID or number.')
  .max(20, "ID or number must be less than 20 characters long.")
  .regex(/^[A-Za-z0-9\s-]+$/, "ID or number can only contain letters, numbers, or special character -");

const currentYear = new Date().getFullYear();
const credentialsYear = z
  .string()
  .trim()
  .nonempty("Please add a year.")
  .regex(/^\d{4}$/, "Please enter a valid year (YYYY).")
  .refine(
    (val) => {
      const year = parseInt(val, 10);
      return year >= 1900 && year <= currentYear;
    },
    {
      message: `Please enter a year between 1900 and ${currentYear}.`,
    }
  );

export const profileCredentialsSchema = z.object({
  name: credentialsName,
  state: stateValidation,
  number: credentialsNumber,
  year: credentialsYear,
  title: z.string().trim()
}).superRefine((data, ctx) => {
  const name = data.name;
  if (data.title === '') {
    ctx.addIssue({
      path: ["title"],
      message: `Please add an ${name === 'membership' ? 'Organization name' : 'Issuer name'}`,
    });
  }
  if (data.title?.length > 100) {
    ctx.addIssue({
      path: ["title"],
      message: `${name === 'membership' ? 'Organization name' : 'Issuer name'} must be less than 100 characters long.`,
    });
  }
  if (data.title?.length < 2) {
    ctx.addIssue({
      path: ["title"],
      message: `${name === 'membership' ? 'Organization name' : 'Issuer name'} must be at least 2 characters long.`,
    });
  }
  if (!/^[A-Za-z\s'-]+$/.test(data.title)) {
    ctx.addIssue({
      path: ["title"],
      message: `${name === 'membership' ? 'Organization name' : 'Issuer name'} can only contain letters or special characters ‘ and -"`,
    });
  }
});

const trainingTypes = z
  .enum(['certificate', 'fellowship', 'post-doctoral', 'residency'], {
    errorMap: () => ({ message: "Please select a training type." }),
  });

const institutionNameSchema = z
  .string()
  .trim()
  .min(1, { message: 'Please add an institution/organization name.' })
  .min(2, { message: 'Institution/Organization name must be at least 2 characters long.' })
  .max(100, { message: 'Institution/Organization name must be less than 100 characters long.' })
  .regex(/^[A-Za-z' .-]+$/, {
    message: 'Institution/Organization name can only contain letters or special characters ‘ - .',
  });

const focusSchema = z
  .string()
  .trim()
  .min(1, { message: 'Please add a training focus.' })
  .min(2, { message: 'Training focus must be at least 2 characters long.' })
  .max(100, { message: 'Training focus must be less than 100 characters long.' })
  .regex(/^[A-Za-z' .-]+$/, {
    message: 'Training focus can only contain letters or special characters ‘ - .',
  });

export const profileTrainingSchema = z.object({
  type: trainingTypes,
  name: institutionNameSchema,
  focus: focusSchema,
  year: credentialsYear,
});

const facilityNameSchema = z
  .string()
  .trim()
  .min(1, { message: "Please add a name." })
  .min(2, { message: "Name must be at least 2 characters long." })
  .max(50, { message: "Name must be less than 50 characters long." })
  .regex(/^[A-Za-z'. -]+$/, {
    message: "Name can only contain letters or special characters ‘ - .",
  });


export const basicTreatmentProgramSchema = z.object({
  facility_name: facilityNameSchema,
  business_number: requiredPhoneNumberValidation,
  website_link: websiteLinkValidation,
  facility_type_ids: z.array(z.number().int())
    .nonempty("Please select a treatment facility type.")
});

export const treatmentProgramAddressSchema = z.object({
  address1: streetAddressValidation,
  state: stateValidation,
  city: cityValidation,
  zip_code: zipCodeValidation,
  phone_number: requiredPhoneNumberValidation,
  fax_number: optionalPhoneNumberValidation
});


export const profileAvailableServicesSchema = z.object({
  available_services: z.array(z.number().int())
    .nonempty(`Please select at least one service.`),
  detox_for: z.array(z.number().int()),
  payment_options: z.array(z.number().int())
    .nonempty(`Please select at least one payment option.`),
});

const faqValidation = z.string()
  .regex(/^[A-Za-z0-9.,!'\-\s]*$/, {
    message: "FAQ can only contain letters, numbers, spaces, and . , ! ' - characters.",
  })
  .nullable()
  .superRefine((value, ctx) => {
    if (value === null || value.length === 0) {
      return;
    }
    if (value.length > 0 && value.length < 5) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'FAQ must be at least 5 characters long.',
      });
    }
    if (value.length > 1000) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'FAQ must be less than 1000 characters long.',
      });
    }
  })


export const profileFaqSchema = z.object({
  question_1: faqValidation,
  question_2: faqValidation,
  question_3: faqValidation,
  question_4: faqValidation,
  question_5: faqValidation,
})

export const profileTeamInvitationSchema = z.object({
  is_email_exists: z.boolean(),
  role: z.enum([
    "team_admin",
    "team_assistant",
    "mental_health_provider",
  ], {
    errorMap: () => ({ message: "Please select a role." }),
  }),
  invite_as_mental_health: z.boolean().optional(),
  emails: z.array(emailValidation).min(1, 'Please add a team member email.'),
});

export const profileImageValidationSchema = z.object({
  main_image: uploadLogoValidation
});

export const sessionFeeSchema = z.object({
  Individuals: sessionFeeRange,
  Couples: sessionFeeRange,
  Families: sessionFeeRange,
  Groups: sessionFeeRange,
  "Clinical Assessment": sessionFeeRange,
  Testing: sessionFeeRange,
  Consultation: sessionFeeRange,
  Supervision: sessionFeeRange,
})