import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { LOADING, SUCCESS } from '../../../../constants/common';
import { useBasicInfo } from '../../../../contexts/BasicInfo';
import PhoneNumberInput from '../../../PhoneNumberInput';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { additionalOfficeValidation } from '../../../../validations/profileValidations';

const OfficeInfoDrawer = ({ onClose, open }) => {
  const { editOfficeInfo, updateInfoState, updateOfficeInfoStatus, fetchInfo, data } = useBasicInfo();
  const initialState = {
    fax_number: '',
    office_number: '',
  }
  const [state, setState] = useState(initialState);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
    setValue,
  } = useForm({
    resolver: zodResolver(additionalOfficeValidation),
    mode: "onChange",
    defaultValues: {
      phone_number: data?.info?.fax_number || '',
      business_number: data?.info?.office_number || '',
    },
  });


  useEffect(() => {
    if (updateOfficeInfoStatus === SUCCESS) {
      updateInfoState('updateOfficeInfoStatus', '')
      fetchInfo();
      handleClose();
    }
  }, [updateOfficeInfoStatus]);

  useEffect(() => {
    if (data && open) {
      setState({
        fax_number: data?.info?.fax_number,
        office_number: data?.info?.office_number,
      })
    }
  }, [data, open]);


  const handleClose = () => {
    setState(initialState);
    onClose();
  };

  const handleOfficeNumberInput = (fomattedNumber) => {
    setValue('office_number', fomattedNumber.office_number);
    setState((prev) => ({ ...prev, office_number: fomattedNumber.office_number }))
  };

  const handleFaxNumberInput = (fomattedNumber) => {
    setValue('fax_number', fomattedNumber.fax_number);
    setState((prev) => ({ ...prev, fax_number: fomattedNumber.fax_number }))
  };

  const onSubmit = () => {
    if (isValid)
      editOfficeInfo(state);
  };


  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Office Information</Typography>
          <Divider sx={{ mb: 4 }} />

          <PhoneNumberInput
            fullWidth
            variant='outlined'
            sx={{ mb: 3 }}
            name='fax_number'
            value={state.fax_number}
            onChange={handleFaxNumberInput}
            error={!!errors.fax_number}
            helperText={errors.fax_number?.message}
            label='Fax Number'
            control={control}
            trigger={trigger}
          />

          <PhoneNumberInput
            fullWidth
            variant='outlined'
            label='Office Number'
            sx={{ mb: 3 }}
            onChange={handleOfficeNumberInput}
            name='office_number'
            error={!!errors.office_number}
            helperText={errors.office_number?.message}
            value={state.office_number}
            control={control}
            trigger={trigger}
          />
        </Box>

        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            loading={updateOfficeInfoStatus === LOADING}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default OfficeInfoDrawer;