import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { ReactComponent as Logo } from '../assets/Icons/logo-large.svg';
import { ReactComponent as ArrowLeft } from '../assets/Icons/arrow-left.svg';
import { useOnboarding } from '../contexts/onboarding';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import paths from '../constants/paths';
import { useAuth } from '../contexts/Auth';
import "./style.css";

const OnboardingLayout = ({ children, withArrow, isLogin }) => {
  const { prev, step } = useOnboarding()
  const { prev: previous, step: forgetPasswordStep } = useAuth()
  const { isLoggedIn } = useAuth()
  const navigate = useNavigate();
  const location = useLocation();

  const back = () => {
    if (location.pathname === paths.forgetPassword) {
      if (forgetPasswordStep > 1)
        previous()
      else
        navigate(paths.login)
    } else if (location.pathname === paths.resetPassword) {
      navigate(paths.forgetPassword)
    } else {
      if (step > 1)
        prev()
      else
        navigate(-1)
    }
  }

  useEffect(() => {
    if (isLoggedIn)
      navigate(paths.home);
  }, [isLoggedIn])

  if (isLoggedIn)
    return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '100%',
      }}
    >
      <div className='styleHeader'>
        <Container className={isLogin || step === 1 ? null : 'shadowLayout'} sx={{ position: 'sticky', top: '0', zIndex: '5', background: 'white', paddingBottom: '15px' }} maxWidth='md'>
          <div className='layoutForm'>
            {withArrow ? (
              <IconButton sx={{ p: 0.5, color: 'border.dark' }} onClick={back}>
                <ArrowLeft />
              </IconButton>
            ) : (
              <Box width={32} height={32} />
            )}
            <Logo />
            <Box width={32} height={32} />
          </div>
        </Container>
      </div>
      <Box component='main' sx={{ paddingTop: '0px' }} py={5} width='100%'>
        <div className='paddingLarge'>
          {children}
        </div>
      </Box>
      {(step === 1 || location.pathname === paths.login) && (
        <Box
          sx={{
            textAlign: 'center',
            pb: 4.25,
          }}
          component='footer'
        >
          <div className='hideSmall'>
            <Typography sx={{ padding: "2px 20px" }} variant='body2'>
              <Typography variant='body2' color='text.secondary' component='span'>
                ©2023 Word of Mouth
              </Typography>
              {' '}
              - Privacy Cookies  Legal  Contact  Feedback
            </Typography>
          </div>
          <div className='hideLg'>
            <Typography
              variant='body2'
              sx={{
                color: 'text.secondary',
                maxWidth: 300,
                mx: 'auto',
                textAlign: 'center'
              }}
            >
              By signing up, you agree to the{" "}
              <a sx={{ color: "#0C8CE9 !important" }} href={"https://wordofmouththerapists.com/terms"} target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and{" "}
              <a sx={{ color: "#0C8CE9 !important" }} href={"https://wordofmouththerapists.com/privacy"} target="_blank" rel="noopener noreferrer">Privacy Policy.</a>
            </Typography>
          </div>
        </Box>
      )}
    </Box>
  )
}

export default OnboardingLayout