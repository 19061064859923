import { axiosInstance } from "./axiosInstance";

function getTreatmentModalities(params) {
    return axiosInstance.get('treatment-modalities', { params });
}

function updateTreatmentModalities(data) {
    return axiosInstance.post('treatment-modalities', data);
}

function getUserTreatmentModalities(params) {
    return axiosInstance.get('treatment-modalities/user', { params });
}

function updateMedicationPrescriber(data) {
    return axiosInstance.put('degrees/medication-prescriber', data);
}

export {
    getTreatmentModalities,
    updateTreatmentModalities,
    getUserTreatmentModalities,
    updateMedicationPrescriber
}