import React from 'react'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SideMenu from '../components/SideMenu';
import MainLayout from '../layouts/MainLayout';
import { settingsNavMenu } from '../constants/NavMenus';

const SettingsLayout = ({ children, menu }) => {
  return (
    <MainLayout
      currentPage='Profile'
      currentPageTitle='Settings'
      noWarning
    >
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2.4}>
              <SideMenu
                menu={settingsNavMenu}
                withBackground
              />
          </Grid>
          <Grid item xs={12} md={9.6}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </MainLayout>
  )
}

export default SettingsLayout;