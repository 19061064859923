import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Drawer from "@mui/material/Drawer";

import UserCard from "../../components/UserCard";
import { ReactComponent as SearchIcon } from "../../assets/Icons/search.svg";
import { networkNavMenu } from "../../constants/NavMenus";
import { useNetwork } from "../../contexts/NetWork";
import {
  LOADING,
  SUCCESS,
  favorites,
  treatment_program,
} from "../../constants/common";
import { ReactComponent as ChevronDown } from "../../assets/Icons/chevron-down.svg";
import { ReactComponent as CloseIcon } from "../../assets/Icons/close.svg";
import { ReactComponent as FilterIcon } from "../../assets/Icons/filter.svg";
import { ReactComponent as FilterCloseIcon } from "../../assets/Icons/filter-close.svg";
import { ReactComponent as FilterWhiteIcon } from "../../assets/Icons/white-filter-icon.svg";
import ReferalModal from "../../components/ReferalModal";
import CardLoader from "../../components/CardLoader";
import { useLocation, useParams } from "react-router-dom";
import Filters from "./Filters";
import FilterDrawerContent from "./Filters/FilterDrawerContent";
import SendToFriendModal from "../../components/SendToFriendModal";
import { useUserProfile } from "../../contexts/UserProfile";
import { useAuth } from "../../contexts/Auth";
import useResize from "../../hooks/useResize";
import useFilters from "../../hooks/useFilter";
import { validateSearch } from "../../helper/Validations";
import { trackClickEvent } from "../../mixpanel";

let timer;
const initialFilters = { page: 1, provider: "therapists" };

const CustomChip = ({ filters, clearFilterType }) => {
  return (
    <>
      {filters?.provider ? (
        <Chip
          label="Provider"
          onClick={() => clearFilterType("provider")}
          onDelete={() => clearFilterType("provider")}
        />
      ) : null}
      {(filters?.state || filters?.city || filters?.distance || filters?.zip_code) ? (
        <Chip
          label="Location"
          onClick={() => {
            clearFilterType("state")
            clearFilterType("city")
            clearFilterType("distance")
            clearFilterType("zip_code")
          }}
          onDelete={() => {
            clearFilterType("state")
            clearFilterType("city")
            clearFilterType("distance")
            clearFilterType("zip_code")
          }}
        />
      ) : null}
      {
        (
          filters?.treatment_types?.length ||
          filters?.available_services?.length ||
          filters?.detox_for?.length ||
          filters?.payment_options?.length
        ) ? (
          <Chip
            label="Services Available"
            onClick={() => {
              clearFilterType("treatment_types")
              clearFilterType("available_services")
              clearFilterType("detox_for")
              clearFilterType("payment_options")
            }}
            onDelete={() => {
              clearFilterType("treatment_types")
              clearFilterType("available_services")
              clearFilterType("detox_for")
              clearFilterType("payment_options")
            }}
          />
        ) : null
      }
      {filters?.accepting_clients ? (
        <Chip
          label="Plans Accepted"
          onClick={() => clearFilterType("accepting_clients")}
          onDelete={() => clearFilterType("accepting_clients")}
        />
      ) : null}
      {filters?.insurance?.length ? (
        <Chip
          label="Insurance"
          onClick={() => clearFilterType("insurance")}
          onDelete={() => clearFilterType("insurance")}
        />
      ) : null}
      {filters?.user_types?.length ? (
        <Chip
          label="Connection"
          onClick={() => clearFilterType("user_types")}
          onDelete={() => clearFilterType("user_types")}
        />
      ) : null}
      {
        (filters?.fees_min || filters?.fees_max) ? (
          <Chip
            label="Fees"
            onClick={() => {
              clearFilterType("fees_min");
              clearFilterType("fees_max");
            }}
            onDelete={() => {
              clearFilterType("fees_min");
              clearFilterType("fees_max");
            }}
          />
        ) : null
      }
      {
        (filters?.gender_ids?.length ||
          filters?.races?.length ||
          filters?.religions?.length ||
          filters?.sexualOrientationsList?.length) ? (
          <Chip
            label="Provider Demographics"
            onClick={() => {
              clearFilterType("gender_ids");
              clearFilterType("races");
              clearFilterType("sexualOrientationsList");
              clearFilterType("religions");
            }}
            onDelete={() => {
              clearFilterType("gender_ids");
              clearFilterType("races");
              clearFilterType("sexualOrientationsList");
              clearFilterType("religions");
            }}
          />
        ) : null
      }
      {filters?.degrees?.length ? (
        <Chip
          label="Degrees"
          onClick={() => clearFilterType("degrees")}
          onDelete={() => clearFilterType("degrees")}
        />
      ) : null}
      {filters?.age?.length ? (
        <Chip
          label="Age"
          onClick={() => clearFilterType("age")}
          onDelete={() => clearFilterType("age")}
        />
      ) : null}
      {filters?.specialties?.length ? (
        <Chip
          label="Specialties"
          onClick={() => clearFilterType("specialties")}
          onDelete={() => clearFilterType("specialties")}
        />
      ) : null}
      {filters?.modalities?.length ? (
        <Chip
          label="Treatment Modalities"
          onClick={() => clearFilterType("modalities")}
          onDelete={() => clearFilterType("modalities")}
        />
      ) : null}
      {filters?.office_hours_day?.length ? (
        <Chip
          label="Office Hours"
          onClick={() => clearFilterType("office_hours_day")}
          onDelete={() => clearFilterType("office_hours_day")}
        />
      ) : null}
      {filters?.credentials?.length ? (
        <Chip
          label="Credentials"
          onClick={() => clearFilterType("credentials")}
          onDelete={() => clearFilterType("credentials")}
        />
      ) : null}
      {filters?.trainings?.length ? (
        <Chip
          label="Training"
          onClick={() => clearFilterType("trainings")}
          onDelete={() => clearFilterType("trainings")}
        />
      ) : null}
      {filters?.language_ids?.length ? (
        <Chip
          label="Languages"
          onClick={() => clearFilterType("language_ids")}
          onDelete={() => clearFilterType("language_ids")}
        />
      ) : null}
    </>
  )
}

const Network = () => {
  const [openFilters, setOpenFilters] = useState(true);
  const [activeUser, setActiveUser] = useState({});
  const [openReferral, setOpenReferral] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [clearStatus, setClearStatus] = useState(false);
  const [filters, setFilters] = useFilters(initialFilters);
  const [search, setSearch] = useState("");

  const { id } = useParams();
  const { fetchNetwork, fetchStatus, network, connectStatus } = useNetwork();
  const { likeStatus } = useUserProfile();
  const { account_type } = useAuth();
  const isMobileView = useResize(600);
  const [isMounted, setIsMounted] = useState(false);
  const location = useLocation();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (isMounted) {
      fetchNetwork({ ...filters, account_type: account_type === treatment_program ? treatment_program : "user" }, id);
    } else {
      setIsMounted(true);
    }
  }, [filters, id]);

  useEffect(() => {
    if (filters?.search && filters.search !== search) {
      setSearch(filters.search || "");
    }
  }, [filters.search]);

  useEffect(() => {
    scrollToTop();
  }, [filters?.page]);

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setFilters(prevState => ({ ...prevState, search, page: 1 }));
    }, 500);
  }, [search, location.pathname]);

  useEffect(() => {
    if (connectStatus === SUCCESS) {
      fetchNetwork({ ...filters, account_type: account_type === treatment_program ? treatment_program : "user" }, id, true);
    }
  }, [filters, connectStatus]);

  useEffect(() => {
    if (likeStatus === SUCCESS && id === favorites) {
      setFilters(prevState => ({ ...prevState, page: 1 }));
    }
  }, [likeStatus]);


  // Event handlers
  const clearFilterType = key => {
    setFilters(prevState => {
      const updatedState = { ...prevState };
      delete updatedState[key];
      return updatedState;
    });
  };

  const clearSearch = () => {
    clearFilterType('search')
    setSearch("");
  };

  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);
  const handleOpenFilters = () => setOpenFilters(true);
  const handleCloseFilters = () => setOpenFilters(false);
  const handleOpenReferralModal = data => { setActiveUser(data); setOpenReferral(true); };
  const handleCloseReferralModal = () => setOpenReferral(false);
  const handleOpenShareModal = data => { setActiveUser(data); setOpenShare(true); };
  const handleCloseShareModal = () => setOpenShare(false);
  const handleChange = ev => {
    setFilters(prevState => (
      { ...prevState, [ev.target?.name]: ev.target?.value, page: 1 }
    ));
    trackClickEvent(
      `Add ${ev.target?.name} Filter`, 
      { page: 'Network', component: 'Filters', button: ev.target?.name }
    );
  };
  const handleSearch = ev => setSearch(ev.target?.value);
  const handlePagination = (_, page) => { setFilters(prevState => ({ ...prevState, page })); };
  const reset = () => { setFilters({ page: 1, provider: "therapists" }); setClearStatus(true); setSearch(""); };

  return (
    <MainLayout currentPage="My Network">
      <Container>
        <Grid container spacing={isMobileView ? 0 : 3}>

          {/* 
            Mobile view drawer
          */}

          {isMobileView && (
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={handleDrawerToggle}
              style={{ width: "90%" }}
            >
              <FilterDrawerContent
                isMobileView={isMobileView}
                filters={filters}
                setFilters={setFilters}
                handleChange={handleChange}
                reset={reset}
                closeFilters={() => {
                  handleCloseFilters();
                  setDrawerOpen(false);
                }}
                filterLength={network.data.length}
              />
            </Drawer>
          )}

          <Grid item xs={3.5}>
            {!isMobileView && openFilters && (
              <Filters
                isMobileView={isMobileView}
                filters={filters}
                setFilters={setFilters}
                handleChange={handleChange}
                reset={reset}
                closeFilters={handleCloseFilters}
                setClearStatus={setClearStatus}
                clearStatus={clearStatus}
              />
            )}
          </Grid>
          {isMobileView && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1.5,
                  width: "100%",
                }}
              >
                <Paper
                  sx={{
                    boxShadow: "none",
                    borderColor: "border.main",
                    width: "100%",
                    mb: 2,
                    display: "flex",
                  }}
                >
                  {networkNavMenu.map((item) => {
                    const isActive = location.pathname === item.path;
                    return (
                      <Link
                        key={item.id}
                        href={item.path}
                        sx={{
                          color: isActive ? "text.primary" : "text.secondary",
                          fontSize: isMobileView ? "13px" : "15px",
                          fontWeight: 500,
                          padding: "12px 4px",
                          flex: 1,
                          height: "45px",
                          textAlign: "center",
                          backgroundColor: isActive ? "#F3F9FD" : "transparent",
                          borderBottom: "2px solid",
                          borderColor: isActive
                            ? "primary.main"
                            : "transparent",
                        }}
                      >
                        {item.text}
                      </Link>
                    );
                  })}
                </Paper>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    width: "80%",
                  }}
                >
                  <TextField
                    fullWidth
                    placeholder="Search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: search && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => clearSearch()}
                            sx={{
                              p: 0,
                              color: "border.dark",
                              width: 25
                            }}
                          >
                            <CloseIcon width={20} height={20} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      "input:not(textarea)": {
                        pt: { xs: 1.75, sm: 1.5 },
                        pb: 1.5,
                        fontSize: { xs: 12, sm: 14 },
                        pl: 0,
                        backgroundColor: isMobileView ? "#fff" : "none",
                      },
                    }}
                    onChange={handleChange}
                    value={search || ""}
                    name="search"
                  />
                </Box>
                <Box sx={{ pl: 1 }}>
                  {" "}
                  <Button
                    startIcon={!isMobileView ? <FilterCloseIcon /> : Object.keys(filters)?.length > 1 ? <FilterWhiteIcon /> : <FilterIcon />}
                    onClick={
                      isMobileView
                        ? handleDrawerToggle
                        : openFilters
                          ? handleCloseFilters
                          : handleOpenFilters
                    }
                    sx={{
                      padding: "11px",
                      border: "1px solid #0C8CE9",
                      backgroundColor: !isMobileView ? "transparent" : Object.keys(filters)?.length > 1 ? "#0C8CE9" : "#ffffff",
                      color: "primary.main",
                      "&:hover": {
                        backgroundColor: isMobileView
                          ? "#ffffff"
                          : "transparent",
                      },
                    }}
                    disableRipple
                  >
                    {!isMobileView && (
                      <Typography variant="body2" color="text.primary">
                        Filter
                      </Typography>
                    )}
                  </Button>
                </Box>
              </Box>
            </>
          )}
          {isMobileView && (
            <Box
              sx={{
                width: "100%",
                overflow: "scroll",
                mb: 1,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "4px",
              }}
              className="tags-container"
            >
              <CustomChip filters={filters} clearFilterType={clearFilterType} />
            </Box>
          )}

          <Grid item xs={isMobileView ? 12 : openFilters ? 8.5 : 12}>
            <Paper
              sx={{
                boxShadow: "none",
                pb: 4,
                borderColor: "border.main",
                height: "100%",
              }}
            >
              {!isMobileView && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1.5,
                  }}
                >
                  {networkNavMenu.map((item) => {
                    const isActive = location.pathname === item.path;
                    return (
                      <Link
                        key={item.id}
                        href={item.path}
                        sx={{
                          color: isActive ? "text.primary" : "text.secondary",
                          py: 2,
                          fontSize: isMobileView ? "12px" : "15px",
                          fontWeight: isMobileView ? 400 : 500,
                          px: 1,
                          flex: 1,
                          textAlign: "center",
                          backgroundColor: isActive ? "#F3F9FD" : "transparent",
                          borderBottom: "2px solid",
                          borderColor: isActive
                            ? "primary.main"
                            : "transparent",
                        }}
                      >
                        {item.text}
                      </Link>
                    );
                  })}
                </Box>
              )}

              <Box
                sx={{
                  py: isMobileView ? 0 : 3,
                  backgroundColor: isMobileView
                    ? "transparent"
                    : "background.paper",
                }}
              >
                {!isMobileView && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: isMobileView ? "row-reverse" : "row",
                      alignItems: "center",
                      px: 3,
                      gap: 2,
                    }}
                  >
                    <Button
                      startIcon={
                        openFilters ? <FilterCloseIcon /> : <FilterIcon />
                      }
                      onClick={
                        isMobileView
                          ? handleDrawerToggle
                          : openFilters
                            ? handleCloseFilters
                            : handleOpenFilters
                      }
                      sx={{
                        p: 0,
                        color: "primary.main",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      disableRipple
                    >
                      {!isMobileView && (
                        <Typography variant="body2" color="text.primary">
                          Filter
                        </Typography>
                      )}
                    </Button>

                    {!isMobileView && (
                      <Box
                        sx={{
                          flexGrow: 1,
                        }}
                      >
                        <TextField
                          fullWidth
                          placeholder="Search by Therapist Name, Email or Address"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                            endAdornment: search && (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => clearSearch()}
                                  sx={{
                                    p: 0,
                                    color: "border.dark",
                                    width: 25
                                  }}
                                >
                                  <CloseIcon width={20} height={20} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            "input:not(textarea)": {
                              pt: { xs: 1.75, sm: 1.5 },
                              pb: 1.5,
                              fontSize: { xs: 12, sm: 14 },
                              pl: 0,
                            },
                          }}
                          onChange={handleSearch}
                          value={search || ""}
                          error={search && !validateSearch(search)}
                          helperText={
                            !validateSearch(search)
                            && "Please enter valid search input"
                          }
                          name="search"
                        />
                      </Box>
                    )}
                    {isMobileView ? null : (
                      <FormControl sx={{ height: 45 }}>
                        {!filters?.sortBy && (
                          <InputLabel
                            id="sortBy"
                            shrink={false}
                            sx={{
                              "&[data-shrink=false]": {
                                transform: "translate(12px, 11.5px) scale(1)",
                                color: "text.secondary",
                              },
                            }}
                          >
                            Sort by: default
                          </InputLabel>
                        )}
                        <Select
                          labelId="sortBy"
                          variant="filled"
                          label="Sort by:"
                          sx={{
                            minWidth: { xs: 130, sm: 193 },
                            height: 45,
                            "& .MuiSelect-select": {
                              py: 1.375,
                              fontSize: 14,
                            },
                          }}
                          placeholder="test"
                          IconComponent={(props) => (
                            <Box
                              {...props}
                              component="span"
                              sx={{
                                minWidth: 24,
                                width: 24,
                                height: 24,
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                                top: "50% !important",
                                right: "2px !important",
                                transform: "translateY(-50%) !important",
                              }}
                            >
                              <ChevronDown width={12} height={6} />
                            </Box>
                          )}
                          renderValue={(selected) => (
                            <>
                              Sort by:{" "}
                              <Box component="span" fontWeight={600}>
                                {selected === "created_at" && "Recently added"}
                                {selected === "name" && "Name"}
                              </Box>
                            </>
                          )}
                          value={filters?.sortBy || ""}
                          onChange={handleChange}
                          name="sortBy"
                        >
                          <MenuItem value="created_at">Recently added</MenuItem>
                          <MenuItem value="name">Name</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  </Box>
                )}
              </Box>
              {!isMobileView && (
                <Box
                  sx={{
                    overflow: "scroll",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 1,
                    mx: 3,
                    mb: 1,
                  }}
                  className="tags-container"
                >
                  <CustomChip filters={filters} clearFilterType={clearFilterType} />
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  px: isMobileView ? 0 : 3,
                  backgroundColor: isMobileView ? "#FAFAFA" : "#FFFFFF",
                }}
              >
                {fetchStatus === LOADING ? (
                  <>
                    <CardLoader />
                    <CardLoader />
                    <CardLoader />
                    <CardLoader />
                  </>
                ) : network.data.length === 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#ffff",
                    }}
                  >
                    <Typography
                      variant="h4"
                      color="text.primary"
                      sx={{ my: 2 }}
                    >
                      No matches were found. Please modify your search and try again.
                    </Typography>
                  </Box>
                ) : (
                  network.data.map((item) => (
                    <UserCard
                      key={item.id}
                      data={item}
                      onReferralClick={handleOpenReferralModal}
                      onShareClick={handleOpenShareModal}
                    />
                  ))
                )}
                {network.last_page > 1 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      py: 3,
                    }}
                  >
                    <Pagination
                      count={network.last_page}
                      shape="rounded"
                      color="primary"
                      onChange={handlePagination}
                      page={filters?.page}
                    />
                  </Box>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <ReferalModal
        open={openReferral}
        onClose={handleCloseReferralModal}
        data={activeUser}
      />

      <SendToFriendModal
        open={openShare}
        onClose={handleCloseShareModal}
        data={activeUser}
      />
    </MainLayout>
  );
};

export default Network;
