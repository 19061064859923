import { axiosInstance } from "./axiosInstance";

function getSuggestedPeople() {
  return axiosInstance.get("suggested-people");
}

function getPosts({ post_id, ...params }) {
  return axiosInstance.get(post_id ? `posts/${post_id}` : "posts", { params });
}

function getPost(post_id) {
  return axiosInstance.get(`single-post/${post_id}`);
}

function addPost(data) {
  return axiosInstance.post("posts/add", data);
}

function editPost(id, data) {
  return axiosInstance.put(`posts/${id}`, data);
}

function deletePost(id) {
  return axiosInstance.delete(`posts/${id}`);
}

function likePost({ isLiked, ...data }) {
  return axiosInstance.put(`posts/${isLiked ? "like" : "unlike"}`, data);
}

function addComment(data) {
  return axiosInstance.post("comments/add", data);
}

function editComment(data) {
  return axiosInstance.put(`comments/edit`, data);
}

function likeComment(data) {
  return axiosInstance.post("comments/like", data);
}
function unlikeComment(data) {
  return axiosInstance.post("comments/unlike", data);
}
function likeReply(data) {
  return axiosInstance.post("replies/like", data);
}
function unlikeReply(data) {
  return axiosInstance.post("replies/unlike", data);
}

function addReply(data) {
  return axiosInstance.post("replies/add", data);
}

function editReply(data) {
  return axiosInstance.put(`replies/edit`, data);
}


function getRepliesOfComment(data) {
  return axiosInstance.get(`replies/${data}`);
}
function updateCurrentStatus(data) {
  return axiosInstance.patch("current-status", data);
}

function getInvitationLink() {
  return axiosInstance.get("invitations/generate-link");
}

function getInvitations() {
  return axiosInstance.get("invitations/get-invited_users");
}

function getNotificationSetting() {
  return axiosInstance.get("notifications/get-user-settings");
}

function getNotificationList(data) {
  return axiosInstance.get("notifications", {
    params: {
      ...data,
    },
  });
}

function updateNotificationSetting(data) {
  return axiosInstance.put(`notifications/user-settings`, data);
}

function getUnReadNotificationCount() {
  return axiosInstance.get("/notifications/unseen-count");
}

function updateNotificationReadStatus() {
  return axiosInstance.put("notifications/mark-all-seen");
}

function updateSingleNotificationReadStatus(id) {
  return axiosInstance.put(`notifications/${id}/mark-one-seen`);
}



export {
  getSuggestedPeople,
  getPosts,
  addPost,
  likePost,
  addComment,
  updateCurrentStatus,
  getInvitationLink,
  getInvitations,
  updateNotificationSetting,
  getNotificationSetting,
  getUnReadNotificationCount,
  updateNotificationReadStatus,
  getNotificationList,
  getPost,
  updateSingleNotificationReadStatus,
  deletePost,
  editPost,
  addReply,
  getRepliesOfComment,
  likeComment,
  unlikeComment,
  likeReply,
  unlikeReply,
  editComment,
  editReply
};
