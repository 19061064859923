import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import { ReactComponent as EditIcon } from "../../assets/Icons/edit-3.svg";
import { useCommon } from "../../contexts/common";
import "./style.css";

const DegreeCard = ({ data, onEdit, index, isLocalEditing, isOnBoarding }) => {
  const { degreesList } = useCommon();

  function handleEdit() {
    onEdit(isLocalEditing ? { ...data, index } : data);
  }

  return (
    <div className="degree_style">
      <Box className="degree_style_inner">
        <Box sx={{ mb: 1.5 }}>
          <Typography variant="h5">Degree/Diploma</Typography>
          <Typography variant="body2">
            {isLocalEditing
              ? degreesList.find(
                  (item) => item.id === data.professional_degree_id
                )?.name
              : data.name}
          </Typography>
        </Box>

        <Box sx={{ mb: 1.5 }}>
          <Typography variant="h5">School</Typography>
          <Typography variant="body2">{data.college_name}</Typography>
        </Box>

        <Box sx={{ mb: 1.5 }}>
          <Typography variant="h5">Year Graduated</Typography>
          <Box sx={{ display: "flex", flexDirection: "column", pl: 2 }}>
            <Typography variant="body2">{data.degree_year}</Typography>
          </Box>
        </Box>

        <Box sx={{ mb: 1.5 }}>
          <Typography variant="h5">Year Began Practicing</Typography>
          <Box sx={{ display: "flex", flexDirection: "column", pl: 2 }}>
            <Typography variant="body2">{data.practice_year}</Typography>
          </Box>
        </Box>
      </Box>

      <Box>
        <IconButton sx={{ p: 0.375 }} onClick={handleEdit}>
          <EditIcon />
        </IconButton>
      </Box>
    </div>
  );
};

export default DegreeCard;
