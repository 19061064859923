import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { ReactComponent as VisaCard } from '../../../../../assets/Icons/visa-card.svg';
import { ReactComponent as Amex } from '../../../../../assets/Icons/amex.svg';
import { ReactComponent as PaymentCard } from '../../../../../assets/Icons/payment-method.svg';
import { ReactComponent as MasterCard } from '../../../../../assets/Icons/master-card.svg';
import { ReactComponent as DaysIcon } from '../../../../../assets/Icons/30days.svg';
import { ReactComponent as SafeIcon } from '../../../../../assets/Icons/safe.svg';
import "./style.css";

const Guides = () => {
    return (
        <Box
            sx={{
                maxWidth: 1079,
                mx: 'auto',
                py: 6
            }}
        >
            <div className='guides'>
                <Grid container spacing={0}>
                    <Grid item md={4} xs={12}
                        sx={{
                            borderRight: '1px solid #ECECEC',
                            px: 3
                        }}
                    >
                        <Typography variant='body2' fontWeight={600} mb={2}>ACCEPTED PAYMENT METHODS</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1
                            }}
                        >
                            <VisaCard />
                            <Amex />
                            <PaymentCard />
                            <MasterCard />
                        </Box>
                    </Grid>

                    <Grid item md={4} xs={12}
                        sx={{
                            borderRight: '1px solid #ECECEC',
                            px: 3
                        }}
                    >
                        <div className='paddingStyle'>
                            <Box
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                <Box mr={2}>
                                    <DaysIcon />
                                </Box>
                                <Box>
                                    <Typography variant='body2' fontWeight={600} mb={1}>30-DAY FREE TRIAL</Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        Try us risk-free for 30 days. If you’re not satisfied there’s no charge.
                                    </Typography>
                                </Box>
                            </Box>
                        </div>
                    </Grid>

                    <Grid item md={4} xs={12}
                        sx={{
                            borderRight: '1px solid #ECECEC',
                            px: 3
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Box mr={2}>
                                <SafeIcon />
                            </Box>
                            <Box>
                                <Typography variant='body2' fontWeight={600} mb={1}>SSL SECURE PAYMENT</Typography>
                                <Typography variant='body2' color='text.secondary'>
                                    Your information is protected by 256-bit SSL encryption.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </Box>
    )
}

export default Guides