import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as EditIcon } from '../../assets/Icons/edit-3.svg'
import { useTraining } from '../../contexts/Training'

const TrainingItem = ({ data }) => {
    const { handleOpen } = useTraining();

    function onEdit() {
        handleOpen(data);
    }

    return (
        <Box
            component='li'
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    minHeight: 30,
                    '.MuiIconButton-root': {
                        display: 'none',
                    },
                    '&:hover .MuiIconButton-root': {
                        display: 'flex',
                    }
                }}
            >
                <Typography variant='body2'>{data?.data?.year} {data?.data?.name} / {data?.data?.focus}</Typography>
                <IconButton
                    sx={{ p: 0.375 }}
                    onClick={onEdit}
                >
                    <EditIcon />
                </IconButton>
            </Box>
        </Box>
    )
}

export default TrainingItem