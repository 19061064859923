import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import { ReactComponent as CloseIcon } from "../../assets/Icons/close.svg";
import { useReferrals } from "../../contexts/Referral";
import { LOADING, SUCCESS, treatment_program } from "../../constants/common";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAuth } from "../../contexts/Auth";
import { validateAbout, validateSubject } from "../../helper/Validations";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 622,
  p: 0,
  overflow: 'hidden',
};

function ReferalModal({ open, onClose, data, isProfile }) {
  const initialState = {
    subject: '',
    message: '',
  }
  const [state, setState] = useState(initialState);
  const [isInvalid, setIsInvalid] = useState(false);
  const { sendReferal, sendReferralStatus, updateReferralsState } = useReferrals();
  const { account_type } = useAuth()

  const userData = data?.connected_user || data?.favorite_user || data;

  const handleClose = () => {
    onClose();
    setState(initialState);
    setIsInvalid(false);
  }

  const handleInput = (ev) => {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value
    }))
  }

  const isValid = () => {
    return validateSubject(state.subject)
      && validateAbout(state.message)
  }

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (isValid()) {
      const programId = isProfile ? userData?.info?.id : userData?.treatment_program_info?.treatment_program_id
      sendReferal({
        referral_user_id: (userData?.account_type === treatment_program && userData?.roles?.length === 1) ? programId : userData.id,
        target_user_id: userData.id,
        from: account_type === treatment_program ? treatment_program : 'user',
        to: (userData?.account_type === treatment_program && userData?.roles?.length === 1) ? treatment_program : 'user',
        ...state
      })
    } else {
      setIsInvalid(true);
    }
  }

  useEffect(() => {
    if (sendReferralStatus === SUCCESS) {
      updateReferralsState('sendReferralStatus', '')
      handleClose()
    }
  }, [sendReferralStatus])


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pt: 3,
            px: 3,
            pb: 2
          }}
        >
          <Typography variant='h3'>New Referral</Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              p: 0.25,
              color: 'border.dark'
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          component='form'
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            px: 3,
            pb: 3
          }}
        >
          <Typography variant="body2" fontWeight={600}>To</Typography>
          <Box
            sx={{
              px: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <Avatar
              sx={{
                width: 40,
                height: 40
              }}
              src={userData?.main_image}
            />
            <Box>
              <Typography variant="body2" textTransform='capitalize'>{userData?.first_name} {userData?.last_name}</Typography>
              {userData?.info?.headline && <Typography variant='caption' color='text.secondary'>{userData?.info?.headline}</Typography>}
            </Box>
          </Box>
          <Typography variant="body2" fontWeight={600}>Basic Information</Typography>
          <TextField
            variant="outlined"
            label='Write a title for your referral*'
            name='subject'
            onChange={handleInput}
            value={state.subject}
            error={isInvalid && !validateSubject(state.subject)}
            helperText={
              isInvalid &&
              !validateSubject(state.subject) &&
              "Please add valid subject"
            }
          />
          <TextField
            multiline
            label='Write a description*'
            rows={7}
            name='message'
            onChange={handleInput}
            value={state.message}
            error={isInvalid && !validateAbout(state.message)}
            helperText={
              isInvalid &&
              !validateAbout(state.message) &&
              "Please add valid description"
            }
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <LoadingButton
              variant="contained"
              sx={{
                minWidth: 218
              }}
              loading={sendReferralStatus === LOADING}
              type='submit'
            >
              Send
            </LoadingButton>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
}

export default ReferalModal;