import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';

import { LOADING, SUCCESS } from '../../constants/common';
import { useGeneralProfile } from '../../contexts/Organization';

import { ReactComponent as ImageIcon } from '../../assets/Icons/image.svg'
import { ReactComponent as AddIcon } from '../../assets/Icons/add.svg'
import ImageComponent from '../../components/ImageComponent';
import ConfirmationModal from '../../components/ConfirmationModal';
import { imageTypes } from '../../constants/lists';

const ProfileImages = () => {
  const {
    saveFacilityImages,
    updateFacilityImagesStatus,
    deleteFacilityImage,
    deleteFacilityImagesStatus,
    facilityInfo,
    fetchFacilityInfo,
    updateGeneralProfileState,
  } = useGeneralProfile();

  const initalState = {
    images: [],
  }
  const [state, setState] = useState(initalState);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [activeImage, setActiveImage] = useState({});
  const [error, setError] = useState('');

  function handleOpenConfirmationModal() {
    setOpenConfirmationModal(true)
  }

  function handleCloseConfirmationModal() {
    setOpenConfirmationModal(false)
  }

  function handleImage(ev) {
    setError('');
    const file = ev.target.files?.[0];

    if (!file) {
      setError('facility_image');
      return;
    }

    if (file.size > 5 * 1024 * 1024) { // 5 MB
      setError('The image must be less than 5MB.');
      return;
    }

    if (!imageTypes.includes(file.type)) { // 5 MB
      // sendAlert('The logo must be less than 5 MB.', 'error');
      setError('Please upload a valid image file.\n(jpeg, jpg, png, svg+xml)');
      return;
    }

    if (!file.type.startsWith('image/')) {
      setError('Please select a valid image file.');
      return;
    }
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const width = img.width;
      const height = img.height;

      if (width < 300 || height < 300) {
        setError('The image dimensions should be at least 300x300 pixels.');
        return;
      }

      if (width > 2000 || height > 2000) {
        setError('The image dimensions should not exceed 2000x2000 pixels.');
        return;
      }
      setState((prevState) => ({
        ...prevState,
        images: [...prevState.images, ...ev.target.files]
      }));
    }
    img.onerror = () => {
      setError('There was an error loading the image. Please select a valid image file.');
      return;
    };
  }

  function handleRemoveImage(index) {
    setState((prevState) => ({
      ...prevState,
      images: prevState.images.filter((item, i) => i !== index)
    }))
  }

  function removeImageFunction() {
    deleteFacilityImage(activeImage);
  }

  function handleRemoveExistingImage(id) {
    handleOpenConfirmationModal();
    setActiveImage(id);
  }

  function isValid() {
    return state.images.length > 0
  }


  function handleSubmit(ev) {
    ev.preventDefault();
    if (isValid())
      saveFacilityImages(state.images);
  }

  useEffect(() => {
    if (updateFacilityImagesStatus === SUCCESS) {
      updateGeneralProfileState('updateFacilityImagesStatus', '')
      fetchFacilityInfo();
      setState({
        images: []
      })
    }
  }, [updateFacilityImagesStatus])

  useEffect(() => {
    if (deleteFacilityImagesStatus === SUCCESS) {
      updateGeneralProfileState('deleteFacilityImagesStatus', '')
      fetchFacilityInfo();
      setActiveImage(undefined);
      handleCloseConfirmationModal();
    }
  }, [deleteFacilityImagesStatus])


  return (
    <>
      <Paper
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        }}
        component='form'
        onSubmit={handleSubmit}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'border.dark'
              }}
            >
              <ImageIcon width={24} height={24} />
            </Box>
            <Typography variant='h4' ml={1}>Images</Typography>
          </Box>
        </Box>

        <Grid container spacing={3}>
          {facilityInfo?.info?.gallery?.map((item) => {
            return (
              <Grid item xs={3} key={item.id}>
                <ImageComponent
                  image={item.image_url}
                  index={item.id}
                  handleDelete={handleRemoveExistingImage}
                />
              </Grid>
            )
          })}
        </Grid>
        <Divider />
        <Typography variant='h5' ml={1}>Please upload images of your program or organization</Typography>
        <Grid container spacing={3}>
          {state.images.map((item, index) => {
            return (
              <Grid item xs={3} key={index}>
                <ImageComponent
                  image={item}
                  index={index}
                  handleDelete={handleRemoveImage}
                />
              </Grid>
            )
          })}

          {state.images.length < 4 &&
            (
              <Grid item xs={3}>
                <Box
                  sx={{
                    border: '1px solid',
                    borderColor: 'border.dark',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: 2,
                    cursor: 'pointer',
                    minHeight: 200,
                    width: '100%',
                    color: 'border.dark'
                  }}
                  component='label'
                >
                  <AddIcon />
                  <Box
                    component='input'
                    sx={{
                      display: 'none'
                    }}
                    type='file'
                    onChange={(ev) => { setError(''); handleImage(ev) }}
                    accept='image/*'
                  />
                  {!!error &&
                    <Typography variant='caption' color='error' sx={{ textAlign: 'center' }}>
                      {error}
                    </Typography>
                  }
                </Box>
              </Grid>
            )}
        </Grid>
        {Boolean(state.images.length) && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <LoadingButton
              type='submit'
              variant='contained'
              loading={updateFacilityImagesStatus === LOADING}
            >
              Upload
            </LoadingButton>
          </Box>
        )}
      </Paper>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={handleCloseConfirmationModal}
        message={`Are you sure you want to delete this image?`}
        deleteFunction={removeImageFunction}
        loading={deleteFacilityImagesStatus === LOADING}
      />
    </>
  )
}

export default ProfileImages