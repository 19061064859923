import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import FeesFilter from "./Fees";
import ClientTypesFilter from "./ClientTypes";
import SpecialtiesFilter from "./Specialties";
import TreatmentModalitiesFilter from "./TreatmentModalities";
import AvailabilityFilter from "./Availability";
import DemographicsFilter from "./Demographics";
import UserTypesFilter from "./UserTypes";
import LocationFilter from './Location'
import InsurancePlansFilter from "./InsurancePlans";
import CurrentStatusFilter from "./CurrentStatus";
import DegreesFilter from "./Degrees";
import CredentialsFilter from "./Credential";
import TrainingFilter from "./Training";
import ProviderFilter from "./Provider";
import LanguagesFilter from "./Languages";
import { ReactComponent as BackArrow } from '../../../assets/Icons/filter-back-arrow.svg';
import { ReactComponent as FilterIcon } from '../../../assets/Icons/filter-icon.svg';
import { treatment_program } from "../../../constants/common";
import ServicesAvilable from "./ServicesAvilable";


const Filters = ({
  setFilters,
  filters,
  reset,
  isMobileView,
  closeFilters,
  setClearStatus,
  clearStatus,
}) => {
  const activeFiltersCount = Object.keys(filters ?? {}).filter((key) => {
    const value = filters[key];
    return (
      !["sortBy", "search", "page"].includes(key) &&
      value !== undefined &&
      value !== '' &&
      !(Array.isArray(value) && value.length === 0)
    );
  }).length;
  

  return (
    <Paper>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: isMobileView ? "100vw" : "100%",
          py: 1.5,
          px: 3,
        }}
      >
        {isMobileView ? <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Button
            sx={{
              p: 0,
              minWidth: 0,
              textDecoration: !isMobileView ? "none" : "underline",
              color: "#0C8CE9",
            }}
            onClick={closeFilters}
          >
            <BackArrow />
          </Button>
        </Box> : null}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <FilterIcon />
          <Typography variant="h5">
            Filter {Boolean(activeFiltersCount) && `(${activeFiltersCount})`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Button
            sx={{
              p: 0,
              minWidth: 0,
              textDecoration: !isMobileView ? "none" : "underline",
              color: "#0C8CE9",
            }}
            onClick={reset}
          >
            {!isMobileView ? " Reset all" : "Clear all"}
          </Button>
        </Box>
      </Box>
      <ProviderFilter value={filters?.provider || ""} setFilters={setFilters} />
      <Divider />
      <LocationFilter 
        setFilters={setFilters}
        filters={filters}
      />
      {filters?.provider === treatment_program && (
        <>
          <Divider />
          <ServicesAvilable
            setFilters={setFilters}
            filters={filters}
          />
        </>
      )}
      {filters?.provider === 'therapists' && (
        <>
          <Divider />
          <UserTypesFilter
            value={filters?.user_types || []}
            setFilters={setFilters}
          />
        </>
      )}
      {filters?.provider === 'therapists' && (
        <>
          <Divider />
          <CurrentStatusFilter
            value={filters.accepting_clients || ""}
            setFilters={setFilters}
          />
        </>
      )}
      <Divider />
      <InsurancePlansFilter
        isMobileView={isMobileView}
        value={filters?.insurance || []}
        setFilters={setFilters}
      />
      <Divider />
      <FeesFilter
        filters={filters}
        setFilters={setFilters}
        setClearStatus={setClearStatus}
        clearStatus={clearStatus}
      />
      <Divider />
      <ClientTypesFilter
        filters={filters}
        setFilters={setFilters}
      />
      <Divider />
      <SpecialtiesFilter
      isMobileView={isMobileView}
        value={filters?.specialties || []}
        setFilters={setFilters}
      />
      <Divider />
      <TreatmentModalitiesFilter
        value={filters?.modalities || []}
        setFilters={setFilters}
        isMobileView={isMobileView}
      />
      <Divider />
      <AvailabilityFilter
        filters={filters}
        setFilters={setFilters}
      />
      <Divider />
      <DemographicsFilter
        filters={filters}
        setFilters={setFilters}
      />
      {filters?.provider === 'therapists' && (
        <>
          <Divider />
          <DegreesFilter
            filters={filters}
            setFilters={setFilters}
            isMobileView={isMobileView}
          />
          <Divider />
          <CredentialsFilter
            filters={filters}
            setFilters={setFilters}
          />
          <Divider />
          <TrainingFilter
            filters={filters}
            setFilters={setFilters}
          />
        </>
      )}
      <Divider />
      <LanguagesFilter
        filters={filters}
        setFilters={setFilters}
        
      />
    </Paper>
  );
};

export default Filters;
