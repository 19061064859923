import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as EditIcon } from '../../assets/Icons/edit-3.svg'
import { ReactComponent as GlobeIcon } from '../../assets/Icons/globe.svg';
import GenderDrawer from '../../components/DemographicsDrawers/GenderDrawer';
import SexualOrintationDrawer from '../../components/DemographicsDrawers/SexualOrintationDrawer';
import RaceDrawer from '../../components/DemographicsDrawers/RaceDrawer';
import ReligionDrawer from '../../components/DemographicsDrawers/ReligionDrawer';
import { useDemographics } from '../../contexts/Demographics';
import { useLanguages } from '../../contexts/Languages';
import LanguageDrawer from '../../components/LanguageDrawer';
import { SUCCESS } from '../../constants/common';

const ProfileDemographics = () => {
  const {demographics, fetchDemographicsStatus, fetchDemographics} = useDemographics();
  const [openGederDrawer, setOpenGenderDrawer] = useState(false);
  const [openSexualOriantationDrawer, setOpenSexualOriantationDrawer] = useState(false);
  const [openRaceDrawer, setOpenRaceDrawer] = useState(false);
  const [openReligionDrawer, setOpenReligionDrawer] = useState(false);

  const {languagesList, fetchLanguageList , updateLanguageStatus} = useLanguages();
  const [openLanguageDrawer, setOpenLanguageDrawer] = useState(false);

  function handleOpenLanguageDrawer() {
    setOpenLanguageDrawer(true);
  }

  useEffect(() => {
    if (updateLanguageStatus === SUCCESS) {
      fetchLanguageList()
      setOpenLanguageDrawer(false);
    }
  }, [updateLanguageStatus])

  useEffect(() => {
    fetchLanguageList();
  }, [])
  function handleOpenGenderDrawer() {
    setOpenGenderDrawer(true);
  }

  function handleCloseGenderDrawer() {
    setOpenGenderDrawer(false);
  }

  function handleOpenSexualOriantationDrawer() {
    setOpenSexualOriantationDrawer(true);
  }

  function handleCloseSexualOriantationDrawer() {
    setOpenSexualOriantationDrawer(false);
  }

  function handleOpenRaceDrawer() {
    setOpenRaceDrawer(true);
  }

  function handleCloseRaceDrawer() {
    setOpenRaceDrawer(false);
  }

  function handleOpenReligionDrawer() {
    setOpenReligionDrawer(true);
  }

  function handleCloseReligionDrawer() {
    setOpenReligionDrawer(false);
  }

  function handleCloseLanguageDrawer() {
    setOpenLanguageDrawer(false);
  }
  useEffect(() => {
    fetchDemographics();
  }, [])
  
  return (
    <Paper
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        }}
    >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'border.dark'
                }}
            >
              <GlobeIcon width={24} height={24} />
            </Box>
            <Typography variant='h4' ml={1}>Provider Demographics</Typography>
        </Box>
        
        <Box
          sx={{
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'border.main',
              p: 3,
              borderRadius: 2
          }}
        >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
              }}
            >
              <Box
                  sx={{
                      display: 'flex',
                      gap: 1
                  }}
              >
                <Typography variant='h5'>Gender:</Typography>
                <Typography variant='body2'>{demographics?.user_gender?.join().replaceAll(",",", ")}</Typography>
              </Box>
              <IconButton
                sx={{ p: 0.375 }}
                onClick={handleOpenGenderDrawer}
              >
                  <EditIcon />
              </IconButton>
            </Box>
        </Box>

        <GenderDrawer
          open={openGederDrawer}
          onClose={handleCloseGenderDrawer}
        />
         <Box
          sx={{
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'border.main',
              p: 3,
              borderRadius: 2
          }}
        >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
              }}
            >
              <Box
                  sx={{
                      display: 'flex',
                      gap: 1
                  }}
              >
                <Typography variant='h5'>Languages:</Typography>
                <Typography variant='body2'>{languagesList.filter(item=>item.selected).map(item=>item.name)?.join().replaceAll(",",", ")}</Typography>
              </Box>
              <IconButton
                sx={{ p: 0.375 }}
                onClick={handleOpenLanguageDrawer}
              >
                  <EditIcon />
              </IconButton>
            </Box>
        </Box>
        <LanguageDrawer
        open={openLanguageDrawer}
        onClose={handleCloseLanguageDrawer}
      />
        <Box
          sx={{
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'border.main',
              p: 3,
              borderRadius: 2
          }}
        >
            <Box
              sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
              }}
            >
              <Box
                  sx={{
                      display: 'flex',
                      gap: 1
                  }}
              >
                <Typography variant='h5'>Race:</Typography>
                <Typography variant='body2'>{demographics?.user_races?.map((item) => item.name).join(', ')}</Typography>
              </Box>
              <IconButton
                sx={{ p: 0.375 }}
                onClick={handleOpenRaceDrawer}
              >
                  <EditIcon />
              </IconButton>
            </Box>
        </Box>

        <RaceDrawer
          open={openRaceDrawer}
          onClose={handleCloseRaceDrawer}
        />

        <Box
          sx={{
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'border.main',
              p: 3,
              borderRadius: 2
          }}
        >
            <Box
              sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
              }}
            >
              <Box
                  sx={{
                      display: 'flex',
                      gap: 1
                  }}
              >
                <Typography variant='h5'>Religion:</Typography>
                <Typography variant='body2'>{demographics?.user_religions?.map((item) => item.name).join(', ')}</Typography>
              </Box>
              <IconButton
                sx={{ p: 0.375 }}
                onClick={handleOpenReligionDrawer}
              >
                  <EditIcon />
              </IconButton>
            </Box>
        </Box>

        <ReligionDrawer
          open={openReligionDrawer}
          onClose={handleCloseReligionDrawer}
        />

        <Box
          sx={{
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'border.main',
              p: 3,
              borderRadius: 2
          }}
        >
            <Box
              sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
              }}
            >
              <Box
                  sx={{
                      display: 'flex',
                      gap: 1
                  }}
              >
                <Typography variant='h5'>Sexual Orientation:</Typography>
                <Typography variant='body2'>{demographics?.user_sexual_orientation?.name}</Typography>
              </Box>
              <IconButton
                sx={{ p: 0.375 }}
                onClick={handleOpenSexualOriantationDrawer}
              >
                  <EditIcon />
              </IconButton>
            </Box>
        </Box>
        <SexualOrintationDrawer
          open={openSexualOriantationDrawer}
          onClose={handleCloseSexualOriantationDrawer}
        />
       
    </Paper>
  )
}

export default ProfileDemographics;