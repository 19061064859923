import React, { useEffect, useState } from 'react';

// eslint-disable-next-line no-undef
const Marker = (options) => {
  // eslint-disable-next-line no-undef
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker)
      setMarker(new window.google.maps.Marker());

    // remove marker from map on unmount
    return () => {
      if (marker)
        marker.setMap(null);
    };
  }, [marker]);

  useEffect(() => {
    if (marker)
      marker.setOptions(options);
  }, [marker, options]);

  return null;
};

export default Marker;
