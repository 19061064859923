import React, { createContext, useContext } from 'react';
import { useState } from 'react';

const Notifications = createContext({});

export const NotificationsProvider = ({ children }) => {

    const [notificationState, setNotificationState] = useState({
        notificationsList:[],
        unSeenNotifications:0,
        userInfo:null
    });
    
    function updateNotificationData(list,count) {
        if(!count){
            setNotificationState((prevState) => ({
                ...prevState,
                notificationsList:list
            }))
        }else{
            setNotificationState((prevState) => ({
                ...prevState,
                notificationsList:list,
                unSeenNotifications:count
            }))
        }

    }

    function updateNotificationCount(count) {
        setNotificationState((prevState) => ({
            ...prevState,
            unSeenNotifications:count
        }))
    }

    function updateNotificationUser(user) {
        setNotificationState((prevState) => ({
            ...prevState,
            userInfo:user
        }))
    }

    const state = {
        ...notificationState,
        updateNotificationData,
        updateNotificationCount,
        updateNotificationUser
    };

    return (
        <Notifications.Provider value={state}>
            {children}
        </Notifications.Provider>
    );
};

export const useNotifcations = () => useContext(Notifications);