import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import inviteTherapistImage from "../../assets/imgs/invite-therapist.png";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import copy from "clipboard-copy";
import {
  getInvitationLink,
  getInvitations,
} from "../../services/home.services";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import { baseUrl } from "../../constants/common";
import CustomButton from "../../components/CustomButton";

const InvitatationSettings = () => {
  const [link, setLink] = useState("");
  const [originalLink, setOriginalLink] = useState("");
  const [invitations, setInvitations] = useState([]);
  const regex = /onboarding\/([a-f0-9]{64})$/;

  useEffect(() => {
    getInvitationLink()
      .then((res) => {
        const match = regex.exec(res.data.link);
        if (match) {
          setOriginalLink(res.data.link);
          setLink(`${baseUrl}/invite/${res.data.link}`);
        } else {
          console.error('No match found in invitation link.');
        }
      })
      .catch((err) => {
        console.error('Error fetching invitation link:', err);
      });
  }, []);

  useEffect(() => {
    getInvitations()
      .then((res) => {
        const reversedInvitations = res.data.data.users.reverse();
        setInvitations(reversedInvitations);
      })
      .catch((err) => {
        console.error('Error fetching invitations:', err);
      });
  }, []);

  async function handleOnCopyButtonClick(ev) {
    alert("Link copied to clipboard!");
    try {
      await copy(originalLink);
    } catch (error) {
      console.error('Failed to copy to clipboard:', error);
    }
  }

  return (
    <>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box
          component="form"
          sx={{
            p: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "row",
            backgroundColor: "#E7F4FD",
            height: "165px",
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "128px",
              width: "150px",
            }}
          >
            <img src={inviteTherapistImage} alt="invite Therapist" />
          </Box>
          <Typography
            variant="body1"
            align="left"
            mt={2}
            sx={{ fontSize: "30px", lineHeight: "38px", fontWeight: "medium" }}
          >
            Gift a friend 1 month free
            <br /> Get a <span style={{ color: "#19CB6F" }}>free</span> month
          </Typography>
        </Box>
        <Box m={4} mt={2} sx={{ backgroundColor: "#FFFFFF" }}>
          <Typography
            variant="body2"
            sx={{ color: "#303336", fontSize: "15px" }}
          >
            Copy and send your personalized link below.
          </Typography>
          <Grid container mt={1}>
            <TextField
              variant="outlined"
              disabled={true}
              fullWidth
              value={link}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleOnCopyButtonClick}>
                      <div style={{ width: "185px" }}>
                        <CustomButton
                          variant="contained"
                          size="large"
                          fullWidth
                          onClick={handleOnCopyButtonClick}
                          page='Invitation Settings'
                          component='Invitation Modal'
                          name='Copy link this link'
                          eventName='Copy like'
                        >
                          Copy link this link
                        </CustomButton>
                      </div>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Box>
      </Paper>
      {invitations.length > 0 && (
        <Paper
          mt={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 3,
            p: 3,
            backgroundColor: "#FFFFFF",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontSize: "16px", fontWeight: 500, m: 1 }}
          >
            Status of Invitations
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Invitation Date</TableCell>
                  <TableCell align="left">One Month Free</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invitations.map((InviteItem) => (
                  <TableRow
                    key={InviteItem.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar alt="Remy Sharp" src={InviteItem.main_image} />
                        <div style={{ marginLeft: 8 }}>
                          <Typography variant="body1" style={{ fontWeight: 500 }}>
                            {InviteItem.first_name} {InviteItem.last_name}
                          </Typography>
                          <Typography variant="body1" style={{ color: '#6b6b6b' }}>
                            {InviteItem.collage}
                          </Typography>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: 500 }}>
                      {moment(InviteItem.invitation_date).format('MM-DD-YYYY')}
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: 500 }}>
                      Extend to {moment(InviteItem.subscription_extended_to).format('MM-DD-YYYY')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </>
  );
};

const tableCellStyle = {
  fontSize: 14,
};
export default InvitatationSettings;
