import React from 'react'
import Paper from '@mui/material/Paper'
import Avatar from '@mui/material/Avatar'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { useHomePage } from '../../contexts/HomePage'
import { useBasicInfo } from '../../contexts/BasicInfo'

const PostForm = () => {
    const { handleOpenCreatePostModal }= useHomePage();
    const { data } = useBasicInfo();

    return (
        <Paper
            sx={{
              boxShadow: 'none',
              borderColor: 'border.main',
              p: 3,
            }}
            component='form'
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: 1
                }}
            >
                <Avatar
                    sx={{
                        width: 48,
                        height: 48
                    }}
                    src={data?.main_image}
                />
                <Box position='relative' flexGrow={1}>
                    <Box
                        sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            cursor: 'pointer',
                            zIndex: 9
                        }}
                        onClick={handleOpenCreatePostModal}
                    />
                    <TextField
                        placeholder='Create a post'
                        multiline
                        fullWidth
                        InputProps={{
                            sx: {
                                py: 1.6,
                                px: 1,
                                textarea: {
                                    fontSize: 14,
                                    lineHeight: '22px',
                                    '&::placeholder': {
                                        fontWeight: 700
                                    }
                                }
                            }
                        }}
                        disabled
                    />
                </Box>
            </Box>
        </Paper>
    )
}

export default PostForm