import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import Filter from './Filter'
import { ReactComponent as UsersIcon } from '../../../assets/Icons/users.svg';
import { accepting_new_clients_and_taking_referrals, full_and_need_to_refer_out } from '../../../constants/common';

const CurrentStatusFilter = ({ setFilters, value }) => {

    function handleChange(ev) {
        setFilters((prevState) => {
            return {
                ...prevState,
                accepting_clients: ev.target.value
            }
        })
    }

    return (
        <Filter
            label='Accepting clients'
            icon={<UsersIcon />}
            expandedDefault
        >
            <FormControlLabel
                sx={{
                    gap: 1,
                    mx: 0,
                }}
                control={
                    <Radio
                        sx={{ p: 0 }} 
                        value={accepting_new_clients_and_taking_referrals}
                        onChange={handleChange}
                        checked={accepting_new_clients_and_taking_referrals === value.toString()}
                    />
                }
                label='Accepting New Clients'
            />
            <FormControlLabel
                sx={{
                    gap: 1,
                    mx: 0,
                }}
                control={
                    <Radio
                        sx={{ p: 0 }} 
                        value={full_and_need_to_refer_out}
                        onChange={handleChange}
                        checked={full_and_need_to_refer_out === value.toString()}
                    />
                }
                label='Not Accepting Clients'
            />
        </Filter>
    )
}

export default CurrentStatusFilter