import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import Login from "../../components/Auth/Login";
import Verification from "../../components/Auth/Verification";
import { useAuth } from "../../contexts/Auth";
import "./style.css";

const LoginAndVerification = () => {
  const { step } = useAuth();

  return (
    <Box sx={{ minHeight: "75vh" }}>
      {step === 1 && <Login />}  {/* Renders the Login component if step is 1 */}
      {step === 2 && <Verification />}  {/* Renders the Verification component if step is 2 */}
    </Box>
  );
};

export default LoginAndVerification;
