import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import { ReactComponent as ChevronDown } from '../../assets/Icons/chevron-down.svg'
import { useTraining } from '../../contexts/Training';
import { LOADING, SUCCESS } from '../../constants/common';
import { profileTrainingSchema } from '../../validations/profileValidations';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

const TrainingDrawer = ({ onClose, open, activeTraining }) => {
  const { generateTraining, createStatus, fetchTraining, editTraining, updateStatus, updateTrainingtate, openConfirmationModal, deleteStatus } = useTraining();
  const initialState = {
    type: '',
    name: '',
    focus: '',
    year: ''
  }

  const [state, setState] = useState(initialState);

  const {
    control,
    handleSubmit,
    trigger,
    reset,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    resolver: zodResolver(profileTrainingSchema),
    mode: "onChange",
    defaultValues: {
      type: '',
      name: '',
      focus: '',
      year: ''
    },
  });

  const isEdit = Boolean(activeTraining);
  const formValues = useWatch({ control });


  const handleInput = (ev) => {
    const { name, value } = ev.target;
    setValue(`${name}`, value);
    if (name !== 'type') trigger(name);
  }

  const handleClose = () => {
    onClose();
    setState(initialState);
  }

  const onSubmit = () => {
    if (isValid) {
      if (isEdit) {
        editTraining({
          type: state.type,
          data: state,
          id: activeTraining?.id
        });
      } else {
        generateTraining({
          type: state.type,
          data: state
        });
      }
    }
  }

  useEffect(() => {
    setState(prev => ({ ...prev, ...formValues }));
  }, [formValues]);

  useEffect(() => {
    if (createStatus === SUCCESS) {
      updateTrainingtate('createStatus', '');
      fetchTraining();
      handleClose();
    }
  }, [createStatus])

  useEffect(() => {
    if (updateStatus === SUCCESS) {
      updateTrainingtate('updateStatus', '');
      fetchTraining();
      handleClose();
    }
  }, [updateStatus])


  useEffect(() => {
    if (deleteStatus === SUCCESS) {
      handleClose();
    }
  }, [deleteStatus])

  useEffect(() => {
    reset();
    if (isEdit && open) {
      setValue('type', activeTraining?.data?.type);
      setValue('name', activeTraining?.data?.name);
      setValue('focus', activeTraining?.data?.focus);
      setValue('year', activeTraining?.data?.year);
    }
  }, [activeTraining, open, isEdit])

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Training</Typography>
          <Typography variant='body1' mb={3}>Please provide your training information:</Typography>
          <Divider sx={{ mb: 3 }} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name='type'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    variant="outlined"
                    label='Type of Training'
                    fullWidth
                    error={!!errors.type}
                    helperText={errors.type?.message}
                    SelectProps={{
                      IconComponent: () => (
                        <Box
                          sx={{
                            minWidth: 24,
                            width: 24,
                            height: 24,
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: 'text.secondary',
                            mr: 2,
                          }}
                        >
                          <ChevronDown width={12} height={6} />
                        </Box>
                      ),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 220,
                            width: 250,
                          },
                        },
                      },
                    }}
                    onChange={handleInput}
                    value={state.type}
                    disabled={isEdit}
                  >
                    <MenuItem value={''} disabled sx={{ py: 0 }} />
                    <MenuItem value='certificate' sx={{ py: 1 }}>Certificate</MenuItem>
                    <Divider sx={{ my: 1 }} />
                    <MenuItem value='fellowship' sx={{ py: 1 }}>Fellowship</MenuItem>
                    <Divider sx={{ my: 1 }} />
                    <MenuItem value='post-doctoral' sx={{ py: 1 }}>Post-Doctoral</MenuItem>
                    <Divider sx={{ my: 1 }} />
                    <MenuItem value='residency' sx={{ py: 1 }}>Residency</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            {Boolean(state.type) && (
              <>
                <Grid item xs={12}>
                  <Controller
                    name='name'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        label='Institution/Organization Name'
                        fullWidth
                        value={state.name}
                        sx={{ mb: 4 }}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        onChange={handleInput}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name='focus'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        label='Training Focus'
                        fullWidth
                        value={state.focus}
                        sx={{ mb: 4 }}
                        error={!!errors.focus}
                        helperText={errors.focus?.message}
                        onChange={handleInput}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name='year'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        label='Year Earned (YYYY)'
                        fullWidth
                        value={state.year}
                        sx={{ mb: 4 }}
                        error={!!errors.year}
                        helperText={errors.year?.message}
                        onChange={handleInput}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography variant='body2' color='text.secondary'>Please note that the training information provided will be visible to other users on your profile page.</Typography>
            </Grid>
            {isEdit && (
              <Grid item xs={12} display='flex' justifyContent='flex-end'>
                <Button
                  onClick={openConfirmationModal}
                  size='small'
                  color='error'
                  sx={{
                    minWidth: 120,
                    backgroundColor: 'error.light',
                    color: 'error.main',
                  }}
                >
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>


        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            onClick={handleSubmit(onSubmit)}
            loading={createStatus === LOADING}
          >
            {isEdit ? 'Save' : 'Create'}
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default TrainingDrawer;