import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { accordionData } from '../../../../../constants/lists';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 0,
  '& .MuiAccordionSummary-root': {
    padding: 0,
  },
  '& .MuiAccordionSummary-content': {
    padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
    margin: 0,
  },
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const ExpandIcon = ({ isExpanded }) => (
  <Box
    sx={{
      fontSize: '24px',
      lineHeight: '28px',
      color: '#6D6D6D',
      paddingX: 3,
    }}
  >
    {isExpanded ? '-' : '+'}
  </Box>
);


export default function Faq() {
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {accordionData.map(({ id, question, answer }) => (
        <Accordion key={id} expanded={expanded === id} onChange={handleChange(id)}>
          <MuiAccordionSummary
            aria-controls={`${id}-content`}
            id={`${id}-header`}
            expandIcon={<ExpandIcon isExpanded={expanded === id} />}
          >
            <Typography variant="body1">{question}</Typography>
          </MuiAccordionSummary>
          <AccordionDetails>
            <Typography>{answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
