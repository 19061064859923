/*
  Custom hook to handle resize with given breakpoint.
*/

import { useEffect, useState } from 'react';

const useResize = (breakpoint) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < breakpoint);

  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth < breakpoint);
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [breakpoint]);

  return isMobileView;
};

export default useResize;
