import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { ReactComponent as AwardIcon } from '../../assets/Icons/award.svg'
import DegreeCard from '../../components/DegreeCard';
import { useDegrees } from '../../contexts/Degrees';
import { LOADING } from '../../constants/common';
import { CircularProgress } from '@mui/material';

const ProfileDegree = () => {
  const {
    list,
    fetchStatus,
    fetchUserDegrees,
    handleOpen,
  } = useDegrees();

  function addNew() {
    handleOpen()
  }

  useEffect(() => {
    fetchUserDegrees();
  }, [])

  return (
    <Paper
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <AwardIcon width={24} height={24} />
        <Typography variant='h4' ml={1}>Education</Typography>
      </Box>

      {fetchStatus === LOADING ? (
        <Box
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'border.main',
            p: 3,
            borderRadius: 2,
            minHeight: 194
          }}
        >
          <Skeleton sx={{ mb: 1.5, height: 30 }} />
          <Skeleton sx={{ mb: 1.5, height: 30 }} />
          <Skeleton sx={{ mb: 1.5, height: 30 }} />
          <Skeleton sx={{ height: 30 }} />
        </Box>
      ) : (
        list.map((item) => (
          <DegreeCard
            key={item.id}
            data={item}
            onEdit={handleOpen}
          />
        ))
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Button
          variant='contained'
          sx={{
            minWidth: 150
          }}
          onClick={addNew}
        >
          {fetchStatus === LOADING ?
            (
              <CircularProgress color='secondary' />
            )
            : 'Add New'
          }
        </Button>
      </Box>
    </Paper>
  )
}

export default ProfileDegree;