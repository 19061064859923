// import { networkNavMenu, referralNavMenu } from "./NavMenus";
import { colleagues, received } from "./common";
import paths from "./paths";
import { ReactComponent as HomeIcon } from '../assets/Icons/home.svg';
import { ReactComponent as UsersIcon } from '../assets/Icons/usersfilled.svg';
import { ReactComponent as ReferralIcon } from '../assets/Icons/referral.svg';
import { ReactComponent as FilledNotificationIcon } from '../assets/Icons/filledNotification.svg';
import { ReactComponent as DarkHomeIcon } from '../assets/Icons/darkHomeIcon.svg';
import { ReactComponent as DarkReferralIcon } from '../assets/Icons/darkReferralIcon.svg';
import { ReactComponent as DarkUsersIcon } from '../assets/Icons/darkUsersIcon.svg';
import { ReactComponent as DarkFilledNotificationIcon } from '../assets/Icons/darkFilledNotificationIcon.svg';

const navMenu = [
    { id: 1, path: paths.home, text: 'Home',Icon: HomeIcon ,DarkIcon: DarkHomeIcon },
    { id: 2, text: 'Network', path: paths.network(colleagues),Icon: UsersIcon , DarkIcon: DarkUsersIcon  }, // children: networkNavMenu
    { id: 3, text: 'Referrals', path: paths.referrals(received), Icon: ReferralIcon , DarkIcon:DarkReferralIcon  }, //  children: referralNavMenu,
    { id: 3, text: 'Notifications', path: paths.Notifications ,Icon:FilledNotificationIcon  ,DarkIcon:DarkFilledNotificationIcon  }
]

export default navMenu;