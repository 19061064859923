import React from 'react'
import Filter from './Filter'
import CheckBox from '../../../components/CheckBox'
import { ReactComponent as UsersIcon } from '../../../assets/Icons/users.svg';

const UserTypesFilter = ({ setFilters, value }) => {

    function handleChange(ev) {
        setFilters((prevState) => {
            const arr = (prevState.user_types || [])
            const exists = Boolean(arr.find((item) => item === ev.target.value))
            return {
                ...prevState,
                user_types: (exists ? 
                    arr.filter((item) => item !== ev.target.value)
                :
                    [...arr, ev.target.value])
            }
        })
    }

    return (
        <Filter
            label='Connections'
            icon={<UsersIcon />}
            expandedDefault
        >

            <CheckBox
                label='1st connections'
                value='first'
                onChange={handleChange}
                checked={Boolean(value.find((data) => data.toString() === 'first'))}
            />
            <CheckBox
                label='2nd connections'
                value='second'
                onChange={handleChange}
                checked={Boolean(value.find((data) => data.toString() === 'second'))}
            />
        </Filter>
    )
}

export default UserTypesFilter