import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ReactComponent as FileIcon } from '../../assets/Icons/file-text.svg'
import { useCredentials } from '../../contexts/Credentials';
import CredentialItem from './CredentialItem';

const ProfileCredentials = () => {
  const { handleOpen, fetchCredentials, list } = useCredentials();

  const addNew = () => {
    handleOpen()
  }

  useEffect(() => {
    fetchCredentials();
  }, [])

  return (
    <Paper
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FileIcon width={24} height={24} />
        <Typography variant='h4' ml={1}>Credentials</Typography>
      </Box>


      <Box
        sx={{
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'border.main',
          p: 3,
          borderRadius: 2
        }}
      >
        <Box
          sx={{
            mb: 1.5
          }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant='h5'>Membership:</Typography>
              {list.filter((item) => item.type === 'membership').length < 1 && (
                <Button
                  sx={{
                    p: 0,
                    '&:hover': {
                      backgroundColor: 'transparent'
                    }
                  }}
                  onClick={addNew}
                >
                  Add new
                </Button>
              )}
            </Box>
            <Box component='ol' pl={2}>
              {list.reduce((acc, item) => {
                if (item.type === 'membership') {
                  return [...acc, (
                    <CredentialItem key={item.id} data={item} />
                  )];
                } else {
                  return acc;
                }
              }, [])}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mb: 1.5
          }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant='h5'>License:</Typography>
              {list.filter((item) => item.type === 'licence').length < 1 && (
                <Button
                  sx={{
                    p: 0,
                    '&:hover': {
                      backgroundColor: 'transparent'
                    }
                  }}
                  onClick={addNew}
                >
                  Add new
                </Button>
              )}
            </Box>
            <Box component='ol' pl={2}>
              {list.reduce((acc, item) => {
                if (item.type === 'licence') {
                  return [...acc, (
                    <CredentialItem key={item.id} data={item} />
                  )];
                } else {
                  return acc;
                }
              }, [])}
            </Box>
          </Box>
        </Box>
        {/* <Box
          sx={{
            mb: 1.5
          }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant='h5'>Certificate:</Typography>
              {list.filter((item) => item.type === 'certificate').length < 1 && (
                <Button
                  sx={{
                    p: 0,
                    '&:hover': {
                      backgroundColor: 'transparent'
                    }
                  }}
                  onClick={addNew}
                >
                  Add new
                </Button>
              )}
            </Box>
            <Box component='ol' pl={2}>
              {list.reduce((acc, item) => {
                if (item.type === 'certificate') {
                  return [...acc, (
                    <CredentialItem key={item.id} data={item} />
                  )];
                } else {
                  return acc;
                }
              }, [])}
            </Box>
          </Box>
        </Box> */}
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          variant='contained'
          sx={{
            minWidth: 150
          }}
          onClick={addNew}
        >
          Add New
        </Button>
      </Box>
    </Paper>
  )
}

export default ProfileCredentials;