import { useEffect, useState } from 'react';
import axios from 'axios';
import { googleApiKey } from '../constants/common';

let timer;

const useGeolocation = (zipCode) => {
  const [location, setLocation] = useState({});
  const [loading, setLoading] = useState(false);

  const handleGeocode = () => {
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${googleApiKey}`;
    
    axios
      .get(geocodeUrl)
      .then((response) => {
        if (response.data.status === 'OK') {
          const location = response.data.results[0].geometry.location;
          setLocation({
            latitude: location.lat,
            longitude: location.lng,
          });
        } else {
          setLocation({});
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error getting geocoding information:', error);
        // You might want to handle the error more gracefully (e.g., show an error message to the user)
      });
  };

  useEffect(() => {
    clearTimeout(timer);
    setLoading(true);

    timer = setTimeout(() => {
      if (zipCode) {
        handleGeocode();
      }
    }, 1000);

    return () => {
      clearTimeout(timer); // Cleanup the timeout on component unmount
    };
  }, [zipCode]);

  return { loading, location };
};

export default useGeolocation;
