import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import { ReactComponent as GlobeIcon } from '../../../assets/Icons/globe.svg';
import Filter from './Filter';
import CheckBox from '../../../components/CheckBox';
import { useDemographics } from '../../../contexts/Demographics';

const DemographicsFilter = ({ filters, setFilters, goBack, reset}) => {
    const {
        gendersList,
        racesList,
        religionsList,
        sexualOrientationsList,
        fetchGendersList,
        fetchRacesList,
        fetchReligionsList,
        fetchSexualOrientationsList,
    } = useDemographics();
    const [expanded, setExpanded] = useState(false);
    const [expandedRaces, setExpandedRaces] = useState(false);
    const [expandedSexualOrientation, setExpandedSexualOrientation] = useState(false);
    const [expandedReligion, setExpandedReligion] = useState(false);

    
    useEffect(()=>{
        if(goBack && reset){
            setExpanded(true)
            setExpandedRaces(true)
            setExpandedSexualOrientation(true)
            setExpandedReligion(true)
        }
    },[])

    function toggleExpand() {
        setExpanded((prevState) => !prevState)
    }

    function toggleExpandRaces() {
        setExpandedRaces((prevState) => !prevState)
    }

    function toggleExpandSexualOrientation() {
        setExpandedSexualOrientation((prevState) => !prevState)
    }

    function toggleExpandReligion() {
        setExpandedReligion((prevState) => !prevState)
    }

    function handleChange(ev, multiple) {
        setFilters((prevState) => {
            if (!multiple) {
                return {
                    ...prevState,
                    [ev.target.name]: ev.target.value
                }
            }
            const arr = (prevState[ev.target.name] || [])
            const exists = Boolean(arr.find((item) => item === ev.target.value))
            return {
                ...prevState,
                [ev.target.name]: (exists ? 
                    arr.filter((item) => item !== ev.target.value)
                :
                    [...arr, ev.target.value])
            }
        })
    }

    function handleCheckBox(ev) {
        const id =parseInt(ev.target.name)
        setFilters(filters=>{
            let updatedGenders
            if(filters?.gender_ids){
                if(!filters.gender_ids.includes(id)){
                    updatedGenders= [...filters.gender_ids,id]
                }else{
                    updatedGenders= filters.gender_ids.filter(item=>item !==id)
                }
            } else {
                updatedGenders= [id]
            }
            return {...filters,gender_ids:[...updatedGenders]}
        });
      }


    useEffect(() => {
        fetchGendersList();
        fetchRacesList();
        fetchReligionsList();
        fetchSexualOrientationsList();
    }, [])
    
    return (
        <Filter
            label='Provider Demographics'
            icon={<GlobeIcon />}
            isRigntIcon={true}
            goBack={goBack}
            reset={reset}
        >
            <Typography variant='body2' fontWeight={600}>Gender</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                    maxHeight: expanded ? 'auto' : 90,
                    overflow: 'hidden',
                }}
            >
               {
                    gendersList.map((item) => (
                            <CheckBox
                                label={item.name}
                                name={item.id}
                                key={item.id}
                                onChange={handleCheckBox}
                                checked={filters?.gender_ids?.length > 0 ? filters?.gender_ids?.includes(item.id) : false}
                             />  
                    ))}
                {/* {gendersList.reduce((acc, item) => {
                    const isChecked = Boolean(item.id.toString() === (filters.gender || '').toString())
                    if (isChecked) {
                        return [
                            (
                                <FormControlLabel
                                    key={item.id}
                                    label={item.name}
                                    sx={{
                                        gap: 1,
                                        mx: 0,
                                    }}
                                    control={
                                        <Radio
                                            onChange={(ev) => {
                                                handleChange(ev, false)
                                            }}
                                            value={item.id}
                                            name='gender'
                                            checked={true}
                                            sx={{
                                                p: 0
                                            }}
                                        />
                                    }
                                />
                            ),
                            ...acc
                        ]
                    }
                    return [
                        ...acc,
                        (
                            <FormControlLabel
                                key={item.id}
                                label={item.name}
                                sx={{
                                    gap: 1,
                                    mx: 0,
                                }}
                                control={
                                    <Radio
                                        onChange={(ev) => {
                                            handleChange(ev, false)
                                        }}
                                        value={item.id}
                                        name='gender'
                                        checked={false}
                                        sx={{
                                            p: 0
                                        }}
                                    />
                                }
                            />
                        )
                    ]
                }, [])} */}
            </Box>
            
            <Button
                sx={{
                    p: 0,
                    justifyContent: 'flex-start',
                    backgroundColor: 'transparent', 
                    '&:hover': {
                        backgroundColor: 'transparent', 
                    }
                }}
                onClick={toggleExpand}
                disableRipple
            >
                {expanded ? 'See less' : 'See more'}
            </Button>

            <Typography variant='body2' fontWeight={600}>Ethnicity</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                    maxHeight: expandedRaces ? 'auto' : 90,
                    overflow: 'hidden',
                }}
            >
                {racesList.reduce((acc, item) => {
                    const isChecked = Boolean((filters.races || []).find((data) => data.toString() === item.id.toString()))
                    if (isChecked) {
                        return [
                            (
                                <CheckBox
                                    key={item.id}
                                    label={item.name}
                                    onChange={(ev) => {
                                        handleChange(ev, true)
                                    }}
                                    value={item.id}
                                    name='races'
                                    checked={true}
                                />
                            ),
                            ...acc
                        ]
                    }
                    return [
                        ...acc,
                        (
                            <CheckBox
                                key={item.id}
                                label={item.name}
                                onChange={(ev) => {
                                    handleChange(ev, true)
                                }}
                                value={item.id}
                                name='races'
                                checked={false}
                            />
                        )
                    ]
                }, [])}
            </Box>
            
            <Button
                sx={{
                    p: 0,
                    justifyContent: 'flex-start',
                    backgroundColor: 'transparent', 
                    '&:hover': {
                        backgroundColor: 'transparent', 
                    }
                }}
                onClick={toggleExpandRaces}
                disableRipple
            >
                {expandedRaces ? 'See less' : 'See more'}
            </Button>

            <Typography variant='body2' fontWeight={600}>Sexual Orientation</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                    maxHeight: expandedSexualOrientation ? 'auto' : 90,
                    overflow: 'hidden',
                }}
            >
                 {sexualOrientationsList.reduce((acc, item) => {
                    const isChecked = Boolean((filters.sexualOrientationsList || []).find((data) => data.toString() === item.id.toString()))
                    if (isChecked) {
                        return [
                            (
                                <CheckBox
                                    key={item.id}
                                    label={item.name}
                                    onChange={(ev) => {
                                        handleChange(ev, true)
                                    }}
                                    value={item.id}
                                    name='sexualOrientationsList'
                                    checked={true}
                                />
                            ),
                            ...acc
                        ]
                    }
                    return [
                        ...acc,
                        (
                            <CheckBox
                                key={item.id}
                                label={item.name}
                                onChange={(ev) => {
                                    handleChange(ev, true)
                                }}
                                value={item.id}
                                name='sexualOrientationsList'
                                checked={false}
                            />
                        )
                    ]
                }, [])}
            </Box>
            
            <Button
                sx={{
                    p: 0,
                    justifyContent: 'flex-start',
                    backgroundColor: 'transparent', 
                    '&:hover': {
                        backgroundColor: 'transparent', 
                    }
                }}
                onClick={toggleExpandSexualOrientation}
                disableRipple
            >
                {expandedSexualOrientation ? 'See less' : 'See more'}
            </Button>

            <Typography variant='body2' fontWeight={600}>Religion</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                    maxHeight: expandedReligion ? 'auto' : 90,
                    overflow: 'hidden',
                }}
            >
                {religionsList.reduce((acc, item) => {
                    const isChecked = Boolean((filters.religions || []).find((data) => data.toString() === item.id.toString()))
                    if (isChecked) {
                        return [
                            (
                                <CheckBox
                                    key={item.id}
                                    label={item.name}
                                    onChange={(ev) => {
                                        handleChange(ev, true)
                                    }}
                                    value={item.id}
                                    name='religions'
                                    checked={true}
                                />
                            ),
                            ...acc
                        ]
                    }
                    return [
                        ...acc,
                        (
                            <CheckBox
                                key={item.id}
                                label={item.name}
                                onChange={(ev) => {
                                    handleChange(ev, true)
                                }}
                                value={item.id}
                                name='religions'
                                checked={false}
                            />
                        )
                    ]
                }, [])}
            </Box>
            
            <Button
                sx={{
                    p: 0,
                    justifyContent: 'flex-start',
                    backgroundColor: 'transparent', 
                    '&:hover': {
                        backgroundColor: 'transparent', 
                    }
                }}
                onClick={toggleExpandReligion}
                disableRipple
            >
                {expandedReligion ? 'See less' : 'See more'}
            </Button>

        </Filter>
    )
}

export default DemographicsFilter