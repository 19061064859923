import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import Filter from './Filter';
import { ReactComponent as DollarIcon } from '../../../assets/Icons/dollar-sign.svg';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';


/*
  UTILITY VARIABLES
*/

const fieldInputProps = {
  startAdornment: (
    <InputAdornment position="start">
      $
    </InputAdornment>
  ),
}

const sliderStyles = {
  py: 1,
  '.MuiSlider-rail': {
    backgroundColor: 'background.light',
    borderRadius: 1.5,
    height: 5,
    border: 0,
    opacity: 1,
  },
  '.MuiSlider-track': {
    backgroundColor: '#9BD0F6',
    borderRadius: 1.5,
    height: 5,
    border: 0,
  },
}

const fieldStyles = {
  'input:not(textarea)': {
    pt: { xs: 1.75, sm: 1.5 },
    pb: 1.5,
    fontSize: { xs: 12, sm: 14 },
    pl: 0,
    pr: 1,
    fontWeight: 700,
  },
  '.MuiOutlinedInput-root': {
    pl: 1,
    '.MuiInputAdornment-root': {
      mr: 1,
      '.MuiTypography-root': {
        fontWeight: 700,
        fontSize: 14,
        color: 'text.primary',
      },
    },
  },
}

const CustomTextField = ({ name, field, handler }) => {
  return (
    <TextField
      fullWidth
      sx={fieldStyles}
      name={name}
      value={field === 0 ? "" : field.toString()}
      onChange={handler}
      InputProps={fieldInputProps}
      type='number'
    />
  )
}

const FeesFilter = ({ setFilters, goBack, reset, setClearStatus, clearStatus, filters }) => {
  const [minFee, setMinFee] = useState(filters?.fees_min ?? 0);
  const [maxFee, setMaxFee] = useState(filters?.fees_max ?? 0);

  /*
    USE_EFFECT HOOKS
  */


  useEffect(() => {
    if (clearStatus) {
      setMinFee(0);
      setMaxFee(0);
      setClearStatus(false);
    }
  }, [clearStatus, setClearStatus]);

  useEffect(() => {
    setMinFee(filters?.fees_min ?? 0);
    setMaxFee(filters?.fees_max ?? 0);
  }, [filters?.fees_min, filters?.fees_max]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (minFee !== filters?.fees_min || maxFee !== filters?.fees_max) {
        setFilters((prevState) => ({
          ...prevState,
          fees_min: minFee || undefined,
          fees_max: maxFee || undefined,
        }));
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [minFee, maxFee, filters?.fees_min, filters?.fees_max, setFilters]);

  /*
    HANDLER FUNCTIONS
  */

  const handleMinFeeChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setMinFee(isNaN(value) ? 0 : value);
  };

  const handleMaxFeeChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setMaxFee(isNaN(value) ? 0 : value);
  };

  const handleSliderChange = (event, newValue) => {
    setMinFee(newValue[0]);
    setMaxFee(newValue[1]);
  };


  return (
    <Filter
      label='Session fees'
      icon={<DollarIcon />}
      isRigntIcon={true}
      goBack={goBack}
      reset={reset}
    >
      <Slider
        value={[minFee, maxFee]}
        onChange={handleSliderChange}
        valueLabelDisplay="auto"
        sx={sliderStyles}
        min={0}
        max={1000}
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CustomTextField name='fees_min' field={minFee} handler={handleMinFeeChange} />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField name='fees_max' field={maxFee} handler={handleMaxFeeChange} />
        </Grid>
      </Grid>
    </Filter>
  );
};

export default FeesFilter;
