import CloseIcon from "@mui/icons-material/Close";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from "@mui/material/IconButton";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

import BasicInfo from './components/BasicInfo';
import Contact from './components/Contact';

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch } from "react-hook-form";
import { ReactComponent as BuildingIcon } from '../../assets/Icons/building.svg';
import { ReactComponent as UploadIcon } from '../../assets/Icons/upload.svg';
import AboutMe from '../../components/BasicProfile/AboutMe';
import { LOADING, SUCCESS } from '../../constants/common';
import { imageTypes } from '../../constants/lists';
import { useAlert } from '../../contexts/Alert';
import { useGeneralProfile } from '../../contexts/Organization';
import { profileImageValidationSchema } from "../../validations/profileValidations";

const ProfileOrganization = () => {
  const { facilityInfo, fetchFacilityInfo, editFacilityPhoto, updatePhotoStatus, updateGeneralProfileState, removeFacilityProfilePhoto } = useGeneralProfile();
  const [profileImageURL, setProfileImageURL] = useState(null);
  const { sendAlert } = useAlert();

  const {
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: zodResolver(profileImageValidationSchema),
    mode: 'onChange',
    defaultValues: {
      main_image: facilityInfo.main_image || null,
    },
  });

  const formValues = useWatch({ control });

  useEffect(() => {
    setProfileImageURL(formValues.main_image);
  }, [formValues]);

  useEffect(() => {
    if (facilityInfo.main_image) {
      setValue('main_image', facilityInfo.main_image);
    }
  }, [facilityInfo.main_image]);

  const handleFileRead = (file) => {
    if (!file) {
      setProfileImageURL(null);
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      setProfileImageURL(event.target.result);
    };
    reader.onerror = () => {
      sendAlert('There is an issue while reading this file', 'error')
    };
    reader.readAsDataURL(file);
  };

  const handleDeleteImage = async () => {
    try {
      await removeFacilityProfilePhoto();
      setProfileImageURL(null);
    } catch {
      sendAlert('Something went wrong while removing profile picture', 'error');
    }
  }

  const handleImage = async (ev) => {
    if (ev.target.files && ev.target.files[0]) {
      const file = ev.target.files?.[0];

      if (!file) {
        setProfileImageURL(null);
        setValue('main_image', null);
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        errors['main_image'] = { message: 'The logo must be less than 5 MB.' };
        setProfileImageURL(null);
        setValue('main_image', null);
        return;
      }

      if (!imageTypes.includes(file.type)) {
        errors['main_image'] = { message: 'Please upload a valid image file.\n(jpeg, jpg, png, svg+xml).' };
        setProfileImageURL(null);
        setValue('main_image', null);
        return;
      }

      if (!file.type.startsWith('image/')) {
        setProfileImageURL(null);
        setValue('main_image', null);
        errors['main_image'] = { message: 'Please select a valid image file.' };
        return;
      }

      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        if (width < 300 || height < 300) {
          errors['main_image'] = { message: 'The image dimensions should be at least 300x300 pixels.' };
          setProfileImageURL(null);
          setValue('main_image', null);
          return;
        }

        if (width > 2000 || height > 2000) {
          errors['main_image'] = { message: 'The image dimensions should not exceed 2000x2000 pixels.' };
          setProfileImageURL(null);
          setValue('main_image', null);
          return;
        }
        setValue('main_image', file);
        errors['main_image'] = {};
        editFacilityPhoto(file);
        handleFileRead(file);
      }
      img.onerror = () => {
        errors['main_image'] = { message: 'There was an error loading the image. Please select a valid image file.' };
        setProfileImageURL(null);
        setValue('main_image', null);
        return;
      };
    }
  };

  useEffect(() => {
    fetchFacilityInfo();
  }, [])

  useEffect(() => {
    if (updatePhotoStatus === SUCCESS) {
      updateGeneralProfileState('updatePhotoStatus', '');
      fetchFacilityInfo();
    }
  }, [updatePhotoStatus])

  return (
    <Paper
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'border.dark'
          }}
        >
          <BuildingIcon width={24} height={24} />
        </Box>
        <Typography variant='h4' ml={1}>Organization Settings</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <BasicInfo />
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'border.main',
              p: 3,
              borderRadius: 2
            }}
          >
            <Typography variant='h5' mb={3}>Upload logo</Typography>
            {updatePhotoStatus === LOADING ? (
              <Box
                sx={{
                  border: '1px dashed',
                  borderColor: 'border.dark',
                  borderRadius: 1.5,
                  height: 145,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  position: 'relative',
                  '&:hover': {
                    backgroundColor: 'primary.light'
                  }
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box>
                <Box
                  sx={{
                    border: '1px dashed',
                    borderColor: 'border.dark',
                    borderRadius: 1.5,
                    overflow: 'hidden',
                    height: 158,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: profileImageURL ? 'transparent' : 'primary.light',
                    },
                  }}
                >
                  {profileImageURL ? (
                    <>
                      {/* Clickable Avatar */}
                      <label
                        style={{
                          width: '100%',
                          height: '100%',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative',
                        }}
                      >
                        <Avatar
                          src={profileImageURL}
                          sx={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: 0,
                          }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            textAlign: 'center',
                            position: 'absolute',
                            bottom: 0,
                            backgroundColor: 'rgba(0,0,0,0.4)',
                            width: '100%',
                            color: 'background.paper',
                          }}
                        >
                          Edit
                        </Typography>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                          style={{ display: 'none' }}
                          name="main_image"
                        />
                      </label>
                      {/* Delete Button */}
                      <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleDeleteImage}
                        sx={{
                          position: 'absolute',
                          top: 5,
                          right: 5,
                          width: 22,
                          height: 22,
                          backgroundColor: 'background.paper',
                          borderRadius: '50%',
                          '&:hover': {
                            backgroundColor: 'error.light',
                            color: 'error.main',
                          },
                          zIndex: 10,
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </>
                  ) : (
                    <label style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', width: '100%', height: '100%' }}>
                      <Button
                        startIcon={<UploadIcon />}
                        sx={{
                          p: 0,
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                        component="span"
                      >
                        Upload
                      </Button>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImage}
                        style={{ display: 'none', overflow: 'hidden' }}
                        name="main_image"
                      />
                    </label>
                  )}
                  {!!errors.main_image && (
                    <Typography variant="caption" color="error" sx={{ textAlign: 'center', p: 1 }}>
                      {errors.main_image?.message}
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
            <Box
              component='label'
            >
              <Box
                component='input'
                sx={{
                  display: 'none'
                }}
                type='file'
                accept='image/*'
                onChange={handleImage}
              />
              <Button
                fullWidth
                sx={{
                  backgroundColor: 'primary.light'
                }}
                component='span'
              >
                Upload
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Contact />
      <AboutMe />
    </Paper>
  )
}

export default ProfileOrganization;