import React from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import { postToList } from '../../constants/lists';

import { ReactComponent as ChevronDown } from '../../assets/Icons/chevron-down.svg';

function ListMenuItem({ data, Icon, handleSelect }) {
    function handleClick() {
        handleSelect(data.value)
    }
    return (
        <MenuItem
            onClick={handleClick}
            sx={{
                display: 'flex',
                gap: 0.5,
                alignItems: 'center'
            }}
        >
            <Box
                sx={{
                    color: 'border.dark',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Icon />
            </Box>
            {data.label}
        </MenuItem>
    )
}


const PostToMenu = ({ setState, state }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    function handleSelect(value) {
        setState((prevState) => ({
            ...prevState,
            visibility: value
        }))
        handleClose();
    }

    const { Icon, label, value } = postToList.find((item) => state.visibility === item.value) || {};

    return (
        <>
            <Button
                sx={{
                    p: 0,
                    backgroundColor: 'transparent',
                    color: 'text.primary',
                    alignItems: 'center',
                    '&:hover': {
                        backgroundColor: 'transparent'
                    },
                    '.MuiButton-startIcon': {
                        ml: 0,
                        mr: 0.5
                    },
                    '.MuiButton-endIcon': {
                        mr: 0,
                        ml: 0.5
                    }
                }}
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                disableRipple
                startIcon={(
                    <Box
                        sx={{
                            width: 18,
                            height: 18,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'border.dark'
                        }}
                    >
                        <Icon />
                    </Box>
                )}
                endIcon={(
                    <Box
                        sx={{
                            width: 20,
                            height: 20,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <ChevronDown />
                    </Box>
                )}
            >
                {value === 'for_me' ? 'Post' : 'Post to'} {label}
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {postToList.map(({ Icon, ...item }) => (
                    <ListMenuItem
                        key={item.value}
                        Icon={Icon}
                        data={item}
                        handleSelect={handleSelect}
                    />
                ))}
            </Menu>
        </>
    )
}

export default PostToMenu