import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useUserProfile } from '../../../../contexts/UserProfile';
import { ReactComponent as GridIcon } from '../../../../assets/Icons/grid-9.svg'
import GallerySlider from './GallerySlider';


function GalleryImage({ item, columns, index, openSlider, height }) {
  const { data } = useUserProfile();

  function hanldeActiveIndex() {
    if (openSlider)
      openSlider(index);
  }

  return (
    <Grid item xs={columns}>
      <Box
        component='img'
        src={item?.image_url}
        alt=''
        height={height}
        sx={{
          width: '100%',
          borderRadius: 1.5,
          boxShadow: '0px 0px 64px 0px rgba(0, 0, 0, 0.04)',
          cursor: data?.info?.gallery?.length > 5 ? 'pointer' : 'auto'
        }}
        onClick={hanldeActiveIndex}
      />
    </Grid>
  )
}

const Gallery = () => {
  const { data } = useUserProfile();
  const [activeIndex, setActiveIndex] = useState(0);
  const [open, setOpen] = useState(false);
  function handleOpenSlider(index = 0) {
    setActiveIndex(index);
    setOpen(true)
  }

  function handleCloseSlider() {
    setActiveIndex(0);
    setOpen(false)
  }

  function showAllPhotos() {
    handleOpenSlider();
  }

  return (
    <>
      <Grid container spacing={2} mb={2} position='relative'>
        {data?.info?.gallery?.length === 1 && (
          <GalleryImage
              item={data.info.gallery[0]}
              columns={12}
              height={444}
          />
        )}

        {data?.info?.gallery?.length === 2 && (
          <>
            {data?.info?.gallery.map((item) => (
              <GalleryImage
                item={item}
                key={item.id}
                columns={6}
                height={444}
              />
            ))}
          </>
        )}

        {data?.info?.gallery?.length === 3 && (
          <>
            {data?.info?.gallery.map((item) => (
              <GalleryImage
                item={item}
                key={item.id}
                columns={4}
                height={344}
              />
            ))}
          </>
        )}

        {data?.info?.gallery?.length > 3 && (
          <>
            <GalleryImage
                item={data.info.gallery[0]}
                columns={6}
                height={444}
                index={0}
                openSlider={handleOpenSlider}
            />
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {data?.info?.gallery?.slice(1, 5).map((item, index) => (
                  <GalleryImage
                    item={item}
                    key={item.id}
                    columns={6}
                    index={index + 1}
                    openSlider={handleOpenSlider}
                    height={212}
                  />
                ))}
              </Grid>
            </Grid>
          </>
        )}
        {data?.info?.gallery?.length > 5 && (
          <Button
            size='small'
            sx={{
              position: 'absolute',
              bottom: 24,
              right: 24,
              '.MuiButton-startIcon': {
                mr: 0.5
              },
              '&, &:hover': {
                backgroundColor: 'primary.light',
                color: 'primary.main',
              }
            }}
            startIcon={<GridIcon />}
            onClick={showAllPhotos}
          >
            Show all photo
          </Button>
        )}
      </Grid>
      {open && (
        <GallerySlider
          elements={data?.info?.gallery || []}
          activeIndex={activeIndex}
          onClose={handleCloseSlider}
        />
      )}
    </>
  )
}

export default Gallery