import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import TherapistCardInfo from "../../components/TherapistCardInfo";
import PostCard from "../../components/PostCard";
import { colleagues } from "../../constants/common";
import VerificationModal from "../../components/VerificationModal";
import paths from "../../constants/paths";
import { useParams } from "react-router-dom";
import { getPost, getSuggestedPeople } from "../../services/home.services";
import { useAlert } from "../../contexts/Alert";
import UserVerticalCard from "../../components/UserVerticalCard";
import { useNotifcations } from "../../contexts/Notifications";


const SpecificPostPage = () => {
  const [postData, setPostData] = useState(null);
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const { userInfo } = useNotifcations();
  const [user, setUser] = useState(null);
  const { post_id, comment_id } = useParams();
  const { sendAlert } = useAlert();

  useEffect(() => {
    if (post_id && comment_id) {
      getPost(post_id)
        .then((res) => {
          setPostData(res.data.data);
          const comment = res.data.data.comments.find((item) => {
            return item.id == comment_id;
          });
          setUser({ ...comment.user, connected: comment.connected });
        })
        .catch((error) => {
          sendAlert(error.response?.data?.message, "error");
        });
    } else if (post_id) {
      getPost(post_id)
        .then((res) => {
          setPostData(res.data.data);
        })
        .catch((error) => {
          sendAlert(error.response?.data?.message, "error");
        });
    }
  }, [post_id]);

  useEffect(() => {
    getSuggestedPeople()
      .then((res) => {
        setSuggestedUsers(res.data.data.data);
      })
      .catch((error) => {
        sendAlert(error.response?.data?.message, "error");
      });
  }, []);


  useEffect(()=>{
    setUser(userInfo)
  },[userInfo])

  return (
    <Container>
      <Grid container spacing={{ xs: 0, md: 3 }}>
        <Grid item xs={12} md={2.5}>
          <Box
            sx={{
              position: "sticky",
              top: 80,
              display: "flex",
              flexDirection: "column",
              gap: 2.5,
            }}
          >
            {user && <TherapistCardInfo data={user} />}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {postData ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <PostCard data={postData} />
              {/* <>
                      {suggestPeople.length > 0 && (
                        <Paper
                          sx={{
                            boxShadow: "none",
                            borderColor: "border.main",
                            p: 3,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                          }}
                        >
                          <Typography variant="h5">
                            Grow your network
                          </Typography>
                          {suggestPeople.map((item) => (
                            <UserVerticalCard key={item.id} data={item} />
                          ))}
                          <Link
                            href={paths.network(colleagues)}
                            variant="body1"
                            fontWeight={600}
                            color="text.primary"
                          >
                            Find more specialized therapists
                          </Link>
                        </Paper>
                      )}
                      <PostForm />
                    </> */}
            </Box>
          ) : null}
          <Paper
            sx={{
              boxShadow: "none",
              borderColor: "border.main",
              p: 3,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {suggestedUsers.length
              ? suggestedUsers.map((item, index) => {
                  return <UserVerticalCard key={item.id} data={item} />;
                })
              : null}
            <Link
              href={paths.network(colleagues)}
              variant="body1"
              color="primary"
            >
              Find more specialized therapists
            </Link>
          </Paper>
        </Grid>
      </Grid>
      <VerificationModal />
    </Container>
  );
};

const tableCellStyle = {
  fontSize: 14,
};
export default SpecificPostPage;

/*
  <div
  key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  marginLeft: 8,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Avatar alt="Remy Sharp" src={notificationUser} />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "16px",
                  }}
                >
                  <Typography variant="body1" style={tableCellStyle}>
                    Kathryn Dann
                  </Typography>
                  <Typography variant="body1" style={tableCellStyle}>
                    Bowen Family Systems Therapy
                  </Typography>
                </div>
              </div>
              <Button
                variant="outlined"
                style={{ borderRadius: "25px" }}
                onClick={connect}
                startIcon={<AddIcon />}
              >
                Connect
              </Button>
            </div> */
