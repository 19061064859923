import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { ReactComponent as CloseIcon } from "../../assets/Icons/close.svg";
import { ReactComponent as PhoneIcon } from '../../assets/Icons/phone.svg'
import { ReactComponent as GlobeIcon } from '../../assets/Icons/globe.svg'
import { ReactComponent as InboxIcon } from '../../assets/Icons/inbox.svg'
import { ReactComponent as LocationIcon } from '../../assets/Icons/location.svg'
import { LOADING, SUCCESS, treatment_program } from "../../constants/common";
import LoadingButton from "@mui/lab/LoadingButton";
import { useUserProfile } from "../../contexts/UserProfile";
import { useAuth } from "../../contexts/Auth";
import { validateAbout, validateEmail, validateSubject } from "../../helper/Validations";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 814,
  p: 0,
  overflow: 'hidden',
};

function SendToFriendModal({ open, onClose, data }) {
  const initialState = {
    email: '',
    message: '',
    subject: ''
  }
  const [state, setState] = useState(initialState);
  const [isInvalid, setIsInvalid] = useState(false);
  const { sendEmailToFriend, sendToFriendStatus, updateUserProfileState } = useUserProfile();
  const { account_type } = useAuth()
  const userData = data?.connected_user || data?.favorite_user || data;

  const handleClose = () => {
    onClose();
    setState(initialState);
    setIsInvalid(false);
  }

  const handleInput = (ev) => {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value
    }))
  }

  const isValid = () => {
    return validateEmail(state.email)
      && validateAbout(state.message)
      && validateSubject(state.subject)
  }

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (isValid()) {
      sendEmailToFriend({
        ...state,
        account_type: account_type === treatment_program ? treatment_program : 'user',
        uuid: userData?.uuid
      })
    } else {
      setIsInvalid(true);
    }
  }

  useEffect(() => {
    if (sendToFriendStatus === SUCCESS) {
      updateUserProfileState('sendToFriendStatus', '')
      handleClose()
    }
  }, [sendToFriendStatus])


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pt: 3,
            px: 3,
            pb: 1.5
          }}
        >

          <Typography variant='h4'>Share contact with:</Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              p: 0.25,
              color: 'border.dark'
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          component='form'
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
            px: 3,
            pb: 3
          }}
        >
          <TextField
            variant="outlined"
            label='Send to:'
            name='email'
            onChange={handleInput}
            value={state.email}
            error={isInvalid && !validateEmail(state.email)}
            helperText={
              isInvalid &&
              !validateEmail(state.email) &&
              "Please add valid email"
            }
            InputProps={{
              sx: {
                '&.Mui-focused': {
                  fieldset: {
                    border: '1px solid !important',
                    borderColor: '#A3A6A8 !important',
                  }
                },
                '&:hover': {
                  fieldset: {
                    border: '1px solid !important',
                    borderColor: '#A3A6A8 !important',
                  }
                },
              }
            }}
          />
          <TextField
            variant="outlined"
            label='Subject:'
            name='subject'
            onChange={handleInput}
            value={state.subject}
            error={isInvalid && !validateSubject(state.subject)}
            helperText={
              isInvalid &&
              !validateSubject(state.subject) &&
              "Please enter valid subject of maximum 200 characters"
            }
            InputProps={{
              sx: {
                '&.Mui-focused': {
                  fieldset: {
                    border: '1px solid !important',
                    borderColor: '#A3A6A8 !important',
                  }
                },
                '&:hover': {
                  fieldset: {
                    border: '1px solid !important',
                    borderColor: '#A3A6A8 !important',
                  }
                },
              }
            }}
          />
          <Box
            sx={{
              border: '1px solid',
              borderTop: 0,
              borderColor: 'secondary.main',
              borderRadius: 1.5
            }}
          >
            <TextField
              multiline
              label='Message:'
              fullWidth
              name='message'
              onChange={handleInput}
              value={state.message}
              error={isInvalid && !validateAbout(state.message)}
              helperText={
                isInvalid &&
                !validateAbout(state.message) &&
                "Please add valid message"
              }
              InputProps={{
                sx: {
                  pb: 1,
                  '&.Mui-focused': {
                    fieldset: {
                      border: '1px solid !important',
                      borderColor: '#A3A6A8 !important',
                      borderBottom: '0!important',
                      borderLeft: '0!important',
                      borderRight: '0!important',
                    }
                  },
                  '&:hover': {
                    fieldset: {
                      border: '1px solid !important',
                      borderColor: '#A3A6A8 !important',
                      borderBottom: '0!important',
                      borderLeft: '0!important',
                      borderRight: '0!important',
                    }
                  },
                  fieldset: {
                    borderBottom: 0,
                    borderLeft: 0,
                    borderRight: 0,

                  }
                }
              }}
            />
            <Box
              sx={{
                borderRadius: 2,
                border: '1px solid #9BD0F6',
                backgroundColor: '#F3F9FD',
                py: 1.5,
                px: 3,
                mx: 1.75,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                }}
              >
                <Avatar
                  sx={{
                    width: 50,
                    height: 50,
                  }}
                  src={data?.main_image}
                />
                <Box
                  sx={{
                    flexGrow: 1
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 1,
                    }}
                  >
                    <Box>
                      <Typography variant='body2' textTransform='capitalize' fontWeight={600}>{userData?.info?.title} {userData?.first_name} {userData?.last_name}</Typography>
                      {userData?.info?.headline && <Typography variant='body2'>{userData?.info?.headline}</Typography>}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      columnGap: 2,
                      pt: 0.25,
                      rowGap: 0.25
                    }}
                  >
                    {userData?.info?.phone_number && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1.5,
                        }}
                      >
                        <Box
                          sx={{
                            width: 22,
                            height: 26,
                            display: 'flex',
                            alignItems: 'center',
                            color: 'text.secondary'
                          }}
                        >
                          <PhoneIcon />
                        </Box>
                        <Typography
                          variant='body2'
                        >
                          {userData?.info?.phone_number}
                        </Typography>
                      </Box>
                    )}
                    {Boolean(userData?.email) && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1.5,
                        }}
                      >
                        <Box
                          sx={{
                            width: 22,
                            height: 26,
                            display: 'flex',
                            alignItems: 'center',
                            color: 'text.secondary'
                          }}
                        >
                          <InboxIcon />
                        </Box>
                        <Typography
                          variant='body2'
                        >
                          {userData?.email}
                        </Typography>
                      </Box>
                    )}
                    {userData?.info?.website_link && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1.5,
                        }}
                      >
                        <Box
                          sx={{
                            width: 22,
                            height: 26,
                            display: 'flex',
                            alignItems: 'center',
                            color: 'text.secondary'
                          }}
                        >
                          <GlobeIcon />
                        </Box>
                        <Link
                          variant='body2'
                          color='text.primary'
                          overflow='hidden'
                          textOverflow='ellipsis'
                          href={userData?.info?.website_link}
                          target='_blank'
                        >
                          {userData?.info?.website_link}
                        </Link>
                      </Box>
                    )}
                    {(Boolean((userData?.address || userData?.info?.address)?.city) || Boolean((userData?.address || userData?.info?.address)?.address1)) && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1.5,
                        }}
                      >
                        <Box
                          sx={{
                            width: 22,
                            height: 26,
                            display: 'flex',
                            alignItems: 'center',
                            color: 'text.secondary'
                          }}
                        >
                          <LocationIcon />
                        </Box>
                        <Typography variant='body2'>{(userData?.address || userData?.info?.address)?.city}{(userData?.address || userData?.info?.address)?.address1 && `, ${(userData?.address || userData?.info?.address)?.address1}`}</Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <LoadingButton
              variant="contained"
              sx={{
                minWidth: 218
              }}
              loading={sendToFriendStatus === LOADING}
              type='submit'
            >
              Send
            </LoadingButton>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
}

export default SendToFriendModal;