import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAlert } from './Alert';
import CredentialsDrawer from '../components/CredentialsDrawer';
import { ERROR, LOADING, SUCCESS } from '../constants/common';
import { createCredential, deleteCredential, getCredentials, updateCredential } from '../services/credentials.services';
import ConfirmationModal from '../components/ConfirmationModal';


const CredentialsContext = createContext({});

export const CredentialsContextProvider = ({ children }) => {
    const { sendAlert } = useAlert();
    const initialState = {
        fetchStatus: '',
        list: [],
        createStatus: '',
        updateStatus: '',
        deleteStatus: ''
    }

    const [credentailsState, setCredentailsState] = useState(initialState);
    const [activeCredential, setActiveCredential] = useState({});
    const [open, setOpen] = useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    
    function handleClose() {
        setOpen(false)
    }

    function handleOpen(data) {
        setActiveCredential(data);
        setOpen(true)
    }

    function handleOpenConfirmationModal() {
        setOpenConfirmationModal(true)
    }
    
    function handleCloseConfirmationModal() {
        setOpenConfirmationModal(false)
    }

    function updateCredentialstate(obj) {
        setCredentailsState((prevState) => ({
            ...prevState,
            ...obj
        }))
    }

    async function fetchCredentials() {
        updateCredentialstate({ fetchStatus: LOADING })
        try {
            const response = await getCredentials();
            updateCredentialstate({
                list: response.data.data,
                fetchStatus: SUCCESS
            })
        } catch (error) {
            updateCredentialstate({ fetchStatus: ERROR })
            Promise.reject(error)
        }
    }

    async function generateCredential(data) {
        updateCredentialstate({ createStatus: LOADING })
        try {
            await createCredential(data);
            updateCredentialstate({
                createStatus: SUCCESS
            })
            sendAlert('Created successfully', 'success')
        } catch (error) {
            updateCredentialstate({ createStatus: ERROR })
            sendAlert(error.response?.data?.message, 'error')
            Promise.reject(error)
        }
    }

    async function editCredential(data) {
        updateCredentialstate({ updateStatus: LOADING })
        try {
            await updateCredential(data);
            updateCredentialstate({
                updateStatus: SUCCESS
            })
            sendAlert('Updated successfully', 'success')
        } catch (error) {
            updateCredentialstate({ updateStatus: ERROR })
            sendAlert(error.response?.data?.message, 'error')
            Promise.reject(error)
        }
    }

    async function removeCredential(data) {
        updateCredentialstate({ deleteStatus: LOADING })
        try {
            await deleteCredential(data);
            updateCredentialstate({
                deleteStatus: SUCCESS
            })
            sendAlert('Removed successfully', 'success')
        } catch (error) {
            updateCredentialstate({ deleteStatus: ERROR })
            sendAlert(error.response?.data?.message, 'error')
            Promise.reject(error)
        }
    }

    function removeCredentialFunction() {
        removeCredential({ credential_id: activeCredential?.id })
    }

    useEffect(() => {
        if (credentailsState.deleteStatus === 'SUCCESS') {
            updateCredentialstate('deleteStatus', '');
            setActiveCredential(undefined);
            handleCloseConfirmationModal();
            handleClose();
            fetchCredentials();
        }
    }, [credentailsState.deleteStatus])

    const state = {
        ...credentailsState,
        open,
        handleClose,
        handleOpen,
        fetchCredentials,
        generateCredential,
        editCredential,
        removeCredential,
        openConfirmationModal: handleOpenConfirmationModal,
        updateCredentialstate
    };

    return (
        <CredentialsContext.Provider value={state}>
            {children}

            <CredentialsDrawer
                onClose={handleClose}
                open={open}
                activeCredential={activeCredential}
            />
            <ConfirmationModal
                open={openConfirmationModal}
                onClose={handleCloseConfirmationModal}
                message={`Are you sure you want to delete ${activeCredential?.data?.title}?`}
                deleteFunction={removeCredentialFunction}
                loading={credentailsState.deleteStatus === LOADING}
            />
        </CredentialsContext.Provider>
    );
};

export const useCredentials = () => useContext(CredentialsContext);