import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { useBasicInfo } from '../../../../contexts/BasicInfo';
import { LOADING, SUCCESS } from '../../../../constants/common';
import PhoneNumberInput from '../../../PhoneNumberInput';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { contactValidation } from '../../../../validations/profileValidations';

const ContactDrawer = ({ onClose, open }) => {
  const { editContact, updateInfoState, updateContactStatus, fetchInfo, data } = useBasicInfo();
  const initialState = {
    phone_number: '',
    business_number: '',
    contact_headline: '',
  }
  const [state, setState] = useState(initialState);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
    setValue,
  } = useForm({
    resolver: zodResolver(contactValidation),
    mode: "onChange",
    defaultValues: {
      phone_number: data?.info?.phone_number || '',
      business_number: data?.info?.business_number || '',
    },
  });


  useEffect(() => {
    if (updateContactStatus === SUCCESS) {
      updateInfoState('updateContactStatus', '')
      fetchInfo();
      handleClose();
    }
  }, [updateContactStatus]);

  useEffect(() => {
    if (data && open) {
      setValue('phone_number', data?.info?.phone_number)
      setValue('phone_number', data?.info?.phone_number)
      setState({ phone_number: data?.info?.phone_number, business_number: data?.info?.business_number })
    }
  }, [data, open]);


  const handleClose = () => {
    setState(initialState);
    onClose();
  }

  const handlePhoneNumberInput = (fomattedNumber) => {
    setValue('phone_number', fomattedNumber.phone_number);
    setState((prev) => ({ ...prev, phone_number: fomattedNumber.phone_number }))
  }

  const handleBusinessNumberInput = (fomattedNumber) => {
    setValue('business_number', fomattedNumber.business_number);
    setState((prev) => ({ ...prev, business_number: fomattedNumber.business_number }))
  }

  const onSubmit = () => {
    if (isValid)
      editContact(state);
  }


  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Contact</Typography>
          <Divider sx={{ mb: 4 }} />
          <PhoneNumberInput
            fullWidth
            variant='outlined'
            control={control}
            sx={{ mb: 3 }}
            name='phone_number'
            label='Phone Number'
            value={state.phone_number}
            onChange={handlePhoneNumberInput}
            error={!!errors.phone_number}
            helperText={errors.phone_number?.message}
            trigger={trigger}
          />

          <TextField
            fullWidth
            variant='outlined'
            label='Email Address'
            sx={{ mb: 3 }}
            name='email'
            value={data.email}
            disabled
          />
          <PhoneNumberInput
            fullWidth
            variant='outlined'
            control={control}
            sx={{ mb: 3 }}
            label='Business Number'
            name='business_number'
            value={state.business_number}
            onChange={handleBusinessNumberInput}
            error={!!errors.business_number}
            helperText={errors.business_number?.message}
            trigger={trigger}
          />

        </Box>

        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            type='submit'
            loading={updateContactStatus === LOADING}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default ContactDrawer;