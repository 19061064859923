import React, { useEffect, useState } from 'react'
import Availability from '../../components/Availability';
import { useBasicInfo } from '../../contexts/BasicInfo';
import { SUCCESS, treatment_program } from '../../constants/common';
import { useAuth } from '../../contexts/Auth';
import { useGeneralProfile } from '../../contexts/Organization';

const ProfileAvailability = () => {
  const { fetchInfo, updateAvailabilityStatus, editAvailability, updateInfoState, data } = useBasicInfo();
  const { fetchFacilityInfo, facilityInfo } = useGeneralProfile();
  const { account_type } = useAuth();

  const initialState = {
    office_hours_day: [],
    office_hours_time: [],
  }

  const [state, setState] = useState(initialState);
  const [error, setError] = useState(false);

  function setData(obj) {
    setState((prevState) => ({
      ...prevState,
      ...obj
    }))
  }

  function onSave() {
    if (!state?.office_hours_day?.length) {
      setError(true);
      return;
    }
    if (!state?.office_hours_time?.length) {
      setError(true);
      return;
    }
    editAvailability({
      office_hours_day: state.office_hours_day?.join(','),
      office_hours_time: state.office_hours_time?.join(','),
      account_type: account_type === treatment_program ? account_type : 'user'
    });
  }

  function handleAvailability(ev) {
    const frameAvalibility = state[ev.target.name] || [];
    if (frameAvalibility.includes(ev.target.value)) {
      setState(prev => ({
        ...prev,
        [ev.target.name]: frameAvalibility.filter(
          (item) => item !== ev.target.value
        ),
      }));
      // setError(false);
    } else {
      setState(prev => ({
        ...prev,
        [ev.target.name]: [...frameAvalibility, ev.target.value],
      }));
      // setError(false);
    }
  }

  const info = account_type === treatment_program ? facilityInfo : data;

  useEffect(() => {
    if (account_type === treatment_program)
      fetchFacilityInfo();
    else
      fetchInfo();
  }, [])

  useEffect(() => {
    if (data || facilityInfo) {
      const officeHoursDay = info?.info?.office_hours_day;
      const officeHoursTime = info?.info?.office_hours_time;
  
      setState((prevState) => ({
        ...prevState,
        office_hours_day: officeHoursDay ? officeHoursDay.split(',') : [],
        office_hours_time: officeHoursTime ? officeHoursTime.split(',') : [],
      }));
    }
  }, [data, facilityInfo]);  

  useEffect(() => {
    if (updateAvailabilityStatus === SUCCESS) {
      updateInfoState('updateAvailabilityStatus', '')
      if (account_type === treatment_program)
        fetchFacilityInfo();
      else
        fetchInfo();
    }
  }, [updateAvailabilityStatus, account_type])

  return (
    <Availability
      widthIcon
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
      onSave={onSave}
      error={error}
      setError={setError}
      setData={setData}
      data={state}
      handleAvailability={handleAvailability}

    />
  )
}

export default ProfileAvailability;