import { axiosInstance } from "./axiosInstance";

const getInfo = (data) => {
  return axiosInstance.get('info/full', data);
}

const updateBasicInfo = (data) => {
  return axiosInstance.post('info/basic', data);
}

const updateAboutMe = (data) => {
  return axiosInstance.post('info/about-me', data);
}

const updateContact = (data) => {
  return axiosInstance.post('info/contact', data);
}

const updateOfficeInfo = (data) => {
  return axiosInstance.post('info/office', data);
}

const updateAddress = (data) => {
  return axiosInstance.post('address', data);
}

const updateLinksInfo = (data) => {
  return axiosInstance.post('info/links', data);
}

const updateProfilePhoto = (payload) => {
  const data = new FormData();
  data.append('avatar', payload);
  return axiosInstance.post('profile/avatar', data);
}

const deleteProfilePhoto = () => {
  return axiosInstance.post('profile/destroy_avatar');
}

const updateAvailability = (data) => {
  return axiosInstance.put('availability', data);
}

export {
  getInfo,
  updateBasicInfo,
  updateAboutMe,
  updateContact,
  updateOfficeInfo,
  updateAddress,
  updateLinksInfo,
  updateProfilePhoto,
  deleteProfilePhoto,
  updateAvailability
}