import React from 'react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import { useHomePage } from '../../contexts/HomePage'

import paths from '../../constants/paths'
import ConnectionCard from './ConnectionCard'
import { connections } from '../../constants/common'

const ConnectionsCard = () => {
    const { suggestPeople } = useHomePage();
    return (
        <Paper
            sx={{
                py: 3
            }}
        >
            <Box>
                <Typography variant='captionTitle' color='text.secondary' px={3} mb={2} component='h5' fontWeight='normal'>Add to your Network</Typography>
                
                <Box
                    sx={{
                        px: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2
                    }}
                >
                    {suggestPeople.map((item) => (
                        <ConnectionCard key={item.id} data={item} />
                    ))}
                    
                    <Divider sx={{ borderBottomWidth: 2 }} />

                    <Button
                        variant='outlined'
                        color='secondary'
                        size='small'
                        fullWidth
                        sx={{
                            color: 'primary.main'
                        }}
                        href={paths.network(connections)}
                    >
                        See more
                    </Button>
                </Box>
            </Box>
        </Paper>
    )
}

export default ConnectionsCard