import React, { useRef, useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Link';

const ReadMore = ({ text, linesToShow, lineHeight }) => {
    const textRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(true);
    const [isOverflown, setIsOverflown] = useState(false);

    useEffect(() => {
        const textElement = textRef.current;
        if (textElement) {
            const lines = textElement.scrollHeight / parseInt(lineHeight, 10);
            setIsOverflown(lines > linesToShow);
        }
    }, [text, linesToShow, lineHeight]);

    const toggleTruncate = () => {
        setIsTruncated((prevState) => !prevState);
    };

    return (
        <Typography
            variant='body1'
            ref={textRef}
            sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: isTruncated ? linesToShow : 'unset',
                WebkitBoxOrient: 'vertical',
                mb: 1,
                position: 'relative',
                backgroundColor: 'inherit'
            }}
        >
            {text}{' '}
            {isOverflown && (
                <Link
                    component='button'
                    onClick={toggleTruncate}
                    sx={isTruncated ? {
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'inherit'
                    } : {}}
                >
                    {isTruncated ? <><Box component='span' color='text.primary'>..</Box> Read more</> : 'Read less'}
                </Link>
            )}
        </Typography>
    );
}

export default ReadMore;