import React from 'react';
import {
  ClipLoader,
  BounceLoader,
  BeatLoader,
  RingLoader,
} from 'react-spinners';

const loaderTypes = {
  clip: ClipLoader,
  bounce: BounceLoader,
  beat: BeatLoader,
  ring: RingLoader,
};

const Loader = ({
  type = 'clip',
  size = 50,
  color = '#36d7b7',
  loading = true,
  speedMultiplier = 1,
  style = {},
}) => {
  const SelectedLoader = loaderTypes[type] || ClipLoader;

  if (!loading) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <SelectedLoader
        size={size}
        color={color}
        loading={loading}
        speedMultiplier={speedMultiplier}
      />
    </div>
  );
};

export default Loader;
