import { convertObjectToFormData } from "../utils/helper";
import { axiosInstance } from "./axiosInstance";

const login = (data) => {
    return axiosInstance.post('login', data);
}

const verifyCode = (data) => {
    return axiosInstance.post('verify', data);
}

const register = (data) => {
    return axiosInstance.post('register', data);
}

const registerFacility = (data) => {
    return axiosInstance.post('register/treatment-program', convertObjectToFormData(data));
}

const subscriptionPlans = (data) => {
    return axiosInstance.get(data?.isSpecial ? 'tp-plans' : 'plans');
}

const subscriptionConfirm = (data) => {
    return axiosInstance.post('subscription/confirm', data);
}

const forgetPassword = (data) => {
    return axiosInstance.post('init-reset-password', data);
}

const resetPassword = (data) => {
    return axiosInstance.post('reset-password', data);
}

const resetPasswordLinkCheck = (data) => {
    return axiosInstance.get(`reset-password-link-expiry?email=${data?.email}&token=${data?.token}`);
}

const deleteRegistration = (uuid) => {
    return axiosInstance.delete('remove-registration', { params: { uuid }});
}

const resendEmail = (data) => {
    return axiosInstance.post(`email/verify/${data.email}`);
}

const resendCode = (data) => {
    return axiosInstance.post(`resend-code`, data);
}

const validateLinkToken = (token) => {
    return axiosInstance.get('invitations/validate-link', { params: { token } });
}

const changePassword = ({ password }) => {
    return axiosInstance.put('password/change', {password});
}


export {
    login,
    register,
    subscriptionPlans,
    subscriptionConfirm,
    forgetPassword,
    deleteRegistration,
    resetPassword,
    resetPasswordLinkCheck,
    registerFacility,
    resendEmail,
    resendCode,
    validateLinkToken,
    changePassword,
    verifyCode
}