import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import { ReactComponent as RightArrow } from "../../assets/Icons/chevron-right.svg";
import paths from "../../constants/paths";
import { useParams } from "react-router-dom";

const BreadCrumb = ({ currentPage, currentPageTitle, buttonProps }) => {
  const { id } = useParams();
  return (
    <Box
      sx={{
        height: 66,
      }}
    >
      <AppBar
        sx={{
          top: 56,
          width: "100%",
          border: 0,
          borderRadius: 0,
          borderTop: "1px solid",
          borderBottom: "1px solid",
          borderColor: "border.main",
          backgroundColor: "background.paper",
          height: 66,
          boxShadow: "none",
          zIndex: 998,
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                py: 1,
                flexGrow: 1,
              }}
            >
              <Breadcrumbs
                separator={
                  <Box
                    sx={{
                      color: "border.dark",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <RightArrow width={5} />
                  </Box>
                }
                aria-label="breadcrumb"
              >
                <Link
                  variant="caption"
                  color="text.secondary"
                  href={paths.home}
                >
                  Home
                </Link>
                <Typography color="text.secondary" variant="caption">
                  {currentPage}
                </Typography>
              </Breadcrumbs>
              <Typography
                variant="h4"
                color="text.primary"
                component="span"
                textTransform="capitalize"
                sx={{ fontSize: "24px", fontWeight: "500" }}
              >
                {currentPageTitle || id || currentPage}
              </Typography>
            </Box>
          </Box>
          {Boolean(buttonProps) && (
            <Button
              sx={{
                backgroundColor: "primary.light",
                color: "primary.main",
              }}
              {...(buttonProps.path
                ? { href: buttonProps.path }
                : { onClick: buttonProps.onClick })}
              size="small"
            >
              {buttonProps.text}
            </Button>
          )}
          {currentPage === "Notifications" && (
            <Box>
              <Link
                href={paths.settingsNotifications}
                variant="body1"
                color="primary"
              >
                Notification settings
              </Link>
            </Box>
          )}
        </Container>
      </AppBar>
    </Box>
  );
};

export default BreadCrumb;
