import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import { ReactComponent as ListIcon } from '../../assets/Icons/list.svg'
import { ReactComponent as EditIcon } from '../../assets/Icons/edit-3.svg'
import InterestsDrawer from '../../components/InterestsDrawer';
import { useInterests } from '../../contexts/Interests';
import { LOADING } from '../../constants/common';


const ProfileInterests = () => {
    const { fetchInterests, fetchInterestsStatus, interests } = useInterests();
    const [open, setOpen] = useState(false);

    function handleOpenDrawer() {
        setOpen(true)
    }

    function handleCloseDrawer() {
        setOpen(false)
    }

    useEffect(() => {
        fetchInterests();
    }, [])

    const isEmpty = interests.length === 0;
    
    return (
        <>
            <Paper
                sx={{
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <ListIcon width={24} height={24} />
                        <Typography variant='h4' ml={1}> Interests</Typography>
                    </Box>
                    {isEmpty ? (
                        <Button
                            variant='contained'
                            size='large'
                            sx={{ minWidth: 150 }}
                            onClick={handleOpenDrawer}
                        >
                            Add New
                        </Button>
                    ) : (
                        <IconButton
                            sx={{
                                p: 0.75
                            }}
                            onClick={handleOpenDrawer}
                        >
                            <EditIcon />
                        </IconButton>
                    )}
                    
                    
                </Box>
                {!isEmpty && (
                    <Divider />
                )}

                {fetchInterestsStatus === LOADING && (
                    <>
                        <Skeleton animation='wave' />
                        <Skeleton animation='wave' />
                        <Skeleton animation='wave' />
                    </>
                )}
                {interests.map((item) => (
                    <Box key={item.id}>
                        <Typography variant='h5' mb={1}>{item.title}</Typography>
                        <Typography variant='body1'>{item.body}</Typography>
                    </Box>
                ))}
            </Paper>

            <InterestsDrawer
                open={open}
                onClose={handleCloseDrawer}
            />
        </>
    )
}

export default ProfileInterests;